@charset "UTF-8";

/* GNB */
.c-trip-gnb {
    position: relative;
    height: 70px;
    font-family: $font-family !important;
    letter-spacing: 0;
    background-color: hsl(233, 49%, 55%);
    z-index: 200;
    transform: translateZ(0);

    &::after {
        content: "";
        display: none;
        position: absolute;
        top: 70px;
        left: 0;
        width: 100%;
        height: 460px;
        background-color: $color-gray-14;
        border-bottom: 1px solid #5462c5;
    }

    &.trip-gnb-menu__button--selected {
        &::after {
            display: block;
        }

        .trip-gnb-menu {
            &::before {
                content: "";
                position: absolute;
                left: -3000px;
                top: 0;
                width: 3150px;
                height: 100%;
                background: #3743ae;
            }

            &__all {
                display: block;
                position: absolute;
                top: 70px;
                left: 0;
                z-index: 10;
            }
        }

        .trip-gnb-menu__button {
            > button {
                &::after {
                    border-width: 0px 5px 6px 5px;
                }
            }
        }
    }

    .inner {
        @include clearfix;
        position: relative;
        width: 1240px;
        height: 70px;
        margin: 0 auto;
    }

    .trip-gnb-menu {
        float: left;
        width: 151px;

        &__button {
            > button {
                position: relative;
                width: 100%;
                height: 70px;
                padding: 0 47px 0 0;
                font-size: 20px;
                font-family: $font-family;
                color: #fffefe;
                line-height: 30px;
                border-right: solid 1px rgba(0, 0, 0, 0.2);
                box-sizing: border-box;
                letter-spacing: 0;

                em {
                    font-family: $font-family-lato;
                }

                &:after {
                    content: "";
                    position: absolute;
                    right: 27px;
                    top: 32px;
                    width: 0;
                    border-style: solid;
                    border-width: 6px 5px 0;
                    border-color: #fff transparent;
                }
            }
        }

        &__all {
            display: none;
            position: relative;
            padding: 16px 0;
            width: 1240px;
            height: 428px;
            background: $color-gray-14;

            dl {
                @include clearfix;
                padding-bottom: 16px;
            }

            .trip-gnb-menu__group {
                float: left;
                padding: 0 20px 8px 20px;
                margin-left: 10px;
                width: 200px;
                min-height: 138px;
                max-height: 242px;

                dt {
                    width: 200px;
                    padding: 10px 0;
                    border-bottom: 2px solid $color-gray-01;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 24px;
                    color: $color-gray-03;
                }

                dd {
                    margin-top: 8px;
                    @include text-ellipsis;

                    a {
                        font-weight: normal;
                        font-size: 15px;
                        line-height: 20px;
                        color: $color-gray-04;
                        font-family: $font-family !important;
                    }
                }

                &:nth-child(5n + 1) {
                    margin-left: 0;
                }
            }

            .trip-gnb-menu__group--event {
                dt {
                    padding: 0;
                    margin: 10px 0;
                    width: 52px;
                    height: 24px;
                    font-size: 14px;
                    font-weight: normal;
                    line-height: 24px;
                    color: $color-gray-14;
                    border-bottom: 0 none;
                    background: linear-gradient(to right, rgba(255, 90, 46, 1) 0%, rgba(255, 0, 56, 1) 50%, rgba(255, 0, 239, 1) 100%);
                    border-radius: 24px;
                    text-align: center;
                }

                dd {
                    @include clearfix;

                    a {
                        float: left;
                        color: #5462c5;
                    }

                    .ico_new {
                        &::before {
                            content: "";
                            float: left;
                            margin: 2px 0 0 4px;
                            @include sprite-retina($sp_gnb_2x_notifi_new);
                        }
                    }
                }
            }
        }
    }

    .trip-gnb-category {
        position: absolute;
        left: 150px;
        top: 0;

        &__list {
            @include clearfix;

            > li {
                float: left;
                position: relative;

                > a {
                    display: block;
                    padding: 20px 16px;
                    height: 30px;
                    font-family: $font-family;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 30px;
                    color: $color-gray-14;

                    &:hover,
                    &:focus {
                        font-weight: normal;
                        background-color: #3746a8;
                        text-decoration: none;

                        & + .category__sublist {
                            display: block;
                        }
                    }
                }

                &:first-child {
                    &::before {
                        display: none;
                    }
                    a {
                        padding-left: 25px;
                    }
                }

                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 28px;
                    left: 0;
                    width: 1px;
                    height: 18px;
                    background: rgba(255, 255, 255, 0.15);
                }

                &:hover,
                &:focus {
                    &::before {
                        display: none;
                    }

                    & + li {
                        &::before {
                            display: none;
                        }
                    }
                }

                &.active {
                    > a {
                        font-weight: normal;
                        background-color: #3746a8;
                    }

                    .trip-gnb-category__sublist {
                        display: block;
                    }

                    &::before {
                        display: none;
                    }

                    & + li {
                        &::before {
                            display: none;
                        }
                    }
                }

                .ico_new {
                    position: absolute;
                    top: 18px;
                    right: 2px;

                    &::before {
                        content: "";
                        display: block;
                        @include sprite-retina($sp_gnb_2x_ic_new);
                    }
                }
            }
        }

        &__sublist {
            display: none;
            position: absolute;
            top: 70px;
            left: 0;
            padding: 0 29px;
            width: 122px;
            border: 1px solid #5462c5;
            background-color: $color-gray-14;

            ul {
                @include clearfix;
                width: 100%;
                padding: 20px 0 26px 0;

                > li {
                    margin-top: 6px;

                    a {
                        font-family: $font-family;
                        font-size: 15px;
                        font-weight: normal;
                        line-height: 22px;
                        color: $color-gray-04;
                    }
                }
            }
        }
    }

    .trip-gnb-coupon {
        position: absolute;
        top: 16px;
        right: 0;
    }

    .trip-gnb-best {
        position: absolute;
        top: 16px;
        right: 70px;
    }

    .link {
        display: inline-flex;
        padding: 8px 14px;
        font-family: $font-family;
        font-weight: 500;
        border: 1px solid transparent;
        border-radius: 20px;
        background: $color-gray-14;
        background: linear-gradient(#fff, #fff) padding-box, linear-gradient(90deg, #ff5a2e, #ff0038 52%, #ff00ef);

        .text {
            color: $color-11st-red;
            font-size: 14px;
            line-height: 20px;
            background: linear-gradient(90deg, #ff5a2e, #ff0038 52%, #ff00ef);
            background-image: -ms-linear-gradient(#fff, #fff); //ie미적용관련 코드
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    &.c-trip-gnb--fixed {
        position: fixed;
        left: 0;
        right: 0;
    }
}
