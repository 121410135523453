@charset "UTF-8";

@import "../common/mixins";
@import "../common/helpers/variables";
@import "../common/helpers/copyright";
@import "../trip/sprites/sp_gnb_2x";
@import "partial/navi";
@import "partial/flight_search";
@import "component/c_trip_samll_layer";

body {
    min-width: 1218px;
}

button,
input,
select,
textarea {
    font-family: "Helvetica Neue", "Apple SD Gothic Neo", Arial, "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif;
}

body,
input,
select,
textarea,
button {
    color: #666;
    letter-spacing: -1px;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
main {
    display: block;
}

.main_wrap a,
.listing_wrap a {
    font-family: "Helvetica Neue", "Apple SD Gothic Neo", Arial, "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif;
}

main {
    &.content {
        position: relative;
        z-index: 2;
        width: 100%;
        padding-top: 200px;
        padding-bottom: 30px;
        font-size: 14px;
        line-height: 1.5;

        &:after {
            visibility: hidden;
            display: block;
            font-size: 0;
            content: ".";
            clear: both;
            height: 0;
        }
    }
}

#wrap main {
    &.content {
        padding-top: 0px;
    }
}

/* 초기로딩이슈 개선을 위해 추가 */
.main_article {
    background-repeat: no-repeat;
    background-position: center top;
}

#wrap {
    position: relative;
    width: 100%;
    min-width: 1280px;
    padding: 0px;
    font-family: "Helvetica Neue", "Apple SD Gothic Neo", Arial, "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif;
}

.main_wrap {
    background: #e9edf0;

    .main_article {
        background-color: #eff2f4;
    }
}

.listing_wrap,
#header {
    background: #fff;
}

.f-clear {
    display: block;

    &:after {
        content: ".";
        display: block;
        clear: both;
        height: 0;
        visibility: hidden;
    }

    display: inline-block;
}

* html .f-clear {
    height: 1%;
}

.layer_open {
    display: block !important;
}

.layer_close {
    display: none !important;
}

/* radio & checkbox */
.radio_design {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;

    + label.css-label {
        display: inline-block;
        height: 23px;
        padding-left: 20px;
        font-size: 18px;
        line-height: 22px;
        background: url("/img/trip/common/sp_trip_input.png") 0 4px no-repeat;
        vertical-align: middle;
        cursor: pointer;

        &.on {
            font-weight: bold;
            color: #536dfe;
            background: url("/img/trip/common/sp_trip_input.png") 0 -16px no-repeat;

            span {
                font-weight: normal;
            }
        }
    }
}

.check_design {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;

    + label.css-label {
        display: inline-block;
        height: 15px;
        padding-left: 20px;
        font-size: 13px;
        line-height: 13px;
        color: #333;
        background: url("/img/trip/common/sp_trip_input.png") 0 -40px no-repeat;
        vertical-align: middle;
        cursor: pointer;

        &.on {
            background: url("/img/trip/common/sp_trip_input.png") 0 -60px no-repeat;

            span {
                font-weight: normal;
            }
        }
    }
}

.check_design_2 {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;

    + label.css-label {
        display: inline-block;
        height: 18px;
        padding-left: 26px;
        font-size: 13px;
        line-height: 13px;
        color: #333;
        background: url("/img/trip/common/sp_trip_input.png") 0 -80px no-repeat;
        vertical-align: middle;
        cursor: pointer;

        &.on {
            background: url("/img/trip/common/sp_trip_input.png") 0 -110px no-repeat;

            span {
                font-weight: normal;
            }
        }
    }
}

.radio_design_3 {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;

    + label.css-label {
        display: block;
        position: relative;
        height: 28px;
        padding: 0 14px 0 13px;
        color: #333;
        font-size: 12px;
        line-height: 27px;
        border-radius: 15px;
        text-decoration: none;
        cursor: pointer;

        &:hover {
            color: #fff;
            text-decoration: none;
            background: #464957;
        }

        &.on {
            color: #fff;
            text-decoration: none;
            background: #464957;

            span {
                font-weight: normal;
            }
        }
    }
}

.check_design_3 {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;

    + label.css-label {
        display: block;
        position: relative;
        height: 28px;
        padding: 0 12px 0 11px;
        color: #333;
        font-size: 12px;
        line-height: 27px;
        border-radius: 15px;
        text-decoration: none;
        cursor: pointer;

        &:hover {
            color: #fff;
            text-decoration: none;
            background: #464957;
        }

        &.on {
            color: #fff;
            text-decoration: none;
            background: #464957;

            span {
                font-weight: normal;
            }
        }
    }
}

button.info_input_1 {
    float: left;
    z-index: 1;
    position: relative;
    width: 159px;
    height: 35px;
    margin: 0 0 0 -1px;
    padding: 0 0 0 10px;
    font-size: 14px;
    letter-spacing: -1px;
    color: #2e2e31;
    text-align: left;
    border: 1px solid #ccc;
    background: #fff;

    .arrow {
        display: block;
        position: absolute;
        right: 10px;
        top: 14px;
        width: 10px;
        height: 6px;
        background: url("/img/trip/common/sp_trip.png") -20px 0 no-repeat #fff;
    }

    .cal {
        display: block;
        position: absolute;
        right: 10px;
        top: 6px;
        width: 20px;
        height: 20px;
        background: url("/img/trip/common/sp_trip.png") -140px 0 no-repeat #fff;
    }
}

.info_input_2 {
    float: left;
    z-index: 1;
    position: relative;
    width: 159px;
    height: 29px;
    margin: 0 0 0 -1px;
    padding: 0 0 4px 10px !important;
    font-size: 14px;
    line-height: 2;
    letter-spacing: -1px;
    color: #2e2e31;
    text-align: left;
    border: 1px solid #ccc;
    background: #fff;
}

button.info_input_1.focus {
    z-index: 3;
    font-weight: bold;
    border: 1px solid #464957;
    color: #5770fe;

    .arrow {
        background: url("/img/trip/common/sp_trip.png") -30px 0 no-repeat #fff;
    }

    .cal {
        background: url("/img/trip/common/sp_trip.png") -170px 0 no-repeat #fff;
    }
}

.info_input_2.focus {
    z-index: 3;
    font-weight: bold;
    border: 1px solid #464957;
    color: #5770fe;
}

button.info_input_1.active {
    z-index: 2;
    font-weight: bold;
    border: 1px solid #ccc;
    color: #5770fe;

    .arrow {
        background: url("/img/trip/common/sp_trip.png") -20px 0 no-repeat #fff;
    }

    .cal {
        background: url("/img/trip/common/sp_trip.png") -140px 0 no-repeat #fff;
    }
}

.info_input_2.active {
    z-index: 2;
    font-weight: bold;
    border: 1px solid #ccc;
    color: #5770fe;
}

.focus {
    font-weight: bold;
    border: 1px solid #464957;
    color: #5770fe;
}

/* 입력선택 */

.active {
    font-weight: bold;
    color: #5770fe;
}

/*입력 완료*/

.comp_select_type_1.selected {
    font-weight: bold;
    border: 1px solid #464957;
    color: #5770fe;
}

/* 셀렉트 박스 펼침 */

/* 상품세로형 */
.product_type1 {
    background: #fff;

    a {
        display: block;
        text-decoration: none;
    }

    .thumb {
        position: relative;
        display: block;

        .line_frame {
            position: absolute;
            left: 0px;
            top: 0px;
            display: block;
            width: 100%;
            height: 100%;
            border: 1px solid #000;
            opacity: 0.06;
            filter: alpha(opacity = 6);
            box-sizing: border-box;
        }
    }

    .category {
        margin: 12px 0 0 0;
        color: #5074dd;
        font-size: 12px;
    }

    .summary_in {
        position: relative;
        display: block;
        padding: 2px 10px 3px 10px;
    }

    .subject {
        overflow: hidden;
        display: block;
        height: 21px;
        margin-top: 4px;
        font-size: 16px;
        line-height: 1.31;
        color: #333;
    }

    .subject_row2 {
        height: 42px;
    }

    a:hover {
        .subject {
            text-decoration: underline;
        }

        text-decoration: none;
    }

    .info {
        display: block;

        &.have_price_normal {
            margin-top: 29px;
        }
    }

    .sale {
        font-size: 14px;
        color: #f43142;
    }

    .sale_tx {
        font-size: 18px;
        font-weight: bold;
    }

    .sale .n {
        font-size: 18px;
        font-family: tahoma, sans-serif;
        font-weight: bold;
        letter-spacing: 0;
    }

    .price {
        position: relative;
        margin: 0 0 0 2px;
        font-size: 15px;
        color: #333;

        .n {
            font-size: 18px;
            font-family: tahoma, sans-serif;
            font-weight: bold;
            letter-spacing: 0;
        }

        .text_price {
            font-size: 16px;
        }
    }

    .price_normal {
        position: absolute;
        left: 0px;
        bottom: 20px;
        color: #999;
        font-size: 13px;
        text-decoration: line-through;

        .n {
            font-size: 13px;
            font-family: tahoma, sans-serif;
            font-weight: normal;
        }
    }

    .info_etc {
        margin: 2px 0 6px 0;

        .tx {
            font-size: 11px;

            .n {
                font-family: tahoma, sans-serif;
                letter-spacing: 0;
            }
        }

        .group {
            display: block;
            line-height: 1.1em;
        }

        .point {
            color: #f43142;
        }
    }

    .add {
        overflow: hidden;
        height: 37px;
        line-height: 37px;
        padding: 0 10px 0 10px;
        background: #fcfcfc;
        border-top: 1px solid #f4f4f4;

        .opt {
            margin: 0 4px 0 0;
            font-size: 12px;
            color: #999;
        }

        .point {
            color: #536dfe;
        }

        .tx {
            font-size: 12px;
        }

        .tx_bar {
            display: inline-block;
            width: 1px;
            height: 11px;
            margin: 0 4px 0 5px;
            background: #dbdbdb;
            vertical-align: middle;
        }
    }
}

/* 컴퍼넌트 요소 */
.comp_plus_minus {
    overflow: hidden;
    width: 86px;
    border: 1px solid #ccc;

    button {
        float: left;
        width: 28px;
        height: 28px;
        text-indent: -9999px;
        background: #f5f5f5;

        &.btn_minus {
            background: url("/img/trip/common/sp_trip.png") 0px -50px no-repeat;
        }

        &.btn_plus {
            background: url("/img/trip/common/sp_trip.png") -30px -50px no-repeat;
        }
    }

    &:hover {
        overflow: hidden;
        width: 86px;
        border: 1px solid #000;

        button {
            &.btn_minus {
                background: url("/img/trip/common/sp_trip.png") -60px -50px no-repeat;
            }

            &.btn_plus {
                background: url("/img/trip/common/sp_trip.png") -90px -50px no-repeat;
            }
        }

        input[type="text"] {
            border-right: 1px solid #464957;
            border-left: 1px solid #464957;
        }
    }

    input[type="text"] {
        float: left;
        width: 28px;
        height: 28px;
        padding: 0px !important;
        text-align: center;
        font-size: 14px;
        color: #111;
        border: none;
        border-right: 1px solid #ccc;
        border-left: 1px solid #ccc;

        &.active {
            font-weight: bold;
            color: #5770fe;
        }
    }
}

.comp_select_type_1 {
    position: relative;
    display: inline-block;
    width: 86px;

    .btn_select {
        overflow: hidden;
        width: 100%;
        height: 28px;
        padding: 0 20px 2px 9px;
        font-size: 14px;
        text-align: left;
        border: 1px solid #ccc;
        background: #fff;

        .arrow {
            display: block;
            position: absolute;
            right: 10px;
            top: 11px;
            width: 10px;
            height: 6px;
            background: url("/img/trip/common/sp_trip.png") -20px 0 no-repeat #fff;
        }
    }

    .select_list {
        display: none;
        position: absolute;
        margin: -1px 0 0 0;
        width: 84px;
        padding: 5px 0 5px 0;
        border: 1px solid #464957;
        box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
        background: #fff;

        button {
            width: 100%;
            height: 23px;
            text-align: left;
            padding: 0 0 2px 9px;
            font-size: 14px;
            color: #333;
            background: #fff;

            &:hover {
                color: #5770fe;
                font-weight: bold;
                text-decoration: underline;
            }

            &.selected {
                color: #5770fe;
            }
        }
    }

    &.focus {
        z-index: 3;
        position: relative;
        width: 86px;
        border: none;

        .btn_select {
            font-weight: bold;
            color: #5770fe;
            border: 1px solid #464957;

            .arrow {
                background: url("/img/trip/common/sp_trip.png") -30px 0px no-repeat #fff;
            }
        }

        .select_list {
            display: block;
        }
    }

    &.active .btn_select {
        font-weight: bold;
        color: #5770fe;
        border: 1px solid #ccc;
    }
}

.layer_selecter {
    position: relative;
    width: 1008px;
    border: 2px solid #464957;
    background: #fff;

    .layer_head {
        height: 35px;
        padding: 5px 0 0 14px;
        font-size: 16px;
        line-height: 1.5;
        font-weight: bold;
        color: #fff;
        background: #464957;
    }

    .layer_sub_text {
        position: absolute;
        top: 8px;
        right: 50px;
        font-size: 12px;
        color: #fff;

        .ico {
            display: inline-block;
            width: 14px;
            height: 14px;
            margin-right: 5px;
            vertical-align: middle;
            background: url("/img/trip/common/sp_trip.png") 0 -10px no-repeat;
        }
    }

    .layer_body .bottom_box {
        padding: 16px 0 15px 0;
        text-align: center;

        .btn_type_01 {
            width: 100px;
            height: 36px;
            background: #2b3769;
            color: #fff;
            font-size: 14px;
            line-height: 36px;
            letter-spacing: -1px;
        }

        .btn_type_02 {
            width: 100px;
            height: 36px;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: -1px;
            color: #5770fe;
            line-height: 36px;
            border: 1px solid #6483dc;
            background: #fff;
        }
    }

    .btn_close {
        position: absolute;
        top: 12px;
        right: 17px;
        display: block;
        width: 11px;
        height: 11px;
        text-indent: -99999px;
        background: url("/img/trip/common/sp_trip.png") -20px -10px no-repeat;

        a {
            display: block;
            width: 11px;
            height: 11px;
        }
    }
}

.city_list_select_type_1 {
    position: relative;
    overflow: hidden;
    width: 910px;
    border: 2px solid #464957;
    background: #fff;

    .layer_body {
        min-height: 100px;
        background: url("/img/trip/common/bg_category_list_02.png");

        .list_box {
            overflow: hidden;

            > li {
                float: left;
                width: 129px;
                margin: 0 0 0 0;
                padding: 0 0 0 0;
                border-right: 1px solid #e9e9e9;

                h3 {
                    height: 33px;
                    padding: 9px 0 0 0;
                    font-size: 14px;
                    font-weight: bold;
                    text-align: center;
                    color: #273259;
                    border-bottom: 1px solid #ccc;
                    background: #fafafa;
                }

                ul {
                    overflow: hidden;
                    width: 128px;
                    padding: 6px 0 8px 0;

                    li {
                        width: 98px;
                        padding: 4px 15px 4px 15px;
                        font-size: 12px;

                        a {
                            color: #333;

                            &:hover {
                                color: #335fe4;
                            }
                        }
                    }
                }

                &.row_2 {
                    overflow: hidden;
                    width: 259px;

                    h3 {
                        width: 259px;
                    }

                    ul {
                        float: left;
                        width: 128px;
                        border-left: 1px solid #e9e9e9;

                        &:first-child {
                            border: none;
                        }
                    }
                }
            }
        }
    }
}

.city_list_select_type_2 {
    width: 596px;

    .layer_body .list_box li {
        border-bottom: 1px solid #eee;
        background: #fff;

        &:hover {
            background: #eff5fe;
        }

        a {
            display: block;
            overflow: hidden;
            height: 29px;
            padding: 14px 16px 0 16px;
            font-size: 12px;
            color: #111;

            p {
                float: left;

                strong {
                    font-weight: normal;
                    color: #5770fe;
                }
            }

            div {
                float: right;

                span {
                    &.tx_bar {
                        display: inline-block;
                        width: 1px;
                        height: 9px;
                        margin: 0 5px 0 2px;
                        background: #ccc;
                    }

                    display: inline-block;
                    width: 1px;
                    height: 9px;
                    margin: 0 5px 0 2px;
                    background: #ccc;
                }
            }
        }
    }
}

.city_list_select_type_3 {
    position: relative;
    overflow: hidden;
    width: 375px;
    border: 2px solid #464957;
    background: #fff;

    .layer_body {
        min-height: 100px;

        h3 {
            height: 33px;
            padding: 9px 0 0 15px;
            font-size: 14px;
            font-weight: bold;
            text-align: left;
            color: #273259;
            border-bottom: 1px solid #ccc;
            background: #fafafa;
        }

        ul {
            overflow: hidden;
            width: 375px;
            padding: 6px 0 8px 0;

            li {
                float: left;
                width: 61px;
                padding: 4px 16px 6px 16px;
                font-size: 12px;

                a {
                    color: #333;

                    &:hover {
                        color: #335fe4;
                    }
                }
            }
        }
    }
}

.member_class_select {
    width: 522px;

    .member_class_box {
        overflow: hidden;
        border-bottom: 1px solid #dadada;

        .member_box {
            float: left;
            width: 343px;
            border-right: 1px solid #dadada;

            h3 {
                height: 28px;
                padding: 12px 0 0 0;
                text-align: center;
                font-size: 14px;
                font-weight: bold;
            }

            .member_check_box {
                padding: 18px 0 19px 20px;
                border-top: 1px solid #dadada;

                ul {
                    overflow: hidden;
                    height: 102px;

                    li {
                        float: left;
                        width: 107px;

                        dl {
                            dt {
                                height: 24px;
                                font-size: 14px;
                                color: #111;
                            }

                            dd {
                                height: 21px;
                                font-size: 12px;
                                color: #666;
                            }
                        }
                    }
                }
            }

            p.text {
                font-size: 12px;
                line-height: 18px;
                color: #666;
            }
        }

        .class_box {
            float: left;
            width: 178px;

            h3 {
                height: 28px;
                padding: 12px 0 0 0;
                text-align: center;
                font-size: 14px;
                font-weight: bold;
            }

            .list_box {
                padding: 20px 0 0 20px;
                border-top: 1px solid #dadada;

                li {
                    margin-bottom: 5px;

                    button {
                        position: relative;
                        width: 138px;
                        height: 35px;
                        padding: 0 0 4px 11px;
                        font-size: 12px;
                        text-align: left;
                        border: 1px solid #dadada;
                        background: #fff;

                        span.ico_check {
                            display: none;
                            position: absolute;
                            top: 11px;
                            right: 12px;
                            width: 16px;
                            height: 10px;
                            background: url("/img/trip/common/sp_trip.png") 0 -30px no-repeat;
                        }

                        &.active,
                        &:hover {
                            font-weight: bold;
                            color: #6666ff;
                            border: 1px solid #464957;

                            span {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}

.room_addition {
    width: 395px;

    .list_box {
        z-index: 2;
        position: relative;

        > li {
            position: relative;
            width: 395px;

            &.zindex {
                position: relative;
                z-index: 2;
            }

            .room_option_1 {
                overflow: hidden;
                border-bottom: 1px solid #eee;

                dt {
                    float: left;
                    width: 66px;
                    height: 47px;
                    padding: 36px 0 0 14px;
                    font-size: 14px;
                    font-weight: bold;
                    color: #111;
                    text-align: left;
                    border-right: 1px solid #eee;
                }

                dd {
                    float: left;
                    width: 299px;
                    height: 70px;
                    padding: 13px 0 0 15px;

                    ul {
                        overflow: hidden;

                        li {
                            float: left;
                            margin: 0 10px 0 0;

                            p.type {
                                height: 25px;
                                color: #111;
                            }
                        }
                    }
                }
            }

            .room_option_2 {
                border-bottom: 1px solid #ccc;
                background: #fcfcfc;

                &:after {
                    display: block;
                    clear: both;
                    content: "";
                }

                display: block;

                dt {
                    float: left;
                    width: 67px;
                    height: 40px;
                    font-size: 14px;
                    color: #111;
                    padding: 20px 0 0 14px;
                    text-align: left;
                }

                dd {
                    float: left;
                    width: 299px;
                    min-height: 45px;
                    padding: 15px 0 0 15px;

                    .comp_select_type_1 {
                        width: 88px;
                        margin: 0 6px 0 0;

                        .select_list {
                            width: 86px;
                        }
                    }
                }
            }

            .btn_del {
                position: absolute;
                top: 15px;
                right: 20px;
                display: block;
                width: 11px;
                height: 11px;
                text-indent: -9999px;
                background: url("/img/trip/common/sp_trip.png") -40px -10px no-repeat;
            }
        }
    }

    .bottom_box {
        z-index: 1;
        position: relative;
        padding-bottom: 15px !important;

        .info_text {
            margin: -5px 14px 38px 0;
            font-size: 11px;
            color: #666;
            text-align: right;

            .ico {
                display: inline-block;
                width: 14px;
                height: 14px;
                margin-right: 4px;
                vertical-align: -4px;
                background: url("/img/trip/common/sp_trip.png") -20px -30px no-repeat;
            }

            button {
                margin: 0 3px 0 2px;

                .ico_plus {
                    display: inline-block;
                    width: 9px;
                    height: 9px;
                    margin-right: 4px;
                    background: url("/img/trip/common/sp_trip.png") -40px -30px no-repeat;
                }
            }
        }
    }
}

.recomd_list_box {
    overflow-x: hidden;
    overflow-y: auto;
    width: 396px;
    max-height: 442px;
    border: 2px solid #464957;
    background: #fff;

    h2 {
        height: 24px;
        padding: 6px 0 0 16px;
        font-size: 12px;
        line-height: 1.5;
        color: #111;
        border-bottom: 1px solid #eee;
        background: #f4f4f4;
    }

    ul {
        width: 396px;
        max-height: 442px;

        li {
            width: 396px;
            border-bottom: 1px solid #eee;
            background: #fff;

            &:hover {
                background: #eff5fe;
            }

            a {
                display: block;
                overflow: hidden;
                padding: 14px 16px 16px 16px;
                font-size: 12px;
                color: #111;

                strong {
                    color: #5770fe;
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .no_result {
        font-size: 14px;
        text-align: center;
        padding: 50px 0 50px 0;
    }
}

.hotel_recomd_box .no_result {
    font-size: 14px;
    text-align: center;
    padding: 50px 0 50px 0;
}

.listing_article {
    position: relative;
    background: #fff;
}

/* 해외호텔 - 메인 자동완성 레이어 */
.recomd_list_box2 {
    ul {
        width: 100%;
    }

    .txt {
        overflow: hidden;
        float: left;
    }

    .num {
        float: right;
        margin-left: 5px;
    }
}

.quick_search_box .trip_type.option_2 .option_check_box_2 .option_select_box_1 .option_detail .recomd_list_box.recomd_list_box2 li {
    width: 100%;
}

/* 메인 */
/* 편도,다구간,숙박,국내,해외,여행상품 */
.quick_search_box {
    .btn_type_01 {
        width: 120px;
        height: 35px;
        padding: 0 0 0 0;
        font-size: 16px;
        letter-spacing: -1px;
        color: #fff;
        background: #2b3769;
    }

    .btn_type_02 {
        width: 120px;
        height: 35px;
        padding: 0 0 0 0;
        margin: 0 20px 0 0;
        font-size: 14px;
        font-weight: bold;
        color: #273259;
        border: 1px solid #273259;
        background: #fff;

        span.ico {
            display: inline-block;
            width: 10px;
            height: 10px;
            margin: 0 5px 0 0;
            background: url("/img/trip/common/sp_trip.png") -310px 0 no-repeat;
        }
    }

    z-index: 2;
    position: relative;
    min-width: 1218px;

    .inner {
        width: 1218px;
        margin: 0 auto;
        padding: 90px 0 0 0;
    }

    .tab_menu {
        overflow: hidden;
        width: 1200px;
        margin: 0 auto;
        padding: 0 9px 8px 9px;
        font: 0/0 arial;
        text-align: center;
        background: url("/img/trip/common/bg_option_box_top.png") center bottom no-repeat;

        li {
            display: inline-block;
            zoom: 1;
            *display: inline;
            width: 400px;
            height: 50px;
            font: 0/0 arial;

            a {
                display: block;
                width: 400px;
                height: 50px;
                background: url("/img/trip/common/sp_trip.png") no-repeat;
                text-indent: -9999px;
            }

            &.trip_tyep_1 {
                a {
                    background-position: 0 -90px;
                }

                &.selected a {
                    background-position: 0 -150px;
                }
            }

            &.trip_tyep_2 {
                a {
                    background-position: -400px -90px;
                }

                &.selected a {
                    background-position: -400px -150px;
                }
            }

            &.trip_tyep_3 {
                a {
                    background-position: -800px -90px;
                }

                &.selected a {
                    background-position: -800px -150px;
                }
            }
        }
    }

    .trip_type {
        width: 1218px;
        min-height: 50px;
        padding: 9px 0 11px 0;
        background: url("/img/trip/common/bg_option_box_bottom.png") center bottom no-repeat;

        .type_info_1 {
            float: left;
            width: 37px;
            height: 33px;
            margin: 0 0 0 -1px;
            text-indent: -9999px;
            border: 1px solid #ccc;
            background: url("/img/trip/common/sp_trip.png") -60px 0 no-repeat;
        }

        .type_info_2 {
            float: left;
            width: 37px;
            height: 33px;
            margin: 0 0 0 -1px;
            text-indent: -9999px;
            border: 1px solid #ccc;
            background: url("/img/trip/common/sp_trip.png") -100px 0 no-repeat;
        }

        &.option_1 {
            .option_type_box {
                overflow: hidden;
                height: 30px;
                padding: 0 30px 0 30px;

                li {
                    float: left;
                    min-width: 100px;

                    span.text_1 {
                        font-size: 16px;
                        color: #666;
                    }
                }
            }

            .option_check_box_1 {
                padding: 10px 30px 17px 30px;

                &:after {
                    content: ".";
                    display: block;
                    clear: both;
                    height: 0;
                    visibility: hidden;
                }

                display: inline-block;
                display: block;

                .city_list_select_type_1,
                .city_list_select_type_2,
                .city_list_select_type_3 {
                    position: absolute;
                    top: 40px;
                    left: 0px;
                }

                .option_select_box_1 {
                    position: relative;
                    z-index: 1;
                    float: left;
                    margin-right: 20px;

                    .option_detail {
                        position: relative;
                        padding: 0 0 5px 1px;

                        &:after {
                            content: ".";
                            display: block;
                            clear: both;
                            height: 0;
                            visibility: hidden;
                        }

                        display: inline-block;
                        display: block;

                        .info_input_1 {
                            width: 178px;
                        }

                        .info_input_2 {
                            width: 166px;
                        }
                    }
                }

                .option_select_box_2 {
                    position: relative;
                    z-index: 2;
                    float: left;
                    margin-right: 20px;

                    .option_detail {
                        position: relative;
                        padding: 0 0 5px 1px;

                        &:after {
                            content: ".";
                            display: block;
                            clear: both;
                            height: 0;
                            visibility: hidden;
                        }

                        display: inline-block;
                        display: block;

                        .layer_selecter.trip_date_picker {
                            position: absolute;
                            top: 40px;
                            left: 0px;
                            width: 747px;
                        }

                        &.info_input_1 {
                            width: 159px;
                        }
                    }
                }

                .option_select_box_3 {
                    position: relative;
                    z-index: 3;
                    float: left;
                    margin-right: 20px;

                    .option_detail {
                        position: relative;
                        padding: 0 0 5px 1px;

                        &:after {
                            content: ".";
                            display: block;
                            clear: both;
                            height: 0;
                            visibility: hidden;
                        }

                        display: inline-block;
                        display: block;

                        .member_class_select {
                            position: absolute;
                            top: 40px;
                            right: 0px;
                        }
                    }
                }
            }

            .option_check_box_2 {
                padding: 10px 30px 17px 30px;

                &:after {
                    content: ".";
                    display: block;
                    clear: both;
                    height: 0;
                    visibility: hidden;
                }

                display: inline-block;
                display: block;

                .city_list_select_type_1,
                .city_list_select_type_2,
                .city_list_select_type_3 {
                    position: absolute;
                    top: 40px;
                    left: 0px;
                }

                .option_select_box_1 {
                    position: relative;
                    z-index: 1;
                    float: left;
                    margin-right: 20px;

                    .option_detail {
                        position: relative;
                        padding: 0 0 5px 1px;

                        &:after {
                            content: ".";
                            display: block;
                            clear: both;
                            height: 0;
                            visibility: hidden;
                        }

                        display: inline-block;
                        display: block;

                        .info_input_1 {
                            width: 178px;
                        }

                        .info_input_2 {
                            width: 166px;
                        }
                    }
                }

                .option_select_box_2 {
                    position: relative;
                    z-index: 2;
                    float: left;
                    margin-right: 20px;

                    .option_detail {
                        position: relative;
                        padding: 0 0 5px 1px;

                        &:after {
                            content: ".";
                            display: block;
                            clear: both;
                            height: 0;
                            visibility: hidden;
                        }

                        display: inline-block;
                        display: block;

                        .layer_selecter.trip_date_picker {
                            position: absolute;
                            top: 40px;
                            left: 0px;
                            width: 747px;
                        }

                        &.info_input_1 {
                            width: 159px;
                        }
                    }
                }

                .option_select_box_3 {
                    position: relative;
                    z-index: 3;
                    float: left;
                    margin-right: 20px;

                    .option_detail {
                        position: relative;
                        padding: 0 0 5px 1px;

                        &:after {
                            content: ".";
                            display: block;
                            clear: both;
                            height: 0;
                            visibility: hidden;
                        }

                        display: inline-block;
                        display: block;

                        .member_class_select {
                            position: absolute;
                            top: 40px;
                            right: 0px;
                        }
                    }
                }
            }

            .option_check_box_3 {
                padding: 10px 30px 17px 30px;

                &:after {
                    content: ".";
                    display: block;
                    clear: both;
                    height: 0;
                    visibility: hidden;
                }

                display: inline-block;
                display: block;

                .plan_list {
                    float: left;

                    > li {
                        z-index: 1;
                        position: relative;
                        padding-top: 3px;

                        &.selected {
                            z-index: 2;
                        }

                        &:after {
                            content: ".";
                            display: block;
                            clear: both;
                            height: 0;
                            visibility: hidden;
                        }

                        display: inline-block;
                        display: block;
                    }

                    li.default {
                        z-index: 2;
                        padding-top: 0px;
                        padding-bottom: 8px;
                    }

                    > li .btn_del {
                        float: left;
                        width: 20px;
                        height: 20px;
                        margin-top: 8px;
                        background: url("/img/trip/common/sp_trip.png") -280px 0 no-repeat;
                        text-indent: -9999px;
                    }
                }

                .city_list_select_type_1,
                .city_list_select_type_2,
                .city_list_select_type_3 {
                    position: absolute;
                    top: 40px;
                    left: 0px;
                }

                .option_select_box_1 {
                    position: relative;
                    z-index: 1;
                    float: left;
                    margin-right: 20px;

                    .option_detail {
                        position: relative;
                        padding: 0 0 5px 1px;

                        &:after {
                            content: ".";
                            display: block;
                            clear: both;
                            height: 0;
                            visibility: hidden;
                        }

                        display: inline-block;
                        display: block;

                        .info_input_1 {
                            width: 178px;
                        }

                        .info_input_2 {
                            width: 166px;
                        }

                        .type_info {
                            float: left;
                            width: 37px;
                            height: 33px;
                            margin: 0 0 0 -1px;
                            text-indent: -9999px;
                            border: 1px solid #ccc;
                            background: url("/img/trip/common/sp_trip.png") -100px 0 no-repeat;
                        }
                    }
                }

                .option_select_box_2 {
                    position: relative;
                    z-index: 2;
                    float: left;
                    margin-right: 20px;

                    .option_detail {
                        position: relative;
                        padding: 0 0 5px 1px;

                        &:after {
                            content: ".";
                            display: block;
                            clear: both;
                            height: 0;
                            visibility: hidden;
                        }

                        display: inline-block;
                        display: block;

                        .layer_selecter.trip_date_picker {
                            position: absolute;
                            top: 40px;
                            left: 0px;
                            width: 747px;
                        }

                        &.info_input_1 {
                            width: 159px;
                        }
                    }
                }

                .option_select_box_3 {
                    position: relative;
                    z-index: 3;
                    float: left;
                    margin-right: 20px;

                    .option_detail {
                        position: relative;
                        padding: 0 0 5px 1px;

                        &:after {
                            content: ".";
                            display: block;
                            clear: both;
                            height: 0;
                            visibility: hidden;
                        }

                        display: inline-block;
                        display: block;

                        .member_class_select {
                            position: absolute;
                            top: 40px;
                            right: 0px;
                        }
                    }
                }

                .btn_plan_add {
                    width: 120px;
                    height: 35px;
                    padding: 0 0 5px 0;
                    margin: 0 20px 0 0;
                    font-size: 14px;
                    font-weight: bold;
                    color: #5770fe;
                    border: 1px solid #5770fe;
                    background: #fff;

                    span.ico {
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        margin: 0 5px 0 0;
                        background: url("/img/trip/common/sp_trip.png") -310px 0 no-repeat;
                    }
                }
            }
        }

        .hotel_recomd_box {
            top: 40px;
            left: 0;
            width: 456px;
        }
    }

    .search_history_box {
        overflow: hidden;
        width: 1158px;
        margin: 0 auto;
        padding: 15px 0 9px 0;
        border-top: 1px solid #f4f4f4;

        .title {
            float: left;
            min-width: 67px;
            font-size: 14px;
            font-weight: bold;
            color: #333;

            .num {
                font-size: 12px;
                color: #6483dc;

                .ico {
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    margin: 0 2px -2px 4px;
                    background: url("/img/trip/common/sp_trip.png") -200px 0 no-repeat;
                }
            }
        }

        .list_box {
            float: left;
            overflow: hidden;
            width: 1055px;
            font-size: 12px;
            letter-spacing: 0px;
            margin: 1px 0 0 8px;

            li {
                float: left;
                height: 28px;
                margin: 0 25px 0 0;

                .type_1 {
                    display: inline-block;
                    width: 14px;
                    height: 12px;
                    margin: 0 5px 0 5px;
                    vertical-align: -4px;
                    text-indent: -99999px;
                    background: url("/img/trip/common/sp_trip.png") -240px 0 no-repeat;
                }

                .type_2 {
                    display: inline-block;
                    width: 14px;
                    height: 12px;
                    margin: 0 5px 0 5px;
                    vertical-align: -4px;
                    text-indent: -99999px;
                    background: url("/img/trip/common/sp_trip.png") -260px 0 no-repeat;
                }

                .tx_bar {
                    display: inline-block;
                    width: 1px;
                    height: 6px;
                    margin: 0 9px 0 10px;
                    vertical-align: 1px;
                    background: #ccc;
                }

                .date,
                .member {
                    color: #999;
                }

                .btn_del {
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    margin: -2px 0 0 6px;
                    text-indent: -9999px;
                    vertical-align: middle;
                    background: url("/img/trip/common/sp_trip.png") -220px 0 no-repeat;
                }
            }
        }

        p.no_data {
            padding: 0 0 10px 0;
        }
    }

    .trip_company_list_1 {
        overflow: hidden;
        width: 1198px;
        height: 56px;
        margin: 0 auto;
        background: url("/img/trip/common/sp_trip_company_01.png") no-repeat;

        li {
            float: left;
            text-indent: -9999px;
        }
    }

    .trip_company_list_2 {
        overflow: hidden;
        width: 1198px;
        height: 56px;
        margin: 0 auto;
        background: url("/img/trip/common/sp_trip_company_02.png") no-repeat;

        li {
            float: left;
            text-indent: -9999px;
        }
    }

    .trip_company_list_3 {
        overflow: hidden;
        width: 1198px;
        height: 56px;
        margin: 0 auto;
        background: url("/img/trip/common/sp_trip_company_03.png") no-repeat;

        li {
            float: left;
            text-indent: -9999px;
        }
    }

    .trip_company_list {
        overflow: hidden;
        width: 1198px;
        height: 56px;
        margin: 0 auto;
    }

    .trip_type {
        .flight_inspection_box {
            width: 457px;
            height: 148px;
            padding-top: 24px;
            text-indent: -99999px;
            margin: 0 auto 0 auto;
            background: url("/img/trip/img_flight_01.png") 0 24px no-repeat;
        }

        &.option_2 {
            .option_type_box {
                overflow: hidden;
                height: 30px;
                padding: 0 30px 0 30px;

                li {
                    float: left;
                    min-width: 100px;

                    span.text_1 {
                        font-size: 16px;
                        color: #666;
                    }

                    .radio_design:checked + label.css-label {
                        color: #5770fe;
                    }
                }
            }

            .option_check_box_1 {
                padding: 10px 30px 3px 30px;

                &:after {
                    content: ".";
                    display: block;
                    clear: both;
                    height: 0;
                    visibility: hidden;
                }

                display: inline-block;
                display: block;

                .city_list_select_type_1,
                .city_list_select_type_2,
                .city_list_select_type_3 {
                    position: absolute;
                    top: 40px;
                    left: 0px;
                }

                .option_select_box_1 {
                    z-index: 10;
                    position: relative;
                    float: left;
                    margin-right: 20px;

                    .option_detail {
                        position: relative;
                        padding: 0 0 5px 1px;

                        &:after {
                            content: ".";
                            display: block;
                            clear: both;
                            height: 0;
                            visibility: hidden;
                        }

                        display: inline-block;
                        display: block;

                        .info_input_1 {
                            width: 178px;
                        }

                        .recomd_list_box {
                            position: absolute;
                            top: 40px;
                            left: 0px;
                            width: 455px;

                            li {
                                width: 455px;
                            }
                        }

                        .info_input_2 {
                            width: 448px;
                        }
                    }
                }

                .option_select_box_2 {
                    z-index: 10;
                    position: relative;
                    float: left;
                    margin-right: 20px;

                    .option_detail {
                        position: relative;
                        padding: 0 0 5px 1px;

                        &:after {
                            content: ".";
                            display: block;
                            clear: both;
                            height: 0;
                            visibility: hidden;
                        }

                        display: inline-block;
                        display: block;

                        .layer_selecter.trip_date_picker_v2 {
                            position: absolute;
                            top: 40px;
                            right: 0px;
                            width: 747px;
                        }

                        &.info_input_1 {
                            width: 159px;
                        }
                    }
                }

                .option_select_box_3 {
                    float: left;
                    margin-right: 20px;

                    .option_detail {
                        position: relative;
                        padding: 0 0 5px 1px;

                        &:after {
                            content: ".";
                            display: block;
                            clear: both;
                            height: 0;
                            visibility: hidden;
                        }

                        display: inline-block;
                        display: block;

                        .member_class_select {
                            position: absolute;
                            top: 40px;
                            right: 0px;
                        }
                    }

                    .comp_select_type_1 {
                        width: 200px;

                        .btn_select {
                            height: 35px;

                            .arrow {
                                top: 15px;
                            }
                        }

                        .select_list {
                            width: 198px;
                        }
                    }
                }
            }

            .option_check_box_2 {
                padding: 10px 30px 3px 30px;

                &:after {
                    content: ".";
                    display: block;
                    clear: both;
                    height: 0;
                    visibility: hidden;
                }

                display: inline-block;
                display: block;

                .city_list_select_type_1,
                .city_list_select_type_2,
                .city_list_select_type_3 {
                    position: absolute;
                    top: 40px;
                    left: 0px;
                }

                .comp_select_type_1 {
                    width: 275px;

                    &.focus {
                        z-index: 3;
                    }

                    .btn_select {
                        height: 35px;
                        margin-bottom: 15px;

                        .arrow {
                            top: 15px;
                        }
                    }

                    .select_list {
                        width: 273px;
                        margin-top: -16px;
                    }
                }

                .option_select_box_1 {
                    position: relative;
                    z-index: 1;
                    float: left;
                    margin-right: 20px;

                    .option_detail {
                        position: relative;
                        padding: 0 0 5px 1px;

                        &:after {
                            content: ".";
                            display: block;
                            clear: both;
                            height: 0;
                            visibility: hidden;
                        }

                        display: inline-block;
                        display: block;

                        .info_input_1 {
                            width: 318px;
                        }

                        .info_input_2 {
                            width: 306px;
                        }

                        .recomd_list_box {
                            position: absolute;
                            top: 40px;
                            left: 0px;
                            width: 314px;

                            li {
                                width: 314px;
                            }
                        }
                    }
                }

                .option_select_box_2 {
                    position: relative;
                    z-index: 2;
                    float: left;
                    margin-right: 20px;

                    .option_detail {
                        position: relative;
                        padding: 0 0 5px 1px;

                        &:after {
                            content: ".";
                            display: block;
                            clear: both;
                            height: 0;
                            visibility: hidden;
                        }

                        display: inline-block;
                        display: block;

                        .layer_selecter.trip_date_picker_v2 {
                            position: absolute;
                            top: 40px;
                            left: 0px;
                            width: 747px;
                        }

                        &.info_input_1 {
                            width: 159px;
                        }
                    }
                }

                .option_select_box_3 {
                    position: relative;
                    z-index: 3;
                    float: left;
                    height: 38px;
                    margin-right: 20px;

                    .option_detail {
                        position: relative;
                        padding: 0 0 5px 1px;

                        &:after {
                            content: ".";
                            display: block;
                            clear: both;
                            height: 0;
                            visibility: hidden;
                        }

                        display: inline-block;
                        display: block;

                        .member_class_select {
                            position: absolute;
                            top: 40px;
                            right: 0px;
                        }

                        .info_input_1 {
                            width: 85px;
                        }

                        .comp_select_type_1 {
                            float: left;
                            width: 85px;

                            .select_list {
                                width: 83px;
                            }
                        }

                        .info_input_2 {
                            width: 244px;

                            &.room {
                                width: 74px;
                            }
                        }

                        .btn_type_02 {
                            z-index: 2;
                            position: relative;
                            width: 88px;
                            margin: 0 0 0 -1px;
                        }
                    }

                    .room_addition {
                        z-index: 1;
                        position: absolute;
                        top: 40px;
                        right: 0px;
                    }
                }
            }

            .popular_trip {
                overflow: hidden;
                width: 1198px;
                margin: 0 auto;
                padding: 0 0 15px 0;

                p.title {
                    float: left;
                    min-width: 75px;
                    margin: 0 0 0 2px;
                    padding: 0 0 0 18px;
                    font-size: 12px;
                    font-weight: bold;
                    color: #333;

                    .ico {
                        display: inline-block;
                        width: 13px;
                        height: 15px;
                        margin-right: 5px;
                        vertical-align: -4px;
                        background: url("/img/trip/common/sp_trip.png") -330px 0px no-repeat;
                    }
                }

                .list_box {
                    overflow: hidden;
                    float: left;
                    min-height: 20px;

                    li {
                        float: left;
                        font-size: 12px;

                        .tx_bar {
                            display: inline-block;
                            width: 1px;
                            height: 9px;
                            margin: 0 10px 0 11px;
                            vertical-align: 0px;
                            background: #ccc;
                        }

                        &:first-child .tx_bar {
                            display: none;
                        }
                    }
                }
            }
        }

        &.option_3 {
            .option_type_box {
                overflow: hidden;
                height: 30px;
                padding: 0 30px 0 30px;

                li {
                    float: left;
                    min-width: 138px;

                    span.text_1 {
                        font-size: 16px;
                        color: #666;
                    }

                    .radio_design:checked + label.css-label {
                        color: #5770fe;
                    }
                }
            }

            .option_check_box_1 {
                padding: 10px 30px 20px 30px;

                &:after {
                    content: ".";
                    display: block;
                    clear: both;
                    height: 0;
                    visibility: hidden;
                }

                display: inline-block;
                display: block;

                .option_select_box_1 {
                    float: left;
                    margin-right: 20px;

                    .option_detail {
                        position: relative;
                        padding: 0 0 5px 1px;

                        .info_input_1 {
                            width: 500px;
                        }
                    }
                }

                .comp_select_type_1 {
                    width: 500px;

                    .btn_select {
                        height: 35px;

                        .arrow {
                            top: 15px;
                        }
                    }

                    .select_list {
                        width: 498px;
                    }
                }
            }

            .option_check_box_2 {
                padding: 10px 30px 20px 30px;

                &:after {
                    content: ".";
                    display: block;
                    clear: both;
                    height: 0;
                    visibility: hidden;
                }

                display: inline-block;
                display: block;

                .city_list_select_type_1,
                .city_list_select_type_2,
                .city_list_select_type_3 {
                    position: absolute;
                    top: 40px;
                    left: 0px;
                }

                .option_select_box_1 {
                    float: left;
                    margin-right: 20px;

                    .option_detail {
                        position: relative;
                        padding: 0 0 5px 1px;

                        &:after {
                            content: ".";
                            display: block;
                            clear: both;
                            height: 0;
                            visibility: hidden;
                        }

                        display: inline-block;
                        display: block;

                        .info_input_1 {
                            width: 178px;
                        }

                        .info_input_2 {
                            width: 166px;
                        }
                    }
                }

                .option_select_box_2 {
                    float: left;
                    margin-right: 20px;

                    .option_detail {
                        position: relative;
                        padding: 0 0 5px 1px;

                        &:after {
                            content: ".";
                            display: block;
                            clear: both;
                            height: 0;
                            visibility: hidden;
                        }

                        display: inline-block;
                        display: block;

                        .layer_selecter.trip_date_picker_v2 {
                            position: absolute;
                            top: 40px;
                            left: 0px;
                            width: 747px;
                        }
                    }
                }

                .option_select_box_3 {
                    float: left;
                    margin-right: 20px;

                    .option_detail {
                        position: relative;
                        padding: 0 0 5px 1px;

                        &:after {
                            content: ".";
                            display: block;
                            clear: both;
                            height: 0;
                            visibility: hidden;
                        }

                        display: inline-block;
                        display: block;

                        p.title {
                            float: left;
                            width: 69px;
                            height: 27px;
                            padding: 6px 0 0 0;
                            font-size: 13px;
                            font-weight: bold;
                            letter-spacing: 0px;
                            color: #333;
                            text-align: center;
                            border: 1px solid #ccc;
                        }

                        .list_box {
                            float: left;
                            min-width: 342px;
                            height: 33px;
                            padding: 0 0 0 10px;
                            margin-left: -1px;
                            border: 1px solid #ccc;

                            li {
                                float: left;
                                padding: 5px 10px 0 0;
                                color: #333;
                                font-size: 13px;

                                .tx_bar {
                                    display: inline-block;
                                    width: 1px;
                                    height: 12px;
                                    margin: 0 11px 0 0;
                                    vertical-align: -2px;
                                    background: #ccc;
                                }

                                &:first-child .tx_bar {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }

            .option_check_box_3 {
                padding: 10px 30px 20px 30px;

                &:after {
                    content: ".";
                    display: block;
                    clear: both;
                    height: 0;
                    visibility: hidden;
                }

                display: inline-block;
                display: block;

                .city_list_select_type_1,
                .city_list_select_type_2,
                .city_list_select_type_3 {
                    position: absolute;
                    top: 40px;
                    left: 0px;
                }

                .option_select_box_1 {
                    float: left;
                    margin-right: 20px;

                    .option_detail {
                        position: relative;
                        padding: 0 0 5px 1px;

                        &:after {
                            content: ".";
                            display: block;
                            clear: both;
                            height: 0;
                            visibility: hidden;
                        }

                        display: inline-block;
                        display: block;

                        .info_input_1 {
                            width: 498px;
                        }
                    }
                }

                .comp_select_type_1 {
                    width: 500px;

                    .btn_select {
                        height: 35px;

                        .arrow {
                            top: 15px;
                        }
                    }

                    .select_list {
                        width: 498px;
                    }
                }

                .option_select_box_2 {
                    float: left;
                    margin-right: 20px;

                    .option_detail {
                        position: relative;
                        padding: 0 0 5px 1px;

                        &:after {
                            content: ".";
                            display: block;
                            clear: both;
                            height: 0;
                            visibility: hidden;
                        }

                        display: inline-block;
                        display: block;

                        p.title {
                            float: left;
                            width: 73px;
                            height: 27px;
                            padding: 6px 0 0 0;
                            font-size: 13px;
                            font-weight: bold;
                            color: #333;
                            text-align: center;
                            border: 1px solid #ccc;
                        }

                        .list_box {
                            float: left;
                            height: 33px;
                            padding: 0 0 0 10px;
                            margin-left: -1px;
                            border: 1px solid #ccc;

                            li {
                                float: left;
                                padding: 5px 10px 0 0;
                                color: #333;
                                font-size: 13px;

                                .tx_bar {
                                    display: inline-block;
                                    width: 1px;
                                    height: 12px;
                                    margin: 0 11px 0 0;
                                    vertical-align: -2px;
                                    background: #ccc;
                                }

                                &:first-child .tx_bar {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
* html .quick_search_box .trip_type {
    &.option_1 {
        .option_check_box_1 {
            height: 1%;

            .option_select_box_1 .option_detail,
            .option_select_box_2 .option_detail,
            .option_select_box_3 .option_detail {
                height: 1%;
            }
        }

        .option_check_box_2 {
            height: 1%;

            .option_select_box_1 .option_detail,
            .option_select_box_2 .option_detail,
            .option_select_box_3 .option_detail {
                height: 1%;
            }
        }

        .option_check_box_3 {
            height: 1%;

            .plan_list > li,
            .option_select_box_1 .option_detail,
            .option_select_box_2 .option_detail,
            .option_select_box_3 .option_detail {
                height: 1%;
            }
        }
    }

    &.option_2 {
        .option_check_box_1 {
            height: 1%;

            .option_select_box_1 .option_detail,
            .option_select_box_2 .option_detail,
            .option_select_box_3 .option_detail {
                height: 1%;
            }
        }

        .option_check_box_2 {
            height: 1%;

            .option_select_box_1 .option_detail,
            .option_select_box_2 .option_detail,
            .option_select_box_3 .option_detail {
                height: 1%;
            }
        }
    }

    &.option_3 {
        .option_check_box_1 {
            height: 1%;
        }

        .option_check_box_2 {
            height: 1%;

            .option_select_box_1 .option_detail,
            .option_select_box_2 .option_detail,
            .option_select_box_3 .option_detail {
                height: 1%;
            }
        }

        .option_check_box_3 {
            height: 1%;

            .option_select_box_1 .option_detail,
            .option_select_box_2 .option_detail {
                height: 1%;
            }
        }
    }
}
/* 여행 tab */
.tab_menu_type_1 {
    position: absolute;
    top: -5px;
    right: 8px;
    height: 35px;
    border: 1px solid #d7d7d7;
    border-radius: 20px;

    ul {
        height: 37px;
        margin: -1px 0 0 -1px;
    }

    li {
        position: relative;
        float: left;
        height: 35px;
        border: 1px solid transparent;
        border-radius: 20px;
    }

    a {
        display: block;
        height: 35px;
        padding: 0 24px;
        font-size: 16px;
        line-height: 35px;
        color: #999;
    }

    .ico_dot {
        position: absolute;
        top: 50%;
        left: -5px;
        width: 4px;
        height: 4px;
        margin-top: -2px;
        border-radius: 50%;
        background-color: #999;
    }

    li {
        &:hover,
        &.selected {
            z-index: 1;
            border: 1px solid #5770fe;
            background-color: #fff;
        }

        &:first-child .ico_dot,
        &:hover .ico_dot,
        &.selected .ico_dot {
            display: none;
        }

        &:hover a,
        &.selected a {
            font-weight: bold;
            color: #5770fe;
        }

        a {
            &:hover,
            &:focus,
            &:active {
                text-decoration: none;
            }
        }
    }
}

@keyframes example {
    0% {
        left: 0px;
        top: 0px;
    }

    100% {
        left: 0px;
        top: 198px;
    }
}

@keyframes example_info {
    0% {
        left: 0px;
        bottom: -198px;
    }

    100% {
        left: 0px;
        bottom: 0px;
    }
}

.season_billboard_box {
    position: relative;
    display: block;
    width: 1200px;
    margin: 28px auto 0 auto;
    padding: 0 8px 0 8px;

    .season_recom_list {
        float: left;
        position: relative;
        width: 598px;
        height: 397px;
        padding: 0 0 0 181px;
        border: 1px solid #ccc;
        background: #fff;

        h1 {
            position: absolute;
            top: 0px;
            left: -37px;
            font-size: 14px;
            line-height: 1.5;
        }

        .list_box {
            overflow: hidden;
            width: 598px;
            height: 397px;

            ul {
                overflow: hidden;
                width: 600px;
                height: 398px;
                background: url("/img/trip/common/bg_line_1.png");

                li {
                    position: relative;
                    float: left;
                    overflow: hidden;
                    width: 199px;
                    height: 198px;
                    margin: 0px 1px 1px 0;

                    .thumb_box {
                        position: absolute;
                        top: 0px;
                        left: 0px;

                        img {
                            width: 199px;
                            max-height: 198px;
                        }
                    }

                    .text_box {
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        width: 199px;
                        height: 198px;
                        background: url("/img/trip/common/bg_g_01.png");

                        p {
                            position: absolute;
                            bottom: 0px;
                            left: 0px;
                            overflow: hidden;
                            max-height: 34px;
                            margin-bottom: 11px;
                            padding: 0 11px 0 11px;
                            font-size: 14px;
                            line-height: 16px;
                            color: #fff;
                        }
                    }

                    .category_box {
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        width: 199px;
                        height: 198px;

                        .dim {
                            transition: opacity 0.2s;
                            opacity: 0;
                            filter: alpha(opacity = 0);
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 199px;
                            height: 198px;
                            background: url("/img/trip/common/bg_dim_69.png");
                        }

                        .info {
                            display: none;
                            position: absolute;
                            bottom: 0;
                            left: 0px;
                            width: 199px;
                            height: 148px;
                            padding: 50px 0 0 0;
                            text-align: center;

                            .icon {
                                display: inline-block;
                                width: 35px;
                                height: 35px;
                                text-indent: -9999px;
                                background: url("/img/trip/sp_type_3.png") no-repeat;

                                &.ico_category_type_1 {
                                    background-position: 0 0;
                                }

                                &.ico_category_type_2 {
                                    background-position: 0 -40px;
                                }

                                &.ico_category_type_3 {
                                    background-position: 0 -80px;
                                }

                                &.ico_category_type_4 {
                                    background-position: 0 -120px;
                                }

                                &.ico_category_type_5 {
                                    background-position: 0 -160px;
                                }

                                &.ico_category_type_6 {
                                    background-position: 0 -200px;
                                }

                                &.ico_category_type_7 {
                                    background-position: 0 -240px;
                                }

                                &.ico_category_type_8 {
                                    background-position: 0 -280px;
                                }

                                &.ico_category_type_9 {
                                    background-position: 0 -320px;
                                }

                                &.ico_category_type_10 {
                                    background-position: 0 -360px;
                                }
                            }

                            .text {
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                word-wrap: normal;
                                overflow: hidden;
                                max-width: 199px;
                                height: 27px;
                                margin: 2px 0 17px 0;
                                font-size: 16px;
                                font-weight: bold;
                                color: #fff;
                            }

                            .result {
                                display: inline-block;
                                height: 25px;
                                padding: 3px 10px 0 12px;
                                font-size: 14px;
                                color: #fff;
                                border: 1px solid #fff;
                                border-radius: 15px;

                                span.arrow {
                                    display: inline-block;
                                    width: 6px;
                                    height: 12px;
                                    margin: 0 0 0 4px;
                                    vertical-align: -1px;
                                    background: url("/img/trip/common/sp_trip.png") -240px -50px no-repeat;
                                }
                            }
                        }
                    }

                    &:hover {
                        .text_box {
                            top: 198px;
                            animation-name: example;
                            animation-duration: 0.5s;
                        }

                        .category_box {
                            .dim {
                                opacity: 1;
                                filter: alpha(opacity = 100);
                            }

                            .info {
                                display: block;
                                animation-name: example_info;
                                animation-duration: 0.5s;
                            }
                        }
                    }
                }
            }
        }
    }

    .billboard_box {
        position: relative;
        overflow: hidden;
        float: left;
        width: 398px;
        height: 397px;
        margin: 0 0 0 19px;
        border: 1px solid #ccc;
        background: #fff;

        .list_box li {
            float: left;
            width: 398px;
            text-align: center;

            img {
                width: 398px;
                height: 397px;
            }
        }

        .bx-controls.bx-has-controls-direction.bx-has-pager {
            .bx-pager.bx-default-pager {
                position: absolute;
                top: 21px;
                right: 21px;
                text-align: right;
                font: 0/0 arial;

                .bx-pager-item {
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    margin-left: 5px;

                    .bx-pager-link {
                        display: block;
                        width: 10px;
                        height: 10px;
                        text-indent: -9999px;
                        background: url("/img/trip/common/sp_trip.png") 0 -320px no-repeat;

                        &.active {
                            background: url("/img/trip/common/sp_trip.png") -20px -320px no-repeat;
                        }
                    }
                }
            }

            .bx-controls-direction {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;

                .bx-prev {
                    position: absolute;
                    top: 183px;
                    left: 0px;
                    display: block;
                    width: 32px;
                    height: 32px;
                    text-indent: -9999px;
                    background: url("/img/trip/common/sp_trip.png") 0 -280px no-repeat;
                }

                .bx-next {
                    position: absolute;
                    top: 183px;
                    right: 0px;
                    display: block;
                    width: 32px;
                    height: 32px;
                    text-indent: -9999px;
                    background: url("/img/trip/common/sp_trip.png") -38px -280px no-repeat;
                }
            }
        }
    }

    .billboard ul li {
        float: left;
    }

    .billboard_box .billboard_controls {
        .controls {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;

            .prev {
                position: absolute;
                top: 183px;
                left: 0px;
                display: block;
                width: 32px;
                height: 32px;
                text-indent: -9999px;
                background: url("/img/trip/common/sp_trip.png") 0 -280px no-repeat;
            }

            .next {
                position: absolute;
                top: 183px;
                right: 0px;
                display: block;
                width: 32px;
                height: 32px;
                text-indent: -9999px;
                background: url("/img/trip/common/sp_trip.png") -38px -280px no-repeat;
            }
        }

        .pagination {
            position: absolute;
            top: 21px;
            right: 21px;

            span {
                float: left;
                display: block;
                width: 10px;
                height: 10px;
                margin-left: 5px;
                border-radius: 50%;
                border: 1px solid #fff;
                cursor: pointer;

                &.swiper-pagination-bullet-active {
                    background: #fff;
                }
            }
        }
    }
}

.trip_bnr_box {
    position: relative;
    width: 1200px;
    margin: 33px auto -3px;
    overflow: hidden;

    .tit_area {
        overflow: hidden;
    }

    h1 {
        float: left;
        width: 176px;
        height: 25px;
        font-size: 14px;
        line-height: 1.5;
        text-indent: -9999px;
        background: url("/img/trip/common/tit_bnr.png") no-repeat;
    }

    .sub_text {
        float: left;
        margin-top: 2px;
        font-size: 14px;
        color: #333;
    }

    .list_box {
        position: relative;
        overflow: hidden;
        width: 1220px;
        height: 120px;
        margin-top: 15px;

        > ul {
            overflow: hidden;
            z-index: 1;

            > li {
                float: left;
                width: 590px;
                height: 120px;
                margin-right: 20px;

                .prd_area {
                    display: block;
                    position: relative;
                    height: 100%;
                }

                .prd_bnr {
                    width: 590px;
                    height: 120px;
                }

                .bg_gradation {
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 588px;
                    height: 118px;
                    background: url("/img/trip/common/bg_gradation.png") no-repeat;
                    border: 1px solid #dbdbdb;
                }

                .prd_info {
                    position: absolute;
                    left: 25px;
                    top: 30px;
                    z-index: 1;

                    .bnr_s {
                        float: left;
                        width: 30px;
                        height: 20px;
                        margin-right: 4px;
                    }

                    .bnr_title {
                        float: left;
                        margin-top: -7px;
                        font-size: 20px;
                        color: #333;
                    }

                    .bnr_txt {
                        clear: both;
                        display: block;
                        margin-top: 2px;
                        line-height: 1.4;
                    }
                }
            }
        }
    }

    .tripbnr_func {
        position: absolute;
        top: 0px;
        width: 1200px;
        z-index: 2;

        .btn_paging {
            position: absolute;
            top: 30px;
            width: 60px;
            height: 60px;
            text-indent: -9999px;

            &.btn_prev {
                left: 0px;
                background: url("/img/trip/common/sp_trip.png") 0 -210px no-repeat;
            }

            &.btn_next {
                right: 0px;
                background: url("/img/trip/common/sp_trip.png") -70px -210px no-repeat;
            }
        }
    }

    .list_box:hover .tripbnr_func .btn_paging {
        &.btn_prev {
            right: 0px;
            background: url("/img/trip/common/sp_trip.png") -140px -210px no-repeat;
        }

        &.btn_next {
            right: 0px;
            background: url("/img/trip/common/sp_trip.png") -210px -210px no-repeat;
        }
    }
}

.trip_deal_box {
    z-index: 1;
    position: relative;
    width: 1200px;
    margin: 39px auto 0 auto;
    padding: 0 8px 42px 8px;

    .tit_area {
        overflow: hidden;
    }

    h1 {
        float: left;
        width: 73px;
        height: 30px;
        text-indent: -9999px;
        background: url("/img/trip/common/sp_trip.png") 0 -580px no-repeat;
    }

    .sub_text {
        float: left;
        margin-top: 2px;
        font-size: 14px;
        color: #333;
    }

    .list_box {
        overflow: hidden;
        width: 1220px;
        margin-top: 15px;

        li {
            float: left;
            width: 385px;
            height: 327px;
            margin: 0 19px 20px 0;
            border: 1px solid #ccc;
            background: #fff;

            &:hover {
                border: 1px solid #f43142;
            }
        }

        .product_type1 {
            .thumb {
                height: 189px;
                text-align: center;

                .icon_shockingdeal {
                    position: absolute;
                    top: 9px;
                    left: 9px;
                    width: 66px;
                    height: 28px;
                    text-indent: -9999px;
                    background: url("/img/trip/common/sp_trip.png") 0px -930px no-repeat;
                }

                img {
                    width: 385px;
                    height: 189px;
                }
            }

            .summary_in {
                padding: 7px 14px 7px 14px;

                .subject {
                    height: 20px;
                    font-size: 16px;
                    margin-bottom: 35px;
                }

                .txt_op {
                    position: absolute;
                    top: 32px;
                    left: 14px;
                    overflow: hidden;
                    width: 357px;
                    height: 17px;
                    font-size: 12px;
                    color: #999;
                }

                .info {
                    margin-top: 17px;

                    .sale .n {
                        font-size: 20px;
                    }

                    .price {
                        .price_normal {
                            bottom: 22px;
                            color: #666;

                            .n {
                                font-size: 14px;
                            }
                        }

                        .n {
                            font-size: 20px;
                        }
                    }

                    .buy_history {
                        position: absolute;
                        top: 76px;
                        right: 14px;
                        font-size: 12px;
                        color: #999;
                    }
                }
            }

            .add {
                position: relative;
                height: 33px;
                padding: 0 10px 0 15px;
                border-top: 1px solid #eee;
                background: #fff;

                .num {
                    vertical-align: 5px;
                }

                .company {
                    position: absolute;
                    top: 9px;
                    right: 15px;
                    height: 16px;
                    font-size: 12px;
                    line-height: 15px;
                    color: #999;
                }
            }
        }
    }

    .btn_more {
        display: block;
        height: 41px;
        padding: 13px 0 0 0;
        text-align: center;
        font-size: 18px;
        text-decoration: none;
        border: 1px solid #ccc;
        color: #666;
        box-shadow: 0px 2px 0px 0px rgba(204, 204, 204, 0.21);
        background: #f7f9fa;

        strong {
            display: inline-block;
            height: 25px;
            color: #5770fe;
            text-decoration: none;
            background: url("/img/trip/common/bg_link_line_1.png") bottom left repeat-x;
        }

        span.arrow {
            display: inline-block;
            width: 6px;
            height: 11px;
            margin: 0 0 0 9px;
            vertical-align: 2px;
            background: url("/img/trip/common/sp_trip.png") -370px 0 no-repeat;
        }
    }
}

.weekend_special_box {
    position: relative;
    width: 100%;
    height: 562px;
    background: #dae0f4;

    .inner_box {
        width: 1218px;
        margin: 0 auto;
        padding: 39px 0 0 0;
    }

    .tit_area {
        overflow: hidden;
    }

    h1 {
        float: left;
        width: 200px;
        height: 30px;
        margin-left: 9px;
        font-size: 14px;
        line-height: 1.5;
        text-indent: -9999px;
        background: url("/img/trip/common/sp_trip.png") 0 -550px no-repeat;
    }

    .sub_text {
        float: left;
        margin-top: 2px;
        font-size: 14px;
        color: #333;
    }

    .list_box {
        position: relative;
        overflow: hidden;
        width: 1218px;
        height: 466px;
        margin-top: 15px;

        > ul {
            overflow: hidden;
            position: relative;
            z-index: 1;
            width: 1620px;
            padding: 0 0 10px 0px;

            > li {
                position: relative;
                float: left;
                width: 285px;
                margin: 0 11px 0 9px;
                box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25);
                background: #fff;

                .product_type1 {
                    .thumb {
                        overflow: hidden;
                        text-align: center;
                        width: 285px;
                        height: 285px;

                        img {
                            width: 285px;
                            height: 285px;
                        }
                    }

                    .summary_in {
                        padding: 7px 14px 9px 14px;

                        .subject {
                            overflow: hidden;
                            height: 42px;
                            font-size: 16px;
                            margin-bottom: 15px;
                            line-height: 21px;
                        }

                        .info {
                            margin-top: 0px;

                            .sale .n {
                                font-size: 20px;
                            }

                            .price {
                                .price_normal {
                                    bottom: 20px;
                                    color: #666;

                                    .n {
                                        font-size: 14px;
                                    }
                                }

                                .n {
                                    font-size: 20px;
                                }
                            }
                        }
                    }

                    .add {
                        z-index: 2;
                        position: relative;
                        width: 253px;
                        height: 39px;
                        padding: 4px 15px 0 15px;
                        border: 1px solid #f9fafe;
                        border-top: none;
                        background: #f9fafe;
                    }
                }

                &:hover .product_type1 .add {
                    border: 1px solid #f43142;
                    border-top: none;
                }

                .product_type1 {
                    .add {
                        a {
                            font-size: 12px;
                            color: #999;
                            text-decoration: none;
                        }

                        .icon {
                            margin-right: 3px;
                            vertical-align: -8px;
                        }

                        span.arrow {
                            display: inline-block;
                            width: 7px;
                            height: 11px;
                            margin-left: 7px;
                            vertical-align: -1px;
                            background: url("/img/trip/common/sp_trip.png") -440px 0 no-repeat;
                        }
                    }

                    .border_frame {
                        display: none;
                    }
                }

                &:hover .product_type1 .border_frame {
                    display: block;
                    z-index: 1;
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    width: 100%;
                    height: 100%;
                    border: 1px solid #f43142;
                    box-sizing: border-box;
                }
            }
        }
    }

    .paging {
        position: absolute;
        top: 453px;
        left: 50%;
        width: 1200px;
        margin: 0 0 0 -600px;
        text-align: center;
        font: 0/0 arial;

        .ico {
            width: 10px;
            height: 10px;
            margin-left: 5px;
            text-indent: -9999px;
            background: url("/img/trip/common/sp_trip.png") -420px -210px no-repeat;

            &.on {
                background: url("/img/trip/common/sp_trip.png") -440px -210px no-repeat;
            }
        }
    }

    .billboard_func {
        z-index: 2;
        position: absolute;
        top: 0px;
        left: 50%;
        width: 1200px;
        margin: 0 0 0 -600px;

        .btn_paging {
            position: absolute;
            top: 165px;
            width: 60px;
            height: 60px;
            text-indent: -9999px;

            &.btn_prev {
                left: 0px;
                background: url("/img/trip/common/sp_trip.png") 0 -210px no-repeat;
            }

            &.btn_next {
                right: 0px;
                background: url("/img/trip/common/sp_trip.png") -70px -210px no-repeat;
            }
        }
    }

    .list_box:hover .billboard_func .btn_paging {
        &.btn_prev {
            right: 0px;
            background: url("/img/trip/common/sp_trip.png") -140px -210px no-repeat;
        }

        &.btn_next {
            right: 0px;
            background: url("/img/trip/common/sp_trip.png") -210px -210px no-repeat;
        }
    }
}

.trip_best_list_box {
    position: relative;
    width: 1200px;
    margin: 40px auto 0 auto;
    padding: 0 8px 40px 8px;

    h1 {
        float: left;
        width: 130px;
        height: 30px;
        font-size: 14px;
        line-height: 1.5;
        text-indent: -9999px;
        background: url("/img/trip/common/sp_trip.png") 0 -610px no-repeat;
    }

    .tit_area {
        overflow: hidden;
    }

    .sub_text {
        float: left;
        margin-top: 2px;
        font-size: 14px;
        color: #333;
    }

    .tab_menu_type_1 {
        top: 0px;
    }

    .list_box {
        overflow: hidden;
        width: 1220px;
        margin-top: 26px;

        li {
            position: relative;
            float: left;
            width: 224px;
            height: 358px;
            margin: 0 18px 21px 0;
            border: 1px solid #eff2f4;
            background: #fff;

            &:hover {
                border: 1px solid #f43142;

                .product_type1 {
                    border-bottom: none;
                }
            }

            .product_type1 {
                border-bottom: 1px solid #ccc;

                .thumb {
                    overflow: hidden;
                    width: 224px;
                    height: 224px;

                    .num {
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        width: 29px;
                        height: 25px;
                        padding: 3px 11px 0 0;
                        font-size: 16px;
                        font-weight: bold;
                        color: #fff;
                        text-align: center;
                        background: url("/img/trip/common/sp_trip.png") -380px 0 no-repeat;
                    }

                    img {
                        width: 224px;
                        height: 224px;
                    }
                }

                .summary_in {
                    padding: 5px 14px 8px 14px;

                    .subject {
                        height: 35px;
                        font-size: 14px;
                        line-height: 17px;
                    }

                    .subject_row2 {
                        height: 35px;
                    }

                    .info {
                        margin-top: 17px;

                        .price {
                            .price_normal {
                                bottom: 20px;
                                color: #666;

                                .n {
                                    font-size: 14px;
                                }
                            }

                            .n {
                                font-size: 20px;
                            }
                        }
                    }
                }

                .add {
                    position: relative;
                    height: 34px;
                    border-top: 1px solid #eee;
                    background: #fff;

                    .company {
                        position: absolute;
                        top: 9px;
                        right: 15px;
                        height: 16px;
                        font-size: 12px;
                        line-height: 15px;
                        color: #999;
                    }
                }

                .border_frame {
                    display: none;
                }
            }
        }
    }

    .btn_more {
        display: block;
        height: 41px;
        padding: 15px 0 0 0;
        text-align: center;
        font-size: 18px;
        text-decoration: none;
        border: 1px solid #ccc;
        color: #666;
        box-shadow: 0px 2px 0px 0px rbga(204, 204, 204, 0.21);
        background: #f7f9fa;

        strong {
            display: inline-block;
            height: 25px;
            color: #5770fe;
            text-decoration: none;
            background: url("/img/trip/common/bg_link_line_1.png") bottom left repeat-x;
        }

        span.arrow {
            display: inline-block;
            width: 6px;
            height: 11px;
            margin: 0 0 0 9px;
            vertical-align: 2px;
            background: url("/img/trip/common/sp_trip.png") -370px 0 no-repeat;
        }
    }
}

.trip_company_goto_box {
    position: relative;
    width: 1200px;
    margin: 0px auto 0 auto;
    padding: 0 8px 40px 8px;

    .inner_box {
        width: 1200px;
        margin: 0 auto;
    }

    .tit_area {
        overflow: hidden;
    }

    h1 {
        float: left;
        width: 166px;
        height: 30px;
        font-size: 14px;
        line-height: 1.5;
        text-indent: -9999px;
        background: url("/img/trip/common/sp_trip.png") 0 -640px no-repeat;
    }

    .sub_text {
        float: left;
        margin-top: 2px;
        font-size: 14px;
        color: #333;
    }

    .list_box {
        overflow: hidden;
        position: relative;
        width: 1200px;
        margin-top: 14px;
        background: url("/img/trip/common/bg_line_2.png") repeat-x;

        ul {
            overflow: hidden;
            width: 1200px;
            background: url("/img/trip/common/bg_line_2.png");

            li {
                float: left;
                width: 149px;
                height: 64px;
                padding: 0 0 1px 1px;
                text-align: center;

                a {
                    display: block;
                    width: 148px;
                    height: 50px;
                    padding: 14px 0 0 0;
                    background: #fff;

                    img {
                        width: 120px;
                        height: 33px;
                    }
                }
            }
        }

        .billboard_func {
            position: absolute;
            top: 0px;
            left: 0;
            width: 1200px;

            .btn_paging {
                position: absolute;
                top: 48px;
                width: 40px;
                height: 40px;
                text-indent: -9999px;
                background: url("/img/trip/common/sp_trip.png") -460px -210px no-repeat;

                &.btn_prev {
                    left: 0px;
                    background: url("/img/trip/common/sp_trip.png") -460px -210px no-repeat;
                }

                &.btn_next {
                    right: 0px;
                    background: url("/img/trip/common/sp_trip.png") -510px -210px no-repeat;
                }
            }
        }

        &:hover .billboard_func .btn_paging {
            &.btn_prev {
                left: 0px;
                background: url("/img/trip/common/sp_trip.png") -560px -210px no-repeat;
            }

            &.btn_next {
                right: 0px;
                background: url("/img/trip/common/sp_trip.png") -610px -210px no-repeat;
            }
        }
    }
}

.trip_strong_point_box {
    position: relative;
    height: 320px;
    padding: 0 8px 0 8px;
    background: #dceff9;

    .list_box {
        overflow: hidden;
        width: 1180px;
        margin: 0 auto;
        padding: 62px 0 0 0;

        li {
            float: left;
            width: 295px;
            text-align: center;

            [class^="ico_"] {
                display: block;
                width: 91px;
                height: 107px;
                margin: 0 auto;
                background: url("/img/trip/common/sp_trip.png") no-repeat;
            }

            .ico_visual_01 {
                background-position: 0 -670px;
            }

            .ico_visual_02 {
                background-position: -100px -670px;
            }

            .ico_visual_03 {
                background-position: -200px -670px;
            }

            .ico_visual_04 {
                background-position: -300px -670px;
            }

            dl {
                margin: 13px 0 0 0;

                dt {
                    font-size: 20px;
                    line-height: 22px;
                    color: #044455;

                    a {
                        color: #044455;
                        margin: 0 5px 0 5px;

                        .arrow {
                            display: inline-block;
                            width: 7px;
                            height: 11px;
                            margin: 0 0 0 5px;
                            vertical-align: 1px;
                            background: url("/img/trip/common/sp_trip.png") -430px 0 no-repeat;
                        }
                    }
                }

                dd {
                    margin-top: 13px;
                    font-size: 12px;
                    line-height: 20px;
                    color: #778790;

                    strong {
                        color: #044455;
                    }
                }
            }
        }
    }
}

/* 리스트 */
.location_box {
    position: relative;
    z-index: 3;
    width: 1200px;
    height: 48px;
    padding: 17px 0 0 0;
    margin: 0 auto;

    .btn_home {
        float: left;
        width: 22px;
        height: 18px;
        margin: 3px 9px 0 0;
        text-indent: -9999px;
        background: url("/img/trip/common/sp_trip.png") -460px 0 no-repeat;
    }

    .depth_info {
        position: relative;
        float: left;
        padding: 0 16px 0 9px;
        vertical-align: middle;
        background: url("/img/trip/common/sp_trip.png") right -203px no-repeat;

        &.first {
            padding-left: 0px;
        }

        > {
            a {
                display: block;
                overflow: hidden;
                height: 22px;
            }

            button {
                display: block;
                overflow: hidden;
                height: 22px;
                background: none;
            }
        }

        strong {
            position: relative;
            font-size: 14px;
            line-height: 1.5;
            font-weight: normal;
            vertical-align: 23px;
        }

        span {
            &.arrow {
                display: none;
                width: 7px;
                height: 14px;
                margin: 0px 4px 0 0;
                vertical-align: 21px;
                background: url("/img/trip/common/sp_trip.png") -530px 2px no-repeat;
            }

            &.ico {
                position: relative;
                display: inline-block;
                width: 14px;
                height: 14px;
                vertical-align: 20px;
                margin: 0 0 0 6px;
                background: url("/img/trip/common/sp_trip.png") -490px 0 no-repeat;
            }
        }

        .btn_del {
            display: inline-block;
            width: 15px;
            height: 15px;
            font-size: 14px;
            line-height: 1.5;
            text-indent: -9999px;
            vertical-align: 19px;
            margin: 0 0 0 6px;
            background: url("/img/trip/common/sp_trip.png") -610px 0 no-repeat;
        }

        .list_box {
            display: none;
            position: absolute;
            top: 23px;
            left: 10px;
            overflow: hidden;
            width: 156px;
            padding: 11px 0 13px 0;
            border: 1px solid #5770fe;
            background: #fff;
        }

        &.first .list_box {
            left: 0px;
        }

        .list_box {
            &.list_2 {
                width: 312px;
            }

            &.list_3 {
                width: 468px;
            }

            &.list_4 {
                width: 624px;
            }

            &.list_5 {
                width: 780px;
            }

            &.list_6 {
                width: 936px;
            }

            ul {
                float: left;
                width: 120px;
                padding: 0 18px 0 18px;

                li {
                    padding: 3px 0 4px 0;

                    a {
                        color: #333;

                        &.selected {
                            color: #5770fe;
                        }

                        &:hover {
                            color: #5770fe;
                            font-weight: bold;
                        }
                    }
                }
            }
        }

        &.selected {
            strong {
                font-weight: bold;
                color: #5770fe;
            }

            span.ico {
                background: url("/img/trip/common/sp_trip.png") -510px 0 no-repeat;
            }

            .list_box {
                display: block;
            }
        }

        &.now {
            background: none;

            strong {
                font-size: 14px;
                line-height: 1.5;
                font-weight: bold;
                color: #111;
            }
        }
    }
}

.category_search_option_wrap {
    z-index: 2;
    position: relative;
    width: 1200px;
    margin: 0 auto 71px auto;
    padding: 15px 0 0px 0;
    border-top: 2px solid #000;
    border-bottom: 1px solid #ccc;

    &.researchType {
        border-bottom: none;
        margin-bottom: 0px;
    }

    .result_info {
        min-height: 35px;
        padding: 0 0 10px 0;
        font-size: 18px;
        font-weight: normal;
        border-bottom: 1px solid #e5e5e5;

        .search_text {
            color: #ff3333;
        }

        .num {
            font-size: 14px;
            color: #999;
        }
    }

    .category_list {
        overflow: hidden;
        width: 1199px;
        padding: 19px 0 0 0;

        ul {
            width: 1200px;
            margin-bottom: 19px;
            font: 0/0 arial;

            li {
                display: inline-block;
                width: 180px;
                height: 32px;
                padding: 0 0 0 20px;
                font-size: 12px;
                vertical-align: top;
                line-height: 15px;
                background: url("/img/trip/common/bg_line_3.png");

                a {
                    color: #333;

                    &:hover {
                        font-weight: bold;
                        color: #333;
                    }

                    &.selected {
                        font-weight: bold;
                        color: #ff3333;
                    }
                }

                .num {
                    font-size: 11px;
                    font-weight: normal;
                    font-family: tahoma, sans-serif;
                    color: #999;
                }
            }
        }
    }

    .check_option_box {
        position: relative;
        z-index: 1;
        border-top: 1px solid #ccc;
        background: #fafafa;

        .filter_close {
            display: none !important;
        }

        .filter_open {
            display: block;
        }

        .option_box {
            border-top: 1px solid #e5e5e5;

            &:after {
                content: ".";
                display: block;
                clear: both;
                height: 0;
                visibility: hidden;
            }

            display: inline-block;
            position: relative;
            z-index: 1;
            display: block;

            &.zindex {
                position: relative;
                z-index: 2;
            }

            .option_detail {
                float: left;
                width: 1112px;
                margin: 0 0 0 -1px;
                border-left: 1px solid #e5e5e5;

                &:after {
                    content: ".";
                    display: block;
                    clear: both;
                    height: 0;
                    visibility: hidden;
                }

                display: inline-block;
            }
        }

        .option_box_type_2 .option_detail {
            float: left;
            width: 1112px;
            margin: 0 0 0 -1px;
            border-left: 1px solid #e5e5e5;

            &:after {
                content: ".";
                display: block;
                clear: both;
                height: 0;
                visibility: hidden;
            }

            display: inline-block;
        }

        .option_box {
            .title {
                float: left;
                width: 86px;
                min-height: 34px;
                padding: 14px 0 0 0px;
                font-size: 12px;
                font-weight: bold;
                color: #111;
                text-align: center;
                letter-spacing: 0px;
                border-right: 1px solid #e5e5e5;
            }

            .title_2 {
                float: left;
                width: 86px;
                min-height: 48px;
                padding: 0px 0 0 0px;
                font-size: 12px;
                font-weight: bold;
                color: #111;
                text-align: center;
                letter-spacing: 0px;
                line-height: 48px;
                border-left: 1px solid #e5e5e5;
                border-right: 1px solid #e5e5e5;
            }

            .option_list {
                float: left;
                width: 1103px;
                margin-left: -1px;
                padding: 0px 0 0 10px;

                li {
                    float: left;
                    position: relative;
                    margin: 10px 10px 10px 10px;
                    padding: 0px;

                    a {
                        display: block;
                        position: relative;
                        height: 28px;
                        padding: 0 14px 0 13px;
                        color: #333;
                        font-size: 12px;
                        line-height: 27px;
                        border-radius: 15px;
                        text-decoration: none;
                    }

                    .btn_del {
                        display: none;
                    }

                    a {
                        &:hover {
                            color: #fff;
                            text-decoration: none;
                            background: #464957;
                        }

                        &:focus {
                            text-decoration: none;
                        }

                        &:active {
                            text-decoration: none;
                            outline: 0;
                        }
                    }

                    &.selected {
                        a {
                            color: #fff;
                            background: #464957;
                        }

                        .radio_design_3 + label.css-label,
                        .check_design_3 + label.css-label {
                            color: #fff;
                            text-decoration: none;
                            background: #464957;
                        }

                        .btn_del {
                            display: none;
                        }

                        &:hover .btn_del {
                            display: block;
                            position: absolute;
                            top: -5px;
                            right: -7px;
                            width: 17px;
                            height: 17px;
                            text-indent: -9999px;
                            background: url("/img/trip/common/sp_trip.png") -540px 0 no-repeat;
                        }
                    }
                }
            }

            .option_list_2 {
                overflow: hidden;
                float: left;
                width: 380px;
                margin-left: -1px;
                padding: 0px 0 0 10px;

                li {
                    float: left;
                    margin: 3px 0 0 0;
                }
            }
        }

        .input_type_1 {
            z-index: 2;
            position: relative;
            float: left;
            padding: 10px 20px 0 20px;

            .info_input_1 {
                width: 128px;
                height: 30px;
                font-size: 12px;
                padding-bottom: 4px;

                .cal {
                    top: 4px;
                }
            }

            .comp_select_type_1 {
                width: 85px;

                .btn_select {
                    height: 30px;
                }
            }

            .info_input_2 {
                width: 155px;
                height: 24px;
                font-size: 12px;

                &.room {
                    width: 78px;
                    height: 24px;
                    font-size: 12px;
                }
            }
        }

        .option_box {
            &.start_day {
                .option_list {
                    width: 848px;
                }

                .trip_date_picker_v2 {
                    position: absolute;
                    top: 45px;
                    left: 20px;
                }
            }

            &.check_in_out .trip_date_picker_v2 {
                position: absolute;
                top: 45px;
                left: 20px;
            }

            &.room_check {
                .input_type_1 {
                    .comp_select_type_1 {
                        float: left;
                    }

                    .btn_type_02 {
                        position: relative;
                        z-index: 2;
                        width: 88px;
                        height: 30px;
                        padding: 0 0 4px 0;
                        margin: 0 0 0 -1px;
                        font-size: 12px;
                        font-weight: bold;
                        color: #273259;
                        border: 1px solid #273259;
                        background: #fff;

                        span.ico {
                            display: inline-block;
                            width: 10px;
                            height: 10px;
                            vertical-align: -1px;
                            margin: 0 5px 0 0;
                            background: url("/img/trip/common/sp_trip.png") -310px 0 no-repeat;
                        }
                    }
                }

                .room_addition {
                    position: absolute;
                    top: 45px;
                    left: 20px;

                    .btn_type_02 {
                        width: 100px;
                        height: 36px;
                        padding: 0 0 5px 0;
                        font-size: 14px;
                        font-weight: bold;
                        letter-spacing: -1px;
                        color: #5770fe;
                        border: 1px solid #6483dc;
                        background: #fff;
                    }
                }
            }

            &.search_area {
                .input_type_1 {
                    float: left;
                    width: 1073px;
                    position: relative;
                    padding: 10px 20px 0 20px;

                    .info_input_2 {
                        float: left;
                        width: 89px;
                        height: 22px;
                        font-size: 12px;
                        font-family: tahoma, sans-serif;
                        color: #999;

                        &:-ms-input-placeholder,
                        &::-webkit-input-placeholder,
                        &::-moz-placeholder {
                            color: #ccc;
                        }

                        &.focus,
                        &.active {
                            color: #999;
                        }
                    }

                    .ico_wave {
                        float: left;
                        margin: 0 9px 0 8px;
                        padding: 5px 0 0 0;
                        font-size: 11px;
                        color: #999;
                    }

                    .btn_price_search {
                        float: left;
                        width: 70px;
                        height: 28px;
                        margin: 0 10px 0 10px;
                        padding-bottom: 3px;
                        font-size: 12px;
                        font-weight: bold;
                        border: 1px solid #bdbdbd;
                        color: #111;
                        background: #fff;
                    }

                    .check_design_2 + label.css-label span {
                        &.shocking_deal {
                            display: block;
                            width: 44px;
                            height: 18px;
                            margin-right: 10px;
                            text-indent: -9999px;
                            background: url("/img/trip/common/sp_trip.png") -320px -51px no-repeat;
                        }

                        &.txt {
                            display: block;
                            min-width: 44px;
                            height: 18px;
                            padding-top: 2px;
                            text-indent: 0px;
                            font-size: 12px;
                        }
                    }
                }

                .result_in_search {
                    position: absolute;
                    right: 23px;
                    top: 10px;

                    .info_input_2 {
                        width: 298px;
                        height: 22px;
                        font-size: 12px;
                    }

                    .btn_search {
                        float: left;
                        width: 50px;
                        height: 28px;
                        margin: 0 0 0 -1px;
                        padding-bottom: 3px;
                        font-size: 12px;
                        font-weight: bold;
                        border: 1px solid #bdbdbd;
                        color: #111;
                        background: #fff;
                    }
                }
            }
        }
    }

    .btn_option_open {
        position: absolute;
        bottom: -42px;
        width: 395px;
        height: 41px;
        left: 50%;
        margin-left: -197px;
        font-size: 14px;
        color: #666;
        text-align: center;
        background: url("/img/trip/common/sp_trip.png") 0 -790px no-repeat;

        strong {
            color: #333;
        }

        span.arrow {
            display: inline-block;
            width: 18px;
            height: 10px;
            margin-left: 10px;
            background: url("/img/trip/common/sp_trip.png") -630px 0 no-repeat;
        }
    }

    .btn_option_close {
        position: absolute;
        bottom: -42px;
        width: 395px;
        height: 41px;
        left: 50%;
        margin-left: -197px;
        font-size: 14px;
        color: #666;
        text-align: center;
        background: url("/img/trip/common/sp_trip.png") 0 -790px no-repeat;

        strong {
            color: #333;
        }

        span.arrow {
            display: inline-block;
            width: 18px;
            height: 10px;
            margin-left: 10px;
            background: url("/img/trip/common/sp_trip.png") -650px 0 no-repeat;
        }
    }

    .select_history_list .result_txt {
        display: none;

        .btn_op_change {
            position: absolute;
            top: 20px;
            right: 20px;
            height: 28px;
            font-size: 14px;
            color: #fff;
            text-align: center;
            padding: 0 14px 4px 12px;
            border-radius: 15px;
            background: #4b5a98;
        }
    }

    &.fixed .select_history_list {
        z-index: 9999;
        position: fixed;
        top: 0px;
        display: block;
        width: 1200px;
        margin: 0 auto;
        box-shadow: 0px 2px 5px 0px rgba(204, 204, 204, 0.9);

        .result_txt {
            position: relative;
            display: block;
            width: 1200px;
            height: 55px;
            padding: 15px 0 0 0;
            font-size: 22px;
            color: #fff;
            text-align: center;
            background: #2b3769;
        }
    }

    .option_select_list {
        position: relative;
        padding: 13px 0 16px 19px;
        border-top: 1px solid #e5e5e5;
        background: #fff;

        ul {
            overflow: hidden;
            max-width: 1050px;

            li {
                float: left;
                padding: 0 19px 0 0;
                font-size: 12px;

                .type_1 {
                    display: inline-block;
                    width: 16px;
                    height: 12px;
                    margin: 0 5px 0 5px;
                    vertical-align: -4px;
                    text-indent: -99999px;
                    background: url("/img/trip/common/sp_trip.png") -670px 0 no-repeat;
                }

                .type_2 {
                    display: inline-block;
                    width: 16px;
                    height: 12px;
                    margin: 0 5px 0 5px;
                    vertical-align: -6px;
                    text-indent: -99999px;
                    background: url("/img/trip/common/sp_trip.png") -690px 0 no-repeat;
                }

                .btn_del {
                    width: 11px;
                    height: 11px;
                    margin-left: 5px;
                    text-indent: -9999px;
                    background: url("/img/trip/common/sp_trip.png") -560px 0 no-repeat;
                }
            }
        }

        &.op_type_1 ul li.result {
            color: #ff3333;
        }

        &.op_type_2 ul li.result {
            color: #5770fe;
        }

        .btn_reset {
            position: absolute;
            right: 21px;
            top: 50%;
            margin-top: -12px;
            font-size: 12px;
            background: #fff;

            span.ico {
                display: inline-block;
                width: 20px;
                height: 17px;
                margin-right: 5px;
                vertical-align: middle;
                background: url("/img/trip/common/sp_trip.png") -580px 0 no-repeat;
            }
        }
    }
}
* html .category_search_option_wrap .check_option_box {
    .option_box {
        height: 1%;

        .option_detail {
            height: 1%;
        }
    }

    .option_box_type_2 .option_detail {
        height: 1%;
    }
}
/* 사용처 여행 리스트,호텔 메인 */
.trip_deal_box_1 {
    z-index: 1;
    position: relative;
    width: 1200px;
    height: 496px;
    margin: 0 auto 0 auto;

    .tit_area {
        overflow: hidden;
        height: 39px;

        h1 {
            float: left;
            width: 71px;
            font-size: 22px;
            color: #111;
        }

        .sub_text {
            float: left;
            width: 320px;
            font-size: 14px;
            line-height: 38px;
            color: #666;
        }
    }

    .list_box {
        position: relative;
        overflow: hidden;
        width: 1201px;
        height: 466px;
        margin-top: 2px;

        > ul {
            overflow: hidden;
            width: 1620px;
            padding: 0 0 10px 1px;

            li {
                float: left;
                position: relative;
                width: 301px;
                height: 420px;
                margin-left: -1px;
                background: #fff;

                &:first-child {
                    width: 301px;
                }

                .product_type1 {
                    .thumb {
                        overflow: hidden;
                        width: 302px;
                        height: 300px;
                        text-align: center;

                        img {
                            width: 300px;
                            height: 300px;
                        }
                    }

                    .summary_in {
                        padding: 7px 17px 3px 17px;
                        border-top: 1px solid #ccc;

                        .subject {
                            font-size: 16px;
                        }

                        .txt_op {
                            position: absolute;
                            top: 35px;
                            left: 17px;
                            overflow: hidden;
                            width: 266px;
                            height: 17px;
                            font-size: 12px;
                            color: #999;
                        }

                        .info {
                            margin-top: 48px;

                            .sale {
                                font-size: 15px;

                                .n {
                                    font-size: 20px;
                                }
                            }
                        }

                        .price {
                            .price_normal {
                                bottom: 22px;

                                .n {
                                    font-size: 14px;
                                }
                            }

                            .n {
                                font-size: 20px;
                            }
                        }
                    }

                    .border_frame {
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        width: 299px;
                        height: 418px;
                        border: 1px solid #ccc;
                    }
                }

                &:hover {
                    z-index: 2;

                    .product_type1 .border_frame {
                        border: 1px solid #f43142;
                    }
                }
            }
        }
    }

    .paging {
        position: absolute;
        top: 443px;
        left: 50%;
        width: 1200px;
        margin: 0 0 0 -600px;
        text-align: center;
        font: 0/0 arial;

        .ico {
            width: 10px;
            height: 10px;
            margin-left: 5px;
            text-indent: -9999px;
            background: url("/img/trip/common/sp_trip.png") -420px -210px no-repeat;

            &.on {
                background: url("/img/trip/common/sp_trip.png") -440px -210px no-repeat;
            }
        }
    }

    .billboard_func {
        z-index: 3;
        position: absolute;
        top: 0px;
        left: 50%;
        width: 1200px;
        margin: 0 0 0 -600px;

        .btn_paging {
            position: absolute;
            top: 163px;
            width: 60px;
            height: 60px;
            text-indent: -9999px;

            &.btn_prev {
                left: 0px;
                background: url("/img/trip/common/sp_trip.png") 0 -210px no-repeat;
            }

            &.btn_next {
                right: 0px;
                background: url("/img/trip/common/sp_trip.png") -70px -210px no-repeat;
            }
        }
    }

    .list_box:hover .billboard_func .btn_paging {
        &.btn_prev {
            right: 0px;
            background: url("/img/trip/common/sp_trip.png") -140px -210px no-repeat;
        }

        &.btn_next {
            right: 0px;
            background: url("/img/trip/common/sp_trip.png") -210px -210px no-repeat;
        }
    }

    .btn_more {
        position: absolute;
        top: 6px;
        right: 0px;
        font-size: 12px;
        color: #333;

        span.arrow {
            display: inline-block;
            width: 4px;
            height: 7px;
            margin: 0 0 0 5px;
            vertical-align: 0;
            background: url("/img/trip/common/sp_trip.png") -710px 0 no-repeat;
        }
    }
}

.banner_box_1 {
    z-index: 1;
    position: relative;
    overflow: hidden;
    width: 1200px;
    height: 120px;
    margin: 29px auto 20px auto;

    ul {
        width: 1200px;
        height: 120px;
        text-align: center;

        li {
            text-align: center;
            height: 120px;

            a {
                display: inline-block;
                width: 100%;
            }

            img {
                max-width: 1200px;
                height: 120px;
                vertical-align: top;
            }
        }
    }

    .billboard_func {
        z-index: 3;
        position: absolute;
        top: 0px;
        left: 50%;
        width: 1200px;
        margin: 0 0 0 -600px;

        .btn_paging {
            position: absolute;
            top: 46px;
            width: 28px;
            height: 28px;
            text-indent: -9999px;

            &.btn_prev {
                left: 0px;
                background: url("/img/trip/common/sp_trip.png") -810px -210px no-repeat;
            }

            &.btn_next {
                right: 0px;
                background: url("/img/trip/common/sp_trip.png") -850px -210px no-repeat;
            }
        }
    }

    &:hover .billboard_func .btn_paging {
        &.btn_prev {
            background: url("/img/trip/common/sp_trip.png") -890px -210px no-repeat;
        }

        &.btn_next {
            background: url("/img/trip/common/sp_trip.png") -930px -210px no-repeat;
        }
    }
}

.product_list_box {
    position: relative;
    z-index: 1;
    overflow: hidden;
    width: 1200px;
    margin: 0 auto 0 auto;
    padding-top: 20px;

    .result_sort_box {
        position: relative;
        margin: 0px 0 0 0;
        height: 36px;
        padding: 13px 0 0 0;
        border-top: 1px solid #454746;

        .result_info {
            font-size: 16px;
            font-weight: bold;
            color: #333;

            strong {
                font-weight: bold;
                font-family: tahoma;
                color: #f63041;
            }
        }

        .sort_viewtype_wrap {
            position: absolute;
            top: 14px;
            right: 20px;

            &:after {
                content: ".";
                display: block;
                clear: both;
                height: 0;
                visibility: hidden;
            }

            display: inline-block;
            display: block;
        }

        .sort_menu {
            float: left;
            font-size: 14px;

            &:after {
                content: ".";
                display: block;
                clear: both;
                height: 0;
                visibility: hidden;
            }

            display: inline-block;
            display: block;

            li {
                position: relative;
                float: left;
                color: #333;

                a {
                    color: #333;
                }

                &.selected a {
                    color: #f43142;
                    text-decoration: none;

                    .t_type_01 {
                        color: #333;
                    }
                }

                .btn_help {
                    display: inline-block;
                    width: 14px;
                    height: 14px;
                    margin-left: 9px;
                    margin-top: -2px;
                    vertical-align: middle;
                    text-indent: -9999px;
                    background: url("/img/specialshop/common/sp_common.png") -40px 0px no-repeat;
                }

                .tx_bar {
                    display: inline-block;
                    width: 1px;
                    height: 13px;
                    margin: 0 8px 0 8px;
                    vertical-align: -2px;
                    background: #dbdbdb;
                }

                .help_pop {
                    top: 25px;
                    left: -10px;
                }
            }
        }

        .viewtype_menu {
            float: left;
            overflow: hidden;
            margin: 0 0 0 7px;

            li {
                float: left;
                margin: 0 0 0 14px;

                a {
                    display: block;
                    width: 23px;
                    height: 23px;
                    text-indent: -9999px;
                }

                .btn_list_type {
                    background: url("/img/specialshop/common/sp_common.png") -50px -50px no-repeat;
                }

                .btn_thumb_type {
                    background: url("/img/specialshop/common/sp_common.png") -80px -50px no-repeat;
                }

                .btn_list_type.selected {
                    background: url("/img/specialshop/common/sp_common.png") -50px -20px no-repeat;
                }

                .btn_thumb_type.selected {
                    background: url("/img/specialshop/common/sp_common.png") -80px -20px no-repeat;
                }
            }
        }
    }

    .total_listing_wrap .no_search_box {
        padding: 300px 0 275px 0;

        .ico_deco {
            display: block;
            width: 63px;
            height: 64px;
            margin: 0 auto;
            background: url("/img/trip/common/sp_trip.png") right -550px no-repeat;
        }

        .text {
            margin-top: 11px;
            font-size: 28px;
            font-weight: bold;
            color: #999;
            text-align: center;
        }
    }
}
* html .product_list_box .result_sort_box {
    .sort_viewtype_wrap,
    .sort_menu {
        height: 1%;
    }
}
/* MPSR-66118 */
.help_info_area {
    float: left;

    > input {
        height: 16px;
    }
}

.trip_prod_details table .label {
    display: inline-block;
    height: 18px;
    margin: -2px 4px 0 5px;
    padding: 0 8px;
    border: 1px solid #ecdbed;
    background-color: #fdfbfd;
    color: #9f4ba2;
    font-size: 11px;
    line-height: 20px;
    vertical-align: middle;
}

.label.all_for_coupon,
.tbl_hotel_price .label.all_for_coupon,
.trip_prod_details table .label.all_for_coupon {
    border-color: #c1a7ff;
    background-color: #f9f6ff;
    color: #6423ff;
}
/* //MPSR-66118 */
/* 퀵서치 레이어 팝업 */
.quick_search_layer_wrap {
    z-index: 100;
    position: absolute;
    top: 13px;
    left: 50%;
    width: 1200px;
    margin-left: -600px;

    .btn_quick_search {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 140px;
        height: 32px;
        text-indent: -9999px;
        background: url("/img/trip/common/sp_trip.png") -660px -210px no-repeat;
    }

    .search_option_box {
        display: none;
        position: absolute;
        top: 32px;
        left: 0px;
        width: 1158px;
        padding: 20px;
        border: 1px solid #2b3769;
        background: #fff;

        > .btn_close {
            z-index: 2;
            position: absolute;
            right: 20px;
            bottom: 20px;
            width: 15px;
            height: 14px;
            text-indent: -9999px;
            background: url("/img/trip/common/sp_trip.png") -220px -50px no-repeat;
        }
    }
}

.trip_date_picker_v2 {
    width: 747px;
    height: 452px;

    .box_calendar {
        float: left;
        overflow: hidden;
        width: 356px;
        height: 376px;
        margin-left: -1px;
        border: 1px solid #dadada;
        background-color: #fff;

        .date_g {
            overflow: hidden;
            width: 356px;
            border-bottom: 1px solid #dadada;
        }

        .nav_calender {
            margin-top: 7px;
        }

        .date_g {
            border-bottom: 0 none;
        }
    }

    .nav_calender {
        .btn_pager {
            width: 32px;
            height: 32px;
            position: absolute;
            top: 2px;

            .sp_trip {
                display: block;
                width: 32px;
                height: 32px;
                font-size: 0;
                line-height: 0;
                text-indent: -9999px;
            }
        }

        .btn_prev {
            left: 10px;
        }

        .btn_next {
            right: 10px;
        }

        .btn_prev .sp_trip {
            background-position: 0 -140px;
        }

        .btn_next .sp_trip {
            background-position: 0 -180px;
        }
    }

    .select_calender {
        position: relative;
        width: 96px;
        height: 34px;
        margin: 0 auto;

        .this_month {
            width: 96px;
            height: 34px;
            border: 1px solid #fff;
            background-color: #fff;

            .btn_select {
                display: block;
                width: 96px;
                height: 34px;
                border: 3px solid #fff;
                font-weight: bold;
                font-size: 16px;
                line-height: 28px;
                background: #fff;
            }

            .sp_trip {
                display: inline-block;
                overflow: hidden;
                vertical-align: middle;
                width: 9px;
                height: 6px;
                margin: -2px 0 0 6px;
                background: url("/img/trip/sp_trip.png") no-repeat 0 -220px;
            }
        }

        .list_calender {
            display: none;
            position: absolute;
            top: 35px;
            left: 0;
            z-index: 10;
            width: 96px;
            padding: 5px 0;
            border: 1px solid #6483dc;
            background-color: #fff;

            .select_month {
                width: 96px;
                padding: 0 13px;
                font-size: 14px;
                line-height: 24px;
                text-align: left;
                background: #fff;

                &:hover {
                    color: #6483dc;
                    text-decoration: underline;
                }
            }

            li:first-child .select_month {
                font-weight: bold;
                color: #6483dc;
            }
        }

        &.focus,
        &.active {
            border: none;

            .this_month {
                border-color: #6a84d6;

                .btn_select {
                    border-color: #ebeffe;
                    color: #6483dc;
                    background: #fff;
                }

                .sp_trip {
                    display: inline-block;
                    overflow: hidden;
                    vertical-align: middle;
                    background: url("/img/trip/sp_trip.png") no-repeat 0 -230px;
                }
            }

            .list_calender {
                display: block;
            }
        }
    }

    .layer_body {
        overflow: hidden;
        position: relative;
        padding: 16px;

        .btn_pager {
            width: 32px;
            height: 32px;
            position: absolute;
            top: 25px;
            z-index: 30;
            cursor: pointer;
            background: #fff;

            .sp_trip {
                display: inline-block;
                overflow: hidden;
                width: 32px;
                height: 32px;
                background: url("/img/trip/sp_trip.png") no-repeat;
                font-size: 0;
                line-height: 0;
                text-indent: -9999px;
                vertical-align: middle;
            }
        }

        .btn_prev {
            left: 27px;
        }

        .btn_next {
            right: 27px;
        }

        .btn_prev .sp_trip {
            background-position: 0 -140px;
        }

        .btn_next .sp_trip {
            background-position: 0 -180px;
        }
    }

    .cal_wrap {
        overflow: hidden;
        padding: 0px 0 0 1px;

        .box_calendar {
            width: 356px;

            .date_g {
                margin-top: -4px;
            }
        }

        .tbl_calender {
            float: left;
            width: 358px;
            margin-right: 20px;
            border: 1px solid #f5f5f5;
            border-width: 0 1px 0 0;
            margin-left: -1px;

            caption {
                position: relative;
                height: 1px;
                border: 1px solid #f5f5f5;
                background: #fafafa;
                color: #333;
                font-family: "Tahoma";
                font-size: 22px;
                line-height: 56px;

                .btn_prev {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 47px;
                    height: 56px;
                    background: url("/images/bul/arr_11px_left_666.gif") no-repeat center center;
                    color: transparent;
                    font-size: 11px;
                }

                .btn_next {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 47px;
                    height: 56px;
                    background: url("/images/bul/arr_11px_right_666.gif") no-repeat center center;
                    color: transparent;
                    font-size: 11px;
                }
            }

            thead th {
                height: 33px;
                padding: 0;
                font-size: 14px;
                font-weight: bold;
                text-align: center;
                color: #636363;
                background: url("/img/trip/common/bg_line_dot_2.gif") bottom left repeat-x;

                &:first-of-type {
                    border-left: 1px solid #f5f5f5;
                }
            }

            tr:last-child td {
                border-bottom: 0 none;
            }

            td {
                position: relative;
                height: 50px;
                padding: 0;
                border-bottom: 1px solid #eee;
                border-left: 1px solid #eee;
                color: #666;
                text-align: center;

                a {
                    display: block;
                    min-height: 15px;
                    padding: 5px 0 20px 0;
                    font-size: 16px;
                    color: #636363;
                    text-decoration: none;
                }

                span {
                    display: block;
                    min-height: 15px;
                    padding: 5px 0 21px 0;
                    font-size: 16px;
                }

                .today {
                    position: absolute;
                    z-index: 5;
                    top: 0px;
                    left: 0px;
                    width: 48px;
                    height: 23px;
                    border: 1px solid #302d73;
                    background: #504f98;
                    color: #fff;
                }

                .start,
                .middle,
                .end {
                    position: absolute;
                    z-index: 5;
                    top: 0px;
                    left: 0px;
                    width: 48px;
                    height: 23px;
                    border: 1px solid #302d73;
                    background: #5477e9;
                    color: #fff;
                }

                .selected {
                    position: absolute;
                    z-index: 4;
                    top: 0px;
                    left: 0;
                    width: 50px;
                    height: 25px;
                    background: #e6edfd;
                }

                .txt {
                    position: absolute;
                    z-index: 6;
                    right: 0;
                    bottom: 5px;
                    left: 0;
                    margin: 0 auto;
                    color: #fff;
                    font-size: 11px;
                }
            }

            .sat {
                color: #5477e9;
            }

            .day_sat {
                color: #7d99ff;
            }

            .day_sun {
                color: #ff8686;
            }

            .holiday {
                color: #f43142;
            }

            .disable {
                &.day_sat {
                    color: #c4d2fb;
                }

                &.holiday,
                &.day_sun {
                    color: #fbc7c9;
                }

                color: #bcbcbc;
                background: #f8f8f8;
            }
        }
    }
}

/* [별공통] */
.selr_star {
    display: inline-block;
    overflow: hidden;
    width: 87px;
    height: 20px;
    background: url("/img/common/v2/sp_prdc_ico.png") no-repeat 0 0;
    font-size: 0;

    &.selr_star0 {
        background-position: 0 0;
    }

    &.selr_star10 {
        background-position: 0 -20px;
    }

    &.selr_star20 {
        background-position: 0 -40px;
    }

    &.selr_star30 {
        background-position: 0 -60px;
    }

    &.selr_star40 {
        background-position: 0 -80px;
    }

    &.selr_star50 {
        background-position: 0 -100px;
    }

    &.selr_star60 {
        background-position: 0 -120px;
    }

    &.selr_star70 {
        background-position: 0 -140px;
    }

    &.selr_star80 {
        background-position: 0 -160px;
    }

    &.selr_star90 {
        background-position: 0 -180px;
    }

    &.selr_star100 {
        background-position: 0 -200px;
    }
}
.selr_star_s {
    display: inline-block;
    overflow: hidden;
    width: 87px;
    height: 13px;
    background: url("/img/common/v2/sp_prdc_ico.png") no-repeat 0 0;
    font-size: 0;

    &.selr_star0 {
        background-position: 0 0;
    }

    &.selr_star10 {
        background-position: 0 -263px;
    }

    &.selr_star20 {
        background-position: 0 -283px;
    }

    &.selr_star30 {
        background-position: 0 -303px;
    }

    &.selr_star40 {
        background-position: 0 -323px;
    }

    &.selr_star50 {
        background-position: 0 -343px;
    }

    &.selr_star60 {
        background-position: 0 -363px;
    }

    &.selr_star70 {
        background-position: 0 -383px;
    }

    &.selr_star80 {
        background-position: 0 -403px;
    }

    &.selr_star90 {
        background-position: 0 -423px;
    }

    &.selr_star100 {
        background-position: 0 -443px;
    }
}
.selr_star_b {
    display: inline-block;
    overflow: hidden;
    width: 90px;
    height: 17px;
    background: url("/img/common/v2/sp_prdc_ico.png") no-repeat 0 0;
    font-size: 0;

    &.selr_star0 {
        background-position: 0 -470px;
    }

    &.selr_star10 {
        background-position: 0 -490px;
    }

    &.selr_star20 {
        background-position: 0 -510px;
    }

    &.selr_star30 {
        background-position: 0 -530px;
    }

    &.selr_star40 {
        background-position: 0 -550px;
    }

    &.selr_star50 {
        background-position: 0 -570px;
    }

    &.selr_star60 {
        background-position: 0 -590px;
    }

    &.selr_star70 {
        background-position: 0 -610px;
    }

    &.selr_star80 {
        background-position: 0 -630px;
    }

    &.selr_star90 {
        background-position: 0 -650px;
    }

    &.selr_star100 {
        background-position: 0 -670px;
    }
}

.selr_star_big {
    display: inline-block;
    overflow: hidden;
    width: 112px;
    height: 24px;
    background: url("/img/common/v2/sp_prdc_ico.png") no-repeat 0 0;
    font-size: 0;

    &.selr_star0 {
        background-position: -150px -139px;
    }

    &.selr_star10 {
        background-position: -150px -169px;
    }

    &.selr_star20 {
        background-position: -150px -199px;
    }

    &.selr_star30 {
        background-position: -150px -229px;
    }

    &.selr_star40 {
        background-position: -150px -259px;
    }

    &.selr_star50 {
        background-position: -150px -290px;
    }

    &.selr_star60 {
        background-position: -150px -320px;
    }

    &.selr_star70 {
        background-position: -150px -351px;
    }

    &.selr_star80 {
        background-position: -150px -380px;
    }

    &.selr_star90 {
        background-position: -150px -409px;
    }

    &.selr_star100 {
        background-position: -150px -440px;
    }
}

/* 나에게 딱 맞는 숙박 */
.quick_search_box .trip_type.option_2 .option_check_box_1.my_custom {
    .btn_type_02 {
        margin-right: 0;
        font-size: 16px;
    }

    .option_select_box_1 .option_detail .info_input_2 {
        width: 361px;
    }

    .option_select_box_2 .option_detail button.info_input_1 {
        width: 139px;
    }

    .option_select_box_4 {
        z-index: 1;
        float: left;
        position: relative;
        margin-right: 20px;

        .option_detail {
            position: relative;
            width: 204px;

            .comp_select_type_1 {
                width: 204px;

                .btn_select {
                    height: 35px;
                }
            }

            .my_custom_info_box {
                position: relative;
                width: 894px;
                margin-left: -690px;
            }

            .btn_select .arrow {
                top: 15px;
            }
        }
    }
}

/* 국내숙박,여행 메인에서 사용 */
.my_custom_info_box {
    display: none;
    width: 894px;
    min-height: 40px;
    margin-left: -19px;
    background: #fafafa;

    p.text {
        padding-left: 20px;
        font-size: 14px;
        color: #f43142;
        line-height: 40px;
    }

    .select_list_box {
        overflow: hidden;
        padding: 8px 0 0 20px;

        li {
            float: left;
            padding: 0 20px 11px 0;
            font-size: 14px;
            color: #5461c6;

            .btn_del {
                width: 12px;
                height: 12px;
                margin: -1px 0 0 5px;
                text-indent: -99999px;
                background: url("/img/trip/hotel/sp_hotel.png") 0 -810px no-repeat;
            }
        }
    }

    .detail_list_box {
        display: block;
        position: absolute;
        left: 0px;
        width: 890px;
        min-height: 200px;
        margin-top: 4px;
        border: 2px solid #5461c6;
        background: #fff;

        .list_box {
            padding: 20px 39px 30px 39px;

            &.bg_color {
                background: #fafafa;
            }

            h3.tit {
                font-size: 16px;
                line-height: 1.5;
                font-weight: bold;
                color: #54566d;
            }

            ul {
                overflow: hidden;
                width: 810px;
                margin: 15px 0 0 1px;
                background: url("/img/trip/hotel/bg_line_1.png");

                &.list_type_4 {
                    width: 540px;
                }

                li {
                    float: left;
                    width: 121px;
                    height: 34px;
                    padding: 0 0 0 13px;
                    font-size: 13px;
                    color: #333;
                    border-left: 1px solid #e4e4e4;

                    span.ico_dot {
                        display: none;
                    }

                    .ico {
                        display: inline-block;
                        width: 14px;
                        height: 14px;
                        margin-right: 6px;
                        background: url("/img/trip/hotel/sp_hotel_icon_1.png") no-repeat;
                        vertical-align: -3px;

                        &.ico_hotel {
                            background-position: -20px 0;
                        }

                        &.ico_resort {
                            background-position: -20px -20px;
                        }

                        &.ico_pension {
                            background-position: -20px -40px;
                        }

                        &.ico_motel {
                            background-position: -20px -60px;
                        }

                        &.ico_guesthouse {
                            background-position: -20px -80px;
                        }

                        &.ico_residence {
                            background-position: -20px -100px;
                        }

                        &.ico_glamping {
                            background-position: -20px -120px;
                        }

                        &.ico_caravan {
                            background-position: -20px -140px;
                        }
                    }

                    &.selected {
                        font-weight: bold;
                        color: #5461c6;

                        a {
                            font-weight: bold;
                            color: #5461c6;
                        }

                        span.ico_dot {
                            display: inline-block;
                            width: 4px;
                            height: 4px;
                            margin-left: 4px;
                            vertical-align: 10px;
                            background: url("/img/trip/hotel/sp_hotel.png") 0 -830px;
                        }

                        .ico {
                            &.ico_hotel {
                                background-position: 0 0;
                            }

                            &.ico_resort {
                                background-position: 0 -20px;
                            }

                            &.ico_pension {
                                background-position: 0 -40px;
                            }

                            &.ico_motel {
                                background-position: 0 -60px;
                            }

                            &.ico_guesthouse {
                                background-position: 0 -80px;
                            }

                            &.ico_residence {
                                background-position: 0 -100px;
                            }

                            &.ico_glamping {
                                background-position: 0 -120px;
                            }

                            &.ico_caravan {
                                background-position: 0 -140px;
                            }
                        }
                    }
                }
            }
        }

        .btn_close {
            position: absolute;
            top: 16px;
            right: 16px;
            width: 14px;
            height: 14px;
            text-indent: -9999px;
            background: url("/img/trip/hotel/sp_hotel.png") 0 -860px no-repeat;
        }
    }
}

/* 여행 메인, 국내 숙박, 해외호텔 사용 지도 검색 */
.popup_map_search {
    z-index: 5010;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    min-width: 1200px;
    height: 100%;
    background: #fff;
    font-family: "Helvetica Neue", "Apple SD Gothic Neo", Arial, "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif;
    line-height: 1.3;

    .search_info_box {
        position: relative;
        z-index: 30;
        width: 100%;
        height: 52px;
        padding: 21px 0 0 0;
        background: #54566d;

        .info_box {
            .sch_tx {
                float: left;
                margin: 0 16px 0 26px;
                color: #fff;
                font-size: 20px;
                font-weight: bold;
                line-height: 28px;
            }

            .sch_date {
                float: left;
                margin-right: 19px;
                color: #a9abc0;
                font-size: 16px;
                line-height: 28px;
            }

            .btn_change {
                float: left;
                width: 80px;
                height: 28px;
                border: 1px solid #ccc;
                border-radius: 2px;
                background: #65677c;
                color: #fff;
                font-size: 12px;
                line-height: 28px;

                .ico_arrow {
                    display: inline-block;
                    width: 10px;
                    height: 6px;
                    margin-left: 6px;
                    vertical-align: 1px;
                    background: url("/img/trip/hotel/sp_hotel.png") 0 -600px no-repeat;
                }
            }

            .tx_bar {
                float: left;
                width: 1px;
                height: 28px;
                margin: 0 19px 0 20px;
                background: #727486;
            }

            .result_info {
                float: left;
                color: #fff;
                font-size: 15px;
                line-height: 28px;

                .txt_num {
                    color: #9db4fc;
                }
            }
        }

        &.change_mode .info_box {
            .btn_change {
                display: none;
            }

            .sch_date {
                margin-right: 0px;
            }
        }

        .hotel_quick_search_box {
            position: absolute;
            z-index: 10;
            top: 70px;
            width: 100%;
            height: 86px;
            border-bottom: 1px solid #54566d;
            background: #fff;

            > .inner {
                display: block;
                width: 100%;
            }

            .fixed > .inner {
                z-index: 9999;
                position: fixed;
                top: 0px;
                left: 0px;
                display: block;
                width: 100%;
                margin: 0 auto;
            }

            fieldset {
                width: 100%;
                margin: 0 auto;
                box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
                background: #fff;
            }

            .field_box {
                float: left;
                width: auto;
                height: 74px;
                padding: 12px 0 0 18px;
                border: none;

                .text_search_box {
                    float: left;
                    width: 440px;
                    height: 54px;
                    margin-top: 2px;
                    border-right: 1px solid #e4e4e4;

                    dt {
                        position: relative;
                        left: 0;
                        width: 440px;
                        height: 19px;
                        margin-top: -3px;
                        font-size: 14px;
                        line-height: 1.5;
                        text-indent: 0;
                    }

                    dd {
                        position: relative;
                        margin-top: 2px;

                        input[type="text"] {
                            width: 410px;
                            height: 30px;
                            padding: 0 0 0 25px;
                            font-size: 20px;
                            color: #999;
                            border: 1px solid #fff;
                            background: url("/img/trip/hotel/sp_hotel.png") 0 8px no-repeat;

                            &.focus,
                            &.active {
                                color: #54566d;
                            }
                        }

                        .recomd_list_box {
                            position: absolute;
                            top: 57px;
                            left: -19px;
                            width: 455px;

                            ul {
                                width: 455px;

                                li {
                                    width: 455px;
                                }
                            }
                        }
                    }
                }

                .check_in_out_info_box {
                    position: relative;
                    float: left;
                    height: 72px;
                    padding: 0 0 0 30px;

                    .inner {
                        overflow: hidden;
                        width: 100%;
                    }

                    .date_info_box {
                        float: left;
                        width: 155px;
                        padding: 1px 0 0 16px;

                        dt {
                            width: auto;
                            height: 19px;
                            font-size: 14px;
                            color: #666;
                            position: relative;
                            left: 0;
                            line-height: 1.5;
                            text-indent: 0;
                        }

                        dd {
                            margin-top: -2px;
                            font-size: 23px;
                            line-height: 1.5;
                            font-family: tahoma;
                            color: #333;

                            button {
                                text-align: left;
                                margin-top: 2px;
                                line-height: 1.5;
                                font-size: 23px;
                                letter-spacing: -1px;
                                font-family: tahoma;
                                color: #333;
                                background: #fff;
                            }
                        }
                    }

                    .btn_cal {
                        position: absolute;
                        bottom: 9px;
                        left: 212px;
                        display: block;
                        width: 15px;
                        height: 9px;
                        text-indent: -9999px;
                        background: url("/img/trip/hotel/sp_hotel.png") 0 -30px no-repeat;
                    }

                    .layer_selecter {
                        position: absolute;
                        top: 79px;
                        left: -161px;
                    }
                }
            }

            .btn_box {
                float: left;
                overflow: hidden;
                padding-top: 15px;

                .btn_sch {
                    float: left;
                    width: 95px;
                    height: 56px;
                    padding-bottom: 2px;
                    font-size: 18px;
                    font-weight: bold;
                    line-height: 23px;
                    color: #fff;
                    background: #545982;
                }

                .btn_close {
                    float: left;
                    margin: 18px 0 0 32px;
                    background: #fff;

                    .ico {
                        display: block;
                        width: 22px;
                        height: 22px;
                        text-indent: -99999px;
                        background: url("/img/trip/hotel/sp_hotel.png") 0 -650px;
                    }
                }
            }
        }
    }

    .room_info_box {
        border-left: 1px solid #e4e4e4;
    }

    .search_info_box .btn_right_box {
        position: absolute;
        top: 15px;
        right: 20px;

        .btn_list {
            float: left;
            width: 120px;
            height: 40px;
            margin-left: 8px;
            padding-bottom: 2px;
            border-radius: 2px;
            background: #f8f9fd;
            color: #54566d;
            font-size: 14px;

            .ico_arrow {
                display: inline-block;
                width: 7px;
                height: 10px;
                margin-left: 7px;
                vertical-align: 0px;
                background: url("/img/trip/hotel/sp_hotel.png") 0 -610px no-repeat;
            }
        }

        .btn_close {
            float: left;
            width: 40px;
            height: 40px;
            padding-bottom: 2px;
            margin-left: 8px;
            border-radius: 2px;
            background: #f8f9fd;
            font-size: 12px;

            .ico {
                position: relative;
                display: block;
                width: 14px;
                height: 14px;
                margin: 6px 0 0 14px;
                text-indent: -99999px;
                background: url("/img/trip/hotel/sp_hotel.png") 0 -630px;
            }
        }
    }

    .map_layer_contents {
        z-index: 10;
        position: static;
        overflow: visible;
        width: 100%;
        height: 100%;

        .filter_box {
            position: absolute;
            z-index: 10;
            left: 0px;
            bottom: 0px;
            top: 70px;
            width: 313px;
            height: auto;
            border-right: 1px solid #ebebeb;
            background: #fafafa;

            &.close {
                width: 0;
                overflow-y: hidden;
            }

            h2.tit {
                padding: 18px 0 17px 28px;
                background: #fff;
            }

            .select_list {
                padding: 0 35px 8px 28px;
                margin-top: 0px;
                background: url("/img/trip/common/bg_dash_1.png") bottom left repeat-x #fff;
            }

            .dash_line {
                display: block;
                width: 100%;
                height: 1px;
                margin: -1px 0 0 0;
                padding: 0;
                border: none;
                background: url("/img/trip/common/bg_dash_1.png") top left repeat-x;
            }

            .filter_detail_box {
                overflow: hidden;
                overflow-y: auto;
                height: 100%;
            }

            .type_list_box {
                padding: 26px 0 20px 6px;
                margin: 0 0 0 28px;
            }

            .type_list_box_2 {
                padding: 7px 0 12px 6px;
                margin: 0 0 0 28px;
            }

            .btn_reset {
                top: 19px;
                right: 55px;
            }

            .price_range_box {
                padding: 16px 0 20px 6px;
                margin: 0 0 0 28px;

                .linear_graph {
                    margin: 28px 0 0 2px;
                }

                .price_info_box {
                    margin-left: 2px;
                }
            }

            .convenient_box,
            .result_search_box {
                padding: 16px 0 20px 6px;
                margin: 0 0 0 28px;
            }
        }

        .btn_filter_box.type_2 {
            z-index: 20;
            position: absolute;
            top: 76px;
            left: 0px;
            width: 331px;

            .btn_detail {
                position: absolute;
                right: 18px;
                width: 25px;
                height: 42px;
                padding: 0 0 0 9px;
                font-size: 14px;
                border: 1px solid #ccc;
                border-right: none;
                text-align: left;
                background: url("/img/trip/hotel/sp_hotel.png") 10px -1151px #fafafa;

                .txt {
                    display: none;
                }

                &:hover {
                    width: 60px;
                    height: 42px;
                    padding: 0 0 0 27px;
                    *padding: 10px 0 0 27px;
                    margin-left: -60px;
                    font-size: 14px;
                    border: 1px solid #ccc;
                    border-right: none;
                    text-align: left;

                    .txt {
                        display: inline;
                    }
                }
            }
        }
    }

    &.filter_close .map_layer_contents {
        .filter_box {
            width: 0px;
            overflow: hidden;
            overflow-y: hidden;
        }

        .result_list_box {
            left: 1px;
        }

        .map_box {
            left: 451px;
        }

        .filter_box {
            h2.tit,
            .select_list,
            .type_list_box_2,
            .type_list_box,
            .price_range_box,
            .convenient_box,
            .result_search_box {
                display: none;
            }
        }

        .btn_filter_box.type_2 .btn_detail {
            padding: 0 0 0 9px;
            left: 0;
            right: auto;
            margin-left: 0px;
            border: 1px solid #ccc;
            border-left: none;
            background: url("/img/trip/hotel/sp_hotel.png") 11px -1191px #fafafa;

            &:hover {
                width: 83px;
                padding: 0 0 0 23px;
                *padding: 10px 0 0 23px;
                border-left: none;
            }
        }
    }

    &.no_filter .map_layer_contents .map_box {
        left: 0;
    }

    .map_layer_contents {
        .result_list_box {
            z-index: 1;
            position: absolute;
            left: 314px;
            bottom: 0px;
            top: 70px;
            overflow: hidden;
            overflow-y: auto;
            width: 450px;
            height: auto;
            border-right: 1px solid #ebebeb;
            background: #e8edf0;

            &.close {
                width: 0;
                overflow-y: hidden;
            }

            .list_box {
                width: 433px;
                margin: 0 auto;

                li {
                    position: relative;
                    overflow: hidden;
                    margin-top: 7px;
                    border: 1px solid #dfdfdf;
                    background: #fff;

                    &.selected,
                    &:hover {
                        border: 1px solid #f43142;
                        background: url("/img/trip/hotel/bg_list_01.png") right top no-repeat #fff9fa;
                    }

                    .product_info_box {
                        overflow: hidden;
                        height: 160px;

                        a {
                            display: block;
                            height: 160px;
                            text-decoration: none;

                            &:hover .info_box .product_box .tit {
                                text-decoration: underline;

                                .class_type {
                                    text-decoration: none;
                                }
                            }
                        }

                        .thumb_box {
                            float: left;
                            position: relative;
                            width: 160px;

                            .thumb {
                                display: block;
                                width: 160px;
                                height: 160px;
                            }

                            .hashtag {
                                display: inline-block;
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 160px;
                                height: 160px;
                                background: url("/img/trip/common/bg_thumb_cover_1.png") bottom left repeat-x;

                                .txt {
                                    position: absolute;
                                    bottom: 6px;
                                    left: 8px;
                                    font-size: 12px;
                                    color: #fff;
                                }
                            }

                            .ico_shockingdeal {
                                display: inline-block;
                                position: absolute;
                                top: 5px;
                                left: 5px;
                                width: 66px;
                                height: 28px;
                                margin: 0px;
                                text-indent: -99999px;
                                background: url("/img/trip/common/icon_shockingdeal.png") no-repeat;
                            }
                        }

                        .info_box {
                            float: left;
                            width: 243px;
                            padding: 10px 14px 14px 14px;

                            .product_box {
                                height: 83px;

                                .tit {
                                    overflow: hidden;
                                    max-height: 41px;
                                    font-size: 16px;
                                    line-height: 20px;
                                    color: #333;

                                    .class_type {
                                        overflow: hidden;
                                        display: inline-block;
                                        min-width: 39px;
                                        height: 16px;
                                        margin-right: 7px;
                                        padding: 2px 3px 0 3px;
                                        font-size: 11px;
                                        line-height: 1.15;
                                        vertical-align: -3px;
                                        font-weight: normal;
                                        color: #fff;
                                        text-align: center;
                                        border-radius: 2px;
                                        background: #999;
                                    }
                                }

                                .location {
                                    overflow: hidden;
                                    margin-top: 8px;
                                    font-size: 12px;
                                    letter-spacing: 0;
                                    color: #666;

                                    .ico {
                                        float: left;
                                        display: block;
                                        width: 9px;
                                        height: 12px;
                                        margin: 1px 6px 0 0;
                                        text-indent: -9999px;
                                        background: url("/img/trip/hotel/sp_hotel.png") 0 -680px no-repeat;
                                    }
                                }

                                .stat_box {
                                    overflow: hidden;
                                    margin-top: 4px;
                                    font-size: 12px;
                                    letter-spacing: 0;
                                    color: #999;

                                    .ico_star {
                                        float: left;
                                        display: block;
                                        width: 11px;
                                        height: 10px;
                                        margin: 1px 6px 0 0;
                                        text-indent: -9999px;
                                        background: url("/img/trip/hotel/sp_hotel.png") 0 -700px no-repeat;
                                    }

                                    .ico_adv {
                                        float: left;
                                        display: block;
                                        width: 16px;
                                        height: 10px;
                                        margin: 1px 6px 0 0;
                                        text-indent: -9999px;
                                        background: url("/img/trip/hotel/sp_advisor.png") 0 -39px no-repeat;
                                    }

                                    .n {
                                        color: #333;
                                    }

                                    .tx_bar {
                                        display: inline-block;
                                        width: 1px;
                                        height: 8px;
                                        margin: 0 5px 0 3px;
                                        vertical-align: 1px;
                                        background: #e4e4e4;
                                    }
                                }
                            }

                            .price_company_box {
                                margin-top: -9px;

                                .company {
                                    height: 24px;
                                    font-size: 12px;
                                    color: #5676da;
                                    text-align: right;

                                    .logo {
                                        max-width: 90px;
                                        max-height: 24px;
                                    }
                                }

                                .price_box {
                                    position: relative;
                                    text-align: right;
                                    margin-top: -3px;
                                    background: transparent;
                                    border-bottom: none;

                                    .sale {
                                        display: inline-block;
                                        zoom: 1;
                                        *display: inline;
                                        margin-right: 5px;
                                        *margin-bottom: 15px;
                                        font-size: 14px;
                                        color: #ef5050;

                                        .n {
                                            font-size: 20px;
                                            font-weight: bold;
                                            font-family: tahoma;
                                        }
                                    }

                                    .price_sale {
                                        position: relative;
                                        display: inline-block;
                                        height: 45px;
                                        font-size: 14px;
                                        color: #111;

                                        .n {
                                            font-size: 20px;
                                            font-weight: bold;
                                            font-family: tahoma;
                                        }

                                        .price_normal {
                                            position: absolute;
                                            bottom: 0px;
                                            left: 0px;
                                            color: #999;
                                            font-size: 12px;
                                            text-decoration: line-through;

                                            .n {
                                                font-size: 14px;
                                                font-weight: normal;
                                                font-family: tahoma;
                                            }
                                        }

                                        .price_average {
                                            position: absolute;
                                            bottom: 3px;
                                            right: 0px;
                                            width: 220px;
                                            color: #999;
                                            font-size: 11px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .btn_comparison {
                        width: 434px;
                        height: 40px;
                        padding-bottom: 2px;
                        font-size: 14px;
                        font-weight: bold;
                        text-align: right;
                        color: #333;
                        border-top: 1px solid #eaeaea;
                        background: #fafafa;

                        .txt_sale {
                            font-weight: normal;
                            color: #666;
                        }

                        .ico_arrow {
                            display: inline-block;
                            width: 7px;
                            height: 12px;
                            margin: 0 18px 0 4px;
                            vertical-align: -2px;
                            background: url("/img/trip/hotel/sp_hotel.png") 0 -270px no-repeat;
                        }
                    }
                }
            }

            .research_loading_box {
                display: none;

                p.text {
                    margin-top: 15px;
                    font-size: 16px;
                    font-weight: bold;
                    color: #333;
                }

                .loading_bar {
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 4px;
                    background: #e8e8e8;

                    span {
                        display: block;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 50%;
                        height: 4px;
                        background: #f43142;
                    }
                }
            }

            .dim_box {
                display: none;
            }

            &.researching {
                overflow: hidden;

                .research_loading_box {
                    display: block;
                    z-index: 20;
                    position: relative;
                    width: 461px;
                    height: 60px;
                    margin: 10px auto 0 auto;
                    text-align: center;
                    border: 1px solid #dfdfdf;
                    background: #fff;
                }

                .dim_box {
                    display: block;
                    z-index: 10;
                    position: fixed;
                    top: 0px;
                    width: 450px;
                    height: 100%;
                    background: url("/img/trip/hotel/bg_dim_w_80.png");
                }
            }

            .info_paging {
                display: block;
                position: fixed;
                bottom: 20px;
                width: 450px;
                color: #fff;
                text-align: center;
            }

            &.close .info_paging {
                display: none;
            }

            .pager {
                display: inline-block;
                position: relative;
                z-index: 10;
                height: 34px;
                padding: 0 20px 0 20px;
                line-height: 32px;
                letter-spacing: 0;
                font-size: 16px;
                border-radius: 34px;
                background-color: #000;
                opacity: 0.7;
                filter: alpha(opacity = 70);
            }

            .btn_box {
                position: absolute;
                top: 62px;
                right: -1px;
                width: 1px;

                .btn_detail {
                    position: fixed;
                    width: 23px;
                    height: 42px;
                    padding: 0 0 0 22px;
                    margin-left: -23px;
                    font-size: 14px;
                    border: 1px solid #ccc;
                    border-right: none;
                    text-align: left;
                    background: url("/img/trip/hotel/sp_hotel.png") 9px -1151px #e8edf0;

                    .txt {
                        display: none;
                    }

                    &:hover {
                        position: fixed;
                        width: 60px;
                        height: 42px;
                        padding: 0 0 0 23px;
                        *padding: 10px 0 0 23px;
                        margin-left: -60px;
                        font-size: 14px;
                        border: 1px solid #ccc;
                        border-right: none;
                        text-align: left;

                        .txt {
                            display: inline;
                        }
                    }
                }
            }

            &.close .btn_box .btn_detail {
                padding: 0 0 0 9px;
                margin-left: 0;
                border: 1px solid #ccc;
                border-left: none;
                background: url("/img/trip/hotel/sp_hotel.png") 10px -1191px #e8edf0;

                &:hover {
                    width: 98px;
                    padding: 0 0 0 23px;
                    *padding: 10px 0 0 23px;
                    border-left: none;
                }
            }

            &.no_data {
                background: url("/img/trip/hotel/bg_list_blank.png") 50% 0 repeat-y #e8edf0;

                .list_box,
                .info_paging {
                    display: none;
                }
            }

            .no_data_box {
                display: none;
            }

            &.no_data .no_data_box {
                display: block;
                position: relative;
                overflow: hidden;
                width: 342px;
                height: 113px;
                padding: 48px 0 0 92px;
                margin: 7px auto;
                border: 1px solid #dfdfdf;
                background: #fff;

                strong {
                    display: block;
                    margin-bottom: 8px;
                    font-size: 16px;
                    color: #333;
                }

                span {
                    display: block;
                    margin: 0 0 5px 3px;
                    font-size: 12px;
                    color: #666;
                }
            }
        }

        .map_box {
            position: absolute;
            left: 765px;
            bottom: 0px;
            top: 70px;
            right: 0px;
            background: #f0eee6;

            .marker {
                z-index: 1;
                position: absolute;
                width: 20px;
                height: 20px;
                margin: -10px 0 0 -10px;
                background: url("/img/trip/hotel/ico_map_marker_1.png") 0 0 no-repeat;

                &.selected {
                    z-index: 10;

                    &:before {
                        position: absolute;
                        top: -38px;
                        left: -6px;
                        width: 32px;
                        height: 48px;
                        background: url("/img/trip/hotel/ico_map_marker_2.png") no-repeat;
                        content: "";
                    }

                    .product_info_box {
                        display: block;
                        bottom: -78px;
                    }
                }

                &.landmark {
                    background: none;

                    &:hover {
                        z-index: 9999;
                    }

                    &:before {
                        position: absolute;
                        top: -38px;
                        left: -6px;
                        width: 32px;
                        height: 48px;
                        background: url("/img/trip/hotel/ico_map_marker_3.png") no-repeat;
                        content: "";
                    }
                }
            }

            .txt_box {
                display: block;
                position: absolute;
                top: 10px;
                left: 0px;
                min-width: 58px;
                padding: 8px 10px 9px 10px;
                font-size: 14px;
                text-align: center;
                color: #333;
                border: 1px solid #9e9e9e;
                box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.1);
                white-space: nowrap;
                background: #fff;
                z-index: 10;
            }

            .price_layer {
                z-index: 1;
                position: absolute;
                height: 28px;
                margin-top: -40px;
                padding: 4px 9px 0 8px;
                border: 1px solid #fff;
                border-radius: 5px;
                background: #5676da;

                .price_info {
                    font-size: 14px;
                    color: #fff;
                    letter-spacing: 0;

                    strong.n {
                        font-size: 16px;
                        font-family: tahoma;
                    }
                }

                .ico_arrow {
                    position: absolute;
                    bottom: -8px;
                    left: 50%;
                    display: block;
                    width: 12px;
                    height: 8px;
                    margin-left: -6px;
                    background: url("/img/trip/hotel/sp_hotel.png") 0 -770px no-repeat;
                }

                .product_info_box {
                    display: none;
                }

                &.selected {
                    z-index: 10;
                    background: #f43142;

                    .ico_arrow {
                        background: url("/img/trip/hotel/sp_hotel.png") 0 -790px no-repeat;
                    }

                    .product_info_box {
                        display: block;
                    }
                }
            }

            .product_info_box {
                display: none;
                position: absolute;
                left: 0px;
                max-width: 380px;
                padding: 5px 0 0 0;
                border: none;
                box-shadow: none;
                background: transparent;
                z-index: 1;

                .inner {
                    overflow: hidden;
                    position: relative;
                    width: auto;
                    *width: 212px;
                    height: 56px;
                    padding: 8px;
                    border: 1px solid #9e9e9e;
                    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.1);
                    background: #fff;
                }

                a {
                    display: block;
                    height: 56px;
                    overflow: hidden;

                    &:hover {
                        text-decoration: none;

                        .product_box .tit {
                            text-decoration: underline;
                        }
                    }
                }

                .thumb_box {
                    width: 56px;
                    height: 56px;
                    position: absolute;
                    top: 8px;
                    left: 8px;

                    .thumb {
                        width: 56px;
                        height: 56px;
                    }
                }

                .product_box {
                    min-width: 100px;
                    margin-left: 65px;

                    .tit {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        width: auto;
                        *width: 146px;
                        min-width: 146px;
                        font-size: 16px;
                        font-weight: bold;
                        line-height: 20px;
                        color: #333;

                        .class_type {
                            overflow: hidden;
                            display: inline-block;
                            min-width: 39px;
                            height: 16px;
                            margin-right: 7px;
                            padding: 2px 3px 0 3px;
                            font-size: 11px;
                            line-height: 1.15;
                            vertical-align: -3px;
                            font-weight: normal;
                            color: #fff;
                            text-align: center;
                            border-radius: 2px;
                            background: #999;
                        }
                    }

                    .stat_box {
                        overflow: hidden;
                        margin-top: 7px;
                        font-size: 12px;
                        letter-spacing: 0;
                        color: #999;

                        .ico_star {
                            display: inline-block;
                            width: 11px;
                            height: 10px;
                            margin: 1px 6px 0 0;
                            text-indent: -9999px;
                            background: url("/img/trip/hotel/sp_hotel.png") 0 -700px no-repeat;
                        }

                        .ico_adv {
                            display: inline-block;
                            width: 16px;
                            height: 10px;
                            margin: 1px 6px 0 0;
                            text-indent: -9999px;
                            background: url("/img/trip/hotel/sp_advisor.png") 0 -39px no-repeat;
                        }

                        .n {
                            color: #333;
                        }

                        .tx_bar {
                            display: inline-block;
                            width: 1px;
                            height: 8px;
                            margin: 0 5px 0 3px;
                            vertical-align: 1px;
                            background: #e4e4e4;
                        }
                    }
                }
            }
        }

        .pop_loading {
            z-index: 30;
            position: absolute;
            top: 0px;
            left: 0;
            width: 100%;
            height: 100%;
            background: url("/img/trip/hotel/bg_dim_w_80.png");

            .loading_box {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 280px;
                height: 136px;
                margin: -80px 0 0 -140px;
                padding: 24px 0 0 0;
                color: #333;
                text-align: center;
                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
                background: #fff;

                .icon_box {
                    width: 64px;
                    height: 64px;
                    margin: 0 auto;
                    background: url("/img/trip/hotel/ico_map_loading.png") no-repeat;
                }

                p {
                    &.text_1 {
                        margin-top: 9px;
                        font-size: 16px;
                        font-weight: bold;
                    }

                    &.text_2 {
                        margin-top: 2px;
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .pop_compartion_box {
        z-index: 30;
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: 14px;
        line-height: 1.5;
        background: url("/img/trip/common/bg_dim_50.png");

        h3.tit {
            height: 42px;
            padding: 16px 0 0 27px;
            font-size: 16px;
            font-weight: bold;
            color: #333;
            border-bottom: 1px solid #ccc;
            background: #fafafa;

            span.txt_sale {
                font-weight: normal;
                color: #666;
            }
        }

        .compartion_list_box {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 901px;
            height: 580px;
            margin: -290px 0 0 -450px;
            border: none;
            background: #fff;

            .inner {
                width: 901px;
                height: 481px;
            }

            li {
                border-left: 1px solid #fff;
                border-right: 1px solid #fff;
            }

            .etc_box .help_box .lay_nw_def {
                top: auto;
                bottom: 29px;

                .ico_arrow {
                    top: auto;
                    bottom: -13px;
                    background: url("/img/trip/hotel/sp_hotel.png") 0 -720px;
                }
            }
        }

        .btn_close {
            position: absolute;
            top: 21px;
            right: 20px;
            width: 16px;
            height: 16px;
            text-indent: -9999px;
            background: url("/img/trip/hotel/sp_hotel.png") 0 -740px;
        }

        .compartion_list_box .no_data_box {
            display: none;
        }

        &.no_data {
            .compartion_list_box {
                display: block;
                height: 496px;
                margin: -248px 0 0 -450px;
            }

            h3.tit span.txt_sale {
                display: none;
            }

            .compartion_list_box {
                .inner,
                .etc_box {
                    display: none;
                }

                .no_data_box {
                    display: block;
                    padding: 106px 0 0 0;
                    text-align: center;

                    .img_box {
                        display: block;
                        width: 115px;
                        height: 96px;
                        margin: 0 auto 0 auto;
                        background: url("/img/trip/hotel/sp_hotel.png") 0 -1280px no-repeat;
                    }

                    strong {
                        display: block;
                        margin: 15px auto 0 auto;
                        font-size: 20px;
                        font-weight: bold;
                        color: #333;
                    }

                    span {
                        display: block;
                        margin: 6px auto 0 auto;
                        font-size: 16px;
                        color: #666;
                    }
                }
            }
        }
    }
}

/* 로딩 */
.filter_dimmed {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20000;
    background: url("/img/default/bg_fff_05.png") repeat 0 0;

    img {
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -80px 0 0 -80px;
    }
}

/* wing banner 위치 수정*/
.wing_banner {
    top: 10px;
    margin-left: 610px;

    &.wing_fixed {
        position: fixed;
        top: 10px;
    }
}

/* 마이히스토리 1200 사이즈 변경할때 사용*/
div {
    &.footer_search2,
    &.my_history2_wrap,
    &.ftr_banner {
        width: 1200px;
    }
}

.my_history2_wrap {
    .recom_prd h5 {
        padding-left: 51px;
    }

    .btnctr_pn {
        padding-right: 51px;
    }
}

/* [페이징] */
.s_paging_v2 {
    position: relative;
    width: 100%;
    height: 32px;
    margin-top: 20px;
    padding-bottom: 10px;
    font: normal 11px/1 "tahoma";
    text-align: center;

    a,
    strong {
        display: inline-block;
        width: 30px;
        height: 19px;
        *height: 20px;
        margin: 0 1px;
        padding-top: 9px;
        *padding-top: 8px;
        text-decoration: none !important;
        vertical-align: middle;
    }

    a {
        color: #666;
        background: #fff;
        border: 1px solid #c7c7c7;

        &:hover {
            color: #fff;
            background: #f62e3d;
            border: 1px solid #b70922;
        }
    }

    strong {
        color: #fff;
        background: #f62e3d;
        border: 1px solid #b70922;
    }

    .selected {
        color: #fff;
        background: #f62e3d;
        border: 1px solid #b70922;
        cursor: default;
    }

    a {
        &.first,
        &.prev,
        &.next,
        &.last {
            width: 32px;
            height: 21px;
            *height: 22px;
            border: none;
            background: url("/img/category/v2/sp_category.png") no-repeat;
        }

        &.first {
            background-position: -67px -267px;
        }

        &.prev {
            background-position: -103px -267px;
        }

        &.next {
            background-position: -139px -267px;
        }

        &.last {
            background-position: -175px -267px;
        }
    }
}

.listing_wrap {
    .quick_search_box {
        min-width: 1200px;

        .tab_menu {
            display: none;
        }

        .trip_type {
            width: 1200px;
            background: none;
        }

        .inner {
            padding: 0px;
        }

        .trip_type {
            &.option_2 {
                .option_check_box_1,
                .option_check_box_2 {
                    position: relative;
                    z-index: 2;
                    padding-left: 20px;
                    padding-right: 20px;
                }

                .option_check_box_1 .option_select_box_1 .option_detail .info_input_2 {
                    width: 388px;
                }

                .option_check_box_2 .option_select_box_3 .option_detail .info_input_2 {
                    width: 236px;
                }
            }

            &.option_3 {
                .option_check_box_1,
                .option_check_box_2,
                .option_check_box_3 {
                    position: relative;
                    z-index: 2;
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }
        }
    }

    .quick_search_layer_wrap {
        .search_option_box {
            width: 1198px;
            padding: 20px 0 20px 0;
        }

        .quick_search_box {
            .inner {
                width: 1198px;
                margin: 0;
            }

            .trip_type {
                padding: 0px;
            }

            .trip_company_list_2,
            .trip_company_list_3 {
                width: 1196px;
                margin: 0px;
            }

            .trip_type.option_3 .option_check_box_2 .option_select_box_2 .option_detail .layer_selecter.trip_date_picker_v2 {
                left: -26px;
            }
        }
    }
}

.quick_search_box .bottom_box {
    position: relative;
    z-index: 1;
    clear: both;
    padding: 14px 0 5px 0;
    text-align: center;

    button.btn_type_1 {
        width: 150px;
        height: 40px;
        padding: 0 0 5px 0;
        font-size: 18px;
        color: #fff;
        background: #2b3769;
    }
}
/* 여행 리뷰 */
.trip_review {
    .review_list {
        border-top: 0 none;

        li {
            padding-top: 30px;

            &:first-child {
                padding-top: 0;
            }
        }

        .btnwrap .btn a {
            vertical-align: middle;
        }
    }

    .btn_more {
        width: 64px;
        height: 24px;
        margin-top: 16px;
        border: 1px solid #ccc;
        color: #111;
        font-size: 11px;
        line-height: 22px;

        &:after {
            display: inline-block;
            width: 12px;
            height: 6px;
            margin: 9px 0 0 4px;
            background: url("/img/trip/common/sp_trip.png") -217px -78px no-repeat;
            vertical-align: top;
            content: "";
        }
    }

    li > div {
        padding-bottom: 30px;
        border-bottom: 1px solid #eee;
    }

    .bbs_cont_wrap {
        .bbs_cont {
            overflow: hidden;
        }

        .bbs_img {
            width: 132px;

            img {
                max-width: 132px;
                height: auto;
                max-height: 132px;
            }
        }

        a.lk_img {
            position: relative;
            width: 132px;
            height: 132px;
        }

        .count {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 24px;
            height: 24px;
            background: url("/img/trip/common/bg_dim_50.png");
            color: #fff;
            font-size: 12px;
            font-weight: bold;
            line-height: 24px;
        }

        .bbs_cont.limit .bbs_summary {
            max-height: 66px;
        }
    }

    .bbs_cont.limit .btn_more:after {
        background-position: -217px -68px;
    }

    .bbs_cont_wrap .bbs_cont .option_txt,
    .bbs_top .option_txt {
        color: #666;
        font-size: 12px;
        letter-spacing: 0;
    }

    .bbs_cont_wrap .bbs_cont .bbs_summary {
        max-width: 759px;
        height: auto;

        .summ_conts {
            min-height: 22px;
        }

        .lk_txt {
            display: block;
            font-size: 13px;
            line-height: 22px;
        }
    }

    .bbs_top {
        .re_ico {
            height: 24px;
            padding: 0 10px;
            border-radius: 12px;
            color: #333;
            font-size: 11px;
            font-family: "Helvetica Neue", "Apple SD Gothic Neo", Arial, "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif;
            line-height: 24px;
        }

        .type {
            color: #5676da;
        }

        .top_r {
            .name,
            .age,
            .date {
                color: #333;
            }
        }
    }

    .msg {
        padding: 70px 0;
        border-bottom: 1px solid #eee;
        line-height: 18px;
        text-align: center;
    }

    .btn_box {
        float: right;
        margin: 4px -6px 0 0;

        .btn {
            margin: 0 6px;
            font-size: 13px;
            vertical-align: baseline;

            &.selected {
                color: #f43142;
                font-weight: bold;
            }
        }
    }
}

/* 공통탭 */
.list_tab_menu {
    position: relative;
    z-index: 5;
    left: 0;
    top: 0;
    width: 1199px;
    height: 56px;
    padding-left: 1px;
    border-bottom: 1px solid #555;
    background-color: #fff;

    li {
        float: left;
        width: 217px;
        border-top: 1px solid #ddd;

        &:first-child {
            width: 219px;
        }

        a {
            position: relative;
            z-index: 0;
            display: block;
            height: 55px;
            margin-left: -1px;
            background-color: #f5f5f5;
            border-left: 1px solid #ddd;
            border-right: 1px solid #ddd;
            font-size: 16px;
            color: #666;
            line-height: 52px;
            text-align: center;
        }
    }

    .lk_tab {
        position: relative;
        z-index: 0;
        display: block;
        height: 55px;
        margin-left: -1px;
        background-color: #f5f5f5;
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
        font-size: 16px;
        color: #666;
        line-height: 52px;
        text-align: center;
    }

    li {
        a {
            &:hover,
            &:focus {
                text-decoration: none;
            }
        }

        &.selected {
            a,
            .lk_tab {
                z-index: 1;
                margin: -1px 0 -1px -1px;
                background-color: #fff;
                border: 1px solid #555;
                border-bottom: 1px solid #fff;
                font-weight: bold;
                color: #111;
            }
        }
    }

    .txt_sub {
        color: #999;
        font-size: 12px;
        letter-spacing: 0;
    }
}

/* 공통 위치탭 주변관광지 */
.distance_list {
    height: 300px;

    li {
        overflow: hidden;
        width: 290px;
        font-size: 12px;
        color: #000;
        line-height: 30px;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:before {
            display: inline-block;
            width: 6px;
            height: 6px;
            margin-right: 4px;
            border-radius: 50%;
            font-size: 0;
            line-height: 0;
            vertical-align: middle;
            content: "";
        }

        strong {
            display: inline-block;
            width: 57px;
            font-size: 13px;
        }
    }
}
/* 공통 캘린더 today
.check_in_out_info_box .layer_selecter .tbl_calender a.today,
.price_result .layer_selecter .tbl_calender td a.today {border: 1px solid #f43142;background-color:#f43142;}
*/
/* 공통리스팅 - 필터 */
.price_range_graph {
    margin-top: 12px;
    letter-spacing: 0;

    .tit_max {
        display: block;
        color: #f43142;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
    }

    .inp_max {
        display: block;
        width: 100%;
        border: 0;
        background: none;
        color: #f43142;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        letter-spacing: 0;
    }

    .range_bar {
        display: block;
        position: relative;
        width: 100%;
        height: 2px;
        margin: 10px 0 6px;
        background: #ccc;
    }

    .bar,
    .ui-slider-range {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #f43142;
    }

    .handle {
        position: absolute;
        left: 10px;
        right: 10px;
        height: 100%;
    }

    .icon {
        overflow: hidden;
        position: absolute;
        top: 50%;
        z-index: 1;
        width: 22px;
        height: 22px;
        margin: -10px 0 0 -10px;
        background-color: #f43142;
        border-radius: 50%;
        cursor: pointer;
        text-indent: -999px;
    }

    .btn_handle {
        overflow: hidden;
        position: absolute;
        top: 50%;
        z-index: 1;
        width: 22px;
        height: 22px;
        margin: -10px 0 0 -10px;
        background-color: #f43142;
        border-radius: 50%;
        cursor: pointer;
        text-indent: -999px;
        margin: -11px 0 0 -11px;
        border: 2px solid #f43142;
        background-color: #fff;
        box-sizing: border-box;
    }

    .txt {
        overflow: hidden;
        padding-top: 5px;
        font-size: 11px;
        color: #666;

        .min {
            float: left;
        }

        .max {
            float: right;
        }
    }
}

/* 공통리스팅 - 상세 */
.total_item {
    overflow: hidden;
    height: 210px;
    padding: 20px 0;
    border-bottom: 1px solid #ccc;

    .thum_box {
        float: left;
        margin-right: 20px;
    }

    .info_box {
        float: left;
        position: relative;
        width: 340px;
        height: 100%;
        margin-right: 20px;
    }

    .price_box {
        float: left;
        position: relative;
        height: 100%;
        width: 270px;
        padding: 0 19px 0 20px;
        border-left: 1px solid #eee;
    }

    .thum_box {
        .thum {
            position: relative;
            width: 210px;
            height: 210px;
        }

        .rating {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: url("/img/trip/common/bg_thumb_cover_1.png") repeat-x 0 100%;
        }

        .ico_shockingdeal {
            position: absolute;
            top: 4px;
            left: 4px;
            overflow: hidden;
            display: inline-block;
            width: 66px;
            height: 28px;
            background: url("/img/trip/common/icon_shockingdeal.png") no-repeat;
            line-height: 200px;
            vertical-align: middle;
        }

        .txt {
            position: absolute;
            left: 11px;
            bottom: 7px;
            color: #fff;
        }
    }

    .info_box {
        .hotel_tit {
            display: block;
            overflow: hidden;
            max-height: 81px;
            font-size: 18px;
            color: #000;
            line-height: 26px;

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }

        .txt_range {
            overflow: hidden;
            margin-top: 13px;
            font-size: 12px;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .ico_map_small_black {
            margin-right: 5px;
            vertical-align: middle;
        }

        .review_area {
            position: absolute;
            left: 0;
            bottom: -2px;
            width: 100%;
        }

        .review_txt {
            overflow: hidden;
            margin-top: 20px;
            font-size: 13px;
            color: #324b6e;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .price_box {
        .info {
            margin-top: -3px;
        }

        .company {
            float: left;
            height: 22px;
            margin-top: 2px;

            img {
                height: 100%;
            }
        }

        .total_price {
            float: right;
            font-family: "Tahoma";
            font-size: 22px;
            color: #333;
        }

        .txt_won {
            font-family: "맑은 고딕", "Malgun Gothic", "Apple SD Gothic Neo", Helvetica, "돋움", dotum, Tahoma, sans-serif;
            font-size: 18px;
            font-weight: normal;
        }

        .average {
            clear: both;
            padding-top: 2px;
            font-size: 11px;
            color: #333;
            text-align: right;
        }

        .price_list {
            height: 88px;
            margin-top: 21px;
            font-size: 12px;

            li {
                position: relative;
                margin-top: 3px;
                *margin-top: 0;
                line-height: 18px;
                background: url("/img/trip/common/bg_dash_1.png") repeat-x 50% 50%;

                &:hover .tooltip {
                    display: block;
                }
            }
        }

        .link_txt {
            display: block;
            overflow: hidden;

            &:hover {
                .tit,
                .total_price {
                    text-decoration: underline;
                }
            }

            &:focus {
                text-decoration: underline;
            }
        }

        .price_list {
            .tit {
                float: left;
                padding-right: 10px;
                background: #fff;
            }

            .total_price {
                margin-top: -1px;
                padding-left: 10px;
                background: #fff;
                font-size: 12px;
            }

            .txt_won {
                font-size: 12px;
            }
        }

        .tooltip {
            position: absolute;
            top: -28px;
            right: -9px;
            z-index: 1;
            display: none;
            padding: 5px 8px;
            border: 1px solid #666;
            border-radius: 3px;
            background-color: #fff;
            color: #333;
            line-height: 15px;

            &:after {
                position: absolute;
                bottom: -9px;
                right: 50px;
                display: block;
                overflow: hidden;
                width: 8px;
                height: 9px;
                background: url("/img/trip/hotel/sp_hotel.png") no-repeat 0 -1380px;
                content: "";
            }
        }
    }

    .btn_more {
        position: absolute;
        left: 20px;
        bottom: 0;
        width: 268px;
        height: 36px;
        border-radius: 2px;
        background: #f43142;
        font-size: 14px;
        font-weight: bold;
        color: #fff;
        line-height: 36px;
        text-align: center;

        &:hover {
            text-decoration: none;
        }
    }
}

/* 지역/명소/숙소명 레이어 */
.hotel_recomd_box {
    position: absolute;
    top: 66px;
    left: -19px;
    overflow-x: hidden;
    overflow-y: auto;
    width: 498px;
    max-height: 442px;
    border: 2px solid #464957;
    background-color: #fff;

    .list_box li:hover {
        background-color: #f1f2fb;
    }

    .tit {
        margin-bottom: 9px;
        padding: 17px 0 6px 16px;
        border-bottom: 1px solid #eee;
        background: 0 0;
        color: $color-gray-03;
        font-size: 15px;
    }

    ul li {
        border-bottom: 0 none;
    }

    li {
        a {
            display: flex;
            width: 100%;
            overflow: hidden;
            padding: 11px 16px 12px;
            color: #111;
            box-sizing: border-box;
        }

        .txt_match {
            color: #f43142;
        }

        .txt {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 60%;
            font-size: 15px;
        }

        .num {
            width: 40%;
            color: #999;
            text-align: right;
            font-size: 14px;
        }
    }
}

.listing_qucik_search .hotel_recomd_box {
    width: 332px;
    top: 54px;
}

.popup_map_search .hotel_recomd_box {
    top: 57px;
    width: 455px;
}
.local_box {
    position: relative;

    .local_name {
        width: 154px;

        li {
            &:hover {
                background-color: none;
            }

            .name {
                padding: 0;
            }
        }
    }

    .list_box {
        position: absolute;
        top: 57px;
        left: 154px;
        right: 0;
        padding-left: 19px;
    }
}

.local_name li {
    &.selected {
        &:first-child .name {
            border-top: 0 none;
        }

        .name {
            border-right: 0 none;
            background-color: #fff;
            color: #f43142;
            font-weight: 700;
            cursor: default;
        }
    }

    .name {
        display: block;
        width: 154px;
        height: 48px;
        font-size: 15px;
        border-top: 1px solid #dedede;
        border-right: 1px solid #dedede;
        background-color: #fafafa;
        line-height: 48px;
        text-align: center;
        box-sizing: border-box;

        &:hover,
        &:focus,
        &:active {
            border-right: 0 none;
            background-color: #fff;
            color: #f43142;
            font-weight: 700;
            text-decoration: none;
        }
    }
}

/* 배너 */
.promotion_box {
    width: 1200px;
    margin: 40px auto 0;

    .lk_bnr {
        display: block;

        img {
            display: block;
            width: 100%;
        }
    }
}
