@charset "UTF-8";
/*! Copyright (©) 11ST. All Rights Reserved. */
/* GNB */
.c-trip-gnb { position: relative; height: 70px; font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif !important; letter-spacing: 0; background-color: #5461c4; z-index: 200; transform: translateZ(0); }

.c-trip-gnb::after { content: ""; display: none; position: absolute; top: 70px; left: 0; width: 100%; height: 460px; background-color: #fff; border-bottom: 1px solid #5462c5; }

.c-trip-gnb.trip-gnb-menu__button--selected::after { display: block; }

.c-trip-gnb.trip-gnb-menu__button--selected .trip-gnb-menu::before { content: ""; position: absolute; left: -3000px; top: 0; width: 3150px; height: 100%; background: #3743ae; }

.c-trip-gnb.trip-gnb-menu__button--selected .trip-gnb-menu__all { display: block; position: absolute; top: 70px; left: 0; z-index: 10; }

.c-trip-gnb.trip-gnb-menu__button--selected .trip-gnb-menu__button > button::after { border-width: 0px 5px 6px 5px; }

.c-trip-gnb .inner { zoom: 1; position: relative; width: 1240px; height: 70px; margin: 0 auto; }

.c-trip-gnb .inner:after { display: block; content: ""; clear: both; }

.c-trip-gnb .trip-gnb-menu { float: left; width: 151px; }

.c-trip-gnb .trip-gnb-menu__button > button { position: relative; width: 100%; height: 70px; padding: 0 47px 0 0; font-size: 20px; font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; color: #fffefe; line-height: 30px; border-right: solid 1px rgba(0, 0, 0, 0.2); box-sizing: border-box; letter-spacing: 0; }

.c-trip-gnb .trip-gnb-menu__button > button em { font-family: "Lato New", "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c-trip-gnb .trip-gnb-menu__button > button:after { content: ""; position: absolute; right: 27px; top: 32px; width: 0; border-style: solid; border-width: 6px 5px 0; border-color: #fff transparent; }

.c-trip-gnb .trip-gnb-menu__all { display: none; position: relative; padding: 16px 0; width: 1240px; height: 428px; background: #fff; }

.c-trip-gnb .trip-gnb-menu__all dl { zoom: 1; padding-bottom: 16px; }

.c-trip-gnb .trip-gnb-menu__all dl:after { display: block; content: ""; clear: both; }

.c-trip-gnb .trip-gnb-menu__all .trip-gnb-menu__group { float: left; padding: 0 20px 8px 20px; margin-left: 10px; width: 200px; min-height: 138px; max-height: 242px; }

.c-trip-gnb .trip-gnb-menu__all .trip-gnb-menu__group dt { width: 200px; padding: 10px 0; border-bottom: 2px solid #000; font-weight: bold; font-size: 16px; line-height: 24px; color: #333; }

.c-trip-gnb .trip-gnb-menu__all .trip-gnb-menu__group dd { margin-top: 8px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.c-trip-gnb .trip-gnb-menu__all .trip-gnb-menu__group dd a { font-weight: normal; font-size: 15px; line-height: 20px; color: #666; font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif !important; }

.c-trip-gnb .trip-gnb-menu__all .trip-gnb-menu__group:nth-child(5n + 1) { margin-left: 0; }

.c-trip-gnb .trip-gnb-menu__all .trip-gnb-menu__group--event dt { padding: 0; margin: 10px 0; width: 52px; height: 24px; font-size: 14px; font-weight: normal; line-height: 24px; color: #fff; border-bottom: 0 none; background: linear-gradient(to right, #ff5a2e 0%, #ff0038 50%, #ff00ef 100%); border-radius: 24px; text-align: center; }

.c-trip-gnb .trip-gnb-menu__all .trip-gnb-menu__group--event dd { zoom: 1; }

.c-trip-gnb .trip-gnb-menu__all .trip-gnb-menu__group--event dd:after { display: block; content: ""; clear: both; }

.c-trip-gnb .trip-gnb-menu__all .trip-gnb-menu__group--event dd a { float: left; color: #5462c5; }

.c-trip-gnb .trip-gnb-menu__all .trip-gnb-menu__group--event dd .ico_new::before { content: ""; float: left; margin: 2px 0 0 4px; background-image: url("/img/trip/sprites/sp_gnb_2x_2022126_153126.png"); background-position: -95px 0px; width: 14px; height: 14px; background-size: 109px 85px; }

.c-trip-gnb .trip-gnb-category { position: absolute; left: 150px; top: 0; }

.c-trip-gnb .trip-gnb-category__list { zoom: 1; }

.c-trip-gnb .trip-gnb-category__list:after { display: block; content: ""; clear: both; }

.c-trip-gnb .trip-gnb-category__list > li { float: left; position: relative; }

.c-trip-gnb .trip-gnb-category__list > li > a { display: block; padding: 20px 16px; height: 30px; font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; font-weight: normal; font-size: 16px; line-height: 30px; color: #fff; }

.c-trip-gnb .trip-gnb-category__list > li > a:hover, .c-trip-gnb .trip-gnb-category__list > li > a:focus { font-weight: normal; background-color: #3746a8; text-decoration: none; }

.c-trip-gnb .trip-gnb-category__list > li > a:hover + .category__sublist, .c-trip-gnb .trip-gnb-category__list > li > a:focus + .category__sublist { display: block; }

.c-trip-gnb .trip-gnb-category__list > li:first-child::before { display: none; }

.c-trip-gnb .trip-gnb-category__list > li:first-child a { padding-left: 25px; }

.c-trip-gnb .trip-gnb-category__list > li::before { content: ""; display: block; position: absolute; top: 28px; left: 0; width: 1px; height: 18px; background: rgba(255, 255, 255, 0.15); }

.c-trip-gnb .trip-gnb-category__list > li:hover::before, .c-trip-gnb .trip-gnb-category__list > li:focus::before { display: none; }

.c-trip-gnb .trip-gnb-category__list > li:hover + li::before, .c-trip-gnb .trip-gnb-category__list > li:focus + li::before { display: none; }

.c-trip-gnb .trip-gnb-category__list > li.active > a { font-weight: normal; background-color: #3746a8; }

.c-trip-gnb .trip-gnb-category__list > li.active .trip-gnb-category__sublist { display: block; }

.c-trip-gnb .trip-gnb-category__list > li.active::before { display: none; }

.c-trip-gnb .trip-gnb-category__list > li.active + li::before { display: none; }

.c-trip-gnb .trip-gnb-category__list > li .ico_new { position: absolute; top: 18px; right: 2px; }

.c-trip-gnb .trip-gnb-category__list > li .ico_new::before { content: ""; display: block; background-image: url("/img/trip/sprites/sp_gnb_2x_2022126_153126.png"); background-position: -95px -19px; width: 13px; height: 13px; background-size: 109px 85px; }

.c-trip-gnb .trip-gnb-category__sublist { display: none; position: absolute; top: 70px; left: 0; padding: 0 29px; width: 122px; border: 1px solid #5462c5; background-color: #fff; }

.c-trip-gnb .trip-gnb-category__sublist ul { zoom: 1; width: 100%; padding: 20px 0 26px 0; }

.c-trip-gnb .trip-gnb-category__sublist ul:after { display: block; content: ""; clear: both; }

.c-trip-gnb .trip-gnb-category__sublist ul > li { margin-top: 6px; }

.c-trip-gnb .trip-gnb-category__sublist ul > li a { font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; font-size: 15px; font-weight: normal; line-height: 22px; color: #666; }

.c-trip-gnb .trip-gnb-coupon { position: absolute; top: 16px; right: 0; }

.c-trip-gnb .trip-gnb-best { position: absolute; top: 16px; right: 70px; }

.c-trip-gnb .link { display: inline-flex; padding: 8px 14px; font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; font-weight: 500; border: 1px solid transparent; border-radius: 20px; background: #fff; background: linear-gradient(#fff, #fff) padding-box, linear-gradient(90deg, #ff5a2e, #ff0038 52%, #ff00ef); }

.c-trip-gnb .link .text { color: #ff0038; font-size: 14px; line-height: 20px; background: linear-gradient(90deg, #ff5a2e, #ff0038 52%, #ff00ef); background-image: -ms-linear-gradient(#fff, #fff); -webkit-background-clip: text; -webkit-text-fill-color: transparent; }

.c-trip-gnb .link:hover, .c-trip-gnb .link:focus { text-decoration: none; }

.c-trip-gnb.c-trip-gnb--fixed { position: fixed; left: 0; right: 0; }

/* dim */
.formTourArea_ext .dim { position: fixed; z-index: 99998; top: 0; left: 0; display: none; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.3); }

.formTourArea_ext .dimmed { position: absolute; z-index: 90; top: 0; left: 0; display: none; width: 100%; height: 100%; background: rgba(245, 245, 245, 0.7); }

/* layout */
.formTourArea_ext .ly_wrap, .formTourArea_ext .ly_wrap02, .formTourArea_ext .ly_wrap03 { position: absolute; z-index: 10; top: 50px; border: 2px solid #5477e9; background: #fff; font-family: "Malgun Gothic", "Apple SD Gothic Neo"; }

.formTourArea_ext .ly_area, .formTourArea_ext .ly_area02 { position: relative; width: 100%; }

.formTourArea_ext .ly_header, .formTourArea_ext .ly_header02 { height: 38px; background: #5477e9; }

.formTourArea_ext h1 { padding-left: 20px; color: #fff; font-size: 14px; font-weight: normal; line-height: 38px; }

.formTourArea_ext h1 span.pipe { padding-left: 10px; color: #fff; font-size: 14px; font-weight: normal; line-height: 38px; }

.formTourArea_ext h1 span.text, .formTourArea_ext h1 span.info { padding-left: 10px; color: yellow; font-size: 12px; font-weight: normal; line-height: 38px; }

.formTourArea_ext .ly_ctns { overflow: hidden; width: 100%; }

.formTourArea_ext .ly_close { position: absolute; top: 7px; right: 15px; width: 22px; height: 22px; background: url("/img/trip/flight/btn/btn_ly_close.png") no-repeat center center; }

.formTourArea_ext .ly_close a { display: block; text-indent: -9999px; }

/* 인원 및 좌석 등급 선택 */
.formTourArea_ext .btn_area, .formTourArea_ext .btn_area02 { padding: 20px 0; text-align: center; }

.formTourArea_ext .btn_area a, .formTourArea_ext .btn_area02 a { display: block; width: 90px; height: 35px; margin: 0 auto; padding: 0; background: #5477e9; color: #fff; font-size: 14px; line-height: 35px; text-align: center; text-decoration: none; }

.formTourArea_ext .ly_people_seat { right: -1px; width: 515px; }

/* 수정170120 */
.formTourArea_ext .group_fare .ly_people_seat { width: 343px; }

.formTourArea_ext .ly_people_seat .ly_sel_form { overflow: hidden; height: 265px; border-bottom: 1px solid #dadada; }

.formTourArea_ext .ly_people_seat .ly_sel_form .tit { height: 40px; border-bottom: 1px solid #dadada; color: #5477e9; font-size: 14px; font-weight: bold; line-height: 40px; text-align: center; }

.formTourArea_ext .ly_sel_form .people_select { float: left; width: 344px; padding-bottom: 50px; border-right: 1px solid #dadada; }

.formTourArea_ext .group_fare .ly_sel_form .people_select { border-right: none; }

.formTourArea_ext .ly_sel_form .num_people { overflow: hidden; padding: 17px 20px 0; text-align: center; }

.formTourArea_ext .ly_sel_form .num_people li { float: left; width: 88px; margin: 0 0 0 20px; }

.formTourArea_ext .ly_sel_form .num_people li:first-of-type { float: left; width: 88px; margin: 0; }

.formTourArea_ext .ly_sel_form .num_people li .name { color: #666; font-size: 12px; text-align: center; text-align: left; }

.formTourArea_ext .ly_sel_form .num_people li strong { display: block; margin-bottom: 6px; color: #111; font-size: 14px; font-weight: normal; }

.formTourArea_ext .ly_sel_form .num_people .num_set { position: relative; display: block; width: 88px; height: 28px; margin: 9px 0 0; }

.formTourArea_ext .ly_sel_form .num_people .num_set input { width: 28px; height: 26px; margin: 0 29px; padding: 0; border: 1px solid #d1d1d6; background: #fff; color: #333; font-family: "Malgun Gothic", "Apple SD Gothic Neo"; font-size: 14px; font-weight: normal; line-height: 26px; text-align: center; }

.formTourArea_ext .ly_sel_form .num_people .num_set .qty_minus, .ly_sel_form .num_people .num_set .qty_plus { position: absolute; top: 0; overflow: hidden; width: 30px; height: 28px; border: 1px solid #d1d1d6; background: #f5f5f5; text-indent: -1000px; }

.formTourArea_ext .ly_sel_form .num_people .num_set .qty_minus { left: 0; -webkit-border-radius: 2px 0 0 2px; -moz-border-radius: 2px 0 0 2px; border-radius: 2px 0 0 2px; }

.formTourArea_ext .ly_sel_form .num_people .num_set .qty_plus { left: 58px; -webkit-border-radius: 0 2px 2px 0; -moz-border-radius: 0 2px 2px 0; border-radius: 0 2px 2px 0; }

.formTourArea_ext .ly_sel_form .num_people .num_set .qty_minus:before, .ly_sel_form .num_people .num_set .qty_plus:before { position: absolute; top: 50%; left: 50%; width: 9px; height: 1px; margin: 0 0 0 -4px; background: #5477e9; content: ""; }

.formTourArea_ext .ly_sel_form .num_people .num_set .qty_plus:before { margin-left: -5px; }

.formTourArea_ext .ly_sel_form .num_people .num_set .qty_plus:after { position: absolute; top: 50%; left: 50%; width: 1px; height: 9px; margin: -4px 0 0 -1px; background: #5477e9; content: ""; }

.formTourArea_ext .ly_sel_form .num_people .num_set .qty_minus[disabled], .ly_sel_form .num_people .num_set .qty_plus[disabled] { border-color: #ccc; }

.formTourArea_ext .ly_sel_form .num_people .num_set .qty_minus[disabled]:before, .ly_sel_form .num_people .num_set .qty_plus[disabled]:before, .ly_sel_form .num_people .num_set .qty_plus[disabled]:after { background-color: #ccc; }

.formTourArea_ext .ly_sel_form .guide { padding: 23px 0 0 20px; color: #666; font-size: 12px; line-height: 18px; }

.formTourArea_ext .ly_sel_form .flight_class { float: left; width: 170px; }

.formTourArea_ext .ly_sel_form .flight_class .seat { padding: 15px 0 0 20px; }

.formTourArea_ext .ly_sel_form .flight_class .seat li { margin-top: 5px; }

.formTourArea_ext .ly_sel_form .flight_class .seat li button { width: 128px; height: 33px; padding: 0 10px; border: 1px solid #bdbdbd; background: #f5f5f5; color: #666; font-size: 12px; text-align: left; }

.formTourArea_ext .ly_sel_form .flight_class .seat li.on button { background: #f5f5f5 url("/img/trip/flight/bul/arr_9px_down_blue.gif") no-repeat 102px center; color: #333; }

/* 체류기간 */
.formTourArea_ext .ly_stay { left: 0; width: 230px; }

.formTourArea_ext .ly_stay .stay_day { padding: 10px 0; }

.formTourArea_ext .ly_stay .stay_day li { width: 100%; height: 31px; }

.formTourArea_ext .ly_stay .stay_day li button { display: block; width: 100%; height: 31px; padding-left: 20px; border: none; background: none; color: #666; font-size: 12px; line-height: 31px; text-align: left; }

.formTourArea_ext .ly_stay .stay_day li button.on { background: #5477e9; color: white; }

/* 오픈여정 검색 숨김 2020.06 */
#notConfirmed { display: none !important; }

/* 도시선택 */
.formTourArea_ext .reserve_search .inbox .input_form { position: absolute; top: 1px; left: 0; }

.formTourArea_ext .reserve_search .inbox .input_form input { width: 167px; height: 26px; margin-left: 1px; padding: 11px 10px 9px; border: none; color: #333; font-size: 14px; }

.formTourArea_ext .quick_search .inbox .input_form { position: absolute; top: 1px; left: 0; }

.formTourArea_ext .quick_search .inbox .input_form input { width: 123px; height: 26px; margin-left: 36px; padding: 11px 0 9px; border: none; color: #333; font-size: 14px; }

.formTourArea_ext .city_list { overflow-x: hidden; overflow-y: auto; }

.formTourArea_ext .city_list table { width: 100%; }

.formTourArea_ext .city_list table th { height: 34px; padding: 0 0 0 15px; border-bottom: 1px solid #dadada; background: #e6edfd; color: #333; font-size: 14px; font-weight: normal; text-align: left; }

.formTourArea_ext .city_list table td { padding: 5px 2px; border-bottom: 1px solid #dadada; font-size: 12px; }

.formTourArea_ext .city_list table td:first-of-type { padding-left: 15px; }

.formTourArea_ext .city_list table td:last-of-type { padding-right: 20px; }

.formTourArea_ext .city_list table td a { display: block; color: #666; text-decoration: none; }

.formTourArea_ext .city_list table tr:last-of-type td { border-bottom: none; }

.formTourArea_ext .city_list ul li { position: relative; border-bottom: 1px solid #e3e3e8; }

.formTourArea_ext .city_list ul li a { display: block; padding: 18px 0 18px 20px; text-decoration: none; }

.formTourArea_ext .city_list ul li:last-of-type { border-bottom: none; }

.formTourArea_ext .city_list ul li.no_search { padding: 18px 0 18px 0; text-align: center; }

.formTourArea_ext .city_list ul li dt { color: #333; font-size: 12px; }

.formTourArea_ext .city_list ul li .highlight { margin: 0; padding: 0; font-weight: bold; color: #536dfe; }

.formTourArea_ext .city_list ul li dt .en { margin-left: 1px; }

.formTourArea_ext .city_list ul li dd { margin-top: 5px; font-size: 12px; }

.formTourArea_ext .city_list ul li dd span { position: relative; padding: 0 6px 0 0; background: url("/img/trip/flight/bul/bul_12px_808080.gif") no-repeat right 3px; }

.formTourArea_ext .city_list ul li dd span:last-of-type { margin: 0; padding: 0; background: none; }

/* 달력 */
.formTourArea_ext .ly_select_date { left: -1px; width: 760px; }

/* 수정170120 */
.formTourArea_ext .ly_select_date .cal_wrap { overflow: hidden; padding: 20px; }

.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal { float: left; width: 350px; margin-right: 20px; border: 1px solid #f5f5f5; border-width: 0 1px 0 0; }

.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal:last-of-type { margin-right: 0; }

.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal caption { position: relative; height: 56px; border: 1px solid #f5f5f5; background: #fafafa; color: #333; font-family: "Tahoma"; font-size: 22px; line-height: 56px; }

.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal caption .btn_prev { position: absolute; top: 0; left: 0; width: 47px; height: 56px; background: url("/img/trip/flight/bul/arr_11px_left_666.gif") no-repeat center center; color: transparent; font-size: 11px; }

.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal caption .btn_next { position: absolute; top: 0; right: 0; width: 47px; height: 56px; background: url("/img/trip/flight/bul/arr_11px_right_666.gif") no-repeat center center; color: transparent; font-size: 11px; }

.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal thead th { height: 28px; padding: 0; border-bottom: 1px solid #dadada; font-size: 12px; font-weight: normal; text-align: center; }

.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal thead th:first-of-type { border-left: 1px solid #f5f5f5; }

.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal td { position: relative; height: 52px; padding: 0; border-bottom: 1px solid #f5f5f5; border-left: 1px solid #f5f5f5; color: #666; text-align: center; }

.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal td a { display: block; min-height: 15px; padding: 5px 0 32px 0; color: #666; text-decoration: none; }

.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal td span { display: block; min-height: 15px; padding: 5px 0 32px 0; }

.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal td .today { position: absolute; z-index: 5; top: -1px; left: -1px; width: 100%; border: 1px solid #2d52cb; background: #504f98; color: #fff; }

.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal td .start { position: absolute; z-index: 5; top: -1px; left: -1px; width: 100%; border: 1px solid #2d52cb; background: #5477e9; color: #fff; }

.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal td .middle { position: absolute; z-index: 5; top: -1px; left: -1px; width: 100%; border: 1px solid #2d52cb; background: #5477e9; color: #fff; }

.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal td .end { position: absolute; z-index: 5; top: -1px; left: -1px; width: 100%; border: 1px solid #2d52cb; background: #5477e9; color: #fff; }

.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal td .selected { position: absolute; z-index: 4; top: -1px; left: 0; width: 100%; border: 1px solid #dde4f3; background: #e6edfd; }

.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal td .txt { position: absolute; z-index: 6; right: 0; bottom: 5px; left: 0; margin: 0 auto; color: #fff; font-size: 11px; }

.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal .disable { color: #999; }

.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal .sat { color: #5477e9; }

.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal .holiday { color: #f43142; }

/* new메인추가 (170118) */
.formTourArea_ext .reserve_search .inbox .ico { z-index: -1; display: inline-block; vertical-align: middle; text-indent: -9999px; }

.formTourArea_ext .reserve_search .inbox .ico_not { z-index: -1; display: inline-block; background: url("/img/trip/flight/ico/sp_quick_search.png") no-repeat left top; vertical-align: middle; text-indent: -9999px; }

.formTourArea_ext .reserve_search .inbox a[role="button"] { display: table-cell; padding-left: 10px; color: #333; font-size: 14px; text-align: left; vertical-align: middle; text-decoration: none; }

.formTourArea_ext .reserve_search .inbox.area .ico { width: 16px; height: 20px; margin-right: 9px; background-position: left -26px; }

.formTourArea_ext .reserve_search .inbox.area .txt { display: inline-block; vertical-align: middle; line-height: 14px; }

.formTourArea_ext .reserve_search .inbox.area .txtmd { display: inline-block; vertical-align: -1px; color: #999999; }

.formTourArea_ext .reserve_search .inbox.date { width: 127px; }

/* 날짜 선택 */
.formTourArea_ext .reserve_search .inbox.date .txt { display: inline-block; vertical-align: middle; }

.formTourArea_ext .reserve_search .inbox.md { width: 148px; }

/* 다구간날짜 선택 */
.formTourArea_ext .reserve_search .inbox.period { width: 127px; background: #fff url("/img/trip/flight/bul/arr_6px_down_333.gif") no-repeat 107px center; }

/* 기간 선택 */
.formTourArea_ext .reserve_search .inbox.period a.on { color: #536dfe; font-weight: bold; }

.formTourArea_ext .reserve_search .inbox.date .ico { width: 21px; height: 20px; margin-right: 9px; background-position: -50px -72px; }

.formTourArea_ext .reserve_search .inbox.md .ico { width: 21px; height: 20px; margin-right: 9px; background-position: -50px -72px; }

.formTourArea_ext .reserve_search .inbox.date .ico_not { width: 21px; height: 20px; margin-right: 9px; background-position: -25px -72px; }

.formTourArea_ext .reserve_search .inbox.set { width: 335px; }

/* 인원 및 좌석 선택 */
.formTourArea_ext .reserve_search .inbox.set .ico { float: left; width: 15px; height: 20px; margin-right: 9px; background-position: 0px -95px; }

.formTourArea_ext .reserve_search .inbox.set .txt { float: left; margin-right: 19px; padding-right: 10px; background: url("/img/trip/flight/main/bg_inbox.gif") no-repeat right 5px; }

.formTourArea_ext .reserve_search .inbox.set .txt:last-of-type { margin-right: 0; background: none; }

.formTourArea_ext .reserve_search .fl_option .del { position: absolute; top: 0; right: 0; display: table; width: 48px; height: 48px; border-left: 1px solid #dadada; }

.formTourArea_ext .reserve_search .fl_option .del a { display: table-cell; width: 48px; vertical-align: middle; }

.formTourArea_ext .reserve_search .fl_option .del a span { display: block; width: 11px; height: 11px; margin: 0 auto; background: url("/img/trip/flight/ico/sp_quick_search.png") no-repeat -24px -14px; vertical-align: middle; text-indent: -9999px; }

.formTourArea_ext .reserve_search .fl_option .btn_search { float: left; width: 132px; height: 49px; margin-top: 21px; background: #6c60f0; font-size: 16px; line-height: 50px; text-align: center; }

.formTourArea_ext .reserve_search .fl_option .btn_search a { display: block; color: #fff; text-decoration: none; }

.formTourArea_ext .reserve_search .fl_option .ckbox { position: relative; float: left; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; width: 90px; height: 35px; margin-left: -1px; padding-top: 16px; border: 1px solid #dadada; text-align: center; }

.formTourArea_ext .reserve_search .fl_option .ckbox .ck_box { vertical-align: middle; }

.formTourArea_ext .reserve_search .fl_option .ckbox .label_txt { color: #333; font-size: 14px; }

.formTourArea_ext .reserve_search .fl_option .trip_section { clear: both; padding-top: 10px; }

.formTourArea_ext .reserve_search .fl_option .trip_section a { display: inline-block; padding-left: 12px; margin-left: 12px; background: url("/img/trip/flight/main/bg_trip_section.gif") no-repeat left 2px; color: #333333; }

.formTourArea_ext .reserve_search .fl_option .trip_section02 { clear: both; padding: 10px 0 0 126px; }

/* 다구간 */
.formTourArea_ext .mdtype { margin-top: 16px; }

.formTourArea_ext .mdtype .ply { margin-top: 0 !important; }

.formTourArea_ext .mdtype a.closed { display: inline-block; margin-top: 8px; }

.formTourArea_ext a.planadd { display: block; position: absolute; bottom: 0; right: 0; width: 168px; padding: 14px 0; border: 1px solid #d4d4d4; background: #fff; /* color:#d4d4d4; */ text-align: center; font-size: 14px; }

.formTourArea_ext a.planadd em { display: inline-block; padding-left: 22px; background: url("/img/trip/flight/main/ico_planadd.gif") no-repeat left 3px; }

.formTourArea_ext .ml0 { margin-left: 0 !important; }

/************* 여행메인 항공퀵서치 통합 tour_extend 마이그레이션 20170417 eslee ************/
/*common*/
.formTourArea_ext .mr20 { margin-right: 20px; }

.formTourArea_ext label span { font-size: 13px; }

.formTourArea_ext .reserve_search .inbox .input_form input { width: 155px; height: 19px; margin-left: 1px; padding: 6px 10px 5px; border: none; color: #333; font-size: 14px; }

.formTourArea_ext .reserve_search .inbox .input_form input:focus { outline: none; }

.formTourArea_ext span.txt.on { font-weight: bold; color: #536dfe !important; }

/* checkbox custom */
.formTourArea_ext input[type="checkbox"] { overflow: hidden; width: 0; height: 0; margin: 0; border: none; font-size: 0; line-height: 0; visibility: hidden; }

.formTourArea_ext .ckbox label, .formTourArea_ext .trip_section label, .formTourArea_ext .trip_section02 label { position: absolute; top: 9px; left: 0; height: 18px; padding: 2px 0 0 22px; background: url("/img/trip/flight/tour/sp_trip_input.png") no-repeat 0 -40px; font-size: 12px; line-height: 12px; cursor: pointer; }

.formTourArea_ext .ckbox input[type="checkbox"]:checked + label, .formTourArea_ext .trip_section input[type="checkbox"]:checked + label, .formTourArea_ext .trip_section02 input[type="checkbox"]:checked + label { background: url("/img/trip/flight/tour/sp_trip_input.png") no-repeat 0 -60px; color: #5770fe !important; font-weight: bold; }

.formTourArea_ext .ckbox label { left: 21px; }

.formTourArea_ext .trip_section02 label { left: 160px; }

.formTourArea_ext .reserve_search .fl_option .trip_section .tip_label { padding-top: 1px; }

.formTourArea_ext .reserve_search .fl_option .trip_section .tip_label span.label_name { font-size: 11px; font-weight: 500; color: #333333; }

.formTourArea_ext .reserve_search .fl_option .trip_section .tip_label span.label_tip::before { content: ""; border-left: 1px solid #cccccc; display: inline-block; margin: 0px 6px 0 5px; height: 9px; }

.formTourArea_ext .reserve_search .fl_option .trip_section .tip_label span.label_tip { font-size: 12px; color: #333333; }

.formTourArea_ext .reserve_search .fl_option .trip_section input[type="checkbox"]:checked + label span.label_name { font-weight: bold; color: #5770fe; }

.formTourArea_ext .reserve_search .fl_option .trip_section input[type="checkbox"]:checked + label span.label_tip { font-weight: normal; color: #333333; }

/*여정검색 섹션*/
.formTourArea_ext input[type="radio"], .formTourArea_ext input[type="checkbox"] { margin: 5px 4px 0 0 !important; }

.formTourArea_ext { padding: 0px 24px 0px 30px; }

.formTourArea_ext .tabbox { margin: -2px 0 12px; overflow: hidden; }

.formTourArea_ext .tabbox ul.tabs { float: left; }

.formTourArea_ext .tabbox ul.tabs li { position: relative; float: left; height: 30px; background: none; text-align: left; margin-right: 1px; padding-right: 46px; cursor: pointer; }

.formTourArea_ext .tabbox ul.tabs li:before { content: ""; position: absolute; top: 6px; left: 0px; width: 16px; height: 16px; background: url("/img/trip/flight/tour/bu_radio_main.png") no-repeat 0 -16px; }

.formTourArea_ext .tabbox ul.tabs li a { display: block; width: 100%; font-size: 16px; padding: 0px 0 0px 0; font-size: 18px; color: #000; }

.formTourArea_ext .tabbox ul.tabs li a span { display: inline-block; padding-left: 20px; }

.formTourArea_ext .tabbox ul.tabs li.on:before { background-position: 0px 0px; }

.formTourArea_ext .tabbox ul.tabs li.on a { display: block; width: 100%; color: #5770fe; font-weight: bold; }

.formTourArea_ext .tabbox ul.tabs li.on a span.tabico01 { background: none; }

.formTourArea_ext .reserve_search { position: relative; padding: 0px 0px 10px 0px; background: none; }

.formTourArea_ext .reserve_search .inbox { position: relative; display: table; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; float: left; margin-left: -1px; background: #fff; width: 178px; height: 35px; border: 1px solid #cdcdcd; }

.formTourArea_ext .reserve_search .inbox.on { position: relative; z-index: 10; border: 1px solid #5477e9; }

.formTourArea_ext .reserve_search .inbox.disabled { background-color: #f8f8f8; border-color: #cccccc; }

.formTourArea_ext .reserve_search .inbox.disabled a[role="button"] { color: #bbbbbb; cursor: default; cursor: not-allowed; }

.formTourArea_ext .reserve_search .inbox.disabled a[role="button"] span.txt.on { color: #bbbbbb !important; }

.formTourArea_ext .reserve_search .inbox.period { width: 160px; background-position: 135px center; }

.formTourArea_ext .reserve_search .inbox.date, .formTourArea_ext .reserve_search .inbox.md { width: 160px; }

.formTourArea_ext .reserve_search .inbox.date .ico { float: right; width: 21px; height: 20px; margin-right: 9px; background: url("/img/trip/flight/tour/ico_cal_main.png") no-repeat 50% 50%; margin-top: 1px; }

.formTourArea_ext .reserve_search .inbox.date .ico_not { float: right; width: 21px; height: 20px; margin-right: 9px; background: url("/img/trip/flight/tour/ico_cal_main.png") no-repeat 50% 50%; margin-top: 1px; }

.formTourArea_ext .reserve_search .inbox.md .txt { vertical-align: 2px; }

.formTourArea_ext .reserve_search .inbox.md .ico { float: right; width: 21px; height: 20px; margin-right: 9px; background: url("/img/trip/flight/tour/ico_cal_main.png") no-repeat 50% 50%; margin-top: 1px; }

.formTourArea_ext .reserve_search .inbox.set { width: 268px; }

/* .formTourArea_ext .reserve_search .inbox.set .txt:first-child {position:relative;margin-right:10px;padding-right:30px;background:none;} */
.formTourArea_ext .reserve_search .inbox.set .txt:first-child { position: relative; margin-right: 10px; padding-right: 13px; min-inline-size: 55px; background: none; }

.formTourArea_ext .reserve_search .inbox.set .txt:first-child::after { content: ""; position: absolute; top: -6px; right: 0px; width: 1px; height: 35px; background-color: #ccc; }

.formTourArea_ext .reserve_search .fl_option { position: relative; z-index: 10; }

.formTourArea_ext .reserve_search .fl_option:after { clear: both; display: block; content: ""; }

.formTourArea_ext .reserve_search .fl_option > li { float: left; margin-top: 10px; }

.formTourArea_ext .reserve_search .fl_option > li:first-of-type { margin-top: 0; width: 100%; }

.formTourArea_ext .reserve_search .fl_option .selform { width: 100%; float: left; }

.formTourArea_ext .reserve_search .fl_option .selform .fildbox { float: left; }

.formTourArea_ext .reserve_search .fl_option .selform .fildbox .field { float: left; }

.formTourArea_ext .reserve_search .fl_option .selform .fildbox strong.fieldtit { display: none; margin-bottom: 6px; text-align: left; }

.formTourArea_ext .reserve_search .fl_option .selform .fildbox .ply { display: table; height: 10px; float: left; margin: 0px 0 0 -1px; border: 1px solid #dadada; padding: 8px 10px 4px 11px; background: #fff; }

.formTourArea_ext .reserve_search .fl_option .btn_search { width: 120px; height: 35px; margin-top: 0px; font-weight: bold; line-height: 35px; background: #273259; float: right; margin-right: 7px; }

.formTourArea_ext .reserve_search .fl_option .btn_search.long_text { font-size: 11px; }

.formTourArea_ext .reserve_search .fl_option .trip_section { position: relative; padding-top: 5px; }

.formTourArea_ext .reserve_search .fl_option .trip_section a { position: relative; margin-left: 50px; font-size: 13px; background: none; }

.formTourArea_ext .reserve_search .fl_option .trip_section a::before { content: ""; position: absolute; top: 7px; left: 0px; width: 1px; height: 7px; background: #ccc; }

.formTourArea_ext .reserve_search .fl_option .trip_section02 { position: relative; clear: both; padding: 3px 0 0 159px; }

@-moz-document url-prefix() { .formTourArea_ext .reserve_search .fl_option .trip_section { padding-top: 3px; }
  .formTourArea_ext .reserve_search .fl_option .trip_section a { margin-left: 35px; }
  .formTourArea_ext .reserve_search .fl_option .trip_section a::before { top: 8px; } }

/*firefox hack*/
.formTourArea_ext .mdtype { margin-top: 15px; }

.formTourArea_ext .mdtype a.closed { margin-top: 0px; margin-left: 10px; }

.formTourArea_ext a.planadd { bottom: 0; right: 7px; width: 118px; height: 28px; padding: 5px 0 0; border: 1px solid #273258; }

.formTourArea_ext a.planadd em { display: inline-block; padding-left: 22px; font-weight: bold; color: #273259; background: url("/img/trip/flight/tour/ico_plan_add_main.png") no-repeat 5px 5px; margin-top: 1px; }

.formTourArea_ext .recentSearchArea { padding: 10px 0px 0px; border-top: 1px solid #f4f4f4; }

.formTourArea_ext .recentSearchArea::after { content: ""; clear: both; display: block; }

.formTourArea_ext h3.tit { float: left; margin-right: 5px; color: #333; }

.formTourArea_ext .pick { float: left; }

.formTourArea_ext .pick a { color: #6483dc; font-size: 12px; }

.formTourArea_ext .pick a:before { content: "("; }

.formTourArea_ext .pick a:after { content: ")"; }

.formTourArea_ext .pick span { display: inline-block; width: 15px; height: 15px; margin-top: -3px; text-indent: -9999px; line-height: 12px; background: url("/img/trip/flight/tour/bu_pick_main.png") no-repeat 50% 50%; }

.formTourArea_ext ul.list { float: right; width: 1065px; }

.formTourArea_ext ul.list li { position: relative; float: left; font-size: 12px; }

.formTourArea_ext ul.list > li { margin-bottom: 10px; margin-right: 30px; }

.formTourArea_ext ul.list li div { float: left; }

.formTourArea_ext ul.list li .etc { position: relative; padding-right: 10px; }

.formTourArea_ext ul.list li .etc span { position: relative; padding: 0px 10px; }

.formTourArea_ext ul.list li .etc span::before { content: ""; position: absolute; top: 5px; left: 0; width: 1px; height: 10px; background-color: #ccc; }

.formTourArea_ext ul.list li span.reserico { display: none; }

.formTourArea_ext ul.list li ul { margin-right: 10px; }

.formTourArea_ext ul.list li ul li { font-weight: bold; }

.formTourArea_ext ul.list li ul li.ico { display: inline-block; width: 25px; height: 20px; background: url("/img/trip/flight/tour/ico_flight_route.png") no-repeat 50% 4px; }

.formTourArea_ext ul.list li ul li.ico02 { background-position: 50% -22px; }

.formTourArea_ext ul.list li[role="recentSearch"] a { position: absolute; top: 3px; right: 0; width: 15px; height: 15px; font-size: 0; background: url("/img/trip/flight/tour/btn_del_recent_search_area.png") no-repeat 50% 50%; }

/* 캘린더 레이어 */
.formTourArea_ext .ly_select_date { left: 0px; width: 740px; }

.formTourArea_ext #RTDtm .ly_select_date { left: -159px; }

.formTourArea_ext .ly_wrap, .formTourArea_ext .ly_wrap02, .formTourArea_ext .ly_wrap03 { top: 40px; border: 2px solid #48494e; }

.formTourArea_ext .ly_header, .formTourArea_ext .ly_header02 { height: 38px; background: #474958; }

.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal { width: 350px; margin-right: -1px; border: 1px solid #d5d5d5; border-width: 0px 1px 1px 1px; }

/* 2018-01-30 MAC 사파리 달력 사라짐 수정 */
.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal caption { height: 45px; border: 1px solid #d5d5d5; border-bottom: none; background: #fff; color: #333; font-size: 16px; /* font-weight:bold; */ }

.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal caption .btn_prev { top: 10px; left: 10px; width: 32px; height: 32px; background: url("/img/trip/flight/tour/btn_cal_arrw_main.png") 0px 0px no-repeat; }

.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal caption .btn_next { top: 10px; right: 10px; width: 32px; height: 32px; background: url("/img/trip/flight/tour/btn_cal_arrw_main.png") 0px -32px no-repeat; }

.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal thead th { height: 28px; padding: 0; border-bottom: 1px dotted #dadada; font-size: 14px; font-weight: bold; }

.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal td { height: 50px; border-color: #eee; }

.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal thead th:first-of-type, .formTourArea_ext .ly_select_date .cal_wrap .tbl_cal td:first-child { border-left: none; }

.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal tr:last-child td { border-bottom: none; }

.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal td span { padding: 5px 0 23px 0; font-size: 16px; }

.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal td span.disable { background-color: #f8f8f8; }

.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal td a { padding: 5px 0 23px 0; font-size: 16px; }

.formTourArea_ext .ly_close { background: url("/img/trip/flight/tour/btn_close_layer_main.png") no-repeat center center; }

.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal td .today { background-color: #504f98 !important; }

.formTourArea_ext .reserve_search.multi_search .ly_select_date { left: -156px; }

/*다구간캘린더위치*/
.formTourArea_ext .reserve_search.multi_search .inbox.md { width: 230px; }

/* 좌석 레이어 */
.formTourArea_ext .ly_people_seat .ly_sel_form .tit { color: #333; }

.formTourArea_ext .ly_sel_form .flight_class .seat li button { border: 1px solid #ccc; background: #fff; }

.formTourArea_ext .ly_sel_form .flight_class .seat li.on button { background: #fff url("/img/trip/flight/tour/bu_selected_main.png") no-repeat 102px center; color: #5770fe; font-weight: bold; border: 1px solid #333; }

.formTourArea_ext .btn_area a, .formTourArea_ext .btn_area02 a { background: #2c3768; }

.formTourArea_ext h1 { font-size: 16px; line-height: 33px; }

.formTourArea_ext .ly_sel_form .num_people .num_set .qty_minus::before, .formTourArea_ext .ly_sel_form .num_people .num_set .qty_plus::before, .formTourArea_ext .ly_sel_form .num_people .num_set .qty_plus::after { background: #999; }

/* 도시선택 레이어 */
.formTourArea_ext .city_list table th { font-weight: bold; color: #333; border-right: 1px solid #ccc; border-bottom: 1px solid #ccc; background: #fafafa; }

.formTourArea_ext .city_list table td a:hover { color: #335fe4; font-weight: bold; text-decoration: underline; }

.formTourArea_ext h1 span.text, .formTourArea_ext h1 span.info { float: right; margin-right: 50px; margin-top: -2px; color: #fff; }

.formTourArea_ext .reserve_search .inbox.area h1 span.text i.ico { display: inline-block; width: 14px; height: 14px; margin-right: 5px; margin-bottom: 3px; background: url("/img/trip/flight/tour/bu_caution_main.png") 50% 50% no-repeat; }

.formTourArea_ext .ly_city { left: -1px; width: 600px; }

.formTourArea_ext .ly_city.ly_city_major { width: 1008px; }

.formTourArea_ext .ly_city.ly_city_major.x_major_area_count_8 { width: 1008px; }

.formTourArea_ext .field_arrv .ly_city.ly_city_major.x_major_area_count_8 { left: -70px; }

.formTourArea_ext .ly_city.ly_city_major.x_major_area_count_7 { width: 882px; }

.formTourArea_ext .ly_city.ly_city_major.x_major_area_count_6 { width: 756px; }

.formTourArea_ext .ly_city.ly_city_major.x_major_area_count_5 { width: 630px; }

.formTourArea_ext .ly_city.ly_city_major.x_major_area_count_4 { width: 504px; }

.formTourArea_ext .ly_city.ly_city_major.x_major_area_count_3 { width: 378px; }

.formTourArea_ext .ly_city.ly_city_major.x_major_area_count_2 { width: 378px; }

.formTourArea_ext .ly_city.ly_city_major.x_major_area_count_1 { width: 378px; }

.formTourArea_ext .ly_city.ly_city_major.x_major_area_count_0 { width: 378px; }

.formTourArea_ext ul.major_city::after { content: ""; clear: both; display: block; }

.formTourArea_ext ul.major_city li { float: left; width: 110px; padding: 10px 0 0px 15px; color: #273259; font-weight: bold; border-right: 1px solid #e9e9e9; border-bottom: none; background-color: #fafafa; }

/* #FAFAFA */
.formTourArea_ext .ly_city.ly_city_major.x_major_area_count_2 ul.major_city li { width: 173px; }

.formTourArea_ext .ly_city.ly_city_major.x_major_area_count_1 ul.major_city li { width: 362px; }

.formTourArea_ext ul.major_city li ul { width: 100%; height: 260px; margin: 10px 0 0px -15px; padding: 5px 15px 10px 0px; border-top: 1px solid #ccc; border-right: 1px solid #e9e9e9; background-color: #fff; }

.formTourArea_ext .group_fare ul.major_city li ul { height: 500px; }

.formTourArea_ext ul.major_city li ul::after { content: ""; clear: both; display: block; }

.formTourArea_ext ul.major_city li ul li { padding: 0 0 0 0; height: initial; border-bottom: none; border-right: none; background-color: #fff; }

.formTourArea_ext ul.major_city li ul li a { max-width: 110px; padding: 4px 0px 4px 15px; font-size: 12px; font-weight: normal; background-color: #fff; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.formTourArea_ext ul.major_city li ul li a:hover { color: #335fe4; font-weight: bold; text-decoration: underline; }

.formTourArea_ext .city_list ul[role="airportList"] { max-height: 389px; overflow-y: scroll; }

.formTourArea_ext .city_list ul[role="airportList"] li a { padding: 10px 0 10px 20px; }

.formTourArea_ext .city_list ul[role="airportList"] li:hover { background-color: #eef5ff; }

.formTourArea_ext .city_list ul[role="airportList"] li dl { width: 560px; }

.formTourArea_ext .city_list ul[role="airportList"] li dl::after { content: ""; clear: both; display: block; }

.formTourArea_ext .city_list ul[role="airportList"] li dt { float: left; }

.formTourArea_ext .city_list ul[role="airportList"] li dd { float: right; margin-right: 20px; margin-top: 0px; }

/* 스크롤 불필요, 숨김 */
.formTourArea_ext .city_list ul[role="airportList"] { overflow-y: hidden; }

.formTourArea_ext .city_list ul[role="airportList"] li dl { width: 100%; }

/* 땡처리 */
.formTourArea_ext .reserve_search.group_fare .inbox.set .txt:first-child::after { background: none; }

.formTourArea_ext .reserve_search.group_fare .search-info { padding-top: 8px; font-size: 13px; height: 18px; }

.formTourArea_ext .reserve_search.group_fare .search-info .red { color: #ff0000; }

.formTourArea_ext .reserve_search.group_fare h1 span.info img { display: none; }

.formTourArea_ext .reserve_search.group_fare h1 span.info:before { display: inline-block; width: 14px; height: 14px; margin-right: 5px; margin-bottom: -2px; background: url("/img/trip/flight/tour/bu_caution_main.png") 50% 50% no-repeat; content: ""; }

.formTourArea_ext .reserve_search.group_fare .ly_wrap02 .city_list ul li { color: #273259; background-color: #fff; }

.formTourArea_ext .reserve_search.group_fare .ly_wrap02 .city_list ul li:first-of-type { border-bottom: 1px solid #ccc; background-color: #fafafa; padding: 10px 15px; font-weight: bold; }

.formTourArea_ext .reserve_search.group_fare .ly_wrap02 .city_list ul li table td { width: 25%; }

.formTourArea_ext .reserve_search.group_fare .ly_wrap02 .city_list ul li table td a { padding: 15px; text-align: center; }

.formTourArea_ext .reserve_search.group_fare .ly_wrap02 .city_list ul li table td { padding: 0; }

.formTourArea_ext .reserve_search.group_fare .ly_wrap02.ly_city { width: 500px; }

.c_trip_samll_layer { display: none; position: absolute; padding: 20px 10px 20px 20px; border-radius: 4px; background-color: #fff; box-shadow: 0 6px 24px -8px rgba(0, 0, 0, 0.12), 0 0 1px 0 rgba(0, 0, 0, 0.36); }

.c_trip_samll_layer .c_trip_samll_layer_cont { min-width: 167px; padding-right: 24px; }

.c_trip_samll_layer .c_trip_samll_layer_cont p { font-size: 16px; line-height: 24px; color: #111; text-align: left; }

.c_trip_samll_layer .c_trip_samll_layer_close button { overflow: hidden; position: absolute; top: 20px; right: 11px; width: 24px; height: 24px; overflow: hidden; line-height: 200px; }

.c_trip_samll_layer .c_trip_samll_layer_close button:before, .c_trip_samll_layer .c_trip_samll_layer_close button:after { position: absolute; top: 50%; left: 50%; width: 16px; height: 1px; background-color: #333; content: ""; }

.c_trip_samll_layer .c_trip_samll_layer_close button:before { -webkit-transform: translate(-50%, -50%) rotate(45deg); transform: translate(-50%, -50%) rotate(45deg); }

.c_trip_samll_layer .c_trip_samll_layer_close button:after { -webkit-transform: translate(-50%, -50%) rotate(135deg); transform: translate(-50%, -50%) rotate(135deg); }

.c_trip_samll_layer--active { display: block; z-index: 100; }

.c_trip_share_layer .c_trip_samll_layer, .btn_area .c_trip_samll_layer { width: 280px; padding: 20px 0; background-color: #fff; border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 4px; box-shadow: 0 6px 24px -8px rgba(0, 0, 0, 0.12); box-sizing: border-box; }

.c_trip_share_layer .c_trip_samll_layer_cont, .btn_area .c_trip_samll_layer_cont { padding-right: 0; color: #111; font-weight: normal; font-size: 15px; }

.c_trip_share_layer .c_trip_samll_layer_cont .title, .btn_area .c_trip_samll_layer_cont .title { padding: 0 20px; margin-bottom: 10px; font-weight: bold; font-size: 15px; line-height: 1.5; }

.c_trip_share_layer .c_trip_samll_layer_cont .cont, .btn_area .c_trip_samll_layer_cont .cont { padding: 0 20px; white-space: normal; word-wrap: break-word; word-break: break-all; }

.c_trip_share_layer .c_trip_samll_layer_cont .cont .share_sns, .btn_area .c_trip_samll_layer_cont .cont .share_sns { display: table; width: 100%; padding-top: 10px; text-align: center; table-layout: fixed; }

.c_trip_share_layer .c_trip_samll_layer_cont .cont .share_sns li, .btn_area .c_trip_samll_layer_cont .cont .share_sns li { display: table-cell; text-align: center; }

.c_trip_share_layer .c_trip_samll_layer_cont .cont .share_sns [class^="sns_"], .btn_area .c_trip_samll_layer_cont .cont .share_sns [class^="sns_"] { color: #333; font-size: 13px; font-family: "Lato New", "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c_trip_share_layer .c_trip_samll_layer_cont .cont .share_sns [class^="sns_"]:before, .btn_area .c_trip_samll_layer_cont .cont .share_sns [class^="sns_"]:before { display: block; background-image: url("/img/trip/sprites/sp_icon_2x_20241029_170538.png"); background-position: -235px -74px; width: 48px; height: 48px; background-size: 348px 346px; margin: 0 auto 4px; content: ""; }

.c_trip_share_layer .c_trip_samll_layer_cont .cont .share_sns .sns_twitter:before, .btn_area .c_trip_samll_layer_cont .cont .share_sns .sns_twitter:before { background-image: url("/img/trip/sprites/sp_icon_2x_20241029_170538.png"); background-position: -85px -85px; width: 48px; height: 48px; background-size: 348px 346px; }

.c_trip_share_layer .c_trip_samll_layer_cont .cont .share_sns .sns_x:before, .btn_area .c_trip_samll_layer_cont .cont .share_sns .sns_x:before { background-image: url("/img/trip/sprites/sp_icon_2x_20241029_170538.png"); background-position: 0px -170px; width: 48px; height: 48px; background-size: 348px 346px; }

.c_trip_share_layer .c_trip_samll_layer_cont .cont .share_sns .sns_kakao:before, .btn_area .c_trip_samll_layer_cont .cont .share_sns .sns_kakao:before { background-image: url("/img/trip/sprites/sp_icon_2x_20241029_170538.png"); background-position: -288px -74px; width: 48px; height: 48px; background-size: 348px 346px; }

.c_trip_share_layer .c_trip_samll_layer_cont .cont .share_form, .btn_area .c_trip_samll_layer_cont .cont .share_form { margin-top: 20px; }

.c_trip_share_layer .c_trip_samll_layer_cont .cont .share_form .c_trip_product_input, .btn_area .c_trip_samll_layer_cont .cont .share_form .c_trip_product_input { position: relative; display: block; }

.c_trip_share_layer .c_trip_samll_layer_cont .cont .share_form .c_trip_product_input .form_input, .btn_area .c_trip_samll_layer_cont .cont .share_form .c_trip_product_input .form_input { overflow: hidden; width: 100%; height: 40px; padding: 0 10px 0 12px; font-size: 14px; background-color: #fff; border: 1px solid #ddd; color: #111; font-size: 15px; text-overflow: ellipsis; white-space: nowrap; box-sizing: border-box; }

.c_trip_share_layer .c_trip_samll_layer_cont .cont .share_form .c_trip_product_input .form_input::placeholder, .btn_area .c_trip_samll_layer_cont .cont .share_form .c_trip_product_input .form_input::placeholder { color: #eee; opacity: 1; }

.c_trip_share_layer .c_trip_samll_layer_cont .cont .share_form .c_trip_product_input .form_input::-ms-input-placeholder, .btn_area .c_trip_samll_layer_cont .cont .share_form .c_trip_product_input .form_input::-ms-input-placeholder { color: #eee; }

.c_trip_share_layer .c_trip_samll_layer_cont .cont .share_form .c_trip_product_input .form_input:not(:only-child), .btn_area .c_trip_samll_layer_cont .cont .share_form .c_trip_product_input .form_input:not(:only-child) { width: calc(100% - 58px); padding-right: 0; }

.c_trip_share_layer .c_trip_samll_layer_cont .cont .share_form .c_trip_product_input .form_input:read-only, .btn_area .c_trip_samll_layer_cont .cont .share_form .c_trip_product_input .form_input:read-only { background-color: #fafafa; color: #ccc; }

.c_trip_share_layer .c_trip_samll_layer_cont .cont .share_form .c_trip_product_input .form_add, .btn_area .c_trip_samll_layer_cont .cont .share_form .c_trip_product_input .form_add { overflow: hidden; position: absolute; top: 0; right: 0; bottom: 0; text-align: right; }

.c_trip_share_layer .c_trip_samll_layer_cont .cont .share_form .c_trip_product_input .form_add button, .btn_area .c_trip_samll_layer_cont .cont .share_form .c_trip_product_input .form_add button { position: relative; display: inline-block; min-width: 56px; height: 40px; padding: 0 13px; background-color: #fff; border: 1px solid #ccc; color: #111; font-size: 15px; line-height: 38px; text-align: center; vertical-align: middle; box-sizing: border-box; }

.c_trip_share_layer .c_trip_samll_layer_cont .cont .share_form .c_trip_product_input .form_add button:before, .c_trip_share_layer .c_trip_samll_layer_cont .cont .share_form .c_trip_product_input .form_add button:after, .btn_area .c_trip_samll_layer_cont .cont .share_form .c_trip_product_input .form_add button:before, .btn_area .c_trip_samll_layer_cont .cont .share_form .c_trip_product_input .form_add button:after { display: inline-block; margin: -2px 2px 0 0; vertical-align: middle; content: ""; }

.c_trip_share_layer .c_trip_samll_layer_close button, .btn_area .c_trip_samll_layer_close button { top: 11px; }

.c_trip_share_layer .c_trip_samll_layer_close span, .btn_area .c_trip_samll_layer_close span { width: 16px; height: 16px; overflow: hidden; line-height: 200px; }

.c_trip_share_layer .c_trip_samll_layer_close span:before, .c_trip_share_layer .c_trip_samll_layer_close span:after, .btn_area .c_trip_samll_layer_close span:before, .btn_area .c_trip_samll_layer_close span:after { position: absolute; top: 50%; left: 50%; width: 10px; height: 1px; background-color: #111; content: ""; }

.c_trip_share_layer .c_trip_samll_layer_close span:before, .btn_area .c_trip_samll_layer_close span:before { -webkit-transform: translate(-50%, -50%) rotate(45deg); transform: translate(-50%, -50%) rotate(45deg); }

.c_trip_share_layer .c_trip_samll_layer_close span:after, .btn_area .c_trip_samll_layer_close span:after { -webkit-transform: translate(-50%, -50%) rotate(135deg); transform: translate(-50%, -50%) rotate(135deg); }

.btn_area .c_trip_samll_layer { z-index: 1; right: 0; }

ul.reser_info02 .c_trip_samll_layer { top: 38px; right: 0; }

ul.reser_info02 .c_trip_btn_share { display: flex; align-items: center; color: #fff; text-decoration: none; font-size: 13px; line-height: 30px; }

ul.reser_info02 .c_trip_btn_share::before { display: block; width: 23px; height: 18px; background: url("/img/trip/flight/ico/sp_my_info.png") no-repeat left -48px; content: ""; }

body { min-width: 1218px; }

button, input, select, textarea { font-family: "Helvetica Neue", "Apple SD Gothic Neo", Arial, "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

body, input, select, textarea, button { color: #666; letter-spacing: -1px; }

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, main { display: block; }

.main_wrap a, .listing_wrap a { font-family: "Helvetica Neue", "Apple SD Gothic Neo", Arial, "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

main.content { position: relative; z-index: 2; width: 100%; padding-top: 200px; padding-bottom: 30px; font-size: 14px; line-height: 1.5; }

main.content:after { visibility: hidden; display: block; font-size: 0; content: "."; clear: both; height: 0; }

#wrap main.content { padding-top: 0px; }

/* 초기로딩이슈 개선을 위해 추가 */
.main_article { background-repeat: no-repeat; background-position: center top; }

#wrap { position: relative; width: 100%; min-width: 1280px; padding: 0px; font-family: "Helvetica Neue", "Apple SD Gothic Neo", Arial, "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.main_wrap { background: #e9edf0; }

.main_wrap .main_article { background-color: #eff2f4; }

.listing_wrap, #header { background: #fff; }

.f-clear { display: block; display: inline-block; }

.f-clear:after { content: "."; display: block; clear: both; height: 0; visibility: hidden; }

* html .f-clear { height: 1%; }

.layer_open { display: block !important; }

.layer_close { display: none !important; }

/* radio & checkbox */
.radio_design { position: absolute; overflow: hidden; clip: rect(0 0 0 0); height: 1px; width: 1px; margin: -1px; padding: 0; border: 0; }

.radio_design + label.css-label { display: inline-block; height: 23px; padding-left: 20px; font-size: 18px; line-height: 22px; background: url("/img/trip/common/sp_trip_input.png") 0 4px no-repeat; vertical-align: middle; cursor: pointer; }

.radio_design + label.css-label.on { font-weight: bold; color: #536dfe; background: url("/img/trip/common/sp_trip_input.png") 0 -16px no-repeat; }

.radio_design + label.css-label.on span { font-weight: normal; }

.check_design { position: absolute; overflow: hidden; clip: rect(0 0 0 0); height: 1px; width: 1px; margin: -1px; padding: 0; border: 0; }

.check_design + label.css-label { display: inline-block; height: 15px; padding-left: 20px; font-size: 13px; line-height: 13px; color: #333; background: url("/img/trip/common/sp_trip_input.png") 0 -40px no-repeat; vertical-align: middle; cursor: pointer; }

.check_design + label.css-label.on { background: url("/img/trip/common/sp_trip_input.png") 0 -60px no-repeat; }

.check_design + label.css-label.on span { font-weight: normal; }

.check_design_2 { position: absolute; overflow: hidden; clip: rect(0 0 0 0); height: 1px; width: 1px; margin: -1px; padding: 0; border: 0; }

.check_design_2 + label.css-label { display: inline-block; height: 18px; padding-left: 26px; font-size: 13px; line-height: 13px; color: #333; background: url("/img/trip/common/sp_trip_input.png") 0 -80px no-repeat; vertical-align: middle; cursor: pointer; }

.check_design_2 + label.css-label.on { background: url("/img/trip/common/sp_trip_input.png") 0 -110px no-repeat; }

.check_design_2 + label.css-label.on span { font-weight: normal; }

.radio_design_3 { position: absolute; overflow: hidden; clip: rect(0 0 0 0); height: 1px; width: 1px; margin: -1px; padding: 0; border: 0; }

.radio_design_3 + label.css-label { display: block; position: relative; height: 28px; padding: 0 14px 0 13px; color: #333; font-size: 12px; line-height: 27px; border-radius: 15px; text-decoration: none; cursor: pointer; }

.radio_design_3 + label.css-label:hover { color: #fff; text-decoration: none; background: #464957; }

.radio_design_3 + label.css-label.on { color: #fff; text-decoration: none; background: #464957; }

.radio_design_3 + label.css-label.on span { font-weight: normal; }

.check_design_3 { position: absolute; overflow: hidden; clip: rect(0 0 0 0); height: 1px; width: 1px; margin: -1px; padding: 0; border: 0; }

.check_design_3 + label.css-label { display: block; position: relative; height: 28px; padding: 0 12px 0 11px; color: #333; font-size: 12px; line-height: 27px; border-radius: 15px; text-decoration: none; cursor: pointer; }

.check_design_3 + label.css-label:hover { color: #fff; text-decoration: none; background: #464957; }

.check_design_3 + label.css-label.on { color: #fff; text-decoration: none; background: #464957; }

.check_design_3 + label.css-label.on span { font-weight: normal; }

button.info_input_1 { float: left; z-index: 1; position: relative; width: 159px; height: 35px; margin: 0 0 0 -1px; padding: 0 0 0 10px; font-size: 14px; letter-spacing: -1px; color: #2e2e31; text-align: left; border: 1px solid #ccc; background: #fff; }

button.info_input_1 .arrow { display: block; position: absolute; right: 10px; top: 14px; width: 10px; height: 6px; background: url("/img/trip/common/sp_trip.png") -20px 0 no-repeat #fff; }

button.info_input_1 .cal { display: block; position: absolute; right: 10px; top: 6px; width: 20px; height: 20px; background: url("/img/trip/common/sp_trip.png") -140px 0 no-repeat #fff; }

.info_input_2 { float: left; z-index: 1; position: relative; width: 159px; height: 29px; margin: 0 0 0 -1px; padding: 0 0 4px 10px !important; font-size: 14px; line-height: 2; letter-spacing: -1px; color: #2e2e31; text-align: left; border: 1px solid #ccc; background: #fff; }

button.info_input_1.focus { z-index: 3; font-weight: bold; border: 1px solid #464957; color: #5770fe; }

button.info_input_1.focus .arrow { background: url("/img/trip/common/sp_trip.png") -30px 0 no-repeat #fff; }

button.info_input_1.focus .cal { background: url("/img/trip/common/sp_trip.png") -170px 0 no-repeat #fff; }

.info_input_2.focus { z-index: 3; font-weight: bold; border: 1px solid #464957; color: #5770fe; }

button.info_input_1.active { z-index: 2; font-weight: bold; border: 1px solid #ccc; color: #5770fe; }

button.info_input_1.active .arrow { background: url("/img/trip/common/sp_trip.png") -20px 0 no-repeat #fff; }

button.info_input_1.active .cal { background: url("/img/trip/common/sp_trip.png") -140px 0 no-repeat #fff; }

.info_input_2.active { z-index: 2; font-weight: bold; border: 1px solid #ccc; color: #5770fe; }

.focus { font-weight: bold; border: 1px solid #464957; color: #5770fe; }

/* 입력선택 */
.active { font-weight: bold; color: #5770fe; }

/*입력 완료*/
.comp_select_type_1.selected { font-weight: bold; border: 1px solid #464957; color: #5770fe; }

/* 셀렉트 박스 펼침 */
/* 상품세로형 */
.product_type1 { background: #fff; }

.product_type1 a { display: block; text-decoration: none; }

.product_type1 .thumb { position: relative; display: block; }

.product_type1 .thumb .line_frame { position: absolute; left: 0px; top: 0px; display: block; width: 100%; height: 100%; border: 1px solid #000; opacity: 0.06; filter: alpha(opacity=6); box-sizing: border-box; }

.product_type1 .category { margin: 12px 0 0 0; color: #5074dd; font-size: 12px; }

.product_type1 .summary_in { position: relative; display: block; padding: 2px 10px 3px 10px; }

.product_type1 .subject { overflow: hidden; display: block; height: 21px; margin-top: 4px; font-size: 16px; line-height: 1.31; color: #333; }

.product_type1 .subject_row2 { height: 42px; }

.product_type1 a:hover { text-decoration: none; }

.product_type1 a:hover .subject { text-decoration: underline; }

.product_type1 .info { display: block; }

.product_type1 .info.have_price_normal { margin-top: 29px; }

.product_type1 .sale { font-size: 14px; color: #f43142; }

.product_type1 .sale_tx { font-size: 18px; font-weight: bold; }

.product_type1 .sale .n { font-size: 18px; font-family: tahoma, sans-serif; font-weight: bold; letter-spacing: 0; }

.product_type1 .price { position: relative; margin: 0 0 0 2px; font-size: 15px; color: #333; }

.product_type1 .price .n { font-size: 18px; font-family: tahoma, sans-serif; font-weight: bold; letter-spacing: 0; }

.product_type1 .price .text_price { font-size: 16px; }

.product_type1 .price_normal { position: absolute; left: 0px; bottom: 20px; color: #999; font-size: 13px; text-decoration: line-through; }

.product_type1 .price_normal .n { font-size: 13px; font-family: tahoma, sans-serif; font-weight: normal; }

.product_type1 .info_etc { margin: 2px 0 6px 0; }

.product_type1 .info_etc .tx { font-size: 11px; }

.product_type1 .info_etc .tx .n { font-family: tahoma, sans-serif; letter-spacing: 0; }

.product_type1 .info_etc .group { display: block; line-height: 1.1em; }

.product_type1 .info_etc .point { color: #f43142; }

.product_type1 .add { overflow: hidden; height: 37px; line-height: 37px; padding: 0 10px 0 10px; background: #fcfcfc; border-top: 1px solid #f4f4f4; }

.product_type1 .add .opt { margin: 0 4px 0 0; font-size: 12px; color: #999; }

.product_type1 .add .point { color: #536dfe; }

.product_type1 .add .tx { font-size: 12px; }

.product_type1 .add .tx_bar { display: inline-block; width: 1px; height: 11px; margin: 0 4px 0 5px; background: #dbdbdb; vertical-align: middle; }

/* 컴퍼넌트 요소 */
.comp_plus_minus { overflow: hidden; width: 86px; border: 1px solid #ccc; }

.comp_plus_minus button { float: left; width: 28px; height: 28px; text-indent: -9999px; background: #f5f5f5; }

.comp_plus_minus button.btn_minus { background: url("/img/trip/common/sp_trip.png") 0px -50px no-repeat; }

.comp_plus_minus button.btn_plus { background: url("/img/trip/common/sp_trip.png") -30px -50px no-repeat; }

.comp_plus_minus:hover { overflow: hidden; width: 86px; border: 1px solid #000; }

.comp_plus_minus:hover button.btn_minus { background: url("/img/trip/common/sp_trip.png") -60px -50px no-repeat; }

.comp_plus_minus:hover button.btn_plus { background: url("/img/trip/common/sp_trip.png") -90px -50px no-repeat; }

.comp_plus_minus:hover input[type="text"] { border-right: 1px solid #464957; border-left: 1px solid #464957; }

.comp_plus_minus input[type="text"] { float: left; width: 28px; height: 28px; padding: 0px !important; text-align: center; font-size: 14px; color: #111; border: none; border-right: 1px solid #ccc; border-left: 1px solid #ccc; }

.comp_plus_minus input[type="text"].active { font-weight: bold; color: #5770fe; }

.comp_select_type_1 { position: relative; display: inline-block; width: 86px; }

.comp_select_type_1 .btn_select { overflow: hidden; width: 100%; height: 28px; padding: 0 20px 2px 9px; font-size: 14px; text-align: left; border: 1px solid #ccc; background: #fff; }

.comp_select_type_1 .btn_select .arrow { display: block; position: absolute; right: 10px; top: 11px; width: 10px; height: 6px; background: url("/img/trip/common/sp_trip.png") -20px 0 no-repeat #fff; }

.comp_select_type_1 .select_list { display: none; position: absolute; margin: -1px 0 0 0; width: 84px; padding: 5px 0 5px 0; border: 1px solid #464957; box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1); background: #fff; }

.comp_select_type_1 .select_list button { width: 100%; height: 23px; text-align: left; padding: 0 0 2px 9px; font-size: 14px; color: #333; background: #fff; }

.comp_select_type_1 .select_list button:hover { color: #5770fe; font-weight: bold; text-decoration: underline; }

.comp_select_type_1 .select_list button.selected { color: #5770fe; }

.comp_select_type_1.focus { z-index: 3; position: relative; width: 86px; border: none; }

.comp_select_type_1.focus .btn_select { font-weight: bold; color: #5770fe; border: 1px solid #464957; }

.comp_select_type_1.focus .btn_select .arrow { background: url("/img/trip/common/sp_trip.png") -30px 0px no-repeat #fff; }

.comp_select_type_1.focus .select_list { display: block; }

.comp_select_type_1.active .btn_select { font-weight: bold; color: #5770fe; border: 1px solid #ccc; }

.layer_selecter { position: relative; width: 1008px; border: 2px solid #464957; background: #fff; }

.layer_selecter .layer_head { height: 35px; padding: 5px 0 0 14px; font-size: 16px; line-height: 1.5; font-weight: bold; color: #fff; background: #464957; }

.layer_selecter .layer_sub_text { position: absolute; top: 8px; right: 50px; font-size: 12px; color: #fff; }

.layer_selecter .layer_sub_text .ico { display: inline-block; width: 14px; height: 14px; margin-right: 5px; vertical-align: middle; background: url("/img/trip/common/sp_trip.png") 0 -10px no-repeat; }

.layer_selecter .layer_body .bottom_box { padding: 16px 0 15px 0; text-align: center; }

.layer_selecter .layer_body .bottom_box .btn_type_01 { width: 100px; height: 36px; background: #2b3769; color: #fff; font-size: 14px; line-height: 36px; letter-spacing: -1px; }

.layer_selecter .layer_body .bottom_box .btn_type_02 { width: 100px; height: 36px; font-size: 14px; font-weight: bold; letter-spacing: -1px; color: #5770fe; line-height: 36px; border: 1px solid #6483dc; background: #fff; }

.layer_selecter .btn_close { position: absolute; top: 12px; right: 17px; display: block; width: 11px; height: 11px; text-indent: -99999px; background: url("/img/trip/common/sp_trip.png") -20px -10px no-repeat; }

.layer_selecter .btn_close a { display: block; width: 11px; height: 11px; }

.city_list_select_type_1 { position: relative; overflow: hidden; width: 910px; border: 2px solid #464957; background: #fff; }

.city_list_select_type_1 .layer_body { min-height: 100px; background: url("/img/trip/common/bg_category_list_02.png"); }

.city_list_select_type_1 .layer_body .list_box { overflow: hidden; }

.city_list_select_type_1 .layer_body .list_box > li { float: left; width: 129px; margin: 0 0 0 0; padding: 0 0 0 0; border-right: 1px solid #e9e9e9; }

.city_list_select_type_1 .layer_body .list_box > li h3 { height: 33px; padding: 9px 0 0 0; font-size: 14px; font-weight: bold; text-align: center; color: #273259; border-bottom: 1px solid #ccc; background: #fafafa; }

.city_list_select_type_1 .layer_body .list_box > li ul { overflow: hidden; width: 128px; padding: 6px 0 8px 0; }

.city_list_select_type_1 .layer_body .list_box > li ul li { width: 98px; padding: 4px 15px 4px 15px; font-size: 12px; }

.city_list_select_type_1 .layer_body .list_box > li ul li a { color: #333; }

.city_list_select_type_1 .layer_body .list_box > li ul li a:hover { color: #335fe4; }

.city_list_select_type_1 .layer_body .list_box > li.row_2 { overflow: hidden; width: 259px; }

.city_list_select_type_1 .layer_body .list_box > li.row_2 h3 { width: 259px; }

.city_list_select_type_1 .layer_body .list_box > li.row_2 ul { float: left; width: 128px; border-left: 1px solid #e9e9e9; }

.city_list_select_type_1 .layer_body .list_box > li.row_2 ul:first-child { border: none; }

.city_list_select_type_2 { width: 596px; }

.city_list_select_type_2 .layer_body .list_box li { border-bottom: 1px solid #eee; background: #fff; }

.city_list_select_type_2 .layer_body .list_box li:hover { background: #eff5fe; }

.city_list_select_type_2 .layer_body .list_box li a { display: block; overflow: hidden; height: 29px; padding: 14px 16px 0 16px; font-size: 12px; color: #111; }

.city_list_select_type_2 .layer_body .list_box li a p { float: left; }

.city_list_select_type_2 .layer_body .list_box li a p strong { font-weight: normal; color: #5770fe; }

.city_list_select_type_2 .layer_body .list_box li a div { float: right; }

.city_list_select_type_2 .layer_body .list_box li a div span { display: inline-block; width: 1px; height: 9px; margin: 0 5px 0 2px; background: #ccc; }

.city_list_select_type_2 .layer_body .list_box li a div span.tx_bar { display: inline-block; width: 1px; height: 9px; margin: 0 5px 0 2px; background: #ccc; }

.city_list_select_type_3 { position: relative; overflow: hidden; width: 375px; border: 2px solid #464957; background: #fff; }

.city_list_select_type_3 .layer_body { min-height: 100px; }

.city_list_select_type_3 .layer_body h3 { height: 33px; padding: 9px 0 0 15px; font-size: 14px; font-weight: bold; text-align: left; color: #273259; border-bottom: 1px solid #ccc; background: #fafafa; }

.city_list_select_type_3 .layer_body ul { overflow: hidden; width: 375px; padding: 6px 0 8px 0; }

.city_list_select_type_3 .layer_body ul li { float: left; width: 61px; padding: 4px 16px 6px 16px; font-size: 12px; }

.city_list_select_type_3 .layer_body ul li a { color: #333; }

.city_list_select_type_3 .layer_body ul li a:hover { color: #335fe4; }

.member_class_select { width: 522px; }

.member_class_select .member_class_box { overflow: hidden; border-bottom: 1px solid #dadada; }

.member_class_select .member_class_box .member_box { float: left; width: 343px; border-right: 1px solid #dadada; }

.member_class_select .member_class_box .member_box h3 { height: 28px; padding: 12px 0 0 0; text-align: center; font-size: 14px; font-weight: bold; }

.member_class_select .member_class_box .member_box .member_check_box { padding: 18px 0 19px 20px; border-top: 1px solid #dadada; }

.member_class_select .member_class_box .member_box .member_check_box ul { overflow: hidden; height: 102px; }

.member_class_select .member_class_box .member_box .member_check_box ul li { float: left; width: 107px; }

.member_class_select .member_class_box .member_box .member_check_box ul li dl dt { height: 24px; font-size: 14px; color: #111; }

.member_class_select .member_class_box .member_box .member_check_box ul li dl dd { height: 21px; font-size: 12px; color: #666; }

.member_class_select .member_class_box .member_box p.text { font-size: 12px; line-height: 18px; color: #666; }

.member_class_select .member_class_box .class_box { float: left; width: 178px; }

.member_class_select .member_class_box .class_box h3 { height: 28px; padding: 12px 0 0 0; text-align: center; font-size: 14px; font-weight: bold; }

.member_class_select .member_class_box .class_box .list_box { padding: 20px 0 0 20px; border-top: 1px solid #dadada; }

.member_class_select .member_class_box .class_box .list_box li { margin-bottom: 5px; }

.member_class_select .member_class_box .class_box .list_box li button { position: relative; width: 138px; height: 35px; padding: 0 0 4px 11px; font-size: 12px; text-align: left; border: 1px solid #dadada; background: #fff; }

.member_class_select .member_class_box .class_box .list_box li button span.ico_check { display: none; position: absolute; top: 11px; right: 12px; width: 16px; height: 10px; background: url("/img/trip/common/sp_trip.png") 0 -30px no-repeat; }

.member_class_select .member_class_box .class_box .list_box li button.active, .member_class_select .member_class_box .class_box .list_box li button:hover { font-weight: bold; color: #6666ff; border: 1px solid #464957; }

.member_class_select .member_class_box .class_box .list_box li button.active span, .member_class_select .member_class_box .class_box .list_box li button:hover span { display: block; }

.room_addition { width: 395px; }

.room_addition .list_box { z-index: 2; position: relative; }

.room_addition .list_box > li { position: relative; width: 395px; }

.room_addition .list_box > li.zindex { position: relative; z-index: 2; }

.room_addition .list_box > li .room_option_1 { overflow: hidden; border-bottom: 1px solid #eee; }

.room_addition .list_box > li .room_option_1 dt { float: left; width: 66px; height: 47px; padding: 36px 0 0 14px; font-size: 14px; font-weight: bold; color: #111; text-align: left; border-right: 1px solid #eee; }

.room_addition .list_box > li .room_option_1 dd { float: left; width: 299px; height: 70px; padding: 13px 0 0 15px; }

.room_addition .list_box > li .room_option_1 dd ul { overflow: hidden; }

.room_addition .list_box > li .room_option_1 dd ul li { float: left; margin: 0 10px 0 0; }

.room_addition .list_box > li .room_option_1 dd ul li p.type { height: 25px; color: #111; }

.room_addition .list_box > li .room_option_2 { border-bottom: 1px solid #ccc; background: #fcfcfc; display: block; }

.room_addition .list_box > li .room_option_2:after { display: block; clear: both; content: ""; }

.room_addition .list_box > li .room_option_2 dt { float: left; width: 67px; height: 40px; font-size: 14px; color: #111; padding: 20px 0 0 14px; text-align: left; }

.room_addition .list_box > li .room_option_2 dd { float: left; width: 299px; min-height: 45px; padding: 15px 0 0 15px; }

.room_addition .list_box > li .room_option_2 dd .comp_select_type_1 { width: 88px; margin: 0 6px 0 0; }

.room_addition .list_box > li .room_option_2 dd .comp_select_type_1 .select_list { width: 86px; }

.room_addition .list_box > li .btn_del { position: absolute; top: 15px; right: 20px; display: block; width: 11px; height: 11px; text-indent: -9999px; background: url("/img/trip/common/sp_trip.png") -40px -10px no-repeat; }

.room_addition .bottom_box { z-index: 1; position: relative; padding-bottom: 15px !important; }

.room_addition .bottom_box .info_text { margin: -5px 14px 38px 0; font-size: 11px; color: #666; text-align: right; }

.room_addition .bottom_box .info_text .ico { display: inline-block; width: 14px; height: 14px; margin-right: 4px; vertical-align: -4px; background: url("/img/trip/common/sp_trip.png") -20px -30px no-repeat; }

.room_addition .bottom_box .info_text button { margin: 0 3px 0 2px; }

.room_addition .bottom_box .info_text button .ico_plus { display: inline-block; width: 9px; height: 9px; margin-right: 4px; background: url("/img/trip/common/sp_trip.png") -40px -30px no-repeat; }

.recomd_list_box { overflow-x: hidden; overflow-y: auto; width: 396px; max-height: 442px; border: 2px solid #464957; background: #fff; }

.recomd_list_box h2 { height: 24px; padding: 6px 0 0 16px; font-size: 12px; line-height: 1.5; color: #111; border-bottom: 1px solid #eee; background: #f4f4f4; }

.recomd_list_box ul { width: 396px; max-height: 442px; }

.recomd_list_box ul li { width: 396px; border-bottom: 1px solid #eee; background: #fff; }

.recomd_list_box ul li:hover { background: #eff5fe; }

.recomd_list_box ul li a { display: block; overflow: hidden; padding: 14px 16px 16px 16px; font-size: 12px; color: #111; }

.recomd_list_box ul li a strong { color: #5770fe; }

.recomd_list_box ul li a:hover { text-decoration: none; }

.recomd_list_box .no_result { font-size: 14px; text-align: center; padding: 50px 0 50px 0; }

.hotel_recomd_box .no_result { font-size: 14px; text-align: center; padding: 50px 0 50px 0; }

.listing_article { position: relative; background: #fff; }

/* 해외호텔 - 메인 자동완성 레이어 */
.recomd_list_box2 ul { width: 100%; }

.recomd_list_box2 .txt { overflow: hidden; float: left; }

.recomd_list_box2 .num { float: right; margin-left: 5px; }

.quick_search_box .trip_type.option_2 .option_check_box_2 .option_select_box_1 .option_detail .recomd_list_box.recomd_list_box2 li { width: 100%; }

/* 메인 */
/* 편도,다구간,숙박,국내,해외,여행상품 */
.quick_search_box { z-index: 2; position: relative; min-width: 1218px; }

.quick_search_box .btn_type_01 { width: 120px; height: 35px; padding: 0 0 0 0; font-size: 16px; letter-spacing: -1px; color: #fff; background: #2b3769; }

.quick_search_box .btn_type_02 { width: 120px; height: 35px; padding: 0 0 0 0; margin: 0 20px 0 0; font-size: 14px; font-weight: bold; color: #273259; border: 1px solid #273259; background: #fff; }

.quick_search_box .btn_type_02 span.ico { display: inline-block; width: 10px; height: 10px; margin: 0 5px 0 0; background: url("/img/trip/common/sp_trip.png") -310px 0 no-repeat; }

.quick_search_box .inner { width: 1218px; margin: 0 auto; padding: 90px 0 0 0; }

.quick_search_box .tab_menu { overflow: hidden; width: 1200px; margin: 0 auto; padding: 0 9px 8px 9px; font: 0/0 arial; text-align: center; background: url("/img/trip/common/bg_option_box_top.png") center bottom no-repeat; }

.quick_search_box .tab_menu li { display: inline-block; zoom: 1; *display: inline; width: 400px; height: 50px; font: 0/0 arial; }

.quick_search_box .tab_menu li a { display: block; width: 400px; height: 50px; background: url("/img/trip/common/sp_trip.png") no-repeat; text-indent: -9999px; }

.quick_search_box .tab_menu li.trip_tyep_1 a { background-position: 0 -90px; }

.quick_search_box .tab_menu li.trip_tyep_1.selected a { background-position: 0 -150px; }

.quick_search_box .tab_menu li.trip_tyep_2 a { background-position: -400px -90px; }

.quick_search_box .tab_menu li.trip_tyep_2.selected a { background-position: -400px -150px; }

.quick_search_box .tab_menu li.trip_tyep_3 a { background-position: -800px -90px; }

.quick_search_box .tab_menu li.trip_tyep_3.selected a { background-position: -800px -150px; }

.quick_search_box .trip_type { width: 1218px; min-height: 50px; padding: 9px 0 11px 0; background: url("/img/trip/common/bg_option_box_bottom.png") center bottom no-repeat; }

.quick_search_box .trip_type .type_info_1 { float: left; width: 37px; height: 33px; margin: 0 0 0 -1px; text-indent: -9999px; border: 1px solid #ccc; background: url("/img/trip/common/sp_trip.png") -60px 0 no-repeat; }

.quick_search_box .trip_type .type_info_2 { float: left; width: 37px; height: 33px; margin: 0 0 0 -1px; text-indent: -9999px; border: 1px solid #ccc; background: url("/img/trip/common/sp_trip.png") -100px 0 no-repeat; }

.quick_search_box .trip_type.option_1 .option_type_box { overflow: hidden; height: 30px; padding: 0 30px 0 30px; }

.quick_search_box .trip_type.option_1 .option_type_box li { float: left; min-width: 100px; }

.quick_search_box .trip_type.option_1 .option_type_box li span.text_1 { font-size: 16px; color: #666; }

.quick_search_box .trip_type.option_1 .option_check_box_1 { padding: 10px 30px 17px 30px; display: inline-block; display: block; }

.quick_search_box .trip_type.option_1 .option_check_box_1:after { content: "."; display: block; clear: both; height: 0; visibility: hidden; }

.quick_search_box .trip_type.option_1 .option_check_box_1 .city_list_select_type_1, .quick_search_box .trip_type.option_1 .option_check_box_1 .city_list_select_type_2, .quick_search_box .trip_type.option_1 .option_check_box_1 .city_list_select_type_3 { position: absolute; top: 40px; left: 0px; }

.quick_search_box .trip_type.option_1 .option_check_box_1 .option_select_box_1 { position: relative; z-index: 1; float: left; margin-right: 20px; }

.quick_search_box .trip_type.option_1 .option_check_box_1 .option_select_box_1 .option_detail { position: relative; padding: 0 0 5px 1px; display: inline-block; display: block; }

.quick_search_box .trip_type.option_1 .option_check_box_1 .option_select_box_1 .option_detail:after { content: "."; display: block; clear: both; height: 0; visibility: hidden; }

.quick_search_box .trip_type.option_1 .option_check_box_1 .option_select_box_1 .option_detail .info_input_1 { width: 178px; }

.quick_search_box .trip_type.option_1 .option_check_box_1 .option_select_box_1 .option_detail .info_input_2 { width: 166px; }

.quick_search_box .trip_type.option_1 .option_check_box_1 .option_select_box_2 { position: relative; z-index: 2; float: left; margin-right: 20px; }

.quick_search_box .trip_type.option_1 .option_check_box_1 .option_select_box_2 .option_detail { position: relative; padding: 0 0 5px 1px; display: inline-block; display: block; }

.quick_search_box .trip_type.option_1 .option_check_box_1 .option_select_box_2 .option_detail:after { content: "."; display: block; clear: both; height: 0; visibility: hidden; }

.quick_search_box .trip_type.option_1 .option_check_box_1 .option_select_box_2 .option_detail .layer_selecter.trip_date_picker { position: absolute; top: 40px; left: 0px; width: 747px; }

.quick_search_box .trip_type.option_1 .option_check_box_1 .option_select_box_2 .option_detail.info_input_1 { width: 159px; }

.quick_search_box .trip_type.option_1 .option_check_box_1 .option_select_box_3 { position: relative; z-index: 3; float: left; margin-right: 20px; }

.quick_search_box .trip_type.option_1 .option_check_box_1 .option_select_box_3 .option_detail { position: relative; padding: 0 0 5px 1px; display: inline-block; display: block; }

.quick_search_box .trip_type.option_1 .option_check_box_1 .option_select_box_3 .option_detail:after { content: "."; display: block; clear: both; height: 0; visibility: hidden; }

.quick_search_box .trip_type.option_1 .option_check_box_1 .option_select_box_3 .option_detail .member_class_select { position: absolute; top: 40px; right: 0px; }

.quick_search_box .trip_type.option_1 .option_check_box_2 { padding: 10px 30px 17px 30px; display: inline-block; display: block; }

.quick_search_box .trip_type.option_1 .option_check_box_2:after { content: "."; display: block; clear: both; height: 0; visibility: hidden; }

.quick_search_box .trip_type.option_1 .option_check_box_2 .city_list_select_type_1, .quick_search_box .trip_type.option_1 .option_check_box_2 .city_list_select_type_2, .quick_search_box .trip_type.option_1 .option_check_box_2 .city_list_select_type_3 { position: absolute; top: 40px; left: 0px; }

.quick_search_box .trip_type.option_1 .option_check_box_2 .option_select_box_1 { position: relative; z-index: 1; float: left; margin-right: 20px; }

.quick_search_box .trip_type.option_1 .option_check_box_2 .option_select_box_1 .option_detail { position: relative; padding: 0 0 5px 1px; display: inline-block; display: block; }

.quick_search_box .trip_type.option_1 .option_check_box_2 .option_select_box_1 .option_detail:after { content: "."; display: block; clear: both; height: 0; visibility: hidden; }

.quick_search_box .trip_type.option_1 .option_check_box_2 .option_select_box_1 .option_detail .info_input_1 { width: 178px; }

.quick_search_box .trip_type.option_1 .option_check_box_2 .option_select_box_1 .option_detail .info_input_2 { width: 166px; }

.quick_search_box .trip_type.option_1 .option_check_box_2 .option_select_box_2 { position: relative; z-index: 2; float: left; margin-right: 20px; }

.quick_search_box .trip_type.option_1 .option_check_box_2 .option_select_box_2 .option_detail { position: relative; padding: 0 0 5px 1px; display: inline-block; display: block; }

.quick_search_box .trip_type.option_1 .option_check_box_2 .option_select_box_2 .option_detail:after { content: "."; display: block; clear: both; height: 0; visibility: hidden; }

.quick_search_box .trip_type.option_1 .option_check_box_2 .option_select_box_2 .option_detail .layer_selecter.trip_date_picker { position: absolute; top: 40px; left: 0px; width: 747px; }

.quick_search_box .trip_type.option_1 .option_check_box_2 .option_select_box_2 .option_detail.info_input_1 { width: 159px; }

.quick_search_box .trip_type.option_1 .option_check_box_2 .option_select_box_3 { position: relative; z-index: 3; float: left; margin-right: 20px; }

.quick_search_box .trip_type.option_1 .option_check_box_2 .option_select_box_3 .option_detail { position: relative; padding: 0 0 5px 1px; display: inline-block; display: block; }

.quick_search_box .trip_type.option_1 .option_check_box_2 .option_select_box_3 .option_detail:after { content: "."; display: block; clear: both; height: 0; visibility: hidden; }

.quick_search_box .trip_type.option_1 .option_check_box_2 .option_select_box_3 .option_detail .member_class_select { position: absolute; top: 40px; right: 0px; }

.quick_search_box .trip_type.option_1 .option_check_box_3 { padding: 10px 30px 17px 30px; display: inline-block; display: block; }

.quick_search_box .trip_type.option_1 .option_check_box_3:after { content: "."; display: block; clear: both; height: 0; visibility: hidden; }

.quick_search_box .trip_type.option_1 .option_check_box_3 .plan_list { float: left; }

.quick_search_box .trip_type.option_1 .option_check_box_3 .plan_list > li { z-index: 1; position: relative; padding-top: 3px; display: inline-block; display: block; }

.quick_search_box .trip_type.option_1 .option_check_box_3 .plan_list > li.selected { z-index: 2; }

.quick_search_box .trip_type.option_1 .option_check_box_3 .plan_list > li:after { content: "."; display: block; clear: both; height: 0; visibility: hidden; }

.quick_search_box .trip_type.option_1 .option_check_box_3 .plan_list li.default { z-index: 2; padding-top: 0px; padding-bottom: 8px; }

.quick_search_box .trip_type.option_1 .option_check_box_3 .plan_list > li .btn_del { float: left; width: 20px; height: 20px; margin-top: 8px; background: url("/img/trip/common/sp_trip.png") -280px 0 no-repeat; text-indent: -9999px; }

.quick_search_box .trip_type.option_1 .option_check_box_3 .city_list_select_type_1, .quick_search_box .trip_type.option_1 .option_check_box_3 .city_list_select_type_2, .quick_search_box .trip_type.option_1 .option_check_box_3 .city_list_select_type_3 { position: absolute; top: 40px; left: 0px; }

.quick_search_box .trip_type.option_1 .option_check_box_3 .option_select_box_1 { position: relative; z-index: 1; float: left; margin-right: 20px; }

.quick_search_box .trip_type.option_1 .option_check_box_3 .option_select_box_1 .option_detail { position: relative; padding: 0 0 5px 1px; display: inline-block; display: block; }

.quick_search_box .trip_type.option_1 .option_check_box_3 .option_select_box_1 .option_detail:after { content: "."; display: block; clear: both; height: 0; visibility: hidden; }

.quick_search_box .trip_type.option_1 .option_check_box_3 .option_select_box_1 .option_detail .info_input_1 { width: 178px; }

.quick_search_box .trip_type.option_1 .option_check_box_3 .option_select_box_1 .option_detail .info_input_2 { width: 166px; }

.quick_search_box .trip_type.option_1 .option_check_box_3 .option_select_box_1 .option_detail .type_info { float: left; width: 37px; height: 33px; margin: 0 0 0 -1px; text-indent: -9999px; border: 1px solid #ccc; background: url("/img/trip/common/sp_trip.png") -100px 0 no-repeat; }

.quick_search_box .trip_type.option_1 .option_check_box_3 .option_select_box_2 { position: relative; z-index: 2; float: left; margin-right: 20px; }

.quick_search_box .trip_type.option_1 .option_check_box_3 .option_select_box_2 .option_detail { position: relative; padding: 0 0 5px 1px; display: inline-block; display: block; }

.quick_search_box .trip_type.option_1 .option_check_box_3 .option_select_box_2 .option_detail:after { content: "."; display: block; clear: both; height: 0; visibility: hidden; }

.quick_search_box .trip_type.option_1 .option_check_box_3 .option_select_box_2 .option_detail .layer_selecter.trip_date_picker { position: absolute; top: 40px; left: 0px; width: 747px; }

.quick_search_box .trip_type.option_1 .option_check_box_3 .option_select_box_2 .option_detail.info_input_1 { width: 159px; }

.quick_search_box .trip_type.option_1 .option_check_box_3 .option_select_box_3 { position: relative; z-index: 3; float: left; margin-right: 20px; }

.quick_search_box .trip_type.option_1 .option_check_box_3 .option_select_box_3 .option_detail { position: relative; padding: 0 0 5px 1px; display: inline-block; display: block; }

.quick_search_box .trip_type.option_1 .option_check_box_3 .option_select_box_3 .option_detail:after { content: "."; display: block; clear: both; height: 0; visibility: hidden; }

.quick_search_box .trip_type.option_1 .option_check_box_3 .option_select_box_3 .option_detail .member_class_select { position: absolute; top: 40px; right: 0px; }

.quick_search_box .trip_type.option_1 .option_check_box_3 .btn_plan_add { width: 120px; height: 35px; padding: 0 0 5px 0; margin: 0 20px 0 0; font-size: 14px; font-weight: bold; color: #5770fe; border: 1px solid #5770fe; background: #fff; }

.quick_search_box .trip_type.option_1 .option_check_box_3 .btn_plan_add span.ico { display: inline-block; width: 10px; height: 10px; margin: 0 5px 0 0; background: url("/img/trip/common/sp_trip.png") -310px 0 no-repeat; }

.quick_search_box .trip_type .hotel_recomd_box { top: 40px; left: 0; width: 456px; }

.quick_search_box .search_history_box { overflow: hidden; width: 1158px; margin: 0 auto; padding: 15px 0 9px 0; border-top: 1px solid #f4f4f4; }

.quick_search_box .search_history_box .title { float: left; min-width: 67px; font-size: 14px; font-weight: bold; color: #333; }

.quick_search_box .search_history_box .title .num { font-size: 12px; color: #6483dc; }

.quick_search_box .search_history_box .title .num .ico { display: inline-block; width: 12px; height: 12px; margin: 0 2px -2px 4px; background: url("/img/trip/common/sp_trip.png") -200px 0 no-repeat; }

.quick_search_box .search_history_box .list_box { float: left; overflow: hidden; width: 1055px; font-size: 12px; letter-spacing: 0px; margin: 1px 0 0 8px; }

.quick_search_box .search_history_box .list_box li { float: left; height: 28px; margin: 0 25px 0 0; }

.quick_search_box .search_history_box .list_box li .type_1 { display: inline-block; width: 14px; height: 12px; margin: 0 5px 0 5px; vertical-align: -4px; text-indent: -99999px; background: url("/img/trip/common/sp_trip.png") -240px 0 no-repeat; }

.quick_search_box .search_history_box .list_box li .type_2 { display: inline-block; width: 14px; height: 12px; margin: 0 5px 0 5px; vertical-align: -4px; text-indent: -99999px; background: url("/img/trip/common/sp_trip.png") -260px 0 no-repeat; }

.quick_search_box .search_history_box .list_box li .tx_bar { display: inline-block; width: 1px; height: 6px; margin: 0 9px 0 10px; vertical-align: 1px; background: #ccc; }

.quick_search_box .search_history_box .list_box li .date, .quick_search_box .search_history_box .list_box li .member { color: #999; }

.quick_search_box .search_history_box .list_box li .btn_del { display: inline-block; width: 8px; height: 8px; margin: -2px 0 0 6px; text-indent: -9999px; vertical-align: middle; background: url("/img/trip/common/sp_trip.png") -220px 0 no-repeat; }

.quick_search_box .search_history_box p.no_data { padding: 0 0 10px 0; }

.quick_search_box .trip_company_list_1 { overflow: hidden; width: 1198px; height: 56px; margin: 0 auto; background: url("/img/trip/common/sp_trip_company_01.png") no-repeat; }

.quick_search_box .trip_company_list_1 li { float: left; text-indent: -9999px; }

.quick_search_box .trip_company_list_2 { overflow: hidden; width: 1198px; height: 56px; margin: 0 auto; background: url("/img/trip/common/sp_trip_company_02.png") no-repeat; }

.quick_search_box .trip_company_list_2 li { float: left; text-indent: -9999px; }

.quick_search_box .trip_company_list_3 { overflow: hidden; width: 1198px; height: 56px; margin: 0 auto; background: url("/img/trip/common/sp_trip_company_03.png") no-repeat; }

.quick_search_box .trip_company_list_3 li { float: left; text-indent: -9999px; }

.quick_search_box .trip_company_list { overflow: hidden; width: 1198px; height: 56px; margin: 0 auto; }

.quick_search_box .trip_type .flight_inspection_box { width: 457px; height: 148px; padding-top: 24px; text-indent: -99999px; margin: 0 auto 0 auto; background: url("/img/trip/img_flight_01.png") 0 24px no-repeat; }

.quick_search_box .trip_type.option_2 .option_type_box { overflow: hidden; height: 30px; padding: 0 30px 0 30px; }

.quick_search_box .trip_type.option_2 .option_type_box li { float: left; min-width: 100px; }

.quick_search_box .trip_type.option_2 .option_type_box li span.text_1 { font-size: 16px; color: #666; }

.quick_search_box .trip_type.option_2 .option_type_box li .radio_design:checked + label.css-label { color: #5770fe; }

.quick_search_box .trip_type.option_2 .option_check_box_1 { padding: 10px 30px 3px 30px; display: inline-block; display: block; }

.quick_search_box .trip_type.option_2 .option_check_box_1:after { content: "."; display: block; clear: both; height: 0; visibility: hidden; }

.quick_search_box .trip_type.option_2 .option_check_box_1 .city_list_select_type_1, .quick_search_box .trip_type.option_2 .option_check_box_1 .city_list_select_type_2, .quick_search_box .trip_type.option_2 .option_check_box_1 .city_list_select_type_3 { position: absolute; top: 40px; left: 0px; }

.quick_search_box .trip_type.option_2 .option_check_box_1 .option_select_box_1 { z-index: 10; position: relative; float: left; margin-right: 20px; }

.quick_search_box .trip_type.option_2 .option_check_box_1 .option_select_box_1 .option_detail { position: relative; padding: 0 0 5px 1px; display: inline-block; display: block; }

.quick_search_box .trip_type.option_2 .option_check_box_1 .option_select_box_1 .option_detail:after { content: "."; display: block; clear: both; height: 0; visibility: hidden; }

.quick_search_box .trip_type.option_2 .option_check_box_1 .option_select_box_1 .option_detail .info_input_1 { width: 178px; }

.quick_search_box .trip_type.option_2 .option_check_box_1 .option_select_box_1 .option_detail .recomd_list_box { position: absolute; top: 40px; left: 0px; width: 455px; }

.quick_search_box .trip_type.option_2 .option_check_box_1 .option_select_box_1 .option_detail .recomd_list_box li { width: 455px; }

.quick_search_box .trip_type.option_2 .option_check_box_1 .option_select_box_1 .option_detail .info_input_2 { width: 448px; }

.quick_search_box .trip_type.option_2 .option_check_box_1 .option_select_box_2 { z-index: 10; position: relative; float: left; margin-right: 20px; }

.quick_search_box .trip_type.option_2 .option_check_box_1 .option_select_box_2 .option_detail { position: relative; padding: 0 0 5px 1px; display: inline-block; display: block; }

.quick_search_box .trip_type.option_2 .option_check_box_1 .option_select_box_2 .option_detail:after { content: "."; display: block; clear: both; height: 0; visibility: hidden; }

.quick_search_box .trip_type.option_2 .option_check_box_1 .option_select_box_2 .option_detail .layer_selecter.trip_date_picker_v2 { position: absolute; top: 40px; right: 0px; width: 747px; }

.quick_search_box .trip_type.option_2 .option_check_box_1 .option_select_box_2 .option_detail.info_input_1 { width: 159px; }

.quick_search_box .trip_type.option_2 .option_check_box_1 .option_select_box_3 { float: left; margin-right: 20px; }

.quick_search_box .trip_type.option_2 .option_check_box_1 .option_select_box_3 .option_detail { position: relative; padding: 0 0 5px 1px; display: inline-block; display: block; }

.quick_search_box .trip_type.option_2 .option_check_box_1 .option_select_box_3 .option_detail:after { content: "."; display: block; clear: both; height: 0; visibility: hidden; }

.quick_search_box .trip_type.option_2 .option_check_box_1 .option_select_box_3 .option_detail .member_class_select { position: absolute; top: 40px; right: 0px; }

.quick_search_box .trip_type.option_2 .option_check_box_1 .option_select_box_3 .comp_select_type_1 { width: 200px; }

.quick_search_box .trip_type.option_2 .option_check_box_1 .option_select_box_3 .comp_select_type_1 .btn_select { height: 35px; }

.quick_search_box .trip_type.option_2 .option_check_box_1 .option_select_box_3 .comp_select_type_1 .btn_select .arrow { top: 15px; }

.quick_search_box .trip_type.option_2 .option_check_box_1 .option_select_box_3 .comp_select_type_1 .select_list { width: 198px; }

.quick_search_box .trip_type.option_2 .option_check_box_2 { padding: 10px 30px 3px 30px; display: inline-block; display: block; }

.quick_search_box .trip_type.option_2 .option_check_box_2:after { content: "."; display: block; clear: both; height: 0; visibility: hidden; }

.quick_search_box .trip_type.option_2 .option_check_box_2 .city_list_select_type_1, .quick_search_box .trip_type.option_2 .option_check_box_2 .city_list_select_type_2, .quick_search_box .trip_type.option_2 .option_check_box_2 .city_list_select_type_3 { position: absolute; top: 40px; left: 0px; }

.quick_search_box .trip_type.option_2 .option_check_box_2 .comp_select_type_1 { width: 275px; }

.quick_search_box .trip_type.option_2 .option_check_box_2 .comp_select_type_1.focus { z-index: 3; }

.quick_search_box .trip_type.option_2 .option_check_box_2 .comp_select_type_1 .btn_select { height: 35px; margin-bottom: 15px; }

.quick_search_box .trip_type.option_2 .option_check_box_2 .comp_select_type_1 .btn_select .arrow { top: 15px; }

.quick_search_box .trip_type.option_2 .option_check_box_2 .comp_select_type_1 .select_list { width: 273px; margin-top: -16px; }

.quick_search_box .trip_type.option_2 .option_check_box_2 .option_select_box_1 { position: relative; z-index: 1; float: left; margin-right: 20px; }

.quick_search_box .trip_type.option_2 .option_check_box_2 .option_select_box_1 .option_detail { position: relative; padding: 0 0 5px 1px; display: inline-block; display: block; }

.quick_search_box .trip_type.option_2 .option_check_box_2 .option_select_box_1 .option_detail:after { content: "."; display: block; clear: both; height: 0; visibility: hidden; }

.quick_search_box .trip_type.option_2 .option_check_box_2 .option_select_box_1 .option_detail .info_input_1 { width: 318px; }

.quick_search_box .trip_type.option_2 .option_check_box_2 .option_select_box_1 .option_detail .info_input_2 { width: 306px; }

.quick_search_box .trip_type.option_2 .option_check_box_2 .option_select_box_1 .option_detail .recomd_list_box { position: absolute; top: 40px; left: 0px; width: 314px; }

.quick_search_box .trip_type.option_2 .option_check_box_2 .option_select_box_1 .option_detail .recomd_list_box li { width: 314px; }

.quick_search_box .trip_type.option_2 .option_check_box_2 .option_select_box_2 { position: relative; z-index: 2; float: left; margin-right: 20px; }

.quick_search_box .trip_type.option_2 .option_check_box_2 .option_select_box_2 .option_detail { position: relative; padding: 0 0 5px 1px; display: inline-block; display: block; }

.quick_search_box .trip_type.option_2 .option_check_box_2 .option_select_box_2 .option_detail:after { content: "."; display: block; clear: both; height: 0; visibility: hidden; }

.quick_search_box .trip_type.option_2 .option_check_box_2 .option_select_box_2 .option_detail .layer_selecter.trip_date_picker_v2 { position: absolute; top: 40px; left: 0px; width: 747px; }

.quick_search_box .trip_type.option_2 .option_check_box_2 .option_select_box_2 .option_detail.info_input_1 { width: 159px; }

.quick_search_box .trip_type.option_2 .option_check_box_2 .option_select_box_3 { position: relative; z-index: 3; float: left; height: 38px; margin-right: 20px; }

.quick_search_box .trip_type.option_2 .option_check_box_2 .option_select_box_3 .option_detail { position: relative; padding: 0 0 5px 1px; display: inline-block; display: block; }

.quick_search_box .trip_type.option_2 .option_check_box_2 .option_select_box_3 .option_detail:after { content: "."; display: block; clear: both; height: 0; visibility: hidden; }

.quick_search_box .trip_type.option_2 .option_check_box_2 .option_select_box_3 .option_detail .member_class_select { position: absolute; top: 40px; right: 0px; }

.quick_search_box .trip_type.option_2 .option_check_box_2 .option_select_box_3 .option_detail .info_input_1 { width: 85px; }

.quick_search_box .trip_type.option_2 .option_check_box_2 .option_select_box_3 .option_detail .comp_select_type_1 { float: left; width: 85px; }

.quick_search_box .trip_type.option_2 .option_check_box_2 .option_select_box_3 .option_detail .comp_select_type_1 .select_list { width: 83px; }

.quick_search_box .trip_type.option_2 .option_check_box_2 .option_select_box_3 .option_detail .info_input_2 { width: 244px; }

.quick_search_box .trip_type.option_2 .option_check_box_2 .option_select_box_3 .option_detail .info_input_2.room { width: 74px; }

.quick_search_box .trip_type.option_2 .option_check_box_2 .option_select_box_3 .option_detail .btn_type_02 { z-index: 2; position: relative; width: 88px; margin: 0 0 0 -1px; }

.quick_search_box .trip_type.option_2 .option_check_box_2 .option_select_box_3 .room_addition { z-index: 1; position: absolute; top: 40px; right: 0px; }

.quick_search_box .trip_type.option_2 .popular_trip { overflow: hidden; width: 1198px; margin: 0 auto; padding: 0 0 15px 0; }

.quick_search_box .trip_type.option_2 .popular_trip p.title { float: left; min-width: 75px; margin: 0 0 0 2px; padding: 0 0 0 18px; font-size: 12px; font-weight: bold; color: #333; }

.quick_search_box .trip_type.option_2 .popular_trip p.title .ico { display: inline-block; width: 13px; height: 15px; margin-right: 5px; vertical-align: -4px; background: url("/img/trip/common/sp_trip.png") -330px 0px no-repeat; }

.quick_search_box .trip_type.option_2 .popular_trip .list_box { overflow: hidden; float: left; min-height: 20px; }

.quick_search_box .trip_type.option_2 .popular_trip .list_box li { float: left; font-size: 12px; }

.quick_search_box .trip_type.option_2 .popular_trip .list_box li .tx_bar { display: inline-block; width: 1px; height: 9px; margin: 0 10px 0 11px; vertical-align: 0px; background: #ccc; }

.quick_search_box .trip_type.option_2 .popular_trip .list_box li:first-child .tx_bar { display: none; }

.quick_search_box .trip_type.option_3 .option_type_box { overflow: hidden; height: 30px; padding: 0 30px 0 30px; }

.quick_search_box .trip_type.option_3 .option_type_box li { float: left; min-width: 138px; }

.quick_search_box .trip_type.option_3 .option_type_box li span.text_1 { font-size: 16px; color: #666; }

.quick_search_box .trip_type.option_3 .option_type_box li .radio_design:checked + label.css-label { color: #5770fe; }

.quick_search_box .trip_type.option_3 .option_check_box_1 { padding: 10px 30px 20px 30px; display: inline-block; display: block; }

.quick_search_box .trip_type.option_3 .option_check_box_1:after { content: "."; display: block; clear: both; height: 0; visibility: hidden; }

.quick_search_box .trip_type.option_3 .option_check_box_1 .option_select_box_1 { float: left; margin-right: 20px; }

.quick_search_box .trip_type.option_3 .option_check_box_1 .option_select_box_1 .option_detail { position: relative; padding: 0 0 5px 1px; }

.quick_search_box .trip_type.option_3 .option_check_box_1 .option_select_box_1 .option_detail .info_input_1 { width: 500px; }

.quick_search_box .trip_type.option_3 .option_check_box_1 .comp_select_type_1 { width: 500px; }

.quick_search_box .trip_type.option_3 .option_check_box_1 .comp_select_type_1 .btn_select { height: 35px; }

.quick_search_box .trip_type.option_3 .option_check_box_1 .comp_select_type_1 .btn_select .arrow { top: 15px; }

.quick_search_box .trip_type.option_3 .option_check_box_1 .comp_select_type_1 .select_list { width: 498px; }

.quick_search_box .trip_type.option_3 .option_check_box_2 { padding: 10px 30px 20px 30px; display: inline-block; display: block; }

.quick_search_box .trip_type.option_3 .option_check_box_2:after { content: "."; display: block; clear: both; height: 0; visibility: hidden; }

.quick_search_box .trip_type.option_3 .option_check_box_2 .city_list_select_type_1, .quick_search_box .trip_type.option_3 .option_check_box_2 .city_list_select_type_2, .quick_search_box .trip_type.option_3 .option_check_box_2 .city_list_select_type_3 { position: absolute; top: 40px; left: 0px; }

.quick_search_box .trip_type.option_3 .option_check_box_2 .option_select_box_1 { float: left; margin-right: 20px; }

.quick_search_box .trip_type.option_3 .option_check_box_2 .option_select_box_1 .option_detail { position: relative; padding: 0 0 5px 1px; display: inline-block; display: block; }

.quick_search_box .trip_type.option_3 .option_check_box_2 .option_select_box_1 .option_detail:after { content: "."; display: block; clear: both; height: 0; visibility: hidden; }

.quick_search_box .trip_type.option_3 .option_check_box_2 .option_select_box_1 .option_detail .info_input_1 { width: 178px; }

.quick_search_box .trip_type.option_3 .option_check_box_2 .option_select_box_1 .option_detail .info_input_2 { width: 166px; }

.quick_search_box .trip_type.option_3 .option_check_box_2 .option_select_box_2 { float: left; margin-right: 20px; }

.quick_search_box .trip_type.option_3 .option_check_box_2 .option_select_box_2 .option_detail { position: relative; padding: 0 0 5px 1px; display: inline-block; display: block; }

.quick_search_box .trip_type.option_3 .option_check_box_2 .option_select_box_2 .option_detail:after { content: "."; display: block; clear: both; height: 0; visibility: hidden; }

.quick_search_box .trip_type.option_3 .option_check_box_2 .option_select_box_2 .option_detail .layer_selecter.trip_date_picker_v2 { position: absolute; top: 40px; left: 0px; width: 747px; }

.quick_search_box .trip_type.option_3 .option_check_box_2 .option_select_box_3 { float: left; margin-right: 20px; }

.quick_search_box .trip_type.option_3 .option_check_box_2 .option_select_box_3 .option_detail { position: relative; padding: 0 0 5px 1px; display: inline-block; display: block; }

.quick_search_box .trip_type.option_3 .option_check_box_2 .option_select_box_3 .option_detail:after { content: "."; display: block; clear: both; height: 0; visibility: hidden; }

.quick_search_box .trip_type.option_3 .option_check_box_2 .option_select_box_3 .option_detail p.title { float: left; width: 69px; height: 27px; padding: 6px 0 0 0; font-size: 13px; font-weight: bold; letter-spacing: 0px; color: #333; text-align: center; border: 1px solid #ccc; }

.quick_search_box .trip_type.option_3 .option_check_box_2 .option_select_box_3 .option_detail .list_box { float: left; min-width: 342px; height: 33px; padding: 0 0 0 10px; margin-left: -1px; border: 1px solid #ccc; }

.quick_search_box .trip_type.option_3 .option_check_box_2 .option_select_box_3 .option_detail .list_box li { float: left; padding: 5px 10px 0 0; color: #333; font-size: 13px; }

.quick_search_box .trip_type.option_3 .option_check_box_2 .option_select_box_3 .option_detail .list_box li .tx_bar { display: inline-block; width: 1px; height: 12px; margin: 0 11px 0 0; vertical-align: -2px; background: #ccc; }

.quick_search_box .trip_type.option_3 .option_check_box_2 .option_select_box_3 .option_detail .list_box li:first-child .tx_bar { display: none; }

.quick_search_box .trip_type.option_3 .option_check_box_3 { padding: 10px 30px 20px 30px; display: inline-block; display: block; }

.quick_search_box .trip_type.option_3 .option_check_box_3:after { content: "."; display: block; clear: both; height: 0; visibility: hidden; }

.quick_search_box .trip_type.option_3 .option_check_box_3 .city_list_select_type_1, .quick_search_box .trip_type.option_3 .option_check_box_3 .city_list_select_type_2, .quick_search_box .trip_type.option_3 .option_check_box_3 .city_list_select_type_3 { position: absolute; top: 40px; left: 0px; }

.quick_search_box .trip_type.option_3 .option_check_box_3 .option_select_box_1 { float: left; margin-right: 20px; }

.quick_search_box .trip_type.option_3 .option_check_box_3 .option_select_box_1 .option_detail { position: relative; padding: 0 0 5px 1px; display: inline-block; display: block; }

.quick_search_box .trip_type.option_3 .option_check_box_3 .option_select_box_1 .option_detail:after { content: "."; display: block; clear: both; height: 0; visibility: hidden; }

.quick_search_box .trip_type.option_3 .option_check_box_3 .option_select_box_1 .option_detail .info_input_1 { width: 498px; }

.quick_search_box .trip_type.option_3 .option_check_box_3 .comp_select_type_1 { width: 500px; }

.quick_search_box .trip_type.option_3 .option_check_box_3 .comp_select_type_1 .btn_select { height: 35px; }

.quick_search_box .trip_type.option_3 .option_check_box_3 .comp_select_type_1 .btn_select .arrow { top: 15px; }

.quick_search_box .trip_type.option_3 .option_check_box_3 .comp_select_type_1 .select_list { width: 498px; }

.quick_search_box .trip_type.option_3 .option_check_box_3 .option_select_box_2 { float: left; margin-right: 20px; }

.quick_search_box .trip_type.option_3 .option_check_box_3 .option_select_box_2 .option_detail { position: relative; padding: 0 0 5px 1px; display: inline-block; display: block; }

.quick_search_box .trip_type.option_3 .option_check_box_3 .option_select_box_2 .option_detail:after { content: "."; display: block; clear: both; height: 0; visibility: hidden; }

.quick_search_box .trip_type.option_3 .option_check_box_3 .option_select_box_2 .option_detail p.title { float: left; width: 73px; height: 27px; padding: 6px 0 0 0; font-size: 13px; font-weight: bold; color: #333; text-align: center; border: 1px solid #ccc; }

.quick_search_box .trip_type.option_3 .option_check_box_3 .option_select_box_2 .option_detail .list_box { float: left; height: 33px; padding: 0 0 0 10px; margin-left: -1px; border: 1px solid #ccc; }

.quick_search_box .trip_type.option_3 .option_check_box_3 .option_select_box_2 .option_detail .list_box li { float: left; padding: 5px 10px 0 0; color: #333; font-size: 13px; }

.quick_search_box .trip_type.option_3 .option_check_box_3 .option_select_box_2 .option_detail .list_box li .tx_bar { display: inline-block; width: 1px; height: 12px; margin: 0 11px 0 0; vertical-align: -2px; background: #ccc; }

.quick_search_box .trip_type.option_3 .option_check_box_3 .option_select_box_2 .option_detail .list_box li:first-child .tx_bar { display: none; }

* html .quick_search_box .trip_type.option_1 .option_check_box_1 { height: 1%; }

* html .quick_search_box .trip_type.option_1 .option_check_box_1 .option_select_box_1 .option_detail, * html .quick_search_box .trip_type.option_1 .option_check_box_1 .option_select_box_2 .option_detail, * html .quick_search_box .trip_type.option_1 .option_check_box_1 .option_select_box_3 .option_detail { height: 1%; }

* html .quick_search_box .trip_type.option_1 .option_check_box_2 { height: 1%; }

* html .quick_search_box .trip_type.option_1 .option_check_box_2 .option_select_box_1 .option_detail, * html .quick_search_box .trip_type.option_1 .option_check_box_2 .option_select_box_2 .option_detail, * html .quick_search_box .trip_type.option_1 .option_check_box_2 .option_select_box_3 .option_detail { height: 1%; }

* html .quick_search_box .trip_type.option_1 .option_check_box_3 { height: 1%; }

* html .quick_search_box .trip_type.option_1 .option_check_box_3 .plan_list > li, * html .quick_search_box .trip_type.option_1 .option_check_box_3 .option_select_box_1 .option_detail, * html .quick_search_box .trip_type.option_1 .option_check_box_3 .option_select_box_2 .option_detail, * html .quick_search_box .trip_type.option_1 .option_check_box_3 .option_select_box_3 .option_detail { height: 1%; }

* html .quick_search_box .trip_type.option_2 .option_check_box_1 { height: 1%; }

* html .quick_search_box .trip_type.option_2 .option_check_box_1 .option_select_box_1 .option_detail, * html .quick_search_box .trip_type.option_2 .option_check_box_1 .option_select_box_2 .option_detail, * html .quick_search_box .trip_type.option_2 .option_check_box_1 .option_select_box_3 .option_detail { height: 1%; }

* html .quick_search_box .trip_type.option_2 .option_check_box_2 { height: 1%; }

* html .quick_search_box .trip_type.option_2 .option_check_box_2 .option_select_box_1 .option_detail, * html .quick_search_box .trip_type.option_2 .option_check_box_2 .option_select_box_2 .option_detail, * html .quick_search_box .trip_type.option_2 .option_check_box_2 .option_select_box_3 .option_detail { height: 1%; }

* html .quick_search_box .trip_type.option_3 .option_check_box_1 { height: 1%; }

* html .quick_search_box .trip_type.option_3 .option_check_box_2 { height: 1%; }

* html .quick_search_box .trip_type.option_3 .option_check_box_2 .option_select_box_1 .option_detail, * html .quick_search_box .trip_type.option_3 .option_check_box_2 .option_select_box_2 .option_detail, * html .quick_search_box .trip_type.option_3 .option_check_box_2 .option_select_box_3 .option_detail { height: 1%; }

* html .quick_search_box .trip_type.option_3 .option_check_box_3 { height: 1%; }

* html .quick_search_box .trip_type.option_3 .option_check_box_3 .option_select_box_1 .option_detail, * html .quick_search_box .trip_type.option_3 .option_check_box_3 .option_select_box_2 .option_detail { height: 1%; }

/* 여행 tab */
.tab_menu_type_1 { position: absolute; top: -5px; right: 8px; height: 35px; border: 1px solid #d7d7d7; border-radius: 20px; }

.tab_menu_type_1 ul { height: 37px; margin: -1px 0 0 -1px; }

.tab_menu_type_1 li { position: relative; float: left; height: 35px; border: 1px solid transparent; border-radius: 20px; }

.tab_menu_type_1 a { display: block; height: 35px; padding: 0 24px; font-size: 16px; line-height: 35px; color: #999; }

.tab_menu_type_1 .ico_dot { position: absolute; top: 50%; left: -5px; width: 4px; height: 4px; margin-top: -2px; border-radius: 50%; background-color: #999; }

.tab_menu_type_1 li:hover, .tab_menu_type_1 li.selected { z-index: 1; border: 1px solid #5770fe; background-color: #fff; }

.tab_menu_type_1 li:first-child .ico_dot, .tab_menu_type_1 li:hover .ico_dot, .tab_menu_type_1 li.selected .ico_dot { display: none; }

.tab_menu_type_1 li:hover a, .tab_menu_type_1 li.selected a { font-weight: bold; color: #5770fe; }

.tab_menu_type_1 li a:hover, .tab_menu_type_1 li a:focus, .tab_menu_type_1 li a:active { text-decoration: none; }

@keyframes example { 0% { left: 0px;
    top: 0px; }
  100% { left: 0px;
    top: 198px; } }

@keyframes example_info { 0% { left: 0px;
    bottom: -198px; }
  100% { left: 0px;
    bottom: 0px; } }

.season_billboard_box { position: relative; display: block; width: 1200px; margin: 28px auto 0 auto; padding: 0 8px 0 8px; }

.season_billboard_box .season_recom_list { float: left; position: relative; width: 598px; height: 397px; padding: 0 0 0 181px; border: 1px solid #ccc; background: #fff; }

.season_billboard_box .season_recom_list h1 { position: absolute; top: 0px; left: -37px; font-size: 14px; line-height: 1.5; }

.season_billboard_box .season_recom_list .list_box { overflow: hidden; width: 598px; height: 397px; }

.season_billboard_box .season_recom_list .list_box ul { overflow: hidden; width: 600px; height: 398px; background: url("/img/trip/common/bg_line_1.png"); }

.season_billboard_box .season_recom_list .list_box ul li { position: relative; float: left; overflow: hidden; width: 199px; height: 198px; margin: 0px 1px 1px 0; }

.season_billboard_box .season_recom_list .list_box ul li .thumb_box { position: absolute; top: 0px; left: 0px; }

.season_billboard_box .season_recom_list .list_box ul li .thumb_box img { width: 199px; max-height: 198px; }

.season_billboard_box .season_recom_list .list_box ul li .text_box { position: absolute; top: 0px; left: 0px; width: 199px; height: 198px; background: url("/img/trip/common/bg_g_01.png"); }

.season_billboard_box .season_recom_list .list_box ul li .text_box p { position: absolute; bottom: 0px; left: 0px; overflow: hidden; max-height: 34px; margin-bottom: 11px; padding: 0 11px 0 11px; font-size: 14px; line-height: 16px; color: #fff; }

.season_billboard_box .season_recom_list .list_box ul li .category_box { position: absolute; top: 0px; left: 0px; width: 199px; height: 198px; }

.season_billboard_box .season_recom_list .list_box ul li .category_box .dim { transition: opacity 0.2s; opacity: 0; filter: alpha(opacity=0); position: absolute; top: 0px; left: 0px; width: 199px; height: 198px; background: url("/img/trip/common/bg_dim_69.png"); }

.season_billboard_box .season_recom_list .list_box ul li .category_box .info { display: none; position: absolute; bottom: 0; left: 0px; width: 199px; height: 148px; padding: 50px 0 0 0; text-align: center; }

.season_billboard_box .season_recom_list .list_box ul li .category_box .info .icon { display: inline-block; width: 35px; height: 35px; text-indent: -9999px; background: url("/img/trip/sp_type_3.png") no-repeat; }

.season_billboard_box .season_recom_list .list_box ul li .category_box .info .icon.ico_category_type_1 { background-position: 0 0; }

.season_billboard_box .season_recom_list .list_box ul li .category_box .info .icon.ico_category_type_2 { background-position: 0 -40px; }

.season_billboard_box .season_recom_list .list_box ul li .category_box .info .icon.ico_category_type_3 { background-position: 0 -80px; }

.season_billboard_box .season_recom_list .list_box ul li .category_box .info .icon.ico_category_type_4 { background-position: 0 -120px; }

.season_billboard_box .season_recom_list .list_box ul li .category_box .info .icon.ico_category_type_5 { background-position: 0 -160px; }

.season_billboard_box .season_recom_list .list_box ul li .category_box .info .icon.ico_category_type_6 { background-position: 0 -200px; }

.season_billboard_box .season_recom_list .list_box ul li .category_box .info .icon.ico_category_type_7 { background-position: 0 -240px; }

.season_billboard_box .season_recom_list .list_box ul li .category_box .info .icon.ico_category_type_8 { background-position: 0 -280px; }

.season_billboard_box .season_recom_list .list_box ul li .category_box .info .icon.ico_category_type_9 { background-position: 0 -320px; }

.season_billboard_box .season_recom_list .list_box ul li .category_box .info .icon.ico_category_type_10 { background-position: 0 -360px; }

.season_billboard_box .season_recom_list .list_box ul li .category_box .info .text { text-overflow: ellipsis; white-space: nowrap; word-wrap: normal; overflow: hidden; max-width: 199px; height: 27px; margin: 2px 0 17px 0; font-size: 16px; font-weight: bold; color: #fff; }

.season_billboard_box .season_recom_list .list_box ul li .category_box .info .result { display: inline-block; height: 25px; padding: 3px 10px 0 12px; font-size: 14px; color: #fff; border: 1px solid #fff; border-radius: 15px; }

.season_billboard_box .season_recom_list .list_box ul li .category_box .info .result span.arrow { display: inline-block; width: 6px; height: 12px; margin: 0 0 0 4px; vertical-align: -1px; background: url("/img/trip/common/sp_trip.png") -240px -50px no-repeat; }

.season_billboard_box .season_recom_list .list_box ul li:hover .text_box { top: 198px; animation-name: example; animation-duration: 0.5s; }

.season_billboard_box .season_recom_list .list_box ul li:hover .category_box .dim { opacity: 1; filter: alpha(opacity=100); }

.season_billboard_box .season_recom_list .list_box ul li:hover .category_box .info { display: block; animation-name: example_info; animation-duration: 0.5s; }

.season_billboard_box .billboard_box { position: relative; overflow: hidden; float: left; width: 398px; height: 397px; margin: 0 0 0 19px; border: 1px solid #ccc; background: #fff; }

.season_billboard_box .billboard_box .list_box li { float: left; width: 398px; text-align: center; }

.season_billboard_box .billboard_box .list_box li img { width: 398px; height: 397px; }

.season_billboard_box .billboard_box .bx-controls.bx-has-controls-direction.bx-has-pager .bx-pager.bx-default-pager { position: absolute; top: 21px; right: 21px; text-align: right; font: 0/0 arial; }

.season_billboard_box .billboard_box .bx-controls.bx-has-controls-direction.bx-has-pager .bx-pager.bx-default-pager .bx-pager-item { display: inline-block; width: 10px; height: 10px; margin-left: 5px; }

.season_billboard_box .billboard_box .bx-controls.bx-has-controls-direction.bx-has-pager .bx-pager.bx-default-pager .bx-pager-item .bx-pager-link { display: block; width: 10px; height: 10px; text-indent: -9999px; background: url("/img/trip/common/sp_trip.png") 0 -320px no-repeat; }

.season_billboard_box .billboard_box .bx-controls.bx-has-controls-direction.bx-has-pager .bx-pager.bx-default-pager .bx-pager-item .bx-pager-link.active { background: url("/img/trip/common/sp_trip.png") -20px -320px no-repeat; }

.season_billboard_box .billboard_box .bx-controls.bx-has-controls-direction.bx-has-pager .bx-controls-direction { position: absolute; top: 0px; left: 0px; width: 100%; }

.season_billboard_box .billboard_box .bx-controls.bx-has-controls-direction.bx-has-pager .bx-controls-direction .bx-prev { position: absolute; top: 183px; left: 0px; display: block; width: 32px; height: 32px; text-indent: -9999px; background: url("/img/trip/common/sp_trip.png") 0 -280px no-repeat; }

.season_billboard_box .billboard_box .bx-controls.bx-has-controls-direction.bx-has-pager .bx-controls-direction .bx-next { position: absolute; top: 183px; right: 0px; display: block; width: 32px; height: 32px; text-indent: -9999px; background: url("/img/trip/common/sp_trip.png") -38px -280px no-repeat; }

.season_billboard_box .billboard ul li { float: left; }

.season_billboard_box .billboard_box .billboard_controls .controls { position: absolute; top: 0px; left: 0px; width: 100%; }

.season_billboard_box .billboard_box .billboard_controls .controls .prev { position: absolute; top: 183px; left: 0px; display: block; width: 32px; height: 32px; text-indent: -9999px; background: url("/img/trip/common/sp_trip.png") 0 -280px no-repeat; }

.season_billboard_box .billboard_box .billboard_controls .controls .next { position: absolute; top: 183px; right: 0px; display: block; width: 32px; height: 32px; text-indent: -9999px; background: url("/img/trip/common/sp_trip.png") -38px -280px no-repeat; }

.season_billboard_box .billboard_box .billboard_controls .pagination { position: absolute; top: 21px; right: 21px; }

.season_billboard_box .billboard_box .billboard_controls .pagination span { float: left; display: block; width: 10px; height: 10px; margin-left: 5px; border-radius: 50%; border: 1px solid #fff; cursor: pointer; }

.season_billboard_box .billboard_box .billboard_controls .pagination span.swiper-pagination-bullet-active { background: #fff; }

.trip_bnr_box { position: relative; width: 1200px; margin: 33px auto -3px; overflow: hidden; }

.trip_bnr_box .tit_area { overflow: hidden; }

.trip_bnr_box h1 { float: left; width: 176px; height: 25px; font-size: 14px; line-height: 1.5; text-indent: -9999px; background: url("/img/trip/common/tit_bnr.png") no-repeat; }

.trip_bnr_box .sub_text { float: left; margin-top: 2px; font-size: 14px; color: #333; }

.trip_bnr_box .list_box { position: relative; overflow: hidden; width: 1220px; height: 120px; margin-top: 15px; }

.trip_bnr_box .list_box > ul { overflow: hidden; z-index: 1; }

.trip_bnr_box .list_box > ul > li { float: left; width: 590px; height: 120px; margin-right: 20px; }

.trip_bnr_box .list_box > ul > li .prd_area { display: block; position: relative; height: 100%; }

.trip_bnr_box .list_box > ul > li .prd_bnr { width: 590px; height: 120px; }

.trip_bnr_box .list_box > ul > li .bg_gradation { display: block; position: absolute; left: 0; top: 0; width: 588px; height: 118px; background: url("/img/trip/common/bg_gradation.png") no-repeat; border: 1px solid #dbdbdb; }

.trip_bnr_box .list_box > ul > li .prd_info { position: absolute; left: 25px; top: 30px; z-index: 1; }

.trip_bnr_box .list_box > ul > li .prd_info .bnr_s { float: left; width: 30px; height: 20px; margin-right: 4px; }

.trip_bnr_box .list_box > ul > li .prd_info .bnr_title { float: left; margin-top: -7px; font-size: 20px; color: #333; }

.trip_bnr_box .list_box > ul > li .prd_info .bnr_txt { clear: both; display: block; margin-top: 2px; line-height: 1.4; }

.trip_bnr_box .tripbnr_func { position: absolute; top: 0px; width: 1200px; z-index: 2; }

.trip_bnr_box .tripbnr_func .btn_paging { position: absolute; top: 30px; width: 60px; height: 60px; text-indent: -9999px; }

.trip_bnr_box .tripbnr_func .btn_paging.btn_prev { left: 0px; background: url("/img/trip/common/sp_trip.png") 0 -210px no-repeat; }

.trip_bnr_box .tripbnr_func .btn_paging.btn_next { right: 0px; background: url("/img/trip/common/sp_trip.png") -70px -210px no-repeat; }

.trip_bnr_box .list_box:hover .tripbnr_func .btn_paging.btn_prev { right: 0px; background: url("/img/trip/common/sp_trip.png") -140px -210px no-repeat; }

.trip_bnr_box .list_box:hover .tripbnr_func .btn_paging.btn_next { right: 0px; background: url("/img/trip/common/sp_trip.png") -210px -210px no-repeat; }

.trip_deal_box { z-index: 1; position: relative; width: 1200px; margin: 39px auto 0 auto; padding: 0 8px 42px 8px; }

.trip_deal_box .tit_area { overflow: hidden; }

.trip_deal_box h1 { float: left; width: 73px; height: 30px; text-indent: -9999px; background: url("/img/trip/common/sp_trip.png") 0 -580px no-repeat; }

.trip_deal_box .sub_text { float: left; margin-top: 2px; font-size: 14px; color: #333; }

.trip_deal_box .list_box { overflow: hidden; width: 1220px; margin-top: 15px; }

.trip_deal_box .list_box li { float: left; width: 385px; height: 327px; margin: 0 19px 20px 0; border: 1px solid #ccc; background: #fff; }

.trip_deal_box .list_box li:hover { border: 1px solid #f43142; }

.trip_deal_box .list_box .product_type1 .thumb { height: 189px; text-align: center; }

.trip_deal_box .list_box .product_type1 .thumb .icon_shockingdeal { position: absolute; top: 9px; left: 9px; width: 66px; height: 28px; text-indent: -9999px; background: url("/img/trip/common/sp_trip.png") 0px -930px no-repeat; }

.trip_deal_box .list_box .product_type1 .thumb img { width: 385px; height: 189px; }

.trip_deal_box .list_box .product_type1 .summary_in { padding: 7px 14px 7px 14px; }

.trip_deal_box .list_box .product_type1 .summary_in .subject { height: 20px; font-size: 16px; margin-bottom: 35px; }

.trip_deal_box .list_box .product_type1 .summary_in .txt_op { position: absolute; top: 32px; left: 14px; overflow: hidden; width: 357px; height: 17px; font-size: 12px; color: #999; }

.trip_deal_box .list_box .product_type1 .summary_in .info { margin-top: 17px; }

.trip_deal_box .list_box .product_type1 .summary_in .info .sale .n { font-size: 20px; }

.trip_deal_box .list_box .product_type1 .summary_in .info .price .price_normal { bottom: 22px; color: #666; }

.trip_deal_box .list_box .product_type1 .summary_in .info .price .price_normal .n { font-size: 14px; }

.trip_deal_box .list_box .product_type1 .summary_in .info .price .n { font-size: 20px; }

.trip_deal_box .list_box .product_type1 .summary_in .info .buy_history { position: absolute; top: 76px; right: 14px; font-size: 12px; color: #999; }

.trip_deal_box .list_box .product_type1 .add { position: relative; height: 33px; padding: 0 10px 0 15px; border-top: 1px solid #eee; background: #fff; }

.trip_deal_box .list_box .product_type1 .add .num { vertical-align: 5px; }

.trip_deal_box .list_box .product_type1 .add .company { position: absolute; top: 9px; right: 15px; height: 16px; font-size: 12px; line-height: 15px; color: #999; }

.trip_deal_box .btn_more { display: block; height: 41px; padding: 13px 0 0 0; text-align: center; font-size: 18px; text-decoration: none; border: 1px solid #ccc; color: #666; box-shadow: 0px 2px 0px 0px rgba(204, 204, 204, 0.21); background: #f7f9fa; }

.trip_deal_box .btn_more strong { display: inline-block; height: 25px; color: #5770fe; text-decoration: none; background: url("/img/trip/common/bg_link_line_1.png") bottom left repeat-x; }

.trip_deal_box .btn_more span.arrow { display: inline-block; width: 6px; height: 11px; margin: 0 0 0 9px; vertical-align: 2px; background: url("/img/trip/common/sp_trip.png") -370px 0 no-repeat; }

.weekend_special_box { position: relative; width: 100%; height: 562px; background: #dae0f4; }

.weekend_special_box .inner_box { width: 1218px; margin: 0 auto; padding: 39px 0 0 0; }

.weekend_special_box .tit_area { overflow: hidden; }

.weekend_special_box h1 { float: left; width: 200px; height: 30px; margin-left: 9px; font-size: 14px; line-height: 1.5; text-indent: -9999px; background: url("/img/trip/common/sp_trip.png") 0 -550px no-repeat; }

.weekend_special_box .sub_text { float: left; margin-top: 2px; font-size: 14px; color: #333; }

.weekend_special_box .list_box { position: relative; overflow: hidden; width: 1218px; height: 466px; margin-top: 15px; }

.weekend_special_box .list_box > ul { overflow: hidden; position: relative; z-index: 1; width: 1620px; padding: 0 0 10px 0px; }

.weekend_special_box .list_box > ul > li { position: relative; float: left; width: 285px; margin: 0 11px 0 9px; box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25); background: #fff; }

.weekend_special_box .list_box > ul > li .product_type1 .thumb { overflow: hidden; text-align: center; width: 285px; height: 285px; }

.weekend_special_box .list_box > ul > li .product_type1 .thumb img { width: 285px; height: 285px; }

.weekend_special_box .list_box > ul > li .product_type1 .summary_in { padding: 7px 14px 9px 14px; }

.weekend_special_box .list_box > ul > li .product_type1 .summary_in .subject { overflow: hidden; height: 42px; font-size: 16px; margin-bottom: 15px; line-height: 21px; }

.weekend_special_box .list_box > ul > li .product_type1 .summary_in .info { margin-top: 0px; }

.weekend_special_box .list_box > ul > li .product_type1 .summary_in .info .sale .n { font-size: 20px; }

.weekend_special_box .list_box > ul > li .product_type1 .summary_in .info .price .price_normal { bottom: 20px; color: #666; }

.weekend_special_box .list_box > ul > li .product_type1 .summary_in .info .price .price_normal .n { font-size: 14px; }

.weekend_special_box .list_box > ul > li .product_type1 .summary_in .info .price .n { font-size: 20px; }

.weekend_special_box .list_box > ul > li .product_type1 .add { z-index: 2; position: relative; width: 253px; height: 39px; padding: 4px 15px 0 15px; border: 1px solid #f9fafe; border-top: none; background: #f9fafe; }

.weekend_special_box .list_box > ul > li:hover .product_type1 .add { border: 1px solid #f43142; border-top: none; }

.weekend_special_box .list_box > ul > li .product_type1 .add a { font-size: 12px; color: #999; text-decoration: none; }

.weekend_special_box .list_box > ul > li .product_type1 .add .icon { margin-right: 3px; vertical-align: -8px; }

.weekend_special_box .list_box > ul > li .product_type1 .add span.arrow { display: inline-block; width: 7px; height: 11px; margin-left: 7px; vertical-align: -1px; background: url("/img/trip/common/sp_trip.png") -440px 0 no-repeat; }

.weekend_special_box .list_box > ul > li .product_type1 .border_frame { display: none; }

.weekend_special_box .list_box > ul > li:hover .product_type1 .border_frame { display: block; z-index: 1; position: absolute; left: 0px; top: 0px; width: 100%; height: 100%; border: 1px solid #f43142; box-sizing: border-box; }

.weekend_special_box .paging { position: absolute; top: 453px; left: 50%; width: 1200px; margin: 0 0 0 -600px; text-align: center; font: 0/0 arial; }

.weekend_special_box .paging .ico { width: 10px; height: 10px; margin-left: 5px; text-indent: -9999px; background: url("/img/trip/common/sp_trip.png") -420px -210px no-repeat; }

.weekend_special_box .paging .ico.on { background: url("/img/trip/common/sp_trip.png") -440px -210px no-repeat; }

.weekend_special_box .billboard_func { z-index: 2; position: absolute; top: 0px; left: 50%; width: 1200px; margin: 0 0 0 -600px; }

.weekend_special_box .billboard_func .btn_paging { position: absolute; top: 165px; width: 60px; height: 60px; text-indent: -9999px; }

.weekend_special_box .billboard_func .btn_paging.btn_prev { left: 0px; background: url("/img/trip/common/sp_trip.png") 0 -210px no-repeat; }

.weekend_special_box .billboard_func .btn_paging.btn_next { right: 0px; background: url("/img/trip/common/sp_trip.png") -70px -210px no-repeat; }

.weekend_special_box .list_box:hover .billboard_func .btn_paging.btn_prev { right: 0px; background: url("/img/trip/common/sp_trip.png") -140px -210px no-repeat; }

.weekend_special_box .list_box:hover .billboard_func .btn_paging.btn_next { right: 0px; background: url("/img/trip/common/sp_trip.png") -210px -210px no-repeat; }

.trip_best_list_box { position: relative; width: 1200px; margin: 40px auto 0 auto; padding: 0 8px 40px 8px; }

.trip_best_list_box h1 { float: left; width: 130px; height: 30px; font-size: 14px; line-height: 1.5; text-indent: -9999px; background: url("/img/trip/common/sp_trip.png") 0 -610px no-repeat; }

.trip_best_list_box .tit_area { overflow: hidden; }

.trip_best_list_box .sub_text { float: left; margin-top: 2px; font-size: 14px; color: #333; }

.trip_best_list_box .tab_menu_type_1 { top: 0px; }

.trip_best_list_box .list_box { overflow: hidden; width: 1220px; margin-top: 26px; }

.trip_best_list_box .list_box li { position: relative; float: left; width: 224px; height: 358px; margin: 0 18px 21px 0; border: 1px solid #eff2f4; background: #fff; }

.trip_best_list_box .list_box li:hover { border: 1px solid #f43142; }

.trip_best_list_box .list_box li:hover .product_type1 { border-bottom: none; }

.trip_best_list_box .list_box li .product_type1 { border-bottom: 1px solid #ccc; }

.trip_best_list_box .list_box li .product_type1 .thumb { overflow: hidden; width: 224px; height: 224px; }

.trip_best_list_box .list_box li .product_type1 .thumb .num { position: absolute; top: 0px; left: 0px; width: 29px; height: 25px; padding: 3px 11px 0 0; font-size: 16px; font-weight: bold; color: #fff; text-align: center; background: url("/img/trip/common/sp_trip.png") -380px 0 no-repeat; }

.trip_best_list_box .list_box li .product_type1 .thumb img { width: 224px; height: 224px; }

.trip_best_list_box .list_box li .product_type1 .summary_in { padding: 5px 14px 8px 14px; }

.trip_best_list_box .list_box li .product_type1 .summary_in .subject { height: 35px; font-size: 14px; line-height: 17px; }

.trip_best_list_box .list_box li .product_type1 .summary_in .subject_row2 { height: 35px; }

.trip_best_list_box .list_box li .product_type1 .summary_in .info { margin-top: 17px; }

.trip_best_list_box .list_box li .product_type1 .summary_in .info .price .price_normal { bottom: 20px; color: #666; }

.trip_best_list_box .list_box li .product_type1 .summary_in .info .price .price_normal .n { font-size: 14px; }

.trip_best_list_box .list_box li .product_type1 .summary_in .info .price .n { font-size: 20px; }

.trip_best_list_box .list_box li .product_type1 .add { position: relative; height: 34px; border-top: 1px solid #eee; background: #fff; }

.trip_best_list_box .list_box li .product_type1 .add .company { position: absolute; top: 9px; right: 15px; height: 16px; font-size: 12px; line-height: 15px; color: #999; }

.trip_best_list_box .list_box li .product_type1 .border_frame { display: none; }

.trip_best_list_box .btn_more { display: block; height: 41px; padding: 15px 0 0 0; text-align: center; font-size: 18px; text-decoration: none; border: 1px solid #ccc; color: #666; box-shadow: 0px 2px 0px 0px rbga(204, 204, 204, 0.21); background: #f7f9fa; }

.trip_best_list_box .btn_more strong { display: inline-block; height: 25px; color: #5770fe; text-decoration: none; background: url("/img/trip/common/bg_link_line_1.png") bottom left repeat-x; }

.trip_best_list_box .btn_more span.arrow { display: inline-block; width: 6px; height: 11px; margin: 0 0 0 9px; vertical-align: 2px; background: url("/img/trip/common/sp_trip.png") -370px 0 no-repeat; }

.trip_company_goto_box { position: relative; width: 1200px; margin: 0px auto 0 auto; padding: 0 8px 40px 8px; }

.trip_company_goto_box .inner_box { width: 1200px; margin: 0 auto; }

.trip_company_goto_box .tit_area { overflow: hidden; }

.trip_company_goto_box h1 { float: left; width: 166px; height: 30px; font-size: 14px; line-height: 1.5; text-indent: -9999px; background: url("/img/trip/common/sp_trip.png") 0 -640px no-repeat; }

.trip_company_goto_box .sub_text { float: left; margin-top: 2px; font-size: 14px; color: #333; }

.trip_company_goto_box .list_box { overflow: hidden; position: relative; width: 1200px; margin-top: 14px; background: url("/img/trip/common/bg_line_2.png") repeat-x; }

.trip_company_goto_box .list_box ul { overflow: hidden; width: 1200px; background: url("/img/trip/common/bg_line_2.png"); }

.trip_company_goto_box .list_box ul li { float: left; width: 149px; height: 64px; padding: 0 0 1px 1px; text-align: center; }

.trip_company_goto_box .list_box ul li a { display: block; width: 148px; height: 50px; padding: 14px 0 0 0; background: #fff; }

.trip_company_goto_box .list_box ul li a img { width: 120px; height: 33px; }

.trip_company_goto_box .list_box .billboard_func { position: absolute; top: 0px; left: 0; width: 1200px; }

.trip_company_goto_box .list_box .billboard_func .btn_paging { position: absolute; top: 48px; width: 40px; height: 40px; text-indent: -9999px; background: url("/img/trip/common/sp_trip.png") -460px -210px no-repeat; }

.trip_company_goto_box .list_box .billboard_func .btn_paging.btn_prev { left: 0px; background: url("/img/trip/common/sp_trip.png") -460px -210px no-repeat; }

.trip_company_goto_box .list_box .billboard_func .btn_paging.btn_next { right: 0px; background: url("/img/trip/common/sp_trip.png") -510px -210px no-repeat; }

.trip_company_goto_box .list_box:hover .billboard_func .btn_paging.btn_prev { left: 0px; background: url("/img/trip/common/sp_trip.png") -560px -210px no-repeat; }

.trip_company_goto_box .list_box:hover .billboard_func .btn_paging.btn_next { right: 0px; background: url("/img/trip/common/sp_trip.png") -610px -210px no-repeat; }

.trip_strong_point_box { position: relative; height: 320px; padding: 0 8px 0 8px; background: #dceff9; }

.trip_strong_point_box .list_box { overflow: hidden; width: 1180px; margin: 0 auto; padding: 62px 0 0 0; }

.trip_strong_point_box .list_box li { float: left; width: 295px; text-align: center; }

.trip_strong_point_box .list_box li [class^="ico_"] { display: block; width: 91px; height: 107px; margin: 0 auto; background: url("/img/trip/common/sp_trip.png") no-repeat; }

.trip_strong_point_box .list_box li .ico_visual_01 { background-position: 0 -670px; }

.trip_strong_point_box .list_box li .ico_visual_02 { background-position: -100px -670px; }

.trip_strong_point_box .list_box li .ico_visual_03 { background-position: -200px -670px; }

.trip_strong_point_box .list_box li .ico_visual_04 { background-position: -300px -670px; }

.trip_strong_point_box .list_box li dl { margin: 13px 0 0 0; }

.trip_strong_point_box .list_box li dl dt { font-size: 20px; line-height: 22px; color: #044455; }

.trip_strong_point_box .list_box li dl dt a { color: #044455; margin: 0 5px 0 5px; }

.trip_strong_point_box .list_box li dl dt a .arrow { display: inline-block; width: 7px; height: 11px; margin: 0 0 0 5px; vertical-align: 1px; background: url("/img/trip/common/sp_trip.png") -430px 0 no-repeat; }

.trip_strong_point_box .list_box li dl dd { margin-top: 13px; font-size: 12px; line-height: 20px; color: #778790; }

.trip_strong_point_box .list_box li dl dd strong { color: #044455; }

/* 리스트 */
.location_box { position: relative; z-index: 3; width: 1200px; height: 48px; padding: 17px 0 0 0; margin: 0 auto; }

.location_box .btn_home { float: left; width: 22px; height: 18px; margin: 3px 9px 0 0; text-indent: -9999px; background: url("/img/trip/common/sp_trip.png") -460px 0 no-repeat; }

.location_box .depth_info { position: relative; float: left; padding: 0 16px 0 9px; vertical-align: middle; background: url("/img/trip/common/sp_trip.png") right -203px no-repeat; }

.location_box .depth_info.first { padding-left: 0px; }

.location_box .depth_info > a { display: block; overflow: hidden; height: 22px; }

.location_box .depth_info > button { display: block; overflow: hidden; height: 22px; background: none; }

.location_box .depth_info strong { position: relative; font-size: 14px; line-height: 1.5; font-weight: normal; vertical-align: 23px; }

.location_box .depth_info span.arrow { display: none; width: 7px; height: 14px; margin: 0px 4px 0 0; vertical-align: 21px; background: url("/img/trip/common/sp_trip.png") -530px 2px no-repeat; }

.location_box .depth_info span.ico { position: relative; display: inline-block; width: 14px; height: 14px; vertical-align: 20px; margin: 0 0 0 6px; background: url("/img/trip/common/sp_trip.png") -490px 0 no-repeat; }

.location_box .depth_info .btn_del { display: inline-block; width: 15px; height: 15px; font-size: 14px; line-height: 1.5; text-indent: -9999px; vertical-align: 19px; margin: 0 0 0 6px; background: url("/img/trip/common/sp_trip.png") -610px 0 no-repeat; }

.location_box .depth_info .list_box { display: none; position: absolute; top: 23px; left: 10px; overflow: hidden; width: 156px; padding: 11px 0 13px 0; border: 1px solid #5770fe; background: #fff; }

.location_box .depth_info.first .list_box { left: 0px; }

.location_box .depth_info .list_box.list_2 { width: 312px; }

.location_box .depth_info .list_box.list_3 { width: 468px; }

.location_box .depth_info .list_box.list_4 { width: 624px; }

.location_box .depth_info .list_box.list_5 { width: 780px; }

.location_box .depth_info .list_box.list_6 { width: 936px; }

.location_box .depth_info .list_box ul { float: left; width: 120px; padding: 0 18px 0 18px; }

.location_box .depth_info .list_box ul li { padding: 3px 0 4px 0; }

.location_box .depth_info .list_box ul li a { color: #333; }

.location_box .depth_info .list_box ul li a.selected { color: #5770fe; }

.location_box .depth_info .list_box ul li a:hover { color: #5770fe; font-weight: bold; }

.location_box .depth_info.selected strong { font-weight: bold; color: #5770fe; }

.location_box .depth_info.selected span.ico { background: url("/img/trip/common/sp_trip.png") -510px 0 no-repeat; }

.location_box .depth_info.selected .list_box { display: block; }

.location_box .depth_info.now { background: none; }

.location_box .depth_info.now strong { font-size: 14px; line-height: 1.5; font-weight: bold; color: #111; }

.category_search_option_wrap { z-index: 2; position: relative; width: 1200px; margin: 0 auto 71px auto; padding: 15px 0 0px 0; border-top: 2px solid #000; border-bottom: 1px solid #ccc; }

.category_search_option_wrap.researchType { border-bottom: none; margin-bottom: 0px; }

.category_search_option_wrap .result_info { min-height: 35px; padding: 0 0 10px 0; font-size: 18px; font-weight: normal; border-bottom: 1px solid #e5e5e5; }

.category_search_option_wrap .result_info .search_text { color: #ff3333; }

.category_search_option_wrap .result_info .num { font-size: 14px; color: #999; }

.category_search_option_wrap .category_list { overflow: hidden; width: 1199px; padding: 19px 0 0 0; }

.category_search_option_wrap .category_list ul { width: 1200px; margin-bottom: 19px; font: 0/0 arial; }

.category_search_option_wrap .category_list ul li { display: inline-block; width: 180px; height: 32px; padding: 0 0 0 20px; font-size: 12px; vertical-align: top; line-height: 15px; background: url("/img/trip/common/bg_line_3.png"); }

.category_search_option_wrap .category_list ul li a { color: #333; }

.category_search_option_wrap .category_list ul li a:hover { font-weight: bold; color: #333; }

.category_search_option_wrap .category_list ul li a.selected { font-weight: bold; color: #ff3333; }

.category_search_option_wrap .category_list ul li .num { font-size: 11px; font-weight: normal; font-family: tahoma, sans-serif; color: #999; }

.category_search_option_wrap .check_option_box { position: relative; z-index: 1; border-top: 1px solid #ccc; background: #fafafa; }

.category_search_option_wrap .check_option_box .filter_close { display: none !important; }

.category_search_option_wrap .check_option_box .filter_open { display: block; }

.category_search_option_wrap .check_option_box .option_box { border-top: 1px solid #e5e5e5; display: inline-block; position: relative; z-index: 1; display: block; }

.category_search_option_wrap .check_option_box .option_box:after { content: "."; display: block; clear: both; height: 0; visibility: hidden; }

.category_search_option_wrap .check_option_box .option_box.zindex { position: relative; z-index: 2; }

.category_search_option_wrap .check_option_box .option_box .option_detail { float: left; width: 1112px; margin: 0 0 0 -1px; border-left: 1px solid #e5e5e5; display: inline-block; }

.category_search_option_wrap .check_option_box .option_box .option_detail:after { content: "."; display: block; clear: both; height: 0; visibility: hidden; }

.category_search_option_wrap .check_option_box .option_box_type_2 .option_detail { float: left; width: 1112px; margin: 0 0 0 -1px; border-left: 1px solid #e5e5e5; display: inline-block; }

.category_search_option_wrap .check_option_box .option_box_type_2 .option_detail:after { content: "."; display: block; clear: both; height: 0; visibility: hidden; }

.category_search_option_wrap .check_option_box .option_box .title { float: left; width: 86px; min-height: 34px; padding: 14px 0 0 0px; font-size: 12px; font-weight: bold; color: #111; text-align: center; letter-spacing: 0px; border-right: 1px solid #e5e5e5; }

.category_search_option_wrap .check_option_box .option_box .title_2 { float: left; width: 86px; min-height: 48px; padding: 0px 0 0 0px; font-size: 12px; font-weight: bold; color: #111; text-align: center; letter-spacing: 0px; line-height: 48px; border-left: 1px solid #e5e5e5; border-right: 1px solid #e5e5e5; }

.category_search_option_wrap .check_option_box .option_box .option_list { float: left; width: 1103px; margin-left: -1px; padding: 0px 0 0 10px; }

.category_search_option_wrap .check_option_box .option_box .option_list li { float: left; position: relative; margin: 10px 10px 10px 10px; padding: 0px; }

.category_search_option_wrap .check_option_box .option_box .option_list li a { display: block; position: relative; height: 28px; padding: 0 14px 0 13px; color: #333; font-size: 12px; line-height: 27px; border-radius: 15px; text-decoration: none; }

.category_search_option_wrap .check_option_box .option_box .option_list li .btn_del { display: none; }

.category_search_option_wrap .check_option_box .option_box .option_list li a:hover { color: #fff; text-decoration: none; background: #464957; }

.category_search_option_wrap .check_option_box .option_box .option_list li a:focus { text-decoration: none; }

.category_search_option_wrap .check_option_box .option_box .option_list li a:active { text-decoration: none; outline: 0; }

.category_search_option_wrap .check_option_box .option_box .option_list li.selected a { color: #fff; background: #464957; }

.category_search_option_wrap .check_option_box .option_box .option_list li.selected .radio_design_3 + label.css-label, .category_search_option_wrap .check_option_box .option_box .option_list li.selected .check_design_3 + label.css-label { color: #fff; text-decoration: none; background: #464957; }

.category_search_option_wrap .check_option_box .option_box .option_list li.selected .btn_del { display: none; }

.category_search_option_wrap .check_option_box .option_box .option_list li.selected:hover .btn_del { display: block; position: absolute; top: -5px; right: -7px; width: 17px; height: 17px; text-indent: -9999px; background: url("/img/trip/common/sp_trip.png") -540px 0 no-repeat; }

.category_search_option_wrap .check_option_box .option_box .option_list_2 { overflow: hidden; float: left; width: 380px; margin-left: -1px; padding: 0px 0 0 10px; }

.category_search_option_wrap .check_option_box .option_box .option_list_2 li { float: left; margin: 3px 0 0 0; }

.category_search_option_wrap .check_option_box .input_type_1 { z-index: 2; position: relative; float: left; padding: 10px 20px 0 20px; }

.category_search_option_wrap .check_option_box .input_type_1 .info_input_1 { width: 128px; height: 30px; font-size: 12px; padding-bottom: 4px; }

.category_search_option_wrap .check_option_box .input_type_1 .info_input_1 .cal { top: 4px; }

.category_search_option_wrap .check_option_box .input_type_1 .comp_select_type_1 { width: 85px; }

.category_search_option_wrap .check_option_box .input_type_1 .comp_select_type_1 .btn_select { height: 30px; }

.category_search_option_wrap .check_option_box .input_type_1 .info_input_2 { width: 155px; height: 24px; font-size: 12px; }

.category_search_option_wrap .check_option_box .input_type_1 .info_input_2.room { width: 78px; height: 24px; font-size: 12px; }

.category_search_option_wrap .check_option_box .option_box.start_day .option_list { width: 848px; }

.category_search_option_wrap .check_option_box .option_box.start_day .trip_date_picker_v2 { position: absolute; top: 45px; left: 20px; }

.category_search_option_wrap .check_option_box .option_box.check_in_out .trip_date_picker_v2 { position: absolute; top: 45px; left: 20px; }

.category_search_option_wrap .check_option_box .option_box.room_check .input_type_1 .comp_select_type_1 { float: left; }

.category_search_option_wrap .check_option_box .option_box.room_check .input_type_1 .btn_type_02 { position: relative; z-index: 2; width: 88px; height: 30px; padding: 0 0 4px 0; margin: 0 0 0 -1px; font-size: 12px; font-weight: bold; color: #273259; border: 1px solid #273259; background: #fff; }

.category_search_option_wrap .check_option_box .option_box.room_check .input_type_1 .btn_type_02 span.ico { display: inline-block; width: 10px; height: 10px; vertical-align: -1px; margin: 0 5px 0 0; background: url("/img/trip/common/sp_trip.png") -310px 0 no-repeat; }

.category_search_option_wrap .check_option_box .option_box.room_check .room_addition { position: absolute; top: 45px; left: 20px; }

.category_search_option_wrap .check_option_box .option_box.room_check .room_addition .btn_type_02 { width: 100px; height: 36px; padding: 0 0 5px 0; font-size: 14px; font-weight: bold; letter-spacing: -1px; color: #5770fe; border: 1px solid #6483dc; background: #fff; }

.category_search_option_wrap .check_option_box .option_box.search_area .input_type_1 { float: left; width: 1073px; position: relative; padding: 10px 20px 0 20px; }

.category_search_option_wrap .check_option_box .option_box.search_area .input_type_1 .info_input_2 { float: left; width: 89px; height: 22px; font-size: 12px; font-family: tahoma, sans-serif; color: #999; }

.category_search_option_wrap .check_option_box .option_box.search_area .input_type_1 .info_input_2:-ms-input-placeholder, .category_search_option_wrap .check_option_box .option_box.search_area .input_type_1 .info_input_2::-webkit-input-placeholder, .category_search_option_wrap .check_option_box .option_box.search_area .input_type_1 .info_input_2::-moz-placeholder { color: #ccc; }

.category_search_option_wrap .check_option_box .option_box.search_area .input_type_1 .info_input_2.focus, .category_search_option_wrap .check_option_box .option_box.search_area .input_type_1 .info_input_2.active { color: #999; }

.category_search_option_wrap .check_option_box .option_box.search_area .input_type_1 .ico_wave { float: left; margin: 0 9px 0 8px; padding: 5px 0 0 0; font-size: 11px; color: #999; }

.category_search_option_wrap .check_option_box .option_box.search_area .input_type_1 .btn_price_search { float: left; width: 70px; height: 28px; margin: 0 10px 0 10px; padding-bottom: 3px; font-size: 12px; font-weight: bold; border: 1px solid #bdbdbd; color: #111; background: #fff; }

.category_search_option_wrap .check_option_box .option_box.search_area .input_type_1 .check_design_2 + label.css-label span.shocking_deal { display: block; width: 44px; height: 18px; margin-right: 10px; text-indent: -9999px; background: url("/img/trip/common/sp_trip.png") -320px -51px no-repeat; }

.category_search_option_wrap .check_option_box .option_box.search_area .input_type_1 .check_design_2 + label.css-label span.txt { display: block; min-width: 44px; height: 18px; padding-top: 2px; text-indent: 0px; font-size: 12px; }

.category_search_option_wrap .check_option_box .option_box.search_area .result_in_search { position: absolute; right: 23px; top: 10px; }

.category_search_option_wrap .check_option_box .option_box.search_area .result_in_search .info_input_2 { width: 298px; height: 22px; font-size: 12px; }

.category_search_option_wrap .check_option_box .option_box.search_area .result_in_search .btn_search { float: left; width: 50px; height: 28px; margin: 0 0 0 -1px; padding-bottom: 3px; font-size: 12px; font-weight: bold; border: 1px solid #bdbdbd; color: #111; background: #fff; }

.category_search_option_wrap .btn_option_open { position: absolute; bottom: -42px; width: 395px; height: 41px; left: 50%; margin-left: -197px; font-size: 14px; color: #666; text-align: center; background: url("/img/trip/common/sp_trip.png") 0 -790px no-repeat; }

.category_search_option_wrap .btn_option_open strong { color: #333; }

.category_search_option_wrap .btn_option_open span.arrow { display: inline-block; width: 18px; height: 10px; margin-left: 10px; background: url("/img/trip/common/sp_trip.png") -630px 0 no-repeat; }

.category_search_option_wrap .btn_option_close { position: absolute; bottom: -42px; width: 395px; height: 41px; left: 50%; margin-left: -197px; font-size: 14px; color: #666; text-align: center; background: url("/img/trip/common/sp_trip.png") 0 -790px no-repeat; }

.category_search_option_wrap .btn_option_close strong { color: #333; }

.category_search_option_wrap .btn_option_close span.arrow { display: inline-block; width: 18px; height: 10px; margin-left: 10px; background: url("/img/trip/common/sp_trip.png") -650px 0 no-repeat; }

.category_search_option_wrap .select_history_list .result_txt { display: none; }

.category_search_option_wrap .select_history_list .result_txt .btn_op_change { position: absolute; top: 20px; right: 20px; height: 28px; font-size: 14px; color: #fff; text-align: center; padding: 0 14px 4px 12px; border-radius: 15px; background: #4b5a98; }

.category_search_option_wrap.fixed .select_history_list { z-index: 9999; position: fixed; top: 0px; display: block; width: 1200px; margin: 0 auto; box-shadow: 0px 2px 5px 0px rgba(204, 204, 204, 0.9); }

.category_search_option_wrap.fixed .select_history_list .result_txt { position: relative; display: block; width: 1200px; height: 55px; padding: 15px 0 0 0; font-size: 22px; color: #fff; text-align: center; background: #2b3769; }

.category_search_option_wrap .option_select_list { position: relative; padding: 13px 0 16px 19px; border-top: 1px solid #e5e5e5; background: #fff; }

.category_search_option_wrap .option_select_list ul { overflow: hidden; max-width: 1050px; }

.category_search_option_wrap .option_select_list ul li { float: left; padding: 0 19px 0 0; font-size: 12px; }

.category_search_option_wrap .option_select_list ul li .type_1 { display: inline-block; width: 16px; height: 12px; margin: 0 5px 0 5px; vertical-align: -4px; text-indent: -99999px; background: url("/img/trip/common/sp_trip.png") -670px 0 no-repeat; }

.category_search_option_wrap .option_select_list ul li .type_2 { display: inline-block; width: 16px; height: 12px; margin: 0 5px 0 5px; vertical-align: -6px; text-indent: -99999px; background: url("/img/trip/common/sp_trip.png") -690px 0 no-repeat; }

.category_search_option_wrap .option_select_list ul li .btn_del { width: 11px; height: 11px; margin-left: 5px; text-indent: -9999px; background: url("/img/trip/common/sp_trip.png") -560px 0 no-repeat; }

.category_search_option_wrap .option_select_list.op_type_1 ul li.result { color: #ff3333; }

.category_search_option_wrap .option_select_list.op_type_2 ul li.result { color: #5770fe; }

.category_search_option_wrap .option_select_list .btn_reset { position: absolute; right: 21px; top: 50%; margin-top: -12px; font-size: 12px; background: #fff; }

.category_search_option_wrap .option_select_list .btn_reset span.ico { display: inline-block; width: 20px; height: 17px; margin-right: 5px; vertical-align: middle; background: url("/img/trip/common/sp_trip.png") -580px 0 no-repeat; }

* html .category_search_option_wrap .check_option_box .option_box { height: 1%; }

* html .category_search_option_wrap .check_option_box .option_box .option_detail { height: 1%; }

* html .category_search_option_wrap .check_option_box .option_box_type_2 .option_detail { height: 1%; }

/* 사용처 여행 리스트,호텔 메인 */
.trip_deal_box_1 { z-index: 1; position: relative; width: 1200px; height: 496px; margin: 0 auto 0 auto; }

.trip_deal_box_1 .tit_area { overflow: hidden; height: 39px; }

.trip_deal_box_1 .tit_area h1 { float: left; width: 71px; font-size: 22px; color: #111; }

.trip_deal_box_1 .tit_area .sub_text { float: left; width: 320px; font-size: 14px; line-height: 38px; color: #666; }

.trip_deal_box_1 .list_box { position: relative; overflow: hidden; width: 1201px; height: 466px; margin-top: 2px; }

.trip_deal_box_1 .list_box > ul { overflow: hidden; width: 1620px; padding: 0 0 10px 1px; }

.trip_deal_box_1 .list_box > ul li { float: left; position: relative; width: 301px; height: 420px; margin-left: -1px; background: #fff; }

.trip_deal_box_1 .list_box > ul li:first-child { width: 301px; }

.trip_deal_box_1 .list_box > ul li .product_type1 .thumb { overflow: hidden; width: 302px; height: 300px; text-align: center; }

.trip_deal_box_1 .list_box > ul li .product_type1 .thumb img { width: 300px; height: 300px; }

.trip_deal_box_1 .list_box > ul li .product_type1 .summary_in { padding: 7px 17px 3px 17px; border-top: 1px solid #ccc; }

.trip_deal_box_1 .list_box > ul li .product_type1 .summary_in .subject { font-size: 16px; }

.trip_deal_box_1 .list_box > ul li .product_type1 .summary_in .txt_op { position: absolute; top: 35px; left: 17px; overflow: hidden; width: 266px; height: 17px; font-size: 12px; color: #999; }

.trip_deal_box_1 .list_box > ul li .product_type1 .summary_in .info { margin-top: 48px; }

.trip_deal_box_1 .list_box > ul li .product_type1 .summary_in .info .sale { font-size: 15px; }

.trip_deal_box_1 .list_box > ul li .product_type1 .summary_in .info .sale .n { font-size: 20px; }

.trip_deal_box_1 .list_box > ul li .product_type1 .summary_in .price .price_normal { bottom: 22px; }

.trip_deal_box_1 .list_box > ul li .product_type1 .summary_in .price .price_normal .n { font-size: 14px; }

.trip_deal_box_1 .list_box > ul li .product_type1 .summary_in .price .n { font-size: 20px; }

.trip_deal_box_1 .list_box > ul li .product_type1 .border_frame { position: absolute; top: 0px; left: 0px; width: 299px; height: 418px; border: 1px solid #ccc; }

.trip_deal_box_1 .list_box > ul li:hover { z-index: 2; }

.trip_deal_box_1 .list_box > ul li:hover .product_type1 .border_frame { border: 1px solid #f43142; }

.trip_deal_box_1 .paging { position: absolute; top: 443px; left: 50%; width: 1200px; margin: 0 0 0 -600px; text-align: center; font: 0/0 arial; }

.trip_deal_box_1 .paging .ico { width: 10px; height: 10px; margin-left: 5px; text-indent: -9999px; background: url("/img/trip/common/sp_trip.png") -420px -210px no-repeat; }

.trip_deal_box_1 .paging .ico.on { background: url("/img/trip/common/sp_trip.png") -440px -210px no-repeat; }

.trip_deal_box_1 .billboard_func { z-index: 3; position: absolute; top: 0px; left: 50%; width: 1200px; margin: 0 0 0 -600px; }

.trip_deal_box_1 .billboard_func .btn_paging { position: absolute; top: 163px; width: 60px; height: 60px; text-indent: -9999px; }

.trip_deal_box_1 .billboard_func .btn_paging.btn_prev { left: 0px; background: url("/img/trip/common/sp_trip.png") 0 -210px no-repeat; }

.trip_deal_box_1 .billboard_func .btn_paging.btn_next { right: 0px; background: url("/img/trip/common/sp_trip.png") -70px -210px no-repeat; }

.trip_deal_box_1 .list_box:hover .billboard_func .btn_paging.btn_prev { right: 0px; background: url("/img/trip/common/sp_trip.png") -140px -210px no-repeat; }

.trip_deal_box_1 .list_box:hover .billboard_func .btn_paging.btn_next { right: 0px; background: url("/img/trip/common/sp_trip.png") -210px -210px no-repeat; }

.trip_deal_box_1 .btn_more { position: absolute; top: 6px; right: 0px; font-size: 12px; color: #333; }

.trip_deal_box_1 .btn_more span.arrow { display: inline-block; width: 4px; height: 7px; margin: 0 0 0 5px; vertical-align: 0; background: url("/img/trip/common/sp_trip.png") -710px 0 no-repeat; }

.banner_box_1 { z-index: 1; position: relative; overflow: hidden; width: 1200px; height: 120px; margin: 29px auto 20px auto; }

.banner_box_1 ul { width: 1200px; height: 120px; text-align: center; }

.banner_box_1 ul li { text-align: center; height: 120px; }

.banner_box_1 ul li a { display: inline-block; width: 100%; }

.banner_box_1 ul li img { max-width: 1200px; height: 120px; vertical-align: top; }

.banner_box_1 .billboard_func { z-index: 3; position: absolute; top: 0px; left: 50%; width: 1200px; margin: 0 0 0 -600px; }

.banner_box_1 .billboard_func .btn_paging { position: absolute; top: 46px; width: 28px; height: 28px; text-indent: -9999px; }

.banner_box_1 .billboard_func .btn_paging.btn_prev { left: 0px; background: url("/img/trip/common/sp_trip.png") -810px -210px no-repeat; }

.banner_box_1 .billboard_func .btn_paging.btn_next { right: 0px; background: url("/img/trip/common/sp_trip.png") -850px -210px no-repeat; }

.banner_box_1:hover .billboard_func .btn_paging.btn_prev { background: url("/img/trip/common/sp_trip.png") -890px -210px no-repeat; }

.banner_box_1:hover .billboard_func .btn_paging.btn_next { background: url("/img/trip/common/sp_trip.png") -930px -210px no-repeat; }

.product_list_box { position: relative; z-index: 1; overflow: hidden; width: 1200px; margin: 0 auto 0 auto; padding-top: 20px; }

.product_list_box .result_sort_box { position: relative; margin: 0px 0 0 0; height: 36px; padding: 13px 0 0 0; border-top: 1px solid #454746; }

.product_list_box .result_sort_box .result_info { font-size: 16px; font-weight: bold; color: #333; }

.product_list_box .result_sort_box .result_info strong { font-weight: bold; font-family: tahoma; color: #f63041; }

.product_list_box .result_sort_box .sort_viewtype_wrap { position: absolute; top: 14px; right: 20px; display: inline-block; display: block; }

.product_list_box .result_sort_box .sort_viewtype_wrap:after { content: "."; display: block; clear: both; height: 0; visibility: hidden; }

.product_list_box .result_sort_box .sort_menu { float: left; font-size: 14px; display: inline-block; display: block; }

.product_list_box .result_sort_box .sort_menu:after { content: "."; display: block; clear: both; height: 0; visibility: hidden; }

.product_list_box .result_sort_box .sort_menu li { position: relative; float: left; color: #333; }

.product_list_box .result_sort_box .sort_menu li a { color: #333; }

.product_list_box .result_sort_box .sort_menu li.selected a { color: #f43142; text-decoration: none; }

.product_list_box .result_sort_box .sort_menu li.selected a .t_type_01 { color: #333; }

.product_list_box .result_sort_box .sort_menu li .btn_help { display: inline-block; width: 14px; height: 14px; margin-left: 9px; margin-top: -2px; vertical-align: middle; text-indent: -9999px; background: url("/img/specialshop/common/sp_common.png") -40px 0px no-repeat; }

.product_list_box .result_sort_box .sort_menu li .tx_bar { display: inline-block; width: 1px; height: 13px; margin: 0 8px 0 8px; vertical-align: -2px; background: #dbdbdb; }

.product_list_box .result_sort_box .sort_menu li .help_pop { top: 25px; left: -10px; }

.product_list_box .result_sort_box .viewtype_menu { float: left; overflow: hidden; margin: 0 0 0 7px; }

.product_list_box .result_sort_box .viewtype_menu li { float: left; margin: 0 0 0 14px; }

.product_list_box .result_sort_box .viewtype_menu li a { display: block; width: 23px; height: 23px; text-indent: -9999px; }

.product_list_box .result_sort_box .viewtype_menu li .btn_list_type { background: url("/img/specialshop/common/sp_common.png") -50px -50px no-repeat; }

.product_list_box .result_sort_box .viewtype_menu li .btn_thumb_type { background: url("/img/specialshop/common/sp_common.png") -80px -50px no-repeat; }

.product_list_box .result_sort_box .viewtype_menu li .btn_list_type.selected { background: url("/img/specialshop/common/sp_common.png") -50px -20px no-repeat; }

.product_list_box .result_sort_box .viewtype_menu li .btn_thumb_type.selected { background: url("/img/specialshop/common/sp_common.png") -80px -20px no-repeat; }

.product_list_box .total_listing_wrap .no_search_box { padding: 300px 0 275px 0; }

.product_list_box .total_listing_wrap .no_search_box .ico_deco { display: block; width: 63px; height: 64px; margin: 0 auto; background: url("/img/trip/common/sp_trip.png") right -550px no-repeat; }

.product_list_box .total_listing_wrap .no_search_box .text { margin-top: 11px; font-size: 28px; font-weight: bold; color: #999; text-align: center; }

* html .product_list_box .result_sort_box .sort_viewtype_wrap, * html .product_list_box .result_sort_box .sort_menu { height: 1%; }

/* MPSR-66118 */
.help_info_area { float: left; }

.help_info_area > input { height: 16px; }

.trip_prod_details table .label { display: inline-block; height: 18px; margin: -2px 4px 0 5px; padding: 0 8px; border: 1px solid #ecdbed; background-color: #fdfbfd; color: #9f4ba2; font-size: 11px; line-height: 20px; vertical-align: middle; }

.label.all_for_coupon, .tbl_hotel_price .label.all_for_coupon, .trip_prod_details table .label.all_for_coupon { border-color: #c1a7ff; background-color: #f9f6ff; color: #6423ff; }

/* //MPSR-66118 */
/* 퀵서치 레이어 팝업 */
.quick_search_layer_wrap { z-index: 100; position: absolute; top: 13px; left: 50%; width: 1200px; margin-left: -600px; }

.quick_search_layer_wrap .btn_quick_search { position: absolute; top: 0px; right: 0px; width: 140px; height: 32px; text-indent: -9999px; background: url("/img/trip/common/sp_trip.png") -660px -210px no-repeat; }

.quick_search_layer_wrap .search_option_box { display: none; position: absolute; top: 32px; left: 0px; width: 1158px; padding: 20px; border: 1px solid #2b3769; background: #fff; }

.quick_search_layer_wrap .search_option_box > .btn_close { z-index: 2; position: absolute; right: 20px; bottom: 20px; width: 15px; height: 14px; text-indent: -9999px; background: url("/img/trip/common/sp_trip.png") -220px -50px no-repeat; }

.trip_date_picker_v2 { width: 747px; height: 452px; }

.trip_date_picker_v2 .box_calendar { float: left; overflow: hidden; width: 356px; height: 376px; margin-left: -1px; border: 1px solid #dadada; background-color: #fff; }

.trip_date_picker_v2 .box_calendar .date_g { overflow: hidden; width: 356px; border-bottom: 1px solid #dadada; }

.trip_date_picker_v2 .box_calendar .nav_calender { margin-top: 7px; }

.trip_date_picker_v2 .box_calendar .date_g { border-bottom: 0 none; }

.trip_date_picker_v2 .nav_calender .btn_pager { width: 32px; height: 32px; position: absolute; top: 2px; }

.trip_date_picker_v2 .nav_calender .btn_pager .sp_trip { display: block; width: 32px; height: 32px; font-size: 0; line-height: 0; text-indent: -9999px; }

.trip_date_picker_v2 .nav_calender .btn_prev { left: 10px; }

.trip_date_picker_v2 .nav_calender .btn_next { right: 10px; }

.trip_date_picker_v2 .nav_calender .btn_prev .sp_trip { background-position: 0 -140px; }

.trip_date_picker_v2 .nav_calender .btn_next .sp_trip { background-position: 0 -180px; }

.trip_date_picker_v2 .select_calender { position: relative; width: 96px; height: 34px; margin: 0 auto; }

.trip_date_picker_v2 .select_calender .this_month { width: 96px; height: 34px; border: 1px solid #fff; background-color: #fff; }

.trip_date_picker_v2 .select_calender .this_month .btn_select { display: block; width: 96px; height: 34px; border: 3px solid #fff; font-weight: bold; font-size: 16px; line-height: 28px; background: #fff; }

.trip_date_picker_v2 .select_calender .this_month .sp_trip { display: inline-block; overflow: hidden; vertical-align: middle; width: 9px; height: 6px; margin: -2px 0 0 6px; background: url("/img/trip/sp_trip.png") no-repeat 0 -220px; }

.trip_date_picker_v2 .select_calender .list_calender { display: none; position: absolute; top: 35px; left: 0; z-index: 10; width: 96px; padding: 5px 0; border: 1px solid #6483dc; background-color: #fff; }

.trip_date_picker_v2 .select_calender .list_calender .select_month { width: 96px; padding: 0 13px; font-size: 14px; line-height: 24px; text-align: left; background: #fff; }

.trip_date_picker_v2 .select_calender .list_calender .select_month:hover { color: #6483dc; text-decoration: underline; }

.trip_date_picker_v2 .select_calender .list_calender li:first-child .select_month { font-weight: bold; color: #6483dc; }

.trip_date_picker_v2 .select_calender.focus, .trip_date_picker_v2 .select_calender.active { border: none; }

.trip_date_picker_v2 .select_calender.focus .this_month, .trip_date_picker_v2 .select_calender.active .this_month { border-color: #6a84d6; }

.trip_date_picker_v2 .select_calender.focus .this_month .btn_select, .trip_date_picker_v2 .select_calender.active .this_month .btn_select { border-color: #ebeffe; color: #6483dc; background: #fff; }

.trip_date_picker_v2 .select_calender.focus .this_month .sp_trip, .trip_date_picker_v2 .select_calender.active .this_month .sp_trip { display: inline-block; overflow: hidden; vertical-align: middle; background: url("/img/trip/sp_trip.png") no-repeat 0 -230px; }

.trip_date_picker_v2 .select_calender.focus .list_calender, .trip_date_picker_v2 .select_calender.active .list_calender { display: block; }

.trip_date_picker_v2 .layer_body { overflow: hidden; position: relative; padding: 16px; }

.trip_date_picker_v2 .layer_body .btn_pager { width: 32px; height: 32px; position: absolute; top: 25px; z-index: 30; cursor: pointer; background: #fff; }

.trip_date_picker_v2 .layer_body .btn_pager .sp_trip { display: inline-block; overflow: hidden; width: 32px; height: 32px; background: url("/img/trip/sp_trip.png") no-repeat; font-size: 0; line-height: 0; text-indent: -9999px; vertical-align: middle; }

.trip_date_picker_v2 .layer_body .btn_prev { left: 27px; }

.trip_date_picker_v2 .layer_body .btn_next { right: 27px; }

.trip_date_picker_v2 .layer_body .btn_prev .sp_trip { background-position: 0 -140px; }

.trip_date_picker_v2 .layer_body .btn_next .sp_trip { background-position: 0 -180px; }

.trip_date_picker_v2 .cal_wrap { overflow: hidden; padding: 0px 0 0 1px; }

.trip_date_picker_v2 .cal_wrap .box_calendar { width: 356px; }

.trip_date_picker_v2 .cal_wrap .box_calendar .date_g { margin-top: -4px; }

.trip_date_picker_v2 .cal_wrap .tbl_calender { float: left; width: 358px; margin-right: 20px; border: 1px solid #f5f5f5; border-width: 0 1px 0 0; margin-left: -1px; }

.trip_date_picker_v2 .cal_wrap .tbl_calender caption { position: relative; height: 1px; border: 1px solid #f5f5f5; background: #fafafa; color: #333; font-family: "Tahoma"; font-size: 22px; line-height: 56px; }

.trip_date_picker_v2 .cal_wrap .tbl_calender caption .btn_prev { position: absolute; top: 0; left: 0; width: 47px; height: 56px; background: url("/images/bul/arr_11px_left_666.gif") no-repeat center center; color: transparent; font-size: 11px; }

.trip_date_picker_v2 .cal_wrap .tbl_calender caption .btn_next { position: absolute; top: 0; right: 0; width: 47px; height: 56px; background: url("/images/bul/arr_11px_right_666.gif") no-repeat center center; color: transparent; font-size: 11px; }

.trip_date_picker_v2 .cal_wrap .tbl_calender thead th { height: 33px; padding: 0; font-size: 14px; font-weight: bold; text-align: center; color: #636363; background: url("/img/trip/common/bg_line_dot_2.gif") bottom left repeat-x; }

.trip_date_picker_v2 .cal_wrap .tbl_calender thead th:first-of-type { border-left: 1px solid #f5f5f5; }

.trip_date_picker_v2 .cal_wrap .tbl_calender tr:last-child td { border-bottom: 0 none; }

.trip_date_picker_v2 .cal_wrap .tbl_calender td { position: relative; height: 50px; padding: 0; border-bottom: 1px solid #eee; border-left: 1px solid #eee; color: #666; text-align: center; }

.trip_date_picker_v2 .cal_wrap .tbl_calender td a { display: block; min-height: 15px; padding: 5px 0 20px 0; font-size: 16px; color: #636363; text-decoration: none; }

.trip_date_picker_v2 .cal_wrap .tbl_calender td span { display: block; min-height: 15px; padding: 5px 0 21px 0; font-size: 16px; }

.trip_date_picker_v2 .cal_wrap .tbl_calender td .today { position: absolute; z-index: 5; top: 0px; left: 0px; width: 48px; height: 23px; border: 1px solid #302d73; background: #504f98; color: #fff; }

.trip_date_picker_v2 .cal_wrap .tbl_calender td .start, .trip_date_picker_v2 .cal_wrap .tbl_calender td .middle, .trip_date_picker_v2 .cal_wrap .tbl_calender td .end { position: absolute; z-index: 5; top: 0px; left: 0px; width: 48px; height: 23px; border: 1px solid #302d73; background: #5477e9; color: #fff; }

.trip_date_picker_v2 .cal_wrap .tbl_calender td .selected { position: absolute; z-index: 4; top: 0px; left: 0; width: 50px; height: 25px; background: #e6edfd; }

.trip_date_picker_v2 .cal_wrap .tbl_calender td .txt { position: absolute; z-index: 6; right: 0; bottom: 5px; left: 0; margin: 0 auto; color: #fff; font-size: 11px; }

.trip_date_picker_v2 .cal_wrap .tbl_calender .sat { color: #5477e9; }

.trip_date_picker_v2 .cal_wrap .tbl_calender .day_sat { color: #7d99ff; }

.trip_date_picker_v2 .cal_wrap .tbl_calender .day_sun { color: #ff8686; }

.trip_date_picker_v2 .cal_wrap .tbl_calender .holiday { color: #f43142; }

.trip_date_picker_v2 .cal_wrap .tbl_calender .disable { color: #bcbcbc; background: #f8f8f8; }

.trip_date_picker_v2 .cal_wrap .tbl_calender .disable.day_sat { color: #c4d2fb; }

.trip_date_picker_v2 .cal_wrap .tbl_calender .disable.holiday, .trip_date_picker_v2 .cal_wrap .tbl_calender .disable.day_sun { color: #fbc7c9; }

/* [별공통] */
.selr_star { display: inline-block; overflow: hidden; width: 87px; height: 20px; background: url("/img/common/v2/sp_prdc_ico.png") no-repeat 0 0; font-size: 0; }

.selr_star.selr_star0 { background-position: 0 0; }

.selr_star.selr_star10 { background-position: 0 -20px; }

.selr_star.selr_star20 { background-position: 0 -40px; }

.selr_star.selr_star30 { background-position: 0 -60px; }

.selr_star.selr_star40 { background-position: 0 -80px; }

.selr_star.selr_star50 { background-position: 0 -100px; }

.selr_star.selr_star60 { background-position: 0 -120px; }

.selr_star.selr_star70 { background-position: 0 -140px; }

.selr_star.selr_star80 { background-position: 0 -160px; }

.selr_star.selr_star90 { background-position: 0 -180px; }

.selr_star.selr_star100 { background-position: 0 -200px; }

.selr_star_s { display: inline-block; overflow: hidden; width: 87px; height: 13px; background: url("/img/common/v2/sp_prdc_ico.png") no-repeat 0 0; font-size: 0; }

.selr_star_s.selr_star0 { background-position: 0 0; }

.selr_star_s.selr_star10 { background-position: 0 -263px; }

.selr_star_s.selr_star20 { background-position: 0 -283px; }

.selr_star_s.selr_star30 { background-position: 0 -303px; }

.selr_star_s.selr_star40 { background-position: 0 -323px; }

.selr_star_s.selr_star50 { background-position: 0 -343px; }

.selr_star_s.selr_star60 { background-position: 0 -363px; }

.selr_star_s.selr_star70 { background-position: 0 -383px; }

.selr_star_s.selr_star80 { background-position: 0 -403px; }

.selr_star_s.selr_star90 { background-position: 0 -423px; }

.selr_star_s.selr_star100 { background-position: 0 -443px; }

.selr_star_b { display: inline-block; overflow: hidden; width: 90px; height: 17px; background: url("/img/common/v2/sp_prdc_ico.png") no-repeat 0 0; font-size: 0; }

.selr_star_b.selr_star0 { background-position: 0 -470px; }

.selr_star_b.selr_star10 { background-position: 0 -490px; }

.selr_star_b.selr_star20 { background-position: 0 -510px; }

.selr_star_b.selr_star30 { background-position: 0 -530px; }

.selr_star_b.selr_star40 { background-position: 0 -550px; }

.selr_star_b.selr_star50 { background-position: 0 -570px; }

.selr_star_b.selr_star60 { background-position: 0 -590px; }

.selr_star_b.selr_star70 { background-position: 0 -610px; }

.selr_star_b.selr_star80 { background-position: 0 -630px; }

.selr_star_b.selr_star90 { background-position: 0 -650px; }

.selr_star_b.selr_star100 { background-position: 0 -670px; }

.selr_star_big { display: inline-block; overflow: hidden; width: 112px; height: 24px; background: url("/img/common/v2/sp_prdc_ico.png") no-repeat 0 0; font-size: 0; }

.selr_star_big.selr_star0 { background-position: -150px -139px; }

.selr_star_big.selr_star10 { background-position: -150px -169px; }

.selr_star_big.selr_star20 { background-position: -150px -199px; }

.selr_star_big.selr_star30 { background-position: -150px -229px; }

.selr_star_big.selr_star40 { background-position: -150px -259px; }

.selr_star_big.selr_star50 { background-position: -150px -290px; }

.selr_star_big.selr_star60 { background-position: -150px -320px; }

.selr_star_big.selr_star70 { background-position: -150px -351px; }

.selr_star_big.selr_star80 { background-position: -150px -380px; }

.selr_star_big.selr_star90 { background-position: -150px -409px; }

.selr_star_big.selr_star100 { background-position: -150px -440px; }

/* 나에게 딱 맞는 숙박 */
.quick_search_box .trip_type.option_2 .option_check_box_1.my_custom .btn_type_02 { margin-right: 0; font-size: 16px; }

.quick_search_box .trip_type.option_2 .option_check_box_1.my_custom .option_select_box_1 .option_detail .info_input_2 { width: 361px; }

.quick_search_box .trip_type.option_2 .option_check_box_1.my_custom .option_select_box_2 .option_detail button.info_input_1 { width: 139px; }

.quick_search_box .trip_type.option_2 .option_check_box_1.my_custom .option_select_box_4 { z-index: 1; float: left; position: relative; margin-right: 20px; }

.quick_search_box .trip_type.option_2 .option_check_box_1.my_custom .option_select_box_4 .option_detail { position: relative; width: 204px; }

.quick_search_box .trip_type.option_2 .option_check_box_1.my_custom .option_select_box_4 .option_detail .comp_select_type_1 { width: 204px; }

.quick_search_box .trip_type.option_2 .option_check_box_1.my_custom .option_select_box_4 .option_detail .comp_select_type_1 .btn_select { height: 35px; }

.quick_search_box .trip_type.option_2 .option_check_box_1.my_custom .option_select_box_4 .option_detail .my_custom_info_box { position: relative; width: 894px; margin-left: -690px; }

.quick_search_box .trip_type.option_2 .option_check_box_1.my_custom .option_select_box_4 .option_detail .btn_select .arrow { top: 15px; }

/* 국내숙박,여행 메인에서 사용 */
.my_custom_info_box { display: none; width: 894px; min-height: 40px; margin-left: -19px; background: #fafafa; }

.my_custom_info_box p.text { padding-left: 20px; font-size: 14px; color: #f43142; line-height: 40px; }

.my_custom_info_box .select_list_box { overflow: hidden; padding: 8px 0 0 20px; }

.my_custom_info_box .select_list_box li { float: left; padding: 0 20px 11px 0; font-size: 14px; color: #5461c6; }

.my_custom_info_box .select_list_box li .btn_del { width: 12px; height: 12px; margin: -1px 0 0 5px; text-indent: -99999px; background: url("/img/trip/hotel/sp_hotel.png") 0 -810px no-repeat; }

.my_custom_info_box .detail_list_box { display: block; position: absolute; left: 0px; width: 890px; min-height: 200px; margin-top: 4px; border: 2px solid #5461c6; background: #fff; }

.my_custom_info_box .detail_list_box .list_box { padding: 20px 39px 30px 39px; }

.my_custom_info_box .detail_list_box .list_box.bg_color { background: #fafafa; }

.my_custom_info_box .detail_list_box .list_box h3.tit { font-size: 16px; line-height: 1.5; font-weight: bold; color: #54566d; }

.my_custom_info_box .detail_list_box .list_box ul { overflow: hidden; width: 810px; margin: 15px 0 0 1px; background: url("/img/trip/hotel/bg_line_1.png"); }

.my_custom_info_box .detail_list_box .list_box ul.list_type_4 { width: 540px; }

.my_custom_info_box .detail_list_box .list_box ul li { float: left; width: 121px; height: 34px; padding: 0 0 0 13px; font-size: 13px; color: #333; border-left: 1px solid #e4e4e4; }

.my_custom_info_box .detail_list_box .list_box ul li span.ico_dot { display: none; }

.my_custom_info_box .detail_list_box .list_box ul li .ico { display: inline-block; width: 14px; height: 14px; margin-right: 6px; background: url("/img/trip/hotel/sp_hotel_icon_1.png") no-repeat; vertical-align: -3px; }

.my_custom_info_box .detail_list_box .list_box ul li .ico.ico_hotel { background-position: -20px 0; }

.my_custom_info_box .detail_list_box .list_box ul li .ico.ico_resort { background-position: -20px -20px; }

.my_custom_info_box .detail_list_box .list_box ul li .ico.ico_pension { background-position: -20px -40px; }

.my_custom_info_box .detail_list_box .list_box ul li .ico.ico_motel { background-position: -20px -60px; }

.my_custom_info_box .detail_list_box .list_box ul li .ico.ico_guesthouse { background-position: -20px -80px; }

.my_custom_info_box .detail_list_box .list_box ul li .ico.ico_residence { background-position: -20px -100px; }

.my_custom_info_box .detail_list_box .list_box ul li .ico.ico_glamping { background-position: -20px -120px; }

.my_custom_info_box .detail_list_box .list_box ul li .ico.ico_caravan { background-position: -20px -140px; }

.my_custom_info_box .detail_list_box .list_box ul li.selected { font-weight: bold; color: #5461c6; }

.my_custom_info_box .detail_list_box .list_box ul li.selected a { font-weight: bold; color: #5461c6; }

.my_custom_info_box .detail_list_box .list_box ul li.selected span.ico_dot { display: inline-block; width: 4px; height: 4px; margin-left: 4px; vertical-align: 10px; background: url("/img/trip/hotel/sp_hotel.png") 0 -830px; }

.my_custom_info_box .detail_list_box .list_box ul li.selected .ico.ico_hotel { background-position: 0 0; }

.my_custom_info_box .detail_list_box .list_box ul li.selected .ico.ico_resort { background-position: 0 -20px; }

.my_custom_info_box .detail_list_box .list_box ul li.selected .ico.ico_pension { background-position: 0 -40px; }

.my_custom_info_box .detail_list_box .list_box ul li.selected .ico.ico_motel { background-position: 0 -60px; }

.my_custom_info_box .detail_list_box .list_box ul li.selected .ico.ico_guesthouse { background-position: 0 -80px; }

.my_custom_info_box .detail_list_box .list_box ul li.selected .ico.ico_residence { background-position: 0 -100px; }

.my_custom_info_box .detail_list_box .list_box ul li.selected .ico.ico_glamping { background-position: 0 -120px; }

.my_custom_info_box .detail_list_box .list_box ul li.selected .ico.ico_caravan { background-position: 0 -140px; }

.my_custom_info_box .detail_list_box .btn_close { position: absolute; top: 16px; right: 16px; width: 14px; height: 14px; text-indent: -9999px; background: url("/img/trip/hotel/sp_hotel.png") 0 -860px no-repeat; }

/* 여행 메인, 국내 숙박, 해외호텔 사용 지도 검색 */
.popup_map_search { z-index: 5010; position: fixed; top: 0px; left: 0px; width: 100%; min-width: 1200px; height: 100%; background: #fff; font-family: "Helvetica Neue", "Apple SD Gothic Neo", Arial, "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; line-height: 1.3; }

.popup_map_search .search_info_box { position: relative; z-index: 30; width: 100%; height: 52px; padding: 21px 0 0 0; background: #54566d; }

.popup_map_search .search_info_box .info_box .sch_tx { float: left; margin: 0 16px 0 26px; color: #fff; font-size: 20px; font-weight: bold; line-height: 28px; }

.popup_map_search .search_info_box .info_box .sch_date { float: left; margin-right: 19px; color: #a9abc0; font-size: 16px; line-height: 28px; }

.popup_map_search .search_info_box .info_box .btn_change { float: left; width: 80px; height: 28px; border: 1px solid #ccc; border-radius: 2px; background: #65677c; color: #fff; font-size: 12px; line-height: 28px; }

.popup_map_search .search_info_box .info_box .btn_change .ico_arrow { display: inline-block; width: 10px; height: 6px; margin-left: 6px; vertical-align: 1px; background: url("/img/trip/hotel/sp_hotel.png") 0 -600px no-repeat; }

.popup_map_search .search_info_box .info_box .tx_bar { float: left; width: 1px; height: 28px; margin: 0 19px 0 20px; background: #727486; }

.popup_map_search .search_info_box .info_box .result_info { float: left; color: #fff; font-size: 15px; line-height: 28px; }

.popup_map_search .search_info_box .info_box .result_info .txt_num { color: #9db4fc; }

.popup_map_search .search_info_box.change_mode .info_box .btn_change { display: none; }

.popup_map_search .search_info_box.change_mode .info_box .sch_date { margin-right: 0px; }

.popup_map_search .search_info_box .hotel_quick_search_box { position: absolute; z-index: 10; top: 70px; width: 100%; height: 86px; border-bottom: 1px solid #54566d; background: #fff; }

.popup_map_search .search_info_box .hotel_quick_search_box > .inner { display: block; width: 100%; }

.popup_map_search .search_info_box .hotel_quick_search_box .fixed > .inner { z-index: 9999; position: fixed; top: 0px; left: 0px; display: block; width: 100%; margin: 0 auto; }

.popup_map_search .search_info_box .hotel_quick_search_box fieldset { width: 100%; margin: 0 auto; box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1); background: #fff; }

.popup_map_search .search_info_box .hotel_quick_search_box .field_box { float: left; width: auto; height: 74px; padding: 12px 0 0 18px; border: none; }

.popup_map_search .search_info_box .hotel_quick_search_box .field_box .text_search_box { float: left; width: 440px; height: 54px; margin-top: 2px; border-right: 1px solid #e4e4e4; }

.popup_map_search .search_info_box .hotel_quick_search_box .field_box .text_search_box dt { position: relative; left: 0; width: 440px; height: 19px; margin-top: -3px; font-size: 14px; line-height: 1.5; text-indent: 0; }

.popup_map_search .search_info_box .hotel_quick_search_box .field_box .text_search_box dd { position: relative; margin-top: 2px; }

.popup_map_search .search_info_box .hotel_quick_search_box .field_box .text_search_box dd input[type="text"] { width: 410px; height: 30px; padding: 0 0 0 25px; font-size: 20px; color: #999; border: 1px solid #fff; background: url("/img/trip/hotel/sp_hotel.png") 0 8px no-repeat; }

.popup_map_search .search_info_box .hotel_quick_search_box .field_box .text_search_box dd input[type="text"].focus, .popup_map_search .search_info_box .hotel_quick_search_box .field_box .text_search_box dd input[type="text"].active { color: #54566d; }

.popup_map_search .search_info_box .hotel_quick_search_box .field_box .text_search_box dd .recomd_list_box { position: absolute; top: 57px; left: -19px; width: 455px; }

.popup_map_search .search_info_box .hotel_quick_search_box .field_box .text_search_box dd .recomd_list_box ul { width: 455px; }

.popup_map_search .search_info_box .hotel_quick_search_box .field_box .text_search_box dd .recomd_list_box ul li { width: 455px; }

.popup_map_search .search_info_box .hotel_quick_search_box .field_box .check_in_out_info_box { position: relative; float: left; height: 72px; padding: 0 0 0 30px; }

.popup_map_search .search_info_box .hotel_quick_search_box .field_box .check_in_out_info_box .inner { overflow: hidden; width: 100%; }

.popup_map_search .search_info_box .hotel_quick_search_box .field_box .check_in_out_info_box .date_info_box { float: left; width: 155px; padding: 1px 0 0 16px; }

.popup_map_search .search_info_box .hotel_quick_search_box .field_box .check_in_out_info_box .date_info_box dt { width: auto; height: 19px; font-size: 14px; color: #666; position: relative; left: 0; line-height: 1.5; text-indent: 0; }

.popup_map_search .search_info_box .hotel_quick_search_box .field_box .check_in_out_info_box .date_info_box dd { margin-top: -2px; font-size: 23px; line-height: 1.5; font-family: tahoma; color: #333; }

.popup_map_search .search_info_box .hotel_quick_search_box .field_box .check_in_out_info_box .date_info_box dd button { text-align: left; margin-top: 2px; line-height: 1.5; font-size: 23px; letter-spacing: -1px; font-family: tahoma; color: #333; background: #fff; }

.popup_map_search .search_info_box .hotel_quick_search_box .field_box .check_in_out_info_box .btn_cal { position: absolute; bottom: 9px; left: 212px; display: block; width: 15px; height: 9px; text-indent: -9999px; background: url("/img/trip/hotel/sp_hotel.png") 0 -30px no-repeat; }

.popup_map_search .search_info_box .hotel_quick_search_box .field_box .check_in_out_info_box .layer_selecter { position: absolute; top: 79px; left: -161px; }

.popup_map_search .search_info_box .hotel_quick_search_box .btn_box { float: left; overflow: hidden; padding-top: 15px; }

.popup_map_search .search_info_box .hotel_quick_search_box .btn_box .btn_sch { float: left; width: 95px; height: 56px; padding-bottom: 2px; font-size: 18px; font-weight: bold; line-height: 23px; color: #fff; background: #545982; }

.popup_map_search .search_info_box .hotel_quick_search_box .btn_box .btn_close { float: left; margin: 18px 0 0 32px; background: #fff; }

.popup_map_search .search_info_box .hotel_quick_search_box .btn_box .btn_close .ico { display: block; width: 22px; height: 22px; text-indent: -99999px; background: url("/img/trip/hotel/sp_hotel.png") 0 -650px; }

.popup_map_search .room_info_box { border-left: 1px solid #e4e4e4; }

.popup_map_search .search_info_box .btn_right_box { position: absolute; top: 15px; right: 20px; }

.popup_map_search .search_info_box .btn_right_box .btn_list { float: left; width: 120px; height: 40px; margin-left: 8px; padding-bottom: 2px; border-radius: 2px; background: #f8f9fd; color: #54566d; font-size: 14px; }

.popup_map_search .search_info_box .btn_right_box .btn_list .ico_arrow { display: inline-block; width: 7px; height: 10px; margin-left: 7px; vertical-align: 0px; background: url("/img/trip/hotel/sp_hotel.png") 0 -610px no-repeat; }

.popup_map_search .search_info_box .btn_right_box .btn_close { float: left; width: 40px; height: 40px; padding-bottom: 2px; margin-left: 8px; border-radius: 2px; background: #f8f9fd; font-size: 12px; }

.popup_map_search .search_info_box .btn_right_box .btn_close .ico { position: relative; display: block; width: 14px; height: 14px; margin: 6px 0 0 14px; text-indent: -99999px; background: url("/img/trip/hotel/sp_hotel.png") 0 -630px; }

.popup_map_search .map_layer_contents { z-index: 10; position: static; overflow: visible; width: 100%; height: 100%; }

.popup_map_search .map_layer_contents .filter_box { position: absolute; z-index: 10; left: 0px; bottom: 0px; top: 70px; width: 313px; height: auto; border-right: 1px solid #ebebeb; background: #fafafa; }

.popup_map_search .map_layer_contents .filter_box.close { width: 0; overflow-y: hidden; }

.popup_map_search .map_layer_contents .filter_box h2.tit { padding: 18px 0 17px 28px; background: #fff; }

.popup_map_search .map_layer_contents .filter_box .select_list { padding: 0 35px 8px 28px; margin-top: 0px; background: url("/img/trip/common/bg_dash_1.png") bottom left repeat-x #fff; }

.popup_map_search .map_layer_contents .filter_box .dash_line { display: block; width: 100%; height: 1px; margin: -1px 0 0 0; padding: 0; border: none; background: url("/img/trip/common/bg_dash_1.png") top left repeat-x; }

.popup_map_search .map_layer_contents .filter_box .filter_detail_box { overflow: hidden; overflow-y: auto; height: 100%; }

.popup_map_search .map_layer_contents .filter_box .type_list_box { padding: 26px 0 20px 6px; margin: 0 0 0 28px; }

.popup_map_search .map_layer_contents .filter_box .type_list_box_2 { padding: 7px 0 12px 6px; margin: 0 0 0 28px; }

.popup_map_search .map_layer_contents .filter_box .btn_reset { top: 19px; right: 55px; }

.popup_map_search .map_layer_contents .filter_box .price_range_box { padding: 16px 0 20px 6px; margin: 0 0 0 28px; }

.popup_map_search .map_layer_contents .filter_box .price_range_box .linear_graph { margin: 28px 0 0 2px; }

.popup_map_search .map_layer_contents .filter_box .price_range_box .price_info_box { margin-left: 2px; }

.popup_map_search .map_layer_contents .filter_box .convenient_box, .popup_map_search .map_layer_contents .filter_box .result_search_box { padding: 16px 0 20px 6px; margin: 0 0 0 28px; }

.popup_map_search .map_layer_contents .btn_filter_box.type_2 { z-index: 20; position: absolute; top: 76px; left: 0px; width: 331px; }

.popup_map_search .map_layer_contents .btn_filter_box.type_2 .btn_detail { position: absolute; right: 18px; width: 25px; height: 42px; padding: 0 0 0 9px; font-size: 14px; border: 1px solid #ccc; border-right: none; text-align: left; background: url("/img/trip/hotel/sp_hotel.png") 10px -1151px #fafafa; }

.popup_map_search .map_layer_contents .btn_filter_box.type_2 .btn_detail .txt { display: none; }

.popup_map_search .map_layer_contents .btn_filter_box.type_2 .btn_detail:hover { width: 60px; height: 42px; padding: 0 0 0 27px; *padding: 10px 0 0 27px; margin-left: -60px; font-size: 14px; border: 1px solid #ccc; border-right: none; text-align: left; }

.popup_map_search .map_layer_contents .btn_filter_box.type_2 .btn_detail:hover .txt { display: inline; }

.popup_map_search.filter_close .map_layer_contents .filter_box { width: 0px; overflow: hidden; overflow-y: hidden; }

.popup_map_search.filter_close .map_layer_contents .result_list_box { left: 1px; }

.popup_map_search.filter_close .map_layer_contents .map_box { left: 451px; }

.popup_map_search.filter_close .map_layer_contents .filter_box h2.tit, .popup_map_search.filter_close .map_layer_contents .filter_box .select_list, .popup_map_search.filter_close .map_layer_contents .filter_box .type_list_box_2, .popup_map_search.filter_close .map_layer_contents .filter_box .type_list_box, .popup_map_search.filter_close .map_layer_contents .filter_box .price_range_box, .popup_map_search.filter_close .map_layer_contents .filter_box .convenient_box, .popup_map_search.filter_close .map_layer_contents .filter_box .result_search_box { display: none; }

.popup_map_search.filter_close .map_layer_contents .btn_filter_box.type_2 .btn_detail { padding: 0 0 0 9px; left: 0; right: auto; margin-left: 0px; border: 1px solid #ccc; border-left: none; background: url("/img/trip/hotel/sp_hotel.png") 11px -1191px #fafafa; }

.popup_map_search.filter_close .map_layer_contents .btn_filter_box.type_2 .btn_detail:hover { width: 83px; padding: 0 0 0 23px; *padding: 10px 0 0 23px; border-left: none; }

.popup_map_search.no_filter .map_layer_contents .map_box { left: 0; }

.popup_map_search .map_layer_contents .result_list_box { z-index: 1; position: absolute; left: 314px; bottom: 0px; top: 70px; overflow: hidden; overflow-y: auto; width: 450px; height: auto; border-right: 1px solid #ebebeb; background: #e8edf0; }

.popup_map_search .map_layer_contents .result_list_box.close { width: 0; overflow-y: hidden; }

.popup_map_search .map_layer_contents .result_list_box .list_box { width: 433px; margin: 0 auto; }

.popup_map_search .map_layer_contents .result_list_box .list_box li { position: relative; overflow: hidden; margin-top: 7px; border: 1px solid #dfdfdf; background: #fff; }

.popup_map_search .map_layer_contents .result_list_box .list_box li.selected, .popup_map_search .map_layer_contents .result_list_box .list_box li:hover { border: 1px solid #f43142; background: url("/img/trip/hotel/bg_list_01.png") right top no-repeat #fff9fa; }

.popup_map_search .map_layer_contents .result_list_box .list_box li .product_info_box { overflow: hidden; height: 160px; }

.popup_map_search .map_layer_contents .result_list_box .list_box li .product_info_box a { display: block; height: 160px; text-decoration: none; }

.popup_map_search .map_layer_contents .result_list_box .list_box li .product_info_box a:hover .info_box .product_box .tit { text-decoration: underline; }

.popup_map_search .map_layer_contents .result_list_box .list_box li .product_info_box a:hover .info_box .product_box .tit .class_type { text-decoration: none; }

.popup_map_search .map_layer_contents .result_list_box .list_box li .product_info_box .thumb_box { float: left; position: relative; width: 160px; }

.popup_map_search .map_layer_contents .result_list_box .list_box li .product_info_box .thumb_box .thumb { display: block; width: 160px; height: 160px; }

.popup_map_search .map_layer_contents .result_list_box .list_box li .product_info_box .thumb_box .hashtag { display: inline-block; position: absolute; top: 0; left: 0; width: 160px; height: 160px; background: url("/img/trip/common/bg_thumb_cover_1.png") bottom left repeat-x; }

.popup_map_search .map_layer_contents .result_list_box .list_box li .product_info_box .thumb_box .hashtag .txt { position: absolute; bottom: 6px; left: 8px; font-size: 12px; color: #fff; }

.popup_map_search .map_layer_contents .result_list_box .list_box li .product_info_box .thumb_box .ico_shockingdeal { display: inline-block; position: absolute; top: 5px; left: 5px; width: 66px; height: 28px; margin: 0px; text-indent: -99999px; background: url("/img/trip/common/icon_shockingdeal.png") no-repeat; }

.popup_map_search .map_layer_contents .result_list_box .list_box li .product_info_box .info_box { float: left; width: 243px; padding: 10px 14px 14px 14px; }

.popup_map_search .map_layer_contents .result_list_box .list_box li .product_info_box .info_box .product_box { height: 83px; }

.popup_map_search .map_layer_contents .result_list_box .list_box li .product_info_box .info_box .product_box .tit { overflow: hidden; max-height: 41px; font-size: 16px; line-height: 20px; color: #333; }

.popup_map_search .map_layer_contents .result_list_box .list_box li .product_info_box .info_box .product_box .tit .class_type { overflow: hidden; display: inline-block; min-width: 39px; height: 16px; margin-right: 7px; padding: 2px 3px 0 3px; font-size: 11px; line-height: 1.15; vertical-align: -3px; font-weight: normal; color: #fff; text-align: center; border-radius: 2px; background: #999; }

.popup_map_search .map_layer_contents .result_list_box .list_box li .product_info_box .info_box .product_box .location { overflow: hidden; margin-top: 8px; font-size: 12px; letter-spacing: 0; color: #666; }

.popup_map_search .map_layer_contents .result_list_box .list_box li .product_info_box .info_box .product_box .location .ico { float: left; display: block; width: 9px; height: 12px; margin: 1px 6px 0 0; text-indent: -9999px; background: url("/img/trip/hotel/sp_hotel.png") 0 -680px no-repeat; }

.popup_map_search .map_layer_contents .result_list_box .list_box li .product_info_box .info_box .product_box .stat_box { overflow: hidden; margin-top: 4px; font-size: 12px; letter-spacing: 0; color: #999; }

.popup_map_search .map_layer_contents .result_list_box .list_box li .product_info_box .info_box .product_box .stat_box .ico_star { float: left; display: block; width: 11px; height: 10px; margin: 1px 6px 0 0; text-indent: -9999px; background: url("/img/trip/hotel/sp_hotel.png") 0 -700px no-repeat; }

.popup_map_search .map_layer_contents .result_list_box .list_box li .product_info_box .info_box .product_box .stat_box .ico_adv { float: left; display: block; width: 16px; height: 10px; margin: 1px 6px 0 0; text-indent: -9999px; background: url("/img/trip/hotel/sp_advisor.png") 0 -39px no-repeat; }

.popup_map_search .map_layer_contents .result_list_box .list_box li .product_info_box .info_box .product_box .stat_box .n { color: #333; }

.popup_map_search .map_layer_contents .result_list_box .list_box li .product_info_box .info_box .product_box .stat_box .tx_bar { display: inline-block; width: 1px; height: 8px; margin: 0 5px 0 3px; vertical-align: 1px; background: #e4e4e4; }

.popup_map_search .map_layer_contents .result_list_box .list_box li .product_info_box .info_box .price_company_box { margin-top: -9px; }

.popup_map_search .map_layer_contents .result_list_box .list_box li .product_info_box .info_box .price_company_box .company { height: 24px; font-size: 12px; color: #5676da; text-align: right; }

.popup_map_search .map_layer_contents .result_list_box .list_box li .product_info_box .info_box .price_company_box .company .logo { max-width: 90px; max-height: 24px; }

.popup_map_search .map_layer_contents .result_list_box .list_box li .product_info_box .info_box .price_company_box .price_box { position: relative; text-align: right; margin-top: -3px; background: transparent; border-bottom: none; }

.popup_map_search .map_layer_contents .result_list_box .list_box li .product_info_box .info_box .price_company_box .price_box .sale { display: inline-block; zoom: 1; *display: inline; margin-right: 5px; *margin-bottom: 15px; font-size: 14px; color: #ef5050; }

.popup_map_search .map_layer_contents .result_list_box .list_box li .product_info_box .info_box .price_company_box .price_box .sale .n { font-size: 20px; font-weight: bold; font-family: tahoma; }

.popup_map_search .map_layer_contents .result_list_box .list_box li .product_info_box .info_box .price_company_box .price_box .price_sale { position: relative; display: inline-block; height: 45px; font-size: 14px; color: #111; }

.popup_map_search .map_layer_contents .result_list_box .list_box li .product_info_box .info_box .price_company_box .price_box .price_sale .n { font-size: 20px; font-weight: bold; font-family: tahoma; }

.popup_map_search .map_layer_contents .result_list_box .list_box li .product_info_box .info_box .price_company_box .price_box .price_sale .price_normal { position: absolute; bottom: 0px; left: 0px; color: #999; font-size: 12px; text-decoration: line-through; }

.popup_map_search .map_layer_contents .result_list_box .list_box li .product_info_box .info_box .price_company_box .price_box .price_sale .price_normal .n { font-size: 14px; font-weight: normal; font-family: tahoma; }

.popup_map_search .map_layer_contents .result_list_box .list_box li .product_info_box .info_box .price_company_box .price_box .price_sale .price_average { position: absolute; bottom: 3px; right: 0px; width: 220px; color: #999; font-size: 11px; }

.popup_map_search .map_layer_contents .result_list_box .list_box li .btn_comparison { width: 434px; height: 40px; padding-bottom: 2px; font-size: 14px; font-weight: bold; text-align: right; color: #333; border-top: 1px solid #eaeaea; background: #fafafa; }

.popup_map_search .map_layer_contents .result_list_box .list_box li .btn_comparison .txt_sale { font-weight: normal; color: #666; }

.popup_map_search .map_layer_contents .result_list_box .list_box li .btn_comparison .ico_arrow { display: inline-block; width: 7px; height: 12px; margin: 0 18px 0 4px; vertical-align: -2px; background: url("/img/trip/hotel/sp_hotel.png") 0 -270px no-repeat; }

.popup_map_search .map_layer_contents .result_list_box .research_loading_box { display: none; }

.popup_map_search .map_layer_contents .result_list_box .research_loading_box p.text { margin-top: 15px; font-size: 16px; font-weight: bold; color: #333; }

.popup_map_search .map_layer_contents .result_list_box .research_loading_box .loading_bar { display: block; position: absolute; bottom: 0; left: 0; width: 100%; height: 4px; background: #e8e8e8; }

.popup_map_search .map_layer_contents .result_list_box .research_loading_box .loading_bar span { display: block; position: absolute; bottom: 0; left: 0; width: 50%; height: 4px; background: #f43142; }

.popup_map_search .map_layer_contents .result_list_box .dim_box { display: none; }

.popup_map_search .map_layer_contents .result_list_box.researching { overflow: hidden; }

.popup_map_search .map_layer_contents .result_list_box.researching .research_loading_box { display: block; z-index: 20; position: relative; width: 461px; height: 60px; margin: 10px auto 0 auto; text-align: center; border: 1px solid #dfdfdf; background: #fff; }

.popup_map_search .map_layer_contents .result_list_box.researching .dim_box { display: block; z-index: 10; position: fixed; top: 0px; width: 450px; height: 100%; background: url("/img/trip/hotel/bg_dim_w_80.png"); }

.popup_map_search .map_layer_contents .result_list_box .info_paging { display: block; position: fixed; bottom: 20px; width: 450px; color: #fff; text-align: center; }

.popup_map_search .map_layer_contents .result_list_box.close .info_paging { display: none; }

.popup_map_search .map_layer_contents .result_list_box .pager { display: inline-block; position: relative; z-index: 10; height: 34px; padding: 0 20px 0 20px; line-height: 32px; letter-spacing: 0; font-size: 16px; border-radius: 34px; background-color: #000; opacity: 0.7; filter: alpha(opacity=70); }

.popup_map_search .map_layer_contents .result_list_box .btn_box { position: absolute; top: 62px; right: -1px; width: 1px; }

.popup_map_search .map_layer_contents .result_list_box .btn_box .btn_detail { position: fixed; width: 23px; height: 42px; padding: 0 0 0 22px; margin-left: -23px; font-size: 14px; border: 1px solid #ccc; border-right: none; text-align: left; background: url("/img/trip/hotel/sp_hotel.png") 9px -1151px #e8edf0; }

.popup_map_search .map_layer_contents .result_list_box .btn_box .btn_detail .txt { display: none; }

.popup_map_search .map_layer_contents .result_list_box .btn_box .btn_detail:hover { position: fixed; width: 60px; height: 42px; padding: 0 0 0 23px; *padding: 10px 0 0 23px; margin-left: -60px; font-size: 14px; border: 1px solid #ccc; border-right: none; text-align: left; }

.popup_map_search .map_layer_contents .result_list_box .btn_box .btn_detail:hover .txt { display: inline; }

.popup_map_search .map_layer_contents .result_list_box.close .btn_box .btn_detail { padding: 0 0 0 9px; margin-left: 0; border: 1px solid #ccc; border-left: none; background: url("/img/trip/hotel/sp_hotel.png") 10px -1191px #e8edf0; }

.popup_map_search .map_layer_contents .result_list_box.close .btn_box .btn_detail:hover { width: 98px; padding: 0 0 0 23px; *padding: 10px 0 0 23px; border-left: none; }

.popup_map_search .map_layer_contents .result_list_box.no_data { background: url("/img/trip/hotel/bg_list_blank.png") 50% 0 repeat-y #e8edf0; }

.popup_map_search .map_layer_contents .result_list_box.no_data .list_box, .popup_map_search .map_layer_contents .result_list_box.no_data .info_paging { display: none; }

.popup_map_search .map_layer_contents .result_list_box .no_data_box { display: none; }

.popup_map_search .map_layer_contents .result_list_box.no_data .no_data_box { display: block; position: relative; overflow: hidden; width: 342px; height: 113px; padding: 48px 0 0 92px; margin: 7px auto; border: 1px solid #dfdfdf; background: #fff; }

.popup_map_search .map_layer_contents .result_list_box.no_data .no_data_box strong { display: block; margin-bottom: 8px; font-size: 16px; color: #333; }

.popup_map_search .map_layer_contents .result_list_box.no_data .no_data_box span { display: block; margin: 0 0 5px 3px; font-size: 12px; color: #666; }

.popup_map_search .map_layer_contents .map_box { position: absolute; left: 765px; bottom: 0px; top: 70px; right: 0px; background: #f0eee6; }

.popup_map_search .map_layer_contents .map_box .marker { z-index: 1; position: absolute; width: 20px; height: 20px; margin: -10px 0 0 -10px; background: url("/img/trip/hotel/ico_map_marker_1.png") 0 0 no-repeat; }

.popup_map_search .map_layer_contents .map_box .marker.selected { z-index: 10; }

.popup_map_search .map_layer_contents .map_box .marker.selected:before { position: absolute; top: -38px; left: -6px; width: 32px; height: 48px; background: url("/img/trip/hotel/ico_map_marker_2.png") no-repeat; content: ""; }

.popup_map_search .map_layer_contents .map_box .marker.selected .product_info_box { display: block; bottom: -78px; }

.popup_map_search .map_layer_contents .map_box .marker.landmark { background: none; }

.popup_map_search .map_layer_contents .map_box .marker.landmark:hover { z-index: 9999; }

.popup_map_search .map_layer_contents .map_box .marker.landmark:before { position: absolute; top: -38px; left: -6px; width: 32px; height: 48px; background: url("/img/trip/hotel/ico_map_marker_3.png") no-repeat; content: ""; }

.popup_map_search .map_layer_contents .map_box .txt_box { display: block; position: absolute; top: 10px; left: 0px; min-width: 58px; padding: 8px 10px 9px 10px; font-size: 14px; text-align: center; color: #333; border: 1px solid #9e9e9e; box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.1); white-space: nowrap; background: #fff; z-index: 10; }

.popup_map_search .map_layer_contents .map_box .price_layer { z-index: 1; position: absolute; height: 28px; margin-top: -40px; padding: 4px 9px 0 8px; border: 1px solid #fff; border-radius: 5px; background: #5676da; }

.popup_map_search .map_layer_contents .map_box .price_layer .price_info { font-size: 14px; color: #fff; letter-spacing: 0; }

.popup_map_search .map_layer_contents .map_box .price_layer .price_info strong.n { font-size: 16px; font-family: tahoma; }

.popup_map_search .map_layer_contents .map_box .price_layer .ico_arrow { position: absolute; bottom: -8px; left: 50%; display: block; width: 12px; height: 8px; margin-left: -6px; background: url("/img/trip/hotel/sp_hotel.png") 0 -770px no-repeat; }

.popup_map_search .map_layer_contents .map_box .price_layer .product_info_box { display: none; }

.popup_map_search .map_layer_contents .map_box .price_layer.selected { z-index: 10; background: #f43142; }

.popup_map_search .map_layer_contents .map_box .price_layer.selected .ico_arrow { background: url("/img/trip/hotel/sp_hotel.png") 0 -790px no-repeat; }

.popup_map_search .map_layer_contents .map_box .price_layer.selected .product_info_box { display: block; }

.popup_map_search .map_layer_contents .map_box .product_info_box { display: none; position: absolute; left: 0px; max-width: 380px; padding: 5px 0 0 0; border: none; box-shadow: none; background: transparent; z-index: 1; }

.popup_map_search .map_layer_contents .map_box .product_info_box .inner { overflow: hidden; position: relative; width: auto; *width: 212px; height: 56px; padding: 8px; border: 1px solid #9e9e9e; box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.1); background: #fff; }

.popup_map_search .map_layer_contents .map_box .product_info_box a { display: block; height: 56px; overflow: hidden; }

.popup_map_search .map_layer_contents .map_box .product_info_box a:hover { text-decoration: none; }

.popup_map_search .map_layer_contents .map_box .product_info_box a:hover .product_box .tit { text-decoration: underline; }

.popup_map_search .map_layer_contents .map_box .product_info_box .thumb_box { width: 56px; height: 56px; position: absolute; top: 8px; left: 8px; }

.popup_map_search .map_layer_contents .map_box .product_info_box .thumb_box .thumb { width: 56px; height: 56px; }

.popup_map_search .map_layer_contents .map_box .product_info_box .product_box { min-width: 100px; margin-left: 65px; }

.popup_map_search .map_layer_contents .map_box .product_info_box .product_box .tit { overflow: hidden; text-overflow: ellipsis; white-space: nowrap; width: auto; *width: 146px; min-width: 146px; font-size: 16px; font-weight: bold; line-height: 20px; color: #333; }

.popup_map_search .map_layer_contents .map_box .product_info_box .product_box .tit .class_type { overflow: hidden; display: inline-block; min-width: 39px; height: 16px; margin-right: 7px; padding: 2px 3px 0 3px; font-size: 11px; line-height: 1.15; vertical-align: -3px; font-weight: normal; color: #fff; text-align: center; border-radius: 2px; background: #999; }

.popup_map_search .map_layer_contents .map_box .product_info_box .product_box .stat_box { overflow: hidden; margin-top: 7px; font-size: 12px; letter-spacing: 0; color: #999; }

.popup_map_search .map_layer_contents .map_box .product_info_box .product_box .stat_box .ico_star { display: inline-block; width: 11px; height: 10px; margin: 1px 6px 0 0; text-indent: -9999px; background: url("/img/trip/hotel/sp_hotel.png") 0 -700px no-repeat; }

.popup_map_search .map_layer_contents .map_box .product_info_box .product_box .stat_box .ico_adv { display: inline-block; width: 16px; height: 10px; margin: 1px 6px 0 0; text-indent: -9999px; background: url("/img/trip/hotel/sp_advisor.png") 0 -39px no-repeat; }

.popup_map_search .map_layer_contents .map_box .product_info_box .product_box .stat_box .n { color: #333; }

.popup_map_search .map_layer_contents .map_box .product_info_box .product_box .stat_box .tx_bar { display: inline-block; width: 1px; height: 8px; margin: 0 5px 0 3px; vertical-align: 1px; background: #e4e4e4; }

.popup_map_search .map_layer_contents .pop_loading { z-index: 30; position: absolute; top: 0px; left: 0; width: 100%; height: 100%; background: url("/img/trip/hotel/bg_dim_w_80.png"); }

.popup_map_search .map_layer_contents .pop_loading .loading_box { position: absolute; top: 50%; left: 50%; width: 280px; height: 136px; margin: -80px 0 0 -140px; padding: 24px 0 0 0; color: #333; text-align: center; box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5); background: #fff; }

.popup_map_search .map_layer_contents .pop_loading .loading_box .icon_box { width: 64px; height: 64px; margin: 0 auto; background: url("/img/trip/hotel/ico_map_loading.png") no-repeat; }

.popup_map_search .map_layer_contents .pop_loading .loading_box p.text_1 { margin-top: 9px; font-size: 16px; font-weight: bold; }

.popup_map_search .map_layer_contents .pop_loading .loading_box p.text_2 { margin-top: 2px; font-size: 12px; }

.popup_map_search .pop_compartion_box { z-index: 30; display: none; position: absolute; top: 0; left: 0; width: 100%; height: 100%; font-size: 14px; line-height: 1.5; background: url("/img/trip/common/bg_dim_50.png"); }

.popup_map_search .pop_compartion_box h3.tit { height: 42px; padding: 16px 0 0 27px; font-size: 16px; font-weight: bold; color: #333; border-bottom: 1px solid #ccc; background: #fafafa; }

.popup_map_search .pop_compartion_box h3.tit span.txt_sale { font-weight: normal; color: #666; }

.popup_map_search .pop_compartion_box .compartion_list_box { position: absolute; top: 50%; left: 50%; width: 901px; height: 580px; margin: -290px 0 0 -450px; border: none; background: #fff; }

.popup_map_search .pop_compartion_box .compartion_list_box .inner { width: 901px; height: 481px; }

.popup_map_search .pop_compartion_box .compartion_list_box li { border-left: 1px solid #fff; border-right: 1px solid #fff; }

.popup_map_search .pop_compartion_box .compartion_list_box .etc_box .help_box .lay_nw_def { top: auto; bottom: 29px; }

.popup_map_search .pop_compartion_box .compartion_list_box .etc_box .help_box .lay_nw_def .ico_arrow { top: auto; bottom: -13px; background: url("/img/trip/hotel/sp_hotel.png") 0 -720px; }

.popup_map_search .pop_compartion_box .btn_close { position: absolute; top: 21px; right: 20px; width: 16px; height: 16px; text-indent: -9999px; background: url("/img/trip/hotel/sp_hotel.png") 0 -740px; }

.popup_map_search .pop_compartion_box .compartion_list_box .no_data_box { display: none; }

.popup_map_search .pop_compartion_box.no_data .compartion_list_box { display: block; height: 496px; margin: -248px 0 0 -450px; }

.popup_map_search .pop_compartion_box.no_data h3.tit span.txt_sale { display: none; }

.popup_map_search .pop_compartion_box.no_data .compartion_list_box .inner, .popup_map_search .pop_compartion_box.no_data .compartion_list_box .etc_box { display: none; }

.popup_map_search .pop_compartion_box.no_data .compartion_list_box .no_data_box { display: block; padding: 106px 0 0 0; text-align: center; }

.popup_map_search .pop_compartion_box.no_data .compartion_list_box .no_data_box .img_box { display: block; width: 115px; height: 96px; margin: 0 auto 0 auto; background: url("/img/trip/hotel/sp_hotel.png") 0 -1280px no-repeat; }

.popup_map_search .pop_compartion_box.no_data .compartion_list_box .no_data_box strong { display: block; margin: 15px auto 0 auto; font-size: 20px; font-weight: bold; color: #333; }

.popup_map_search .pop_compartion_box.no_data .compartion_list_box .no_data_box span { display: block; margin: 6px auto 0 auto; font-size: 16px; color: #666; }

/* 로딩 */
.filter_dimmed { display: none; position: fixed; top: 0; bottom: 0; left: 0; right: 0; z-index: 20000; background: url("/img/default/bg_fff_05.png") repeat 0 0; }

.filter_dimmed img { position: absolute; left: 50%; top: 50%; margin: -80px 0 0 -80px; }

/* wing banner 위치 수정*/
.wing_banner { top: 10px; margin-left: 610px; }

.wing_banner.wing_fixed { position: fixed; top: 10px; }

/* 마이히스토리 1200 사이즈 변경할때 사용*/
div.footer_search2, div.my_history2_wrap, div.ftr_banner { width: 1200px; }

.my_history2_wrap .recom_prd h5 { padding-left: 51px; }

.my_history2_wrap .btnctr_pn { padding-right: 51px; }

/* [페이징] */
.s_paging_v2 { position: relative; width: 100%; height: 32px; margin-top: 20px; padding-bottom: 10px; font: normal 11px/1 "tahoma"; text-align: center; }

.s_paging_v2 a, .s_paging_v2 strong { display: inline-block; width: 30px; height: 19px; *height: 20px; margin: 0 1px; padding-top: 9px; *padding-top: 8px; text-decoration: none !important; vertical-align: middle; }

.s_paging_v2 a { color: #666; background: #fff; border: 1px solid #c7c7c7; }

.s_paging_v2 a:hover { color: #fff; background: #f62e3d; border: 1px solid #b70922; }

.s_paging_v2 strong { color: #fff; background: #f62e3d; border: 1px solid #b70922; }

.s_paging_v2 .selected { color: #fff; background: #f62e3d; border: 1px solid #b70922; cursor: default; }

.s_paging_v2 a.first, .s_paging_v2 a.prev, .s_paging_v2 a.next, .s_paging_v2 a.last { width: 32px; height: 21px; *height: 22px; border: none; background: url("/img/category/v2/sp_category.png") no-repeat; }

.s_paging_v2 a.first { background-position: -67px -267px; }

.s_paging_v2 a.prev { background-position: -103px -267px; }

.s_paging_v2 a.next { background-position: -139px -267px; }

.s_paging_v2 a.last { background-position: -175px -267px; }

.listing_wrap .quick_search_box { min-width: 1200px; }

.listing_wrap .quick_search_box .tab_menu { display: none; }

.listing_wrap .quick_search_box .trip_type { width: 1200px; background: none; }

.listing_wrap .quick_search_box .inner { padding: 0px; }

.listing_wrap .quick_search_box .trip_type.option_2 .option_check_box_1, .listing_wrap .quick_search_box .trip_type.option_2 .option_check_box_2 { position: relative; z-index: 2; padding-left: 20px; padding-right: 20px; }

.listing_wrap .quick_search_box .trip_type.option_2 .option_check_box_1 .option_select_box_1 .option_detail .info_input_2 { width: 388px; }

.listing_wrap .quick_search_box .trip_type.option_2 .option_check_box_2 .option_select_box_3 .option_detail .info_input_2 { width: 236px; }

.listing_wrap .quick_search_box .trip_type.option_3 .option_check_box_1, .listing_wrap .quick_search_box .trip_type.option_3 .option_check_box_2, .listing_wrap .quick_search_box .trip_type.option_3 .option_check_box_3 { position: relative; z-index: 2; padding-left: 20px; padding-right: 20px; }

.listing_wrap .quick_search_layer_wrap .search_option_box { width: 1198px; padding: 20px 0 20px 0; }

.listing_wrap .quick_search_layer_wrap .quick_search_box .inner { width: 1198px; margin: 0; }

.listing_wrap .quick_search_layer_wrap .quick_search_box .trip_type { padding: 0px; }

.listing_wrap .quick_search_layer_wrap .quick_search_box .trip_company_list_2, .listing_wrap .quick_search_layer_wrap .quick_search_box .trip_company_list_3 { width: 1196px; margin: 0px; }

.listing_wrap .quick_search_layer_wrap .quick_search_box .trip_type.option_3 .option_check_box_2 .option_select_box_2 .option_detail .layer_selecter.trip_date_picker_v2 { left: -26px; }

.quick_search_box .bottom_box { position: relative; z-index: 1; clear: both; padding: 14px 0 5px 0; text-align: center; }

.quick_search_box .bottom_box button.btn_type_1 { width: 150px; height: 40px; padding: 0 0 5px 0; font-size: 18px; color: #fff; background: #2b3769; }

/* 여행 리뷰 */
.trip_review .review_list { border-top: 0 none; }

.trip_review .review_list li { padding-top: 30px; }

.trip_review .review_list li:first-child { padding-top: 0; }

.trip_review .review_list .btnwrap .btn a { vertical-align: middle; }

.trip_review .btn_more { width: 64px; height: 24px; margin-top: 16px; border: 1px solid #ccc; color: #111; font-size: 11px; line-height: 22px; }

.trip_review .btn_more:after { display: inline-block; width: 12px; height: 6px; margin: 9px 0 0 4px; background: url("/img/trip/common/sp_trip.png") -217px -78px no-repeat; vertical-align: top; content: ""; }

.trip_review li > div { padding-bottom: 30px; border-bottom: 1px solid #eee; }

.trip_review .bbs_cont_wrap .bbs_cont { overflow: hidden; }

.trip_review .bbs_cont_wrap .bbs_img { width: 132px; }

.trip_review .bbs_cont_wrap .bbs_img img { max-width: 132px; height: auto; max-height: 132px; }

.trip_review .bbs_cont_wrap a.lk_img { position: relative; width: 132px; height: 132px; }

.trip_review .bbs_cont_wrap .count { position: absolute; bottom: 0; right: 0; width: 24px; height: 24px; background: url("/img/trip/common/bg_dim_50.png"); color: #fff; font-size: 12px; font-weight: bold; line-height: 24px; }

.trip_review .bbs_cont_wrap .bbs_cont.limit .bbs_summary { max-height: 66px; }

.trip_review .bbs_cont.limit .btn_more:after { background-position: -217px -68px; }

.trip_review .bbs_cont_wrap .bbs_cont .option_txt, .trip_review .bbs_top .option_txt { color: #666; font-size: 12px; letter-spacing: 0; }

.trip_review .bbs_cont_wrap .bbs_cont .bbs_summary { max-width: 759px; height: auto; }

.trip_review .bbs_cont_wrap .bbs_cont .bbs_summary .summ_conts { min-height: 22px; }

.trip_review .bbs_cont_wrap .bbs_cont .bbs_summary .lk_txt { display: block; font-size: 13px; line-height: 22px; }

.trip_review .bbs_top .re_ico { height: 24px; padding: 0 10px; border-radius: 12px; color: #333; font-size: 11px; font-family: "Helvetica Neue", "Apple SD Gothic Neo", Arial, "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; line-height: 24px; }

.trip_review .bbs_top .type { color: #5676da; }

.trip_review .bbs_top .top_r .name, .trip_review .bbs_top .top_r .age, .trip_review .bbs_top .top_r .date { color: #333; }

.trip_review .msg { padding: 70px 0; border-bottom: 1px solid #eee; line-height: 18px; text-align: center; }

.trip_review .btn_box { float: right; margin: 4px -6px 0 0; }

.trip_review .btn_box .btn { margin: 0 6px; font-size: 13px; vertical-align: baseline; }

.trip_review .btn_box .btn.selected { color: #f43142; font-weight: bold; }

/* 공통탭 */
.list_tab_menu { position: relative; z-index: 5; left: 0; top: 0; width: 1199px; height: 56px; padding-left: 1px; border-bottom: 1px solid #555; background-color: #fff; }

.list_tab_menu li { float: left; width: 217px; border-top: 1px solid #ddd; }

.list_tab_menu li:first-child { width: 219px; }

.list_tab_menu li a { position: relative; z-index: 0; display: block; height: 55px; margin-left: -1px; background-color: #f5f5f5; border-left: 1px solid #ddd; border-right: 1px solid #ddd; font-size: 16px; color: #666; line-height: 52px; text-align: center; }

.list_tab_menu .lk_tab { position: relative; z-index: 0; display: block; height: 55px; margin-left: -1px; background-color: #f5f5f5; border-left: 1px solid #ddd; border-right: 1px solid #ddd; font-size: 16px; color: #666; line-height: 52px; text-align: center; }

.list_tab_menu li a:hover, .list_tab_menu li a:focus { text-decoration: none; }

.list_tab_menu li.selected a, .list_tab_menu li.selected .lk_tab { z-index: 1; margin: -1px 0 -1px -1px; background-color: #fff; border: 1px solid #555; border-bottom: 1px solid #fff; font-weight: bold; color: #111; }

.list_tab_menu .txt_sub { color: #999; font-size: 12px; letter-spacing: 0; }

/* 공통 위치탭 주변관광지 */
.distance_list { height: 300px; }

.distance_list li { overflow: hidden; width: 290px; font-size: 12px; color: #000; line-height: 30px; text-overflow: ellipsis; white-space: nowrap; }

.distance_list li:before { display: inline-block; width: 6px; height: 6px; margin-right: 4px; border-radius: 50%; font-size: 0; line-height: 0; vertical-align: middle; content: ""; }

.distance_list li strong { display: inline-block; width: 57px; font-size: 13px; }

/* 공통 캘린더 today
.check_in_out_info_box .layer_selecter .tbl_calender a.today,
.price_result .layer_selecter .tbl_calender td a.today {border: 1px solid #f43142;background-color:#f43142;}
*/
/* 공통리스팅 - 필터 */
.price_range_graph { margin-top: 12px; letter-spacing: 0; }

.price_range_graph .tit_max { display: block; color: #f43142; font-size: 14px; font-weight: bold; text-align: center; }

.price_range_graph .inp_max { display: block; width: 100%; border: 0; background: none; color: #f43142; font-size: 14px; font-weight: bold; text-align: center; letter-spacing: 0; }

.price_range_graph .range_bar { display: block; position: relative; width: 100%; height: 2px; margin: 10px 0 6px; background: #ccc; }

.price_range_graph .bar, .price_range_graph .ui-slider-range { position: absolute; width: 100%; height: 100%; background: #f43142; }

.price_range_graph .handle { position: absolute; left: 10px; right: 10px; height: 100%; }

.price_range_graph .icon { overflow: hidden; position: absolute; top: 50%; z-index: 1; width: 22px; height: 22px; margin: -10px 0 0 -10px; background-color: #f43142; border-radius: 50%; cursor: pointer; text-indent: -999px; }

.price_range_graph .btn_handle { overflow: hidden; position: absolute; top: 50%; z-index: 1; width: 22px; height: 22px; margin: -10px 0 0 -10px; background-color: #f43142; border-radius: 50%; cursor: pointer; text-indent: -999px; margin: -11px 0 0 -11px; border: 2px solid #f43142; background-color: #fff; box-sizing: border-box; }

.price_range_graph .txt { overflow: hidden; padding-top: 5px; font-size: 11px; color: #666; }

.price_range_graph .txt .min { float: left; }

.price_range_graph .txt .max { float: right; }

/* 공통리스팅 - 상세 */
.total_item { overflow: hidden; height: 210px; padding: 20px 0; border-bottom: 1px solid #ccc; }

.total_item .thum_box { float: left; margin-right: 20px; }

.total_item .info_box { float: left; position: relative; width: 340px; height: 100%; margin-right: 20px; }

.total_item .price_box { float: left; position: relative; height: 100%; width: 270px; padding: 0 19px 0 20px; border-left: 1px solid #eee; }

.total_item .thum_box .thum { position: relative; width: 210px; height: 210px; }

.total_item .thum_box .rating { position: absolute; left: 0; bottom: 0; width: 100%; height: 100%; background: url("/img/trip/common/bg_thumb_cover_1.png") repeat-x 0 100%; }

.total_item .thum_box .ico_shockingdeal { position: absolute; top: 4px; left: 4px; overflow: hidden; display: inline-block; width: 66px; height: 28px; background: url("/img/trip/common/icon_shockingdeal.png") no-repeat; line-height: 200px; vertical-align: middle; }

.total_item .thum_box .txt { position: absolute; left: 11px; bottom: 7px; color: #fff; }

.total_item .info_box .hotel_tit { display: block; overflow: hidden; max-height: 81px; font-size: 18px; color: #000; line-height: 26px; }

.total_item .info_box .hotel_tit:hover, .total_item .info_box .hotel_tit:focus { text-decoration: underline; }

.total_item .info_box .txt_range { overflow: hidden; margin-top: 13px; font-size: 12px; white-space: nowrap; text-overflow: ellipsis; }

.total_item .info_box .ico_map_small_black { margin-right: 5px; vertical-align: middle; }

.total_item .info_box .review_area { position: absolute; left: 0; bottom: -2px; width: 100%; }

.total_item .info_box .review_txt { overflow: hidden; margin-top: 20px; font-size: 13px; color: #324b6e; white-space: nowrap; text-overflow: ellipsis; }

.total_item .price_box .info { margin-top: -3px; }

.total_item .price_box .company { float: left; height: 22px; margin-top: 2px; }

.total_item .price_box .company img { height: 100%; }

.total_item .price_box .total_price { float: right; font-family: "Tahoma"; font-size: 22px; color: #333; }

.total_item .price_box .txt_won { font-family: "맑은 고딕", "Malgun Gothic", "Apple SD Gothic Neo", Helvetica, "돋움", dotum, Tahoma, sans-serif; font-size: 18px; font-weight: normal; }

.total_item .price_box .average { clear: both; padding-top: 2px; font-size: 11px; color: #333; text-align: right; }

.total_item .price_box .price_list { height: 88px; margin-top: 21px; font-size: 12px; }

.total_item .price_box .price_list li { position: relative; margin-top: 3px; *margin-top: 0; line-height: 18px; background: url("/img/trip/common/bg_dash_1.png") repeat-x 50% 50%; }

.total_item .price_box .price_list li:hover .tooltip { display: block; }

.total_item .price_box .link_txt { display: block; overflow: hidden; }

.total_item .price_box .link_txt:hover .tit, .total_item .price_box .link_txt:hover .total_price { text-decoration: underline; }

.total_item .price_box .link_txt:focus { text-decoration: underline; }

.total_item .price_box .price_list .tit { float: left; padding-right: 10px; background: #fff; }

.total_item .price_box .price_list .total_price { margin-top: -1px; padding-left: 10px; background: #fff; font-size: 12px; }

.total_item .price_box .price_list .txt_won { font-size: 12px; }

.total_item .price_box .tooltip { position: absolute; top: -28px; right: -9px; z-index: 1; display: none; padding: 5px 8px; border: 1px solid #666; border-radius: 3px; background-color: #fff; color: #333; line-height: 15px; }

.total_item .price_box .tooltip:after { position: absolute; bottom: -9px; right: 50px; display: block; overflow: hidden; width: 8px; height: 9px; background: url("/img/trip/hotel/sp_hotel.png") no-repeat 0 -1380px; content: ""; }

.total_item .btn_more { position: absolute; left: 20px; bottom: 0; width: 268px; height: 36px; border-radius: 2px; background: #f43142; font-size: 14px; font-weight: bold; color: #fff; line-height: 36px; text-align: center; }

.total_item .btn_more:hover { text-decoration: none; }

/* 지역/명소/숙소명 레이어 */
.hotel_recomd_box { position: absolute; top: 66px; left: -19px; overflow-x: hidden; overflow-y: auto; width: 498px; max-height: 442px; border: 2px solid #464957; background-color: #fff; }

.hotel_recomd_box .list_box li:hover { background-color: #f1f2fb; }

.hotel_recomd_box .tit { margin-bottom: 9px; padding: 17px 0 6px 16px; border-bottom: 1px solid #eee; background: 0 0; color: #333; font-size: 15px; }

.hotel_recomd_box ul li { border-bottom: 0 none; }

.hotel_recomd_box li a { display: flex; width: 100%; overflow: hidden; padding: 11px 16px 12px; color: #111; box-sizing: border-box; }

.hotel_recomd_box li .txt_match { color: #f43142; }

.hotel_recomd_box li .txt { overflow: hidden; white-space: nowrap; text-overflow: ellipsis; width: 60%; font-size: 15px; }

.hotel_recomd_box li .num { width: 40%; color: #999; text-align: right; font-size: 14px; }

.listing_qucik_search .hotel_recomd_box { width: 332px; top: 54px; }

.popup_map_search .hotel_recomd_box { top: 57px; width: 455px; }

.local_box { position: relative; }

.local_box .local_name { width: 154px; }

.local_box .local_name li:hover { background-color: none; }

.local_box .local_name li .name { padding: 0; }

.local_box .list_box { position: absolute; top: 57px; left: 154px; right: 0; padding-left: 19px; }

.local_name li.selected:first-child .name { border-top: 0 none; }

.local_name li.selected .name { border-right: 0 none; background-color: #fff; color: #f43142; font-weight: 700; cursor: default; }

.local_name li .name { display: block; width: 154px; height: 48px; font-size: 15px; border-top: 1px solid #dedede; border-right: 1px solid #dedede; background-color: #fafafa; line-height: 48px; text-align: center; box-sizing: border-box; }

.local_name li .name:hover, .local_name li .name:focus, .local_name li .name:active { border-right: 0 none; background-color: #fff; color: #f43142; font-weight: 700; text-decoration: none; }

/* 배너 */
.promotion_box { width: 1200px; margin: 40px auto 0; }

.promotion_box .lk_bnr { display: block; }

.promotion_box .lk_bnr img { display: block; width: 100%; }
