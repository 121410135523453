@charset 'utf-8';

/* dim */
.formTourArea_ext .dim {
    position: fixed;
    z-index: 99998;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
}
.formTourArea_ext .dimmed {
    position: absolute;
    z-index: 90;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    background: rgba(245, 245, 245, 0.7);
}

/* layout */
.formTourArea_ext .ly_wrap,
.formTourArea_ext .ly_wrap02,
.formTourArea_ext .ly_wrap03 {
    position: absolute;
    z-index: 10;
    top: 50px;
    border: 2px solid #5477e9;
    background: #fff;
    font-family: "Malgun Gothic", "Apple SD Gothic Neo";
}
.formTourArea_ext .ly_area,
.formTourArea_ext .ly_area02 {
    position: relative;
    width: 100%;
}
.formTourArea_ext .ly_header,
.formTourArea_ext .ly_header02 {
    height: 38px;
    background: #5477e9;
}
.formTourArea_ext h1 {
    padding-left: 20px;
    color: #fff;
    font-size: 14px;
    font-weight: normal;
    line-height: 38px;
}
.formTourArea_ext h1 span.pipe {
    padding-left: 10px;
    color: #fff;
    font-size: 14px;
    font-weight: normal;
    line-height: 38px;
}
.formTourArea_ext h1 span.text,
.formTourArea_ext h1 span.info {
    padding-left: 10px;
    color: yellow;
    font-size: 12px;
    font-weight: normal;
    line-height: 38px;
}
.formTourArea_ext .ly_ctns {
    overflow: hidden;
    width: 100%;
}
.formTourArea_ext .ly_close {
    position: absolute;
    top: 7px;
    right: 15px;
    width: 22px;
    height: 22px;
    background: url("/img/trip/flight/btn/btn_ly_close.png") no-repeat center center;
}
.formTourArea_ext .ly_close a {
    display: block;
    text-indent: -9999px;
}

/* 인원 및 좌석 등급 선택 */
.formTourArea_ext .btn_area,
.formTourArea_ext .btn_area02 {
    padding: 20px 0;
    text-align: center;
}
.formTourArea_ext .btn_area a,
.formTourArea_ext .btn_area02 a {
    display: block;
    width: 90px;
    height: 35px;
    margin: 0 auto;
    padding: 0;
    background: #5477e9;
    color: #fff;
    font-size: 14px;
    line-height: 35px;
    text-align: center;
    text-decoration: none;
}
.formTourArea_ext .ly_people_seat {
    right: -1px;
    width: 515px;
} /* 수정170120 */
.formTourArea_ext .group_fare .ly_people_seat {
    width: 343px;
}
.formTourArea_ext .ly_people_seat .ly_sel_form {
    overflow: hidden;
    height: 265px;
    border-bottom: 1px solid #dadada;
}
.formTourArea_ext .ly_people_seat .ly_sel_form .tit {
    height: 40px;
    border-bottom: 1px solid #dadada;
    color: #5477e9;
    font-size: 14px;
    font-weight: bold;
    line-height: 40px;
    text-align: center;
}
.formTourArea_ext .ly_sel_form .people_select {
    float: left;
    width: 344px;
    padding-bottom: 50px;
    border-right: 1px solid #dadada;
}
.formTourArea_ext .group_fare .ly_sel_form .people_select {
    border-right: none;
}
.formTourArea_ext .ly_sel_form .num_people {
    overflow: hidden;
    padding: 17px 20px 0;
    text-align: center;
}
.formTourArea_ext .ly_sel_form .num_people li {
    float: left;
    width: 88px;
    margin: 0 0 0 20px;
}
.formTourArea_ext .ly_sel_form .num_people li:first-of-type {
    float: left;
    width: 88px;
    margin: 0;
}
.formTourArea_ext .ly_sel_form .num_people li .name {
    color: #666;
    font-size: 12px;
    text-align: center;
    text-align: left;
}
.formTourArea_ext .ly_sel_form .num_people li strong {
    display: block;
    margin-bottom: 6px;
    color: #111;
    font-size: 14px;
    font-weight: normal;
}
.formTourArea_ext .ly_sel_form .num_people .num_set {
    position: relative;
    display: block;
    width: 88px;
    height: 28px;
    margin: 9px 0 0;
}
.formTourArea_ext .ly_sel_form .num_people .num_set input {
    width: 28px;
    height: 26px;
    margin: 0 29px;
    padding: 0;
    border: 1px solid #d1d1d6;
    background: #fff;
    color: #333;
    font-family: "Malgun Gothic", "Apple SD Gothic Neo";
    font-size: 14px;
    font-weight: normal;
    line-height: 26px;
    text-align: center;
}
.formTourArea_ext .ly_sel_form .num_people .num_set .qty_minus,
.ly_sel_form .num_people .num_set .qty_plus {
    position: absolute;
    top: 0;
    overflow: hidden;
    width: 30px;
    height: 28px;
    border: 1px solid #d1d1d6;
    background: #f5f5f5;
    text-indent: -1000px;
}
.formTourArea_ext .ly_sel_form .num_people .num_set .qty_minus {
    left: 0;
    -webkit-border-radius: 2px 0 0 2px;
    -moz-border-radius: 2px 0 0 2px;
    border-radius: 2px 0 0 2px;
}
.formTourArea_ext .ly_sel_form .num_people .num_set .qty_plus {
    left: 58px;
    -webkit-border-radius: 0 2px 2px 0;
    -moz-border-radius: 0 2px 2px 0;
    border-radius: 0 2px 2px 0;
}
.formTourArea_ext .ly_sel_form .num_people .num_set .qty_minus:before,
.ly_sel_form .num_people .num_set .qty_plus:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 9px;
    height: 1px;
    margin: 0 0 0 -4px;
    background: #5477e9;
    content: "";
}
.formTourArea_ext .ly_sel_form .num_people .num_set .qty_plus:before {
    margin-left: -5px;
}
.formTourArea_ext .ly_sel_form .num_people .num_set .qty_plus:after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1px;
    height: 9px;
    margin: -4px 0 0 -1px;
    background: #5477e9;
    content: "";
}
.formTourArea_ext .ly_sel_form .num_people .num_set .qty_minus[disabled],
.ly_sel_form .num_people .num_set .qty_plus[disabled] {
    border-color: #ccc;
}
.formTourArea_ext .ly_sel_form .num_people .num_set .qty_minus[disabled]:before,
.ly_sel_form .num_people .num_set .qty_plus[disabled]:before,
.ly_sel_form .num_people .num_set .qty_plus[disabled]:after {
    background-color: #ccc;
}
.formTourArea_ext .ly_sel_form .guide {
    padding: 23px 0 0 20px;
    color: #666;
    font-size: 12px;
    line-height: 18px;
}
.formTourArea_ext .ly_sel_form .flight_class {
    float: left;
    width: 170px;
}
.formTourArea_ext .ly_sel_form .flight_class .seat {
    padding: 15px 0 0 20px;
}
.formTourArea_ext .ly_sel_form .flight_class .seat li {
    margin-top: 5px;
}
.formTourArea_ext .ly_sel_form .flight_class .seat li button {
    width: 128px;
    height: 33px;
    padding: 0 10px;
    border: 1px solid #bdbdbd;
    background: #f5f5f5;
    color: #666;
    font-size: 12px;
    text-align: left;
}
.formTourArea_ext .ly_sel_form .flight_class .seat li.on button {
    background: #f5f5f5 url("/img/trip/flight/bul/arr_9px_down_blue.gif") no-repeat 102px center;
    color: #333;
}

/* 체류기간 */
.formTourArea_ext .ly_stay {
    left: 0;
    width: 230px;
}
.formTourArea_ext .ly_stay .stay_day {
    padding: 10px 0;
}
.formTourArea_ext .ly_stay .stay_day li {
    width: 100%;
    height: 31px;
}
.formTourArea_ext .ly_stay .stay_day li button {
    display: block;
    width: 100%;
    height: 31px;
    padding-left: 20px;
    border: none;
    background: none;
    color: #666;
    font-size: 12px;
    line-height: 31px;
    text-align: left;
}
.formTourArea_ext .ly_stay .stay_day li button.on {
    background: #5477e9;
    color: white;
}

/* 오픈여정 검색 숨김 2020.06 */
#notConfirmed {
    display: none !important;
}

/* 도시선택 */
.formTourArea_ext .reserve_search .inbox .input_form {
    position: absolute;
    top: 1px;
    left: 0;
}
.formTourArea_ext .reserve_search .inbox .input_form input {
    width: 167px;
    height: 26px;
    margin-left: 1px;
    padding: 11px 10px 9px;
    border: none;
    color: #333;
    font-size: 14px;
}
.formTourArea_ext .quick_search .inbox .input_form {
    position: absolute;
    top: 1px;
    left: 0;
}
.formTourArea_ext .quick_search .inbox .input_form input {
    width: 123px;
    height: 26px;
    margin-left: 36px;
    padding: 11px 0 9px;
    border: none;
    color: #333;
    font-size: 14px;
}
.formTourArea_ext .city_list {
    overflow-x: hidden;
    overflow-y: auto;
}
.formTourArea_ext .city_list table {
    width: 100%;
}
.formTourArea_ext .city_list table th {
    height: 34px;
    padding: 0 0 0 15px;
    border-bottom: 1px solid #dadada;
    background: #e6edfd;
    color: #333;
    font-size: 14px;
    font-weight: normal;
    text-align: left;
}
.formTourArea_ext .city_list table td {
    padding: 5px 2px;
    border-bottom: 1px solid #dadada;
    font-size: 12px;
}
.formTourArea_ext .city_list table td:first-of-type {
    padding-left: 15px;
}
.formTourArea_ext .city_list table td:last-of-type {
    padding-right: 20px;
}
.formTourArea_ext .city_list table td a {
    display: block;
    color: #666;
    text-decoration: none;
}
.formTourArea_ext .city_list table tr:last-of-type td {
    border-bottom: none;
}
.formTourArea_ext .city_list ul li {
    position: relative;
    border-bottom: 1px solid #e3e3e8;
}
.formTourArea_ext .city_list ul li a {
    display: block;
    padding: 18px 0 18px 20px;
    text-decoration: none;
}
.formTourArea_ext .city_list ul li:last-of-type {
    border-bottom: none;
}
.formTourArea_ext .city_list ul li.no_search {
    padding: 18px 0 18px 0;
    text-align: center;
}
.formTourArea_ext .city_list ul li dt {
    color: #333;
    font-size: 12px;
}
.formTourArea_ext .city_list ul li .highlight {
    margin: 0;
    padding: 0;
    font-weight: bold;
    color: #536dfe;
}
.formTourArea_ext .city_list ul li dt .en {
    margin-left: 1px;
}
.formTourArea_ext .city_list ul li dd {
    margin-top: 5px;
    font-size: 12px;
}
.formTourArea_ext .city_list ul li dd span {
    position: relative;
    padding: 0 6px 0 0;
    background: url("/img/trip/flight/bul/bul_12px_808080.gif") no-repeat right 3px;
}
.formTourArea_ext .city_list ul li dd span:last-of-type {
    margin: 0;
    padding: 0;
    background: none;
}

/* 달력 */
.formTourArea_ext .ly_select_date {
    left: -1px;
    width: 760px;
} /* 수정170120 */
.formTourArea_ext .ly_select_date .cal_wrap {
    overflow: hidden;
    padding: 20px;
}
.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal {
    float: left;
    width: 350px;
    margin-right: 20px;
    border: 1px solid #f5f5f5;
    border-width: 0 1px 0 0;
}
.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal:last-of-type {
    margin-right: 0;
}
.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal caption {
    position: relative;
    height: 56px;
    border: 1px solid #f5f5f5;
    background: #fafafa;
    color: #333;
    font-family: "Tahoma";
    font-size: 22px;
    line-height: 56px;
}
.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal caption .btn_prev {
    position: absolute;
    top: 0;
    left: 0;
    width: 47px;
    height: 56px;
    background: url("/img/trip/flight/bul/arr_11px_left_666.gif") no-repeat center center;
    color: transparent;
    font-size: 11px;
}
.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal caption .btn_next {
    position: absolute;
    top: 0;
    right: 0;
    width: 47px;
    height: 56px;
    background: url("/img/trip/flight/bul/arr_11px_right_666.gif") no-repeat center center;
    color: transparent;
    font-size: 11px;
}
.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal thead th {
    height: 28px;
    padding: 0;
    border-bottom: 1px solid #dadada;
    font-size: 12px;
    font-weight: normal;
    text-align: center;
}
.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal thead th:first-of-type {
    border-left: 1px solid #f5f5f5;
}
.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal td {
    position: relative;
    height: 52px;
    padding: 0;
    border-bottom: 1px solid #f5f5f5;
    border-left: 1px solid #f5f5f5;
    color: #666;
    text-align: center;
}
.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal td a {
    display: block;
    min-height: 15px;
    padding: 5px 0 32px 0;
    color: #666;
    text-decoration: none;
}
.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal td span {
    display: block;
    min-height: 15px;
    padding: 5px 0 32px 0;
}
.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal td .today {
    position: absolute;
    z-index: 5;
    top: -1px;
    left: -1px;
    width: 100%;
    border: 1px solid #2d52cb;
    background: #504f98;
    color: #fff;
}
.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal td .start {
    position: absolute;
    z-index: 5;
    top: -1px;
    left: -1px;
    width: 100%;
    border: 1px solid #2d52cb;
    background: #5477e9;
    color: #fff;
}
.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal td .middle {
    position: absolute;
    z-index: 5;
    top: -1px;
    left: -1px;
    width: 100%;
    border: 1px solid #2d52cb;
    background: #5477e9;
    color: #fff;
}
.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal td .end {
    position: absolute;
    z-index: 5;
    top: -1px;
    left: -1px;
    width: 100%;
    border: 1px solid #2d52cb;
    background: #5477e9;
    color: #fff;
}
.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal td .selected {
    position: absolute;
    z-index: 4;
    top: -1px;
    left: 0;
    width: 100%;
    border: 1px solid #dde4f3;
    background: #e6edfd;
}
.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal td .txt {
    position: absolute;
    z-index: 6;
    right: 0;
    bottom: 5px;
    left: 0;
    margin: 0 auto;
    color: #fff;
    font-size: 11px;
}
.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal .disable {
    color: #999;
}
.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal .sat {
    color: #5477e9;
}
.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal .holiday {
    color: #f43142;
}

/* new메인추가 (170118) */

.formTourArea_ext .reserve_search .inbox .ico {
    z-index: -1;
    display: inline-block;
    vertical-align: middle;
    text-indent: -9999px;
}
.formTourArea_ext .reserve_search .inbox .ico_not {
    z-index: -1;
    display: inline-block;
    background: url("/img/trip/flight/ico/sp_quick_search.png") no-repeat left top;
    vertical-align: middle;
    text-indent: -9999px;
}
.formTourArea_ext .reserve_search .inbox a[role="button"] {
    display: table-cell;
    padding-left: 10px;
    color: #333;
    font-size: 14px;
    text-align: left;
    vertical-align: middle;
    text-decoration: none;
}

.formTourArea_ext .reserve_search .inbox.area .ico {
    width: 16px;
    height: 20px;
    margin-right: 9px;
    background-position: left -26px;
}
.formTourArea_ext .reserve_search .inbox.area .txt {
    display: inline-block;
    vertical-align: middle;
    line-height: 14px;
}
.formTourArea_ext .reserve_search .inbox.area .txtmd {
    display: inline-block;
    vertical-align: -1px;
    color: #999999;
}
.formTourArea_ext .reserve_search .inbox.date {
    width: 127px;
} /* 날짜 선택 */
.formTourArea_ext .reserve_search .inbox.date .txt {
    display: inline-block;
    vertical-align: middle;
}
.formTourArea_ext .reserve_search .inbox.md {
    width: 148px;
} /* 다구간날짜 선택 */
.formTourArea_ext .reserve_search .inbox.period {
    width: 127px;
    background: #fff url("/img/trip/flight/bul/arr_6px_down_333.gif") no-repeat 107px center;
} /* 기간 선택 */
.formTourArea_ext .reserve_search .inbox.period a.on {
    color: #536dfe;
    font-weight: bold;
}
.formTourArea_ext .reserve_search .inbox.date .ico {
    width: 21px;
    height: 20px;
    margin-right: 9px;
    background-position: -50px -72px;
}
.formTourArea_ext .reserve_search .inbox.md .ico {
    width: 21px;
    height: 20px;
    margin-right: 9px;
    background-position: -50px -72px;
}
.formTourArea_ext .reserve_search .inbox.date .ico_not {
    width: 21px;
    height: 20px;
    margin-right: 9px;
    background-position: -25px -72px;
}
.formTourArea_ext .reserve_search .inbox.set {
    width: 335px;
} /* 인원 및 좌석 선택 */
.formTourArea_ext .reserve_search .inbox.set .ico {
    float: left;
    width: 15px;
    height: 20px;
    margin-right: 9px;
    background-position: 0px -95px;
}
.formTourArea_ext .reserve_search .inbox.set .txt {
    float: left;
    margin-right: 19px;
    padding-right: 10px;
    background: url("/img/trip/flight/main/bg_inbox.gif") no-repeat right 5px;
}
.formTourArea_ext .reserve_search .inbox.set .txt:last-of-type {
    margin-right: 0;
    background: none;
}
.formTourArea_ext .reserve_search .fl_option .del {
    position: absolute;
    top: 0;
    right: 0;
    display: table;
    width: 48px;
    height: 48px;
    border-left: 1px solid #dadada;
}
.formTourArea_ext .reserve_search .fl_option .del a {
    display: table-cell;
    width: 48px;
    vertical-align: middle;
}
.formTourArea_ext .reserve_search .fl_option .del a span {
    display: block;
    width: 11px;
    height: 11px;
    margin: 0 auto;
    background: url("/img/trip/flight/ico/sp_quick_search.png") no-repeat -24px -14px;
    vertical-align: middle;
    text-indent: -9999px;
}
.formTourArea_ext .reserve_search .fl_option .btn_search {
    float: left;
    width: 132px;
    height: 49px;
    margin-top: 21px;
    background: #6c60f0;
    font-size: 16px;
    line-height: 50px;
    text-align: center;
}
.formTourArea_ext .reserve_search .fl_option .btn_search a {
    display: block;
    color: #fff;
    text-decoration: none;
}
.formTourArea_ext .reserve_search .fl_option .ckbox {
    position: relative;
    float: left;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 90px;
    height: 35px;
    margin-left: -1px;
    padding-top: 16px;
    border: 1px solid #dadada;
    text-align: center;
}
.formTourArea_ext .reserve_search .fl_option .ckbox .ck_box {
    vertical-align: middle;
}
.formTourArea_ext .reserve_search .fl_option .ckbox .label_txt {
    color: #333;
    font-size: 14px;
}
.formTourArea_ext .reserve_search .fl_option .trip_section {
    clear: both;
    padding-top: 10px;
}
.formTourArea_ext .reserve_search .fl_option .trip_section a {
    display: inline-block;
    padding-left: 12px;
    margin-left: 12px;
    background: url("/img/trip/flight/main/bg_trip_section.gif") no-repeat left 2px;
    color: #333333;
}
.formTourArea_ext .reserve_search .fl_option .trip_section02 {
    clear: both;
    padding: 10px 0 0 126px;
}

/* 다구간 */
.formTourArea_ext .mdtype {
    margin-top: 16px;
}
.formTourArea_ext .mdtype .ply {
    margin-top: 0 !important;
}
.formTourArea_ext .mdtype a.closed {
    display: inline-block;
    margin-top: 8px;
}
.formTourArea_ext a.planadd {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 168px;
    padding: 14px 0;
    border: 1px solid #d4d4d4;
    background: #fff; /* color:#d4d4d4; */
    text-align: center;
    font-size: 14px;
}
.formTourArea_ext a.planadd em {
    display: inline-block;
    padding-left: 22px;
    background: url("/img/trip/flight/main/ico_planadd.gif") no-repeat left 3px;
}
.formTourArea_ext .ml0 {
    margin-left: 0 !important;
}

/************* 여행메인 항공퀵서치 통합 tour_extend 마이그레이션 20170417 eslee ************/
/*common*/
.formTourArea_ext .mr20 {
    margin-right: 20px;
}
.formTourArea_ext label span {
    font-size: 13px;
}
.formTourArea_ext .reserve_search .inbox .input_form input {
    width: 155px;
    height: 19px;
    margin-left: 1px;
    padding: 6px 10px 5px;
    border: none;
    color: #333;
    font-size: 14px;
}
.formTourArea_ext .reserve_search .inbox .input_form input:focus {
    outline: none;
}
.formTourArea_ext span.txt.on {
    font-weight: bold;
    color: #536dfe !important;
}

/* checkbox custom */
.formTourArea_ext input[type="checkbox"] {
    overflow: hidden;
    width: 0;
    height: 0;
    margin: 0;
    border: none;
    font-size: 0;
    line-height: 0;
    visibility: hidden;
}

.formTourArea_ext .ckbox label,
.formTourArea_ext .trip_section label,
.formTourArea_ext .trip_section02 label {
    position: absolute;
    top: 9px;
    left: 0;
    height: 18px;
    padding: 2px 0 0 22px;
    background: url("/img/trip/flight/tour/sp_trip_input.png") no-repeat 0 -40px;
    font-size: 12px;
    line-height: 12px;
    cursor: pointer;
}

.formTourArea_ext .ckbox input[type="checkbox"]:checked + label,
.formTourArea_ext .trip_section input[type="checkbox"]:checked + label,
.formTourArea_ext .trip_section02 input[type="checkbox"]:checked + label {
    background: url("/img/trip/flight/tour/sp_trip_input.png") no-repeat 0 -60px;
    color: #5770fe !important;
    font-weight: bold;
}

.formTourArea_ext .ckbox label {
    left: 21px;
}
.formTourArea_ext .trip_section02 label {
    left: 160px;
}

.formTourArea_ext .reserve_search .fl_option .trip_section .tip_label {
    padding-top: 1px;
}
.formTourArea_ext .reserve_search .fl_option .trip_section .tip_label span.label_name {
    font-size: 11px;
    font-weight: 500;
    color: #333333;
}
.formTourArea_ext .reserve_search .fl_option .trip_section .tip_label span.label_tip::before {
    content: "";
    border-left: 1px solid #cccccc;
    display: inline-block;
    margin: 0px 6px 0 5px;
    height: 9px;
}
.formTourArea_ext .reserve_search .fl_option .trip_section .tip_label span.label_tip {
    font-size: 12px;
    color: #333333;
}
.formTourArea_ext .reserve_search .fl_option .trip_section input[type="checkbox"]:checked + label span.label_name {
    font-weight: bold;
    color: #5770fe;
}
.formTourArea_ext .reserve_search .fl_option .trip_section input[type="checkbox"]:checked + label span.label_tip {
    font-weight: normal;
    color: #333333;
}

/*여정검색 섹션*/
.formTourArea_ext input[type="radio"],
.formTourArea_ext input[type="checkbox"] {
    margin: 5px 4px 0 0 !important;
}
.formTourArea_ext {
    padding: 0px 24px 0px 30px;
}
.formTourArea_ext .tabbox {
    margin: -2px 0 12px;
    overflow: hidden;
}
.formTourArea_ext .tabbox ul.tabs {
    float: left;
}
.formTourArea_ext .tabbox ul.tabs li {
    position: relative;
    float: left;
    height: 30px;
    background: none;
    text-align: left;
    margin-right: 1px;
    padding-right: 46px;
    cursor: pointer;
}
.formTourArea_ext .tabbox ul.tabs li:before {
    content: "";
    position: absolute;
    top: 6px;
    left: 0px;
    width: 16px;
    height: 16px;
    background: url("/img/trip/flight/tour/bu_radio_main.png") no-repeat 0 -16px;
}
.formTourArea_ext .tabbox ul.tabs li a {
    display: block;
    width: 100%;
    font-size: 16px;
    padding: 0px 0 0px 0;
    font-size: 18px;
    color: #000;
}
.formTourArea_ext .tabbox ul.tabs li a span {
    display: inline-block;
    padding-left: 20px;
}
.formTourArea_ext .tabbox ul.tabs li.on:before {
    background-position: 0px 0px;
}
.formTourArea_ext .tabbox ul.tabs li.on a {
    display: block;
    width: 100%;
    color: #5770fe;
    font-weight: bold;
}
.formTourArea_ext .tabbox ul.tabs li.on a span.tabico01 {
    background: none;
}

.formTourArea_ext .reserve_search {
    position: relative;
    padding: 0px 0px 10px 0px;
    background: none;
}
.formTourArea_ext .reserve_search .inbox {
    position: relative;
    display: table;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    margin-left: -1px;
    background: #fff;
    width: 178px;
    height: 35px;
    border: 1px solid #cdcdcd;
}
.formTourArea_ext .reserve_search .inbox.on {
    position: relative;
    z-index: 10;
    border: 1px solid #5477e9;
}

.formTourArea_ext .reserve_search .inbox.disabled {
    background-color: #f8f8f8;
    border-color: #cccccc;
}
.formTourArea_ext .reserve_search .inbox.disabled a[role="button"] {
    color: #bbbbbb;
    cursor: default;
    cursor: not-allowed;
}
.formTourArea_ext .reserve_search .inbox.disabled a[role="button"] span.txt.on {
    color: #bbbbbb !important;
}

.formTourArea_ext .reserve_search .inbox.period {
    width: 160px;
    background-position: 135px center;
}
.formTourArea_ext .reserve_search .inbox.date,
.formTourArea_ext .reserve_search .inbox.md {
    width: 160px;
}
.formTourArea_ext .reserve_search .inbox.date .ico {
    float: right;
    width: 21px;
    height: 20px;
    margin-right: 9px;
    background: url("/img/trip/flight/tour/ico_cal_main.png") no-repeat 50% 50%;
    margin-top: 1px;
}
.formTourArea_ext .reserve_search .inbox.date .ico_not {
    float: right;
    width: 21px;
    height: 20px;
    margin-right: 9px;
    background: url("/img/trip/flight/tour/ico_cal_main.png") no-repeat 50% 50%;
    margin-top: 1px;
}
.formTourArea_ext .reserve_search .inbox.md .txt {
    vertical-align: 2px;
}
.formTourArea_ext .reserve_search .inbox.md .ico {
    float: right;
    width: 21px;
    height: 20px;
    margin-right: 9px;
    background: url("/img/trip/flight/tour/ico_cal_main.png") no-repeat 50% 50%;
    margin-top: 1px;
}
.formTourArea_ext .reserve_search .inbox.set {
    width: 268px;
}
/* .formTourArea_ext .reserve_search .inbox.set .txt:first-child {position:relative;margin-right:10px;padding-right:30px;background:none;} */
.formTourArea_ext .reserve_search .inbox.set .txt:first-child {
    position: relative;
    margin-right: 10px;
    padding-right: 13px;
    min-inline-size: 55px;
    background: none;
}
.formTourArea_ext .reserve_search .inbox.set .txt:first-child::after {
    content: "";
    position: absolute;
    top: -6px;
    right: 0px;
    width: 1px;
    height: 35px;
    background-color: #ccc;
}
.formTourArea_ext .reserve_search .fl_option {
    position: relative;
    z-index: 10;
}
.formTourArea_ext .reserve_search .fl_option:after {
    clear: both;
    display: block;
    content: "";
}
.formTourArea_ext .reserve_search .fl_option > li {
    float: left;
    margin-top: 10px;
}
.formTourArea_ext .reserve_search .fl_option > li:first-of-type {
    margin-top: 0;
    width: 100%;
}
.formTourArea_ext .reserve_search .fl_option .selform {
    width: 100%;
    float: left;
}
.formTourArea_ext .reserve_search .fl_option .selform .fildbox {
    float: left;
}
.formTourArea_ext .reserve_search .fl_option .selform .fildbox .field {
    float: left;
}
.formTourArea_ext .reserve_search .fl_option .selform .fildbox strong.fieldtit {
    display: none;
    margin-bottom: 6px;
    text-align: left;
}
.formTourArea_ext .reserve_search .fl_option .selform .fildbox .ply {
    display: table;
    height: 10px;
    float: left;
    margin: 0px 0 0 -1px;
    border: 1px solid #dadada;
    padding: 8px 10px 4px 11px;
    background: #fff;
}
.formTourArea_ext .reserve_search .fl_option .btn_search {
    width: 120px;
    height: 35px;
    margin-top: 0px;
    font-weight: bold;
    line-height: 35px;
    background: #273259;
    float: right;
    margin-right: 7px;
}
.formTourArea_ext .reserve_search .fl_option .btn_search.long_text {
    font-size: 11px;
}
.formTourArea_ext .reserve_search[data-val="MT"] .fl_option .btn_search {
}
.formTourArea_ext .reserve_search .fl_option .trip_section {
    position: relative;
    padding-top: 5px;
}
.formTourArea_ext .reserve_search .fl_option .trip_section a {
    position: relative;
    margin-left: 50px;
    font-size: 13px;
    background: none;
}
.formTourArea_ext .reserve_search .fl_option .trip_section a::before {
    content: "";
    position: absolute;
    top: 7px;
    left: 0px;
    width: 1px;
    height: 7px;
    background: #ccc;
}
.formTourArea_ext .reserve_search .fl_option .trip_section02 {
    position: relative;
    clear: both;
    padding: 3px 0 0 159px;
}
@-moz-document url-prefix() {
    .formTourArea_ext .reserve_search .fl_option .trip_section {
        padding-top: 3px;
    }
    .formTourArea_ext .reserve_search .fl_option .trip_section a {
        margin-left: 35px;
    }
    .formTourArea_ext .reserve_search .fl_option .trip_section a::before {
        top: 8px;
    }
} /*firefox hack*/
.formTourArea_ext .mdtype {
    margin-top: 15px;
}
.formTourArea_ext .mdtype a.closed {
    margin-top: 0px;
    margin-left: 10px;
}
.formTourArea_ext a.planadd {
    bottom: 0;
    right: 7px;
    width: 118px;
    height: 28px;
    padding: 5px 0 0;
    border: 1px solid #273258;
}
.formTourArea_ext a.planadd em {
    display: inline-block;
    padding-left: 22px;
    font-weight: bold;
    color: #273259;
    background: url("/img/trip/flight/tour/ico_plan_add_main.png") no-repeat 5px 5px;
    margin-top: 1px;
}

.formTourArea_ext .recentSearchArea {
    padding: 10px 0px 0px;
    border-top: 1px solid #f4f4f4;
}
.formTourArea_ext .recentSearchArea::after {
    content: "";
    clear: both;
    display: block;
}
.formTourArea_ext h3.tit {
    float: left;
    margin-right: 5px;
    color: #333;
}
.formTourArea_ext .pick {
    float: left;
}
.formTourArea_ext .pick a {
    color: #6483dc;
    font-size: 12px;
}
.formTourArea_ext .pick a:before {
    content: "(";
}
.formTourArea_ext .pick a:after {
    content: ")";
}
.formTourArea_ext .pick span {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-top: -3px;
    text-indent: -9999px;
    line-height: 12px;
    background: url("/img/trip/flight/tour/bu_pick_main.png") no-repeat 50% 50%;
}
.formTourArea_ext ul.list {
    float: right;
    width: 1065px;
}
.formTourArea_ext ul.list li {
    position: relative;
    float: left;
    font-size: 12px;
}
.formTourArea_ext ul.list > li {
    margin-bottom: 10px;
    margin-right: 30px;
}
.formTourArea_ext ul.list li div {
    float: left;
}
.formTourArea_ext ul.list li .etc {
    position: relative;
    padding-right: 10px;
}
.formTourArea_ext ul.list li .etc span {
    position: relative;
    padding: 0px 10px;
}
.formTourArea_ext ul.list li .etc span::before {
    content: "";
    position: absolute;
    top: 5px;
    left: 0;
    width: 1px;
    height: 10px;
    background-color: #ccc;
}
.formTourArea_ext ul.list li span.reserico {
    display: none;
}
.formTourArea_ext ul.list li ul {
    margin-right: 10px;
}
.formTourArea_ext ul.list li ul li {
    font-weight: bold;
}
.formTourArea_ext ul.list li ul li.ico {
    display: inline-block;
    width: 25px;
    height: 20px;
    background: url("/img/trip/flight/tour/ico_flight_route.png") no-repeat 50% 4px;
}
.formTourArea_ext ul.list li ul li.ico02 {
    background-position: 50% -22px;
}
.formTourArea_ext ul.list li[role="recentSearch"] a {
    position: absolute;
    top: 3px;
    right: 0;
    width: 15px;
    height: 15px;
    font-size: 0;
    background: url("/img/trip/flight/tour/btn_del_recent_search_area.png") no-repeat 50% 50%;
}

/* 캘린더 레이어 */
.formTourArea_ext .ly_select_date {
    left: 0px;
    width: 740px;
}
.formTourArea_ext #RTDtm .ly_select_date {
    left: -159px;
}
.formTourArea_ext .ly_wrap,
.formTourArea_ext .ly_wrap02,
.formTourArea_ext .ly_wrap03 {
    top: 40px;
    border: 2px solid #48494e;
}
.formTourArea_ext .ly_header,
.formTourArea_ext .ly_header02 {
    height: 38px;
    background: #474958;
}
.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal {
    width: 350px;
    margin-right: -1px;
    border: 1px solid #d5d5d5;
    border-width: 0px 1px 1px 1px;
}
/* 2018-01-30 MAC 사파리 달력 사라짐 수정 */
.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal caption {
    height: 45px;
    border: 1px solid #d5d5d5;
    border-bottom: none;
    background: #fff;
    color: #333;
    font-size: 16px; /* font-weight:bold; */
}
.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal caption .btn_prev {
    top: 10px;
    left: 10px;
    width: 32px;
    height: 32px;
    background: url("/img/trip/flight/tour/btn_cal_arrw_main.png") 0px 0px no-repeat;
}
.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal caption .btn_next {
    top: 10px;
    right: 10px;
    width: 32px;
    height: 32px;
    background: url("/img/trip/flight/tour/btn_cal_arrw_main.png") 0px -32px no-repeat;
}
.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal thead th {
    height: 28px;
    padding: 0;
    border-bottom: 1px dotted #dadada;
    font-size: 14px;
    font-weight: bold;
}
.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal td {
    height: 50px;
    border-color: #eee;
}
.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal thead th:first-of-type,
.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal td:first-child {
    border-left: none;
}
.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal tr:last-child td {
    border-bottom: none;
}
.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal td span {
    padding: 5px 0 23px 0;
    font-size: 16px;
}
.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal td span.disable {
    background-color: #f8f8f8;
}
.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal td a {
    padding: 5px 0 23px 0;
    font-size: 16px;
}
.formTourArea_ext .ly_close {
    background: url("/img/trip/flight/tour/btn_close_layer_main.png") no-repeat center center;
}
.formTourArea_ext .ly_select_date .cal_wrap .tbl_cal td .today {
    background-color: #504f98 !important;
}
.formTourArea_ext .reserve_search.multi_search .ly_select_date {
    left: -156px;
} /*다구간캘린더위치*/
.formTourArea_ext .reserve_search.multi_search .inbox.md {
    width: 230px;
}

/* 좌석 레이어 */
.formTourArea_ext .ly_people_seat .ly_sel_form .tit {
    color: #333;
}
.formTourArea_ext .ly_sel_form .flight_class .seat li button {
    border: 1px solid #ccc;
    background: #fff;
}
.formTourArea_ext .ly_sel_form .flight_class .seat li.on button {
    background: #fff url("/img/trip/flight/tour/bu_selected_main.png") no-repeat 102px center;
    color: #5770fe;
    font-weight: bold;
    border: 1px solid #333;
}
.formTourArea_ext .btn_area a,
.formTourArea_ext .btn_area02 a {
    background: #2c3768;
}
.formTourArea_ext h1 {
    font-size: 16px;
    line-height: 33px;
}
.formTourArea_ext .ly_sel_form .num_people .num_set .qty_minus::before,
.formTourArea_ext .ly_sel_form .num_people .num_set .qty_plus::before,
.formTourArea_ext .ly_sel_form .num_people .num_set .qty_plus::after {
    background: #999;
}

/* 도시선택 레이어 */
.formTourArea_ext .city_list table th {
    font-weight: bold;
    color: #333;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    background: #fafafa;
}
.formTourArea_ext .city_list table td a:hover {
    color: #335fe4;
    font-weight: bold;
    text-decoration: underline;
}
.formTourArea_ext h1 span.text,
.formTourArea_ext h1 span.info {
    float: right;
    margin-right: 50px;
    margin-top: -2px;
    color: #fff;
}
.formTourArea_ext .reserve_search .inbox.area h1 span.text i.ico {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 5px;
    margin-bottom: 3px;
    background: url("/img/trip/flight/tour/bu_caution_main.png") 50% 50% no-repeat;
}
.formTourArea_ext .ly_city {
    left: -1px;
    width: 600px;
}
.formTourArea_ext .ly_city.ly_city_major {
    width: 1008px;
}
.formTourArea_ext .ly_city.ly_city_major.x_major_area_count_8 {
    width: 1008px;
}
.formTourArea_ext .field_arrv .ly_city.ly_city_major.x_major_area_count_8 {
    left: -70px;
}
.formTourArea_ext .ly_city.ly_city_major.x_major_area_count_7 {
    width: 882px;
}
.formTourArea_ext .ly_city.ly_city_major.x_major_area_count_6 {
    width: 756px;
}
.formTourArea_ext .ly_city.ly_city_major.x_major_area_count_5 {
    width: 630px;
}
.formTourArea_ext .ly_city.ly_city_major.x_major_area_count_4 {
    width: 504px;
}
.formTourArea_ext .ly_city.ly_city_major.x_major_area_count_3 {
    width: 378px;
}
.formTourArea_ext .ly_city.ly_city_major.x_major_area_count_2 {
    width: 378px;
}
.formTourArea_ext .ly_city.ly_city_major.x_major_area_count_1 {
    width: 378px;
}
.formTourArea_ext .ly_city.ly_city_major.x_major_area_count_0 {
    width: 378px;
}
.formTourArea_ext ul.major_city::after {
    content: "";
    clear: both;
    display: block;
}
.formTourArea_ext ul.major_city li {
    float: left;
    width: 110px;
    padding: 10px 0 0px 15px;
    color: #273259;
    font-weight: bold;
    border-right: 1px solid #e9e9e9;
    border-bottom: none;
    background-color: #fafafa;
} /* #FAFAFA */
.formTourArea_ext .ly_city.ly_city_major.x_major_area_count_2 ul.major_city li {
    width: 173px;
}
.formTourArea_ext .ly_city.ly_city_major.x_major_area_count_1 ul.major_city li {
    width: 362px;
}
.formTourArea_ext ul.major_city li ul {
    width: 100%;
    height: 260px;
    margin: 10px 0 0px -15px;
    padding: 5px 15px 10px 0px;
    border-top: 1px solid #ccc;
    border-right: 1px solid #e9e9e9;
    background-color: #fff;
}
.formTourArea_ext .group_fare ul.major_city li ul {
    height: 500px;
}
.formTourArea_ext ul.major_city li ul::after {
    content: "";
    clear: both;
    display: block;
}
.formTourArea_ext ul.major_city li ul li {
    padding: 0 0 0 0;
    height: initial;
    border-bottom: none;
    border-right: none;
    background-color: #fff;
}
.formTourArea_ext ul.major_city li ul li a {
    max-width: 110px;
    padding: 4px 0px 4px 15px;
    font-size: 12px;
    font-weight: normal;
    background-color: #fff;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.formTourArea_ext ul.major_city li ul li a:hover {
    color: #335fe4;
    font-weight: bold;
    text-decoration: underline;
}
.formTourArea_ext .city_list ul[role="airportList"] {
    max-height: 389px;
    overflow-y: scroll;
}
.formTourArea_ext .city_list ul[role="airportList"] li a {
    padding: 10px 0 10px 20px;
}
.formTourArea_ext .city_list ul[role="airportList"] li:hover {
    background-color: #eef5ff;
}
.formTourArea_ext .city_list ul[role="airportList"] li dl {
    width: 560px;
}
.formTourArea_ext .city_list ul[role="airportList"] li dl::after {
    content: "";
    clear: both;
    display: block;
}
.formTourArea_ext .city_list ul[role="airportList"] li dt {
    float: left;
}
.formTourArea_ext .city_list ul[role="airportList"] li dd {
    float: right;
    margin-right: 20px;
    margin-top: 0px;
}

/* 스크롤 불필요, 숨김 */
.formTourArea_ext .city_list ul[role="airportList"] {
    overflow-y: hidden;
}
.formTourArea_ext .city_list ul[role="airportList"] li dl {
    width: 100%;
}

/* 땡처리 */
.formTourArea_ext .reserve_search.group_fare .inbox.set .txt:first-child::after {
    background: none;
}
.formTourArea_ext .reserve_search.group_fare .search-info {
    padding-top: 8px;
    font-size: 13px;
    height: 18px;
}
.formTourArea_ext .reserve_search.group_fare .search-info .red {
    color: #ff0000;
}
.formTourArea_ext .reserve_search.group_fare h1 span.info img {
    display: none;
}
.formTourArea_ext .reserve_search.group_fare h1 span.info:before {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 5px;
    margin-bottom: -2px;
    background: url("/img/trip/flight/tour/bu_caution_main.png") 50% 50% no-repeat;
    content: "";
}
.formTourArea_ext .reserve_search.group_fare .ly_wrap02 .city_list ul li {
    color: #273259;
    background-color: #fff;
}
.formTourArea_ext .reserve_search.group_fare .ly_wrap02 .city_list ul li:first-of-type {
    border-bottom: 1px solid #ccc;
    background-color: #fafafa;
    padding: 10px 15px;
    font-weight: bold;
}
.formTourArea_ext .reserve_search.group_fare .ly_wrap02 .city_list ul li table td {
    width: 25%;
}
.formTourArea_ext .reserve_search.group_fare .ly_wrap02 .city_list ul li table td a {
    padding: 15px;
    text-align: center;
}
.formTourArea_ext .reserve_search.group_fare .ly_wrap02 .city_list ul li table td {
    padding: 0;
}
.formTourArea_ext .reserve_search.group_fare .ly_wrap02.ly_city {
    width: 500px;
}
